<div *screenSizes="let size" class="grid grid-flow-row auto-rows-max gap-2 bg-white rounded-lg p-4 border md:border-0 md:shadow">
  <div class="flex flex-row gap-2 md:pr-20">
    <svc-user-avatar [info]="userAvatar"></svc-user-avatar>
    <div (click)="openPostFormModal($event, 'text')" class="flex flex-auto pl-4 w-4/5 items-center cursor-pointer border-2 border-[#D0D5DD] rounded-b-3xl rounded-t-3xl hover:bg-slate-100" id="post-open-form-text">
      <span class="text-[#DEDEDE] font-light">
        {{'Iniciar uma publicação' | transloco}}
      </span>
    </div>
  </div>

  <div class="flex flex-row gap-2">

    <button
      class="text-primary action-button"
      mat-flat-button
      (click)="openPostFormModal($event, 'photo')"
      id="post-open-form-photo"
    >
      <mat-icon
        class="text-current icon-size-5"
        svgIcon="heroicons_solid:photo"
      ></mat-icon>
      <span class="ml-2">{{ 'Foto' | transloco }}</span>
    </button>

    <button
      class="text-primary action-button"
      mat-flat-button
      (click)="openPostFormModal($event, 'video')"
      id="post-open-form-video"
    >
      <mat-icon
        class="text-current icon-size-5"
        svgIcon="heroicons_solid:film"
      ></mat-icon>
      <span class="ml-2">{{ 'Vídeo' | transloco }}</span>
    </button>

  </div>

</div>

