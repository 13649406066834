import {
	Component,
	OnInit,
	HostBinding,
	ContentChildren,
	QueryList,
	Input,
	ElementRef,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	ViewChild,
	EventEmitter,
	Output,
	Renderer2,
	Inject,
	forwardRef,
	AfterContentInit,
	ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcDataVirtualScrollService } from '../services/svc-data-virtual-scroll.service';
import { SvcFunctionsHelper, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';
import { SvcDataRowComponent } from '../svc-data-row/svc-data-row.component';

@Component({
	selector: 'svc-data-body',
	templateUrl: './svc-data-body.component.html',
	styleUrls: ['./svc-data-body.component.scss']
})
export class SvcDataBodyComponent implements OnChanges, AfterContentInit, OnDestroy {
	@HostBinding('class.svc-data-body') public class: boolean = true;
	@HostBinding('class.clickable') public clickable: boolean = false;
	@HostBinding('class.hoverable') public hoverable: boolean = false;

	@ViewChild('itemsContainer', { static: false }) public itemsContainerElRef: ElementRef<HTMLElement>;

	@ContentChildren(SvcDataRowComponent) private queryRows: QueryList<SvcDataRowComponent>;
	@ViewChildren(SvcDataRowComponent) private querySkeletonRows: QueryList<SvcDataRowComponent>;

	public minHeight: number = 40;

	@Input('row-height') rowHeight: string = this.minHeight + 'px';
	@Input('drag-and-reorder') dragAndReorder: boolean = false;

	@Output() public onDragReorder = new EventEmitter<any>();

	public get rows() { return this.queryRows?.toArray() ?? []; }
	public get skeletonRows() { return this.querySkeletonRows?.toArray() ?? []; }
	public get el() { return this.elRef?.nativeElement; }
	public get itemsContainerEl() { return this.itemsContainerElRef?.nativeElement; }

	private subscription: Subscription;

	protected skeletonRowsLength = 6;

	constructor(
		@Inject(forwardRef(() => SvcDataTableComponent))
		public parent: SvcDataTableComponent,
		private helper: SvcFunctionsHelper,
		private dataVirtualScrollService: SvcDataVirtualScrollService,
		public elRef: ElementRef<HTMLElement>,
	) {
	}

	public ngAfterContentInit(): void {
		this.onRender();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('dragAndReorder' in changes) {
			const value = this.dragAndReorder;
			this.dragAndReorder = typeof value === 'string' && (value === '' || value === 'true') ? true : value;
			this.rows.forEach(x => {
				x.onDraggableChange();
				x.checkCanDragAndReorder();
			});
		}

		if ('rowHeight' in changes) {
			if (this.helper.onlyNumbers(this.rowHeight) === this.rowHeight) this.rowHeight += 'px';

			if (isNullOrUndefined(this.rowHeight) || this.rowHeight === '') {
				this.rowHeight = this.minHeight + 'px';
				return;
			} else {
				let number = this.helper.onlyNumbers(this.rowHeight);
				let unit = this.rowHeight.replace(number, '');

				if (unit !== 'px') {
					this.rowHeight = this.minHeight + 'px';
					console.warn(`The row-height is invalid. The height must be informed in "px".`);
					return;
				}
			}

			let number = parseInt(this.helper.onlyNumbers(this.rowHeight));
			if (number < this.minHeight) {
				this.rowHeight = this.minHeight + 'px';
				console.warn(`The row-height is invalid because the minimum height should be "${this.minHeight}px".`);
				return;
			}
		}
	}

	public onRender() {
		this.configureChildrens();

		if (this.el) {
			this.parent.lastBodyWidth = this.el.clientWidth + 'px';
			this.parent.lastBodyHeight = this.el.clientHeight + 'px';
		}

		this.parent.checkMinWidthCanApply();

		this.subscription?.unsubscribe();
		this.subscription = this.queryRows.changes.subscribe(this.configureChildrens.bind(this));
		
		this.dataVirtualScrollService.definepreviousItemsHeight(this.parent);
	}

	private configureChildrens() {
		this.clickable = this.parent.clickable;
		this.hoverable = this.parent.hoverable;
		if (this.parent.dragging.state !== 'NONE' && this.parent.dragging.rowIndex >= 0) {
			this.rows.forEach((row) => {
				if (row.index === this.parent.dragging.rowIndex) {
					this.parent.dragging.row = row;
					row.el.style.opacity = '0';
				}
			});
		}
		this.dataVirtualScrollService.definepreviousItemsHeight(this.parent);
	}

	public getRowIndexBasedVirtual(virtualIndex: number) {
		return this.parent.virtualScroll.currentStartIndex + virtualIndex;
	}

	public onBodyWidthChange() {
		let currentWidth = this.el ? this.el.clientWidth + 'px' : null;
		if (currentWidth && this.el) {
			this.rows.forEach(row => {
				row.cells.forEach(cell => {
					cell.setWidth();
					cell.checkFreezing();
				});
			});
		}
	}

	public getRowIfMatchToDrag(targetEl: HTMLElement): SvcDataRowComponent {
		const row = this.rows.find(row => {
			return row.draggable && row.dragButtonEl.contains(targetEl);
		});
		return row;
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}
}
