<ng-container *screenSizes="let size">
  <mat-button-toggle-group *ngIf="viewButtons?.length && size.isAboveSM" [(ngModel)]="view" (ngModelChange)="onActiveViewChanged(view)">
    <mat-button-toggle [value]="button.id" *ngFor="let button of viewButtons;" [disabled]="disabled">
      <mat-icon class="icon-size-6" [svgIcon]="button.icon"></mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="viewButtons?.length && size.isBelowMD" class="flex items-center gap-2.5 rounded-md h-8 px-1" [ngClass]="{ 'bg-primary': !disabled, 'bg-gray-200': disabled }" [disabled]="disabled" [matMenuTriggerFor]="disabled ? null : menu">
    <button mat-icon-button class="w-6 min-w-6 h-6 min-h-6 p-0 text-white" [disabled]="disabled">
      <mat-icon class="text-current icon-size-4.5" [svgIcon]="'fontawesome_solid:angle-' + (showButtons ? 'up' : 'down')"></mat-icon>
    </button>
    <button *ngIf="activeViewButton" mat-icon-button class="w-6 min-w-6 h-6 min-h-6 p-0 text-white" [disabled]="disabled">
      <mat-icon class="text-current icon-size-6" [svgIcon]="activeViewButton.icon"></mat-icon>
    </button>
  </div>
  
  <mat-menu #menu="matMenu" class="bg-primary p-1 rounded-md mt-1 min-w-0" xPosition="before">
    <button *ngFor="let button of viewButtons; let i = index;" class="h-7 min-h-7 p-0 border-b-0" [class.mt-1]="i > 0" [class.cursor-default]="button.id === activeViewButton?.id" mat-menu-item (click)="onActiveViewChanged(button.id)">
      <div class="flex gap-2.5 p-0.5 w-full h-full text-white rounded-md" [class.bg-primary-600]="button.id === activeViewButton?.id">
        <mat-icon class="text-current icon-size-6 m-0" [svgIcon]="button.icon"></mat-icon>
        <div class="flex-auto text-sm pr-2" *ngIf="button.name">{{ button.name }}</div>
      </div>
    </button>
  </mat-menu>
</ng-container>