import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcMasterdataUdaLocationList, SvcMasterdataContractor, SvcMasterdataEquipment } from '../interfaces/svc-masterdata.interface';
import { UserService } from '../../public-api';

@Injectable({
  providedIn: 'root'
})
export class SvcMasterdataService {

  private readonly _apiUrlMasterdata: string = this._appConfig.APIs.apiUrlMasterdata;

  private _userService: UserService = inject(UserService);

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient
  ) { }

  private _getParams(paramsObject: Object): HttpParams {
    let params = new HttpParams();

    Object.keys(paramsObject).forEach(key => {
      const value = paramsObject?.[key];
      if (typeof value === 'boolean' && (value || value === false))
        params = params.append(key, value);
      else if (value || value === 0)
        params = params.append(key, value);
    });

    params = params.append('siteId', this._userService.user.lastSiteId);

    return params;
  }

  public getAreas(isActive?: boolean, deptoId?: number): Observable<SvcMasterdataUdaLocationList[]> {
    const params = this._getParams({ isActive, deptoId });

    return this._http.get<SvcMasterdataUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/area`, {
      params
    });
  }

  public getDepartments(isActive?: boolean): Observable<SvcMasterdataUdaLocationList[]> {
    const params = this._getParams({ isActive });

    return this._http.get<SvcMasterdataUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/department`, {
      params
    });
  }

  public getLocations(isActive?: boolean, areaId?: number): Observable<SvcMasterdataUdaLocationList[]> {
    const params = this._getParams({ isActive, areaId });

    return this._http.get<SvcMasterdataUdaLocationList[]>(`${this._apiUrlMasterdata}/UDA/location`, {
      params
    });
  }

  public getContractors(): Observable<SvcMasterdataContractor[]> {
    return this._http.get<SvcMasterdataContractor[]>(`${this._apiUrlMasterdata}/Contractor/List`);
  }

  public getEquipmentsByLocation(udaId: number): Observable<SvcMasterdataEquipment[]> {
    return this._http.get<SvcMasterdataEquipment[]>(`${this._apiUrlMasterdata}/Equipments/location/${udaId}`);
  }
}
