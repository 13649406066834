<div
	class="total-padding"
	[style.height.px]="parent?.virtualScroll?.totalHeightContent ?? 0"
></div>
<div
	#itemsContainer
	class="scrollable-content"
	[ngClass]="{ 'relative': !parent?.useParentScroll && parent?.height === 'auto' }"
	[style.transform]="'translateY(' + (parent?.virtualScroll?.paddingScroll ?? 0) + 'px)'"
>
	<div style="width: 100%; height: 0.1px;"></div>
	<ng-content></ng-content>
	<ng-container *ngIf="parent.loading && !parent.items?.length">
		<svc-data-row *ngFor="let _ of [].constructor(skeletonRowsLength); let rowIndex = index" class="svc-dt-row-none">
			<svc-data-cell *ngFor="let __ of [].constructor(parent.header?.queryCols.length ?? 0)" [style.opacity]="1 - (rowIndex * 0.165)">
				<ngx-skeleton-loader [theme]="{'margin-bottom': 0, width: '100%', height: '30px'}"/>
			</svc-data-cell>
		</svc-data-row>
	</ng-container>
</div>
<div class="svc-data-table-empty animated fadeIn" *ngIf="parent && !parent.loading && parent?.items.length <= 0">
	<span [innerHTML]="'Não há resultados' | transloco"></span>
</div>
