import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { SvcDialogService, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { Communication, CommunicationService } from '../../services/communication.service';
import { ModalViewCommunicationComponent } from '../../../../modals/modal-view-communication/modal-view-communication.component';
import { Subject, takeUntil, tap } from 'rxjs';
import { AgVirtualSrollComponent } from 'ag-virtual-scroll';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'lib-communication-content',
  templateUrl: './communication-content.component.html',
  styleUrls: ['./communication-content.component.scss']
})
export class CommunicationContentComponent implements OnDestroy {
  @ViewChild(AgVirtualSrollComponent) public agVirtualScroll: AgVirtualSrollComponent;

  protected communications: Communication[];
  protected isLoading$ = this._communicationService.isCommunicationsLoading$;
  protected communicationsAreGone = false;
  protected avatarSize: UserAvatarSize = UserAvatarSize.Notification;

  @Output() clickedItem = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor(
    public elRef: ElementRef<HTMLElement>,
    private tabGroup: MatTabGroup,
    protected tab: MatTab,
    private _communicationService: CommunicationService,
    private _dialogService: SvcDialogService
  ) {
    this.tabGroup.selectedIndexChange.pipe(
      takeUntil(this.destroy$),
      tap((index: number) => {
        if (index === this.tabGroup._tabs.toArray().indexOf(this.tab)) {
          if (!this.communications?.length)
            this._communicationService.getAllCommunications();
          setTimeout(() => this.agVirtualScroll?.refreshData());
        }
      })
    ).subscribe();
    this._communicationService.communications$.subscribe(c => {
      this.communicationsAreGone = c.areGone;
      this.communications = c.communications;
    });
  }

  public emitClickItem(communication: Communication) {
    this.clickedItem.emit();
    this._dialogService.open(ModalViewCommunicationComponent, {
      width: '100%',
      maxWidth: '712px',
      autoFocus: false,
      data: { id: communication.communicationId }
    }).afterClosed().pipe(
      tap((response: any) => {
        if (response?.viewed) {
          communication.isRead = true;
          this._communicationService.getTotalUnread();
        }
      }),
    ).subscribe();
  }

  public nextPage() {
    if (!this.communicationsAreGone)
      this._communicationService.nextPage();
  }

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }
}
