import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvcWordCloudComponent } from './svc-word-cloud.component';
import { AngularD3CloudComponent } from './d3-encapsulation-component/d3-encapsulation-component.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/lib/lib-shared-common.module';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  declarations: [
    AngularD3CloudComponent,
    SvcWordCloudComponent,
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    LibSharedCommonModule,
    TranslocoModule
  ],
  exports: [
    SvcWordCloudComponent,
  ]
})
export class SvcWordCloudModule { }
