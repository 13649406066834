import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceChecklistAnalyticsService } from './lib-service-checklist-analytics.service';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        LibServiceChecklistAnalyticsService,
        ModuleHttpClient,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class LibServiceChecklistAnalyticsModule { }
