<div class="relative bg-white p-1 lg:hidden w-full shadow-top z-99" *screenSizes="let size">
  <div class="flex items-center justify-center gap-4 relative">
    <!-- Botões Centralizados -->
    <div class="flex justify-center items-center gap-4">
      <button mat-icon-button (click)="onClickMenu('tasks')">
        <mat-icon class="text-primary icon-size-8" [svgIcon]="'mat_solid:checklist'"></mat-icon>
        <div *ngIf="loadingTasks || totalTasks > 0" class="absolute -right-1 top-0 z-99 flex flex-col items-center bg-red-500 text-white justify-center text-sm rounded-full px-1 py-0.5">
          <span *ngIf="!loadingTasks">
            {{ totalTasks > 99 ? '+99' : totalTasks }}
          </span>
          <mat-spinner *ngIf="loadingTasks" diameter="14" class="text-current"></mat-spinner>
        </div>
      </button>

      <button mat-fab class="relative bg-primary" (click)="onClickMenu('modules')">
        <mat-icon class="icon-size-12 text-current">
          <svg viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 13.2857V17.5714C19.5 18.2815 18.9963 18.8571 18.375 18.8571H13.625C13.0037 18.8571 12.5 18.2815 12.5 17.5714V13.2857C12.5 12.5756 13.0037 12 13.625 12H18.375C18.9963 12 19.5 12.5756 19.5 13.2857ZM28 26.1429V21.8571C28 21.1471 27.4963 20.5714 26.875 20.5714H22.125C21.5037 20.5714 21 21.1471 21 21.8571V26.1429C21 26.8529 21.5037 27.4286 22.125 27.4286H26.875C27.4963 27.4286 28 26.8529 28 26.1429ZM29.5 13.2857V17.5714C29.5 18.2815 30.0037 18.8571 30.625 18.8571H35.375C35.9963 18.8571 36.5 18.2815 36.5 17.5714V13.2857C36.5 12.5756 35.9963 12 35.375 12H30.625C30.0037 12 29.5 12.5756 29.5 13.2857ZM28 17.5714V13.2857C28 12.5756 27.4963 12 26.875 12H22.125C21.5037 12 21 12.5756 21 13.2857V17.5714C21 18.2815 21.5037 18.8571 22.125 18.8571H26.875C27.4963 18.8571 28 18.2815 28 17.5714ZM18.375 20.5714H13.625C13.0037 20.5714 12.5 21.1471 12.5 21.8571V26.1429C12.5 26.8529 13.0037 27.4286 13.625 27.4286H18.375C18.9963 27.4286 19.5 26.8529 19.5 26.1429V21.8571C19.5 21.1471 18.9963 20.5714 18.375 20.5714ZM12.5 30.4286V34.7143C12.5 35.4244 13.0037 36 13.625 36H18.375C18.9963 36 19.5 35.4244 19.5 34.7143V30.4286C19.5 29.7185 18.9963 29.1429 18.375 29.1429H13.625C13.0037 29.1429 12.5 29.7185 12.5 30.4286ZM30.625 27.4286H35.375C35.9963 27.4286 36.5 26.8529 36.5 26.1429V21.8571C36.5 21.1471 35.9963 20.5714 35.375 20.5714H30.625C30.0037 20.5714 29.5 21.1471 29.5 21.8571V26.1429C29.5 26.8529 30.0037 27.4286 30.625 27.4286ZM30.625 36H35.375C35.9963 36 36.5 35.4244 36.5 34.7143V30.4286C36.5 29.7185 35.9963 29.1429 35.375 29.1429H30.625C30.0037 29.1429 29.5 29.7185 29.5 30.4286V34.7143C29.5 35.4244 30.0037 36 30.625 36ZM21 30.4286V34.7143C21 35.4244 21.5037 36 22.125 36H26.875C27.4963 36 28 35.4244 28 34.7143V30.4286C28 29.7185 27.4963 29.1429 26.875 29.1429H22.125C21.5037 29.1429 21 29.7185 21 30.4286Z"></path>
        </svg>
      </mat-icon>
      </button>

      <button mat-icon-button (click)="onClickMenu('feed')" [class.invisible]="!feedIsVisible">
        <mat-icon class="text-primary icon-size-8" [svgIcon]="'heroicons_mini:newspaper'"></mat-icon>
        <div *ngIf="loadingFeeds || totalFeeds > 0" class="absolute -right-1 top-0 z-99 flex flex-col items-center text-white justify-center text-sm rounded-full px-1 py-0.5 bg-red-500">
          <span *ngIf="!loadingFeeds">
            {{ totalFeeds > 99 ? '+99' : totalFeeds }}
          </span>
          <mat-spinner *ngIf="loadingFeeds" diameter="14" class="text-current"></mat-spinner>
        </div>
      </button>
    </div>

    <div *ngIf="size.isBelowMD && (isMenuReadQrCodeCall || isMenuSyncCall)" class="absolute right-0 mr-4">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Abrir QR Code no aplicativo">
        <mat-icon class="icon-size-6 text-current text-[#323232]" [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="isMenuReadQrCodeCall" mat-menu-item (click)="qrCodeCall()">
          <mat-icon class="icon-size-4 text-slate-900" [svgIcon]="'heroicons_solid:qr-code'"></mat-icon>
          <span class="text-base text-default font-normal">{{ 'Ler QR Code' | transloco }}</span>
        </button>

        <button *ngIf="isMenuSyncCall" mat-menu-item (click)="offlineCall()">
          <mat-icon class="icon-size-4 text-slate-900" [svgIcon]="'heroicons_solid:arrow-path'"></mat-icon>
          <span class="text-base text-default font-normal">{{ 'Sincronizar' | transloco }}</span>
        </button>
      </mat-menu>
    </div>

    <svc-copilot-float-button *ngIf="copilotIsVisible" class="absolute left-5 z-99 flex w-10"></svc-copilot-float-button>
  </div>
</div>
