import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, BehaviorSubject, Subscription, tap, Observable } from 'rxjs';
import { Notification } from '../models/notifications.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _pageIndex = 0;
  private _pageSize = 20;
  private _getAllSubscription: Subscription;
  private _tryMarkAllSubscription: Subscription;

  private _isLoading$ = new BehaviorSubject(true);
  public isLoading$ = this._isLoading$.asObservable();

  private notifications: Notification = { notifications: [], notifiticationsAreGone: false, unreadCount: 0 };
  private set _notifications(value: Notification) {
    this.notifications = this._pageIndex <= 0 ? value : {
      unreadCount: value.unreadCount,
      notifiticationsAreGone: value.notifications.length < this._pageSize,
      notifications: [...this.notifications.notifications, ...value.notifications],
    };
    this._notifications$.next(this.notifications);
  }
  private _notifications$ = new BehaviorSubject<Notification>(this.notifications);
  public notifications$ = this._notifications$.asObservable();
  public totalUnread$ = this.notifications$.pipe(map((response) => response.unreadCount));

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
  ) { }

  public setNotifications(value: Notification): void {
    this._notifications = value;
  }

  public nextPage(): void {
    this._pageIndex++;
    this.getAll();
  }

  public getAll(): void {
    this._getAllSubscription?.unsubscribe();
    this._isLoading$.next(true);
    this._getAllSubscription = this._httpClient.get<Notification>(`${this._appConfig.APIs.apiUrlNotification}/Notification`, {
      params: {
        pageIndex: this._pageIndex,
        pageSize: this._pageSize,
      }
    }).pipe(
      tap((response: Notification) => {
        response.notifications.forEach((item) => {
          if (item.title === item.message) {
            item.message = '';
          }
          else if (item.title && !item.title.endsWith('.')) {
            item.title += '.';
          }
          if (item.badges === item.applicationName) {
            item.badges = null;
          }
        });
        this._notifications = response;
      }),
      finalize(() => this._isLoading$.next(false)),
    ).subscribe();
  }

  public tryMarkAllAsRead(): void {
    if (this.notifications.unreadCount > 0) {
      this._tryMarkAllSubscription?.unsubscribe();
      this._tryMarkAllSubscription = this.markAllAsRead().subscribe();
    }
  }

  public markAllAsRead(): Observable<void> {
    return this._httpClient.put<void>(`${this._appConfig.APIs.apiUrlNotification}/Notification/ReadNotificationsByUserId`, null)
      .pipe(
        finalize(() => {
          this._pageIndex = 0;
          this.notifications = {
            ...this.notifications,
            unreadCount: 0,
          }
          return this.getAll();
        })
      );
  }

}
