import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcChartBarComponent } from './svc-chart-bar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [SvcChartBarComponent],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgxSkeletonLoaderModule
  ],
  exports: [SvcChartBarComponent],
})
export class SvcChartBarModule { }
