import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvcMonthYearComponent } from './svc-month-year.component';
import { MatIconModule} from '@angular/material/icon';
import { SvcButtonsModule} from '../svc-buttons/svc-buttons.module';
import {TranslocoModule} from '@ngneat/transloco';



@NgModule({
  declarations: [
    SvcMonthYearComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    SvcButtonsModule,
    TranslocoModule
  ],
  exports: [
    SvcMonthYearComponent
  ]
})
export class SvcMonthYearModule { }
