import { Injectable } from '@angular/core';
import { SvcFeatureToggle } from './svc-feature-toggle.model';

@Injectable({
  providedIn: 'root',
})
export class SvcFeatureToggleService {

  #featureToggle = new SvcFeatureToggle();

  public get feedIsEnabled(): boolean { return this.#featureToggle.feedIsEnabled }
  public setFeedIsEnabled(value: boolean) {
    this.#featureToggle.feedIsEnabled = value;
  }

  public get postIsEnabled(): boolean { return this.#featureToggle.postIsEnabled }
  public setPostIsEnabled(value: boolean) {
    this.#featureToggle.postIsEnabled = value;
  }

  public get appOffilineEnabled(): boolean { return this.#featureToggle.appOffilineEnabled }
  public setAppOffilineEnabled(value: boolean) {
    this.#featureToggle.appOffilineEnabled = value;
  }
  
  public get copilotIsEnabled(): boolean { return this.#featureToggle.copilotIsEnabled }
  public setCopilotIsEnabled(value: boolean) {
    this.#featureToggle.copilotIsEnabled = value;
  }
}

