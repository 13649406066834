import { ISvcStatusBadge } from 'projects/lib-shared-component/src/public-api';

export interface CommunicationCardModel {
  communicationId: number;
  title: string;
  message?: string;
  statusId: number;
  isPrivate: boolean;
  isFavorite: boolean;
  isRead?: boolean;
  attachment?: {
    fileName?: string,
    assignUrl?: string,
  };
}
