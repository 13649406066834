<svc-chip *ngIf="!isGrouped"
  class="w-full"
  [formControl]="formControl"
  [options]="chipOptions"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [readonly]="readonly"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [fallbackTextValue]="fallbackTextValue"
  [loading]="loading || isLoading"
  readonlyStyle="transparent-chip"
>
  <ng-template let-option="option">
    <svc-user-picker-option [option]="option"></svc-user-picker-option>
  </ng-template>
</svc-chip>

<svc-chip-grouped *ngIf="isGrouped"
  class="w-full"
  [formControl]="formControl"
  [options]="chipGroupedOptions"
  [label]="label"
  [icon]="icon"
  [type]="type"
  [readonly]="readonly"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [fallbackTextValue]="fallbackTextValue"
  [loading]="loading || isLoading"
  readonlyStyle="transparent-chip"
>
  <ng-template let-option="option">
    <svc-user-picker-option [option]="option"></svc-user-picker-option>
  </ng-template>
</svc-chip-grouped>
