interface SvcDataTableAccordionProperties {
  property: string;
  type: 'string' | 'number' | 'numberRounded' | 'status' | 'numberBorderRadius' | 'bar';
  cssClass?: string;
  label?: string;
  propertyBarPercentage?: string;
  cellCssClass?: string
  propertyTooltip?: string;
}

export interface SvcDataTableAccordion {
  columns: SvcDataTableAccordionColumn[];
  subItemsPropertyName: string;
  showHeaderRowSubItems?: boolean;
}

export interface SvcDataTableAccordionColumn extends SvcDataTableAccordionProperties {
  label: string;
  sort: boolean;
  isSearchable?: boolean;
  accordion: SvcDataTableAccordionProperties;
}

export interface SvcDataTableAccordionSubmenu extends SvcDataTableAccordionProperties { }
