import { HttpClient } from "@angular/common/http";
import {Injectable, inject} from "@angular/core";

import { SvcHttpClient } from "projects/lib-shared-common/src/lib/classes/svc-http-client";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { UserService } from "projects/lib-shared-core/src/public-api";

@Injectable({
  providedIn: 'root',
})
export class SvcAlertHttpClient extends SvcHttpClient {

  private userService: UserService = inject(UserService);

  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlAlert, httpClient);
  }

  public get siteId(): { siteId: number } {
    return {
      siteId: this.userService.user.lastSiteId
    };
  }
}
