import { SvcCacheName } from './svc-cache-name.enum';

export class SvcDataCache<T> {
  public name: SvcCacheName;
  public data: T;
  public expiration: number;

  public get isValid(): boolean {
    const expiration = this.expiration ?? 0;
    return !expiration || expiration > Date.now();
  }

  constructor(initial: { name: SvcCacheName, data: T, expiresAfter?: { hours?: number } }) {
    this.name = initial.name;
    this.data = initial.data;
    const { hours } = initial.expiresAfter ?? {};
    if ((hours ?? 0) > 0) {
      const dueDate = new Date();
      dueDate.setHours(dueDate.getHours() + hours);
      this.expiration = dueDate.getTime();
    }
  }

  public static fromCachedObject<T>(object: Partial<SvcDataCache<T>>): SvcDataCache<T> {
    const data = new SvcDataCache({
      name: object.name,
      data: object.data,
    });
    data.expiration = object.expiration;
    return data;
  }
}