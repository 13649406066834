<ng-container *ngFor="let filter of filters; let index = index">
  <div class="flex flex-col mb-4">

    <svc-text
      *ngIf="filter.type == 'text'"
      class="w-full"
      [label]="filter.label"
      [formControl]="controls[index]"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-text>

    <svc-text-area
      *ngIf="filter.type == 'textarea'"
      class="w-full"
      [label]="filter.label"
      [formControl]="controls[index]"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-text-area>

    <svc-select
      *ngIf="filter.type == 'select'"
      class="w-full"
      [label]="filter.label"
      [formControl]="controls[index]"
      [options]="filter.config?.options"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-select>

    <svc-date-picker
      *ngIf="filter.type == 'date'"
      class="w-full"
      [label]="filter.label ?? null"
      [formControl]="controls[index]"
      [min]="filter.config?.minDate"
      [max]="filter.config?.maxDate"
      [format]="filter.config?.format"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-date-picker>

    <svc-chip
      *ngIf="filter.type == 'chip'"
      class="w-full"
      [label]="filter.label"
      [formControl]="controls[index]"
      [options]="filter.config?.options"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-chip>

    <svc-chip-grouped
      *ngIf="filter.type == 'chip-grouped'"
      class="w-full"
      [label]="filter.label"
      [formControl]="controls[index]"
      [options]="filter.config?.options"
      [showCheckbox]="filter.config?.showCheckbox"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    ></svc-chip-grouped>

    <div *ngIf="filter.type == 'toggle'" class="w-full">
      <div *ngIf="filter.label" class="input-label-size input-label-weight text-default mb-1.5">
        {{ filter.label }}
      </div>
      <svc-slide-toggle [formControl]="controls[index]" [inputId]="filter?.id ?? 'filters-field-'+filter.name"></svc-slide-toggle>
    </div>

    <svc-select-period
      class="w-full"
      *ngIf="filter?.type == 'select-period'"
      [label]="filter?.label"
      [formControl]="controls?.[index]"
      [min]="filter?.config?.minDate"
      [max]="filter?.config?.maxDate"
      [format]="filter?.config?.format"
      [inputId]="filter?.id ?? 'filters-field-'+filter.name"
    >
    </svc-select-period>

    <svc-date-picker-range
      *ngIf="filter.type == 'date-range'"
      [label]="filter.label"
      [formControl]="controls?.[index]"
      [min]="filter?.config?.minDate"
      [max]="filter?.config?.maxDate"
      [format]="filter?.config?.format"
    ></svc-date-picker-range>

  </div>
</ng-container>
