import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcDataCacheService } from '../cache/svc-data-cache.service';
import { SvcCacheName } from '../cache/svc-cache-name.enum';
import { SvcParamItem } from './svc-param-item.model';

@Injectable({
  providedIn: 'root',
})
export class ParameterService {
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _dataCacheService: SvcDataCacheService,
  ) { }

  public getSiteParam(paramName: string, options?: { cache?: boolean }): Observable<string> {
    const type = 'site';
    const cachedValue = (options?.cache ?? true) ? this.getParamCachedValue(type, paramName) : null;
    return (cachedValue != null
      ? of({ key: paramName, value: cachedValue })
      : this._httpClient.get<SvcParamItem>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/site/value/${paramName}`)
    ).pipe(
      map((res: SvcParamItem) => {
        (options?.cache ?? true) && this.setParamCachedValue(type, paramName, res.value);
        if (typeof res === 'object') {
          return res.value;
        }
        return res;
      })
    );
  }

  public getGlobalParam(paramName: string, options?: { cache?: boolean }): Observable<string> {
    const type = 'global';
    const cachedValue = (options?.cache ?? true) ? this.getParamCachedValue(type, paramName) : null;
    return (cachedValue != null
      ? of({ key: paramName, value: cachedValue })
      : this._httpClient.get<SvcParamItem>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/global/value/${paramName}`)
    ).pipe(
      map((res: SvcParamItem) => {
        (options?.cache ?? true) && this.setParamCachedValue(type, paramName, res.value);
        if (typeof res === 'object') {
          return res.value;
        }
        return res;
      })
    );
  }

  public getGlobalParams(paramsName: string[], options?: { cache?: boolean }): Observable<SvcParamItem[]> {
    const type = 'global';
    const cachedParams: SvcParamItem[] = (options?.cache ?? true)
      ? paramsName
        .map((paramName) => {
          const value = this.getParamCachedValue(type, paramName);
          return value != null ? <SvcParamItem>{ key: paramName, value } : null;
        })
        .filter((param) => !!(param))
      : [];
    paramsName = paramsName.filter((paramName) => !cachedParams.some((cached) => cached.key === paramName));
    return (
      !paramsName.length
      ? of(cachedParams)
      : this._httpClient.get<SvcParamItem[]>(`${this._appConfig.APIs.apiUrlAdministration}/Parameter/global/values`, {
        params: { paramKeys: paramsName },
      }).pipe(
        map((params) => {
          if (options?.cache ?? true) {
            params.forEach((param) => {
              this.setParamCachedValue(type, param.key, param.value);
            });
          }
          return [
            ...params,
            ...cachedParams,
          ];
        }),
      )
    );
  }

  private setParamCachedValue(type: 'site' | 'global', name: string, value: string): void {
    this._dataCacheService.set<string>(`${type.toUpperCase()}_PARAM_${name}` as SvcCacheName, value);
  }

  private getParamCachedValue(type: 'site' | 'global', name: string): string {
    return this._dataCacheService.get<string>(`${type.toUpperCase()}_PARAM_${name}` as SvcCacheName);
  }
}
