import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AlertApplicationFeed } from '../interfaces/alert-application-feed.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertApplicationFeedService {

  private readonly _apiUrlAlert: string = this._appConfig.APIs.apiUrlAlert;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient
  ) { }

  public getApplicationFeed(): Observable<AlertApplicationFeed> {
    return this._http.get<AlertApplicationFeed>(`${this._apiUrlAlert}/ApplicationFeed`);
  }
}
