import { Directive, ElementRef, Input, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTabActiveNotification]'
})
export class TabActiveClassDirective implements OnInit, OnDestroy {
  private tabChangeSubscription!: Subscription;
  private tabIndex: number = -1;

  constructor(
    private tabGroup: MatTabGroup,
    private el: ElementRef,
    private renderer: Renderer2,
    private tab: MatTab
  ) {}

  ngOnInit(): void {
    this.findTabIndex();
    this.tabChangeSubscription = this.tabGroup.selectedIndexChange.subscribe((index: number) => {
      this.updateStyles(index === +this.tabIndex);
    });

    this.updateStyles(this.tabGroup.selectedIndex === +this.tabIndex);
  }

  ngOnDestroy(): void {
    this.tabChangeSubscription.unsubscribe();
  }

  private findTabIndex(): void {
    this.tabIndex = this.tabGroup._tabs.toArray().indexOf(this.tab);
  }

  private updateStyles(isActive: boolean): void {
    if (isActive) {
      this.renderer.removeClass(this.el.nativeElement, 'bg-gray-400');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'bg-gray-400');
    }
  }
}
