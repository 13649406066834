import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { SvcLocationsService } from '../service/svc-location.service';

export interface IModalSelectAreaOrLocationData {
  canSelectArea: boolean;
}

@Component({
  selector: 'modal-select-area-or-location',
  templateUrl: './modal-select-area-or-location.component.html',
  styleUrls: ['./modal-select-area-or-location.component.scss'],
})
export class ModalSelectAreaOrLocationComponent implements OnInit, OnDestroy {
  
  protected form: FormGroup;
  protected canSelectArea: boolean = true;
  protected departments: ISvcSelectOption[] = [];
  protected areas: ISvcSelectOption[] = [];
  protected locations: ISvcSelectOption[] = [];
  protected isLoading = true;
  protected isAreasLoading = false;
  protected isLocationsLoading = false;
  
  #destroy = new Subject();
  #areasSubscription: Subscription;
  #locationsSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IModalSelectAreaOrLocationData,
    private _dialogRef: MatDialogRef<ModalSelectAreaOrLocationComponent>,
    private _formBuilder: FormBuilder,
    private _service: SvcLocationsService,
  ) {
    this.canSelectArea = data.canSelectArea;
    this.form = this._formBuilder.group({
      departmentId: [{ value: null }, [Validators.required]],
      areaId: [{ value: null, disabled: true }, [Validators.required]],
      locationId: [{ value: null, disabled: true }, []],
    });

    this.form.get('departmentId').valueChanges.pipe(
      takeUntil(this.#destroy),
      tap((departmentId) => {
        this.#getAreasByDepartmentId(departmentId);
      }),
    ).subscribe();

    this.form.get('areaId').valueChanges.pipe(
      takeUntil(this.#destroy),
      tap((departmentId) => {
        this.#getLocationsByAreaId(departmentId);
      }),
    ).subscribe();
  }

  public ngOnInit() {
    this._service.initialOptions$.pipe(
      takeUntil(this.#destroy),
      tap((initialOptions) => {
        this.departments = initialOptions.departments.map((d) => (<ISvcSelectOption>{
          text: d.udaName,
          value: d.udaId,
        }));
      })
    ).subscribe();
    this._service.initialOptionsIsLoading$.pipe(
      takeUntil(this.#destroy),
      tap((loading) => {
        this.isLoading = loading;
      })
    ).subscribe();
  }

  #getAreasByDepartmentId(departmentId: number) {
    this.form.patchValue({
      areaId: null,
      locationId: null,
    }, { emitEvent: false });
    this.form.get('areaId').disable();
    this.form.get('locationId').disable();
    this.#areasSubscription?.unsubscribe();
    this.#locationsSubscription?.unsubscribe();
    if (departmentId) {
      this.isAreasLoading = true;
      this.#areasSubscription = this._service.getAreasByDepartmentId(departmentId).pipe(
        tap((areas) => {
          this.areas = areas.map((a) => (<ISvcSelectOption>{
            text: a.udaName,
            value: a.udaId,
          }))
        }),
        finalize(() => {
          this.form.get('areaId').enable();
          this.isAreasLoading = false;
        }),
      ).subscribe();
    }
  }

  #getLocationsByAreaId(areaId: number) {
    this.form.patchValue({
      locationId: null,
    }, { emitEvent: false });
    this.form.get('locationId').disable();
    this.#locationsSubscription?.unsubscribe();
    if (areaId) {
      this.isLocationsLoading = true;
      this.#locationsSubscription = this._service.getLocationsByAreaId(areaId).pipe(
        tap((locations) => {
          this.locations = locations.map((a) => (<ISvcSelectOption>{
            text: a.udaName,
            value: a.udaId,
          }))
        }),
        finalize(() => {
          this.isLocationsLoading = false;
          this.form.get('locationId').enable();
        }),
      ).subscribe();
    }
  }

  public close() {
    this._dialogRef.close();
  }

  public save() {
    if (this.isLoading || this.isAreasLoading || this.isLocationsLoading) return;
    this.form.markAllAsTouched();
    if ((this.canSelectArea && this.form.get('areaId').value) || this.form.get('locationId').value) {
      if (this.form.get('locationId').value) {
        const id = this.form.get('locationId').value;
        const location = this.locations.find(x => x.value === id)?.text;
        this._dialogRef.close({ id, text: location });
      }
      else {
        const id = this.form.get('areaId').value;
        const area = this.areas.find(x => x.value === id)?.text;
        this._dialogRef.close({ id, text: area });
      }
    }
  }

  public ngOnDestroy() {
    this.#destroy?.next(null);
    this.#destroy?.complete();
    this.#areasSubscription?.unsubscribe();
    this.#locationsSubscription?.unsubscribe();
  }
}
