import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HIGHLIGHT_OPTIONS, HighlightJSOptions } from 'ngx-highlightjs';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CheckImageLoadedDirective } from './directives/check-image-loaded.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { MatDialogFullscreenDirective } from './directives/mat-dialog-fullscreen.directive';
import { ScreenSizesDirective } from './directives/screen-sizes.directive';
import { TemplateTooltipComponent } from './directives/template-tooltip/template-tooltip.component';
import { TemplateTooltipDirective } from './directives/template-tooltip/template-tooltip.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FormatNumberDefaultPipe } from './pipes/format-number.pipe';
import { GetPropertyPipe } from './pipes/get-property.pipe';
import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { RemoveHtmlPipe } from './pipes/remove-html.pipe';
import { TimeHasPassedPipe } from './pipes/time-has-passed.pipe';
import { PadLeftPipe } from './pipes/pad-left.pipe';
import { PadRightPipe } from './pipes/pad-right.pipe';
import { BlobToUrlPipe } from './pipes/blob-to-url';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { UserInitialsPipe } from "./pipes/user-initials.pipe";
import { MergePipe } from './pipes/merge.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { LongPressDirective } from './directives/long-press.directive';

@NgModule({
  declarations: [
    HtmlSanitizerPipe,
    CheckImageLoadedDirective,
    TemplateTooltipComponent,
    TemplateTooltipDirective,
    MatDialogFullscreenDirective,
    ScreenSizesDirective,
    LongPressDirective,
    FormatNumberDefaultPipe,
    OrderByPipe,
    JoinPipe,
    GetPropertyPipe,
    TimeHasPassedPipe,
    RemoveHtmlPipe,
    InfiniteScrollDirective,
    EllipsisPipe,
    PadLeftPipe,
    PadRightPipe,
    BlobToUrlPipe,
    DateFormatPipe,
    TimeFormatPipe,
    UserInitialsPipe,
    MergePipe,
    TimeAgoPipe,
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HtmlSanitizerPipe,
    CheckImageLoadedDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    TemplateTooltipDirective,
    MatDialogFullscreenDirective,
    ScreenSizesDirective,
    LongPressDirective,
    FormatNumberDefaultPipe,
    OrderByPipe,
    JoinPipe,
    GetPropertyPipe,
    TimeHasPassedPipe,
    RemoveHtmlPipe,
    InfiniteScrollDirective,
    EllipsisPipe,
    PadLeftPipe,
    PadRightPipe,
    BlobToUrlPipe,
    DateFormatPipe,
    TimeFormatPipe,
    UserInitialsPipe,
    MergePipe,
    TimeAgoPipe,
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightJSOptions>{
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml')
        },
      }
    }
  ]
})
export class LibSharedCommonModule { }
