import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AvailableHashtags, Hashtag } from "../../services/user-hashtags.types";
import { UserHashtagsService } from "../../services/user-hastags.service";
import { UserService } from "../../../services/user.service";
import { catchError, finalize, tap } from 'rxjs';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { SvcToastService } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.service';

@Component({
  selector: 'add-hashtag-form',
  templateUrl: './add-hashtag-form.component.html',
  styleUrls: ['./add-hashtag-form.component.scss']
})
export class AddHashtagFormComponent implements OnInit {

  hashtags: Hashtag[]
  hashtagForm: FormGroup;
  availableHashtags: AvailableHashtags[];
  userData: any;
  isLoading = false;

  constructor(
    private _dialogReg: MatDialogRef<AddHashtagFormComponent>,
    private _userHashtagService: UserHashtagsService,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
    private _toastService: SvcToastService,
  ) { }

  ngOnInit() {
    this._userHashtagService.hashtags$.subscribe((hashtags) => {
      this.hashtags = hashtags;
      this._userHashtagService.getAvailableHashtags(this._userService.user.originSiteId).subscribe(
        res => {
          this.availableHashtags = res.map(a => ({
            ...a,
            types: a.types.map(t => ({
              ...t,
              hashtags: t.hashtags.filter((h) => !(this.hashtags.filter(x => x.hashtagId === h.hashtagId).length > 0))
            })).filter(x => x.hashtags.length > 0)
          })).filter(x => x.types.length > 0);
        });
    });

    this.hashtagForm = this._formBuilder.group({
      hashtags: [null, [Validators.required]]
    });
  }

  save() {
    if (!this.hashtagForm.valid)
      return;

    this.isLoading = true;
    const hashtagIds: number[] = this.hashtagForm.get('hashtags').value.map((hashtag: Hashtag) => hashtag.hashtagId);
    this._userHashtagService.addHashtag(hashtagIds).pipe(
      tap(() => {
        this._userHashtagService.getUserHashtags().subscribe();
        this._dialogReg.close();
        this._toastService.success(this._translocoService.translate('Hashtags atualizadas com sucesso!'));
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => this.isLoading = false),
    ).subscribe();
  }

  public removeHashtag(hashtagToRemove: Hashtag): void {
    const hashtags: Hashtag = this.hashtagForm.get('hashtags').value.filter((hashtag: Hashtag) => hashtag.hashtagId !== hashtagToRemove.hashtagId);
    this.hashtagForm.get('hashtags').patchValue(hashtags);
  }
}

