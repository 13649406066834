import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SvcFilterEvent, SvcFilterValueEvent } from '../../classes/svc-filter-event';
import { SvcFilterField } from '../../classes/svc-filter-field';
import { SvcDrawerComponent } from '../../../svc-drawer/svc-drawer.component';
import { SvcFiltersSidebarComponent } from '../svc-filters-sidebar/svc-filters-sidebar.component';


@Component({
  selector: 'svc-filters-drawer',
  templateUrl: './svc-filters-drawer.component.html',
  styleUrls: ['./svc-filters-drawer.component.scss']
})
export class SvcFiltersDrawerComponent {

  @ViewChild(SvcDrawerComponent)
  protected svcDrawer: SvcDrawerComponent;

  @ViewChild(SvcFiltersSidebarComponent)
  protected svcFilterSidebar: SvcFiltersSidebarComponent;

  @Input() public filters!: SvcFilterField[];
  @Input() public position: 'left' | 'right' = null;
  @Input() public mode: 'over' | 'side' = null;
  @Input() public searchOnlyWhenHaveNewValues: boolean = false;

  @Output() public onFilterChanged = new EventEmitter<SvcFilterEvent>();
  @Output() public onFilterValueChanged = new EventEmitter<SvcFilterValueEvent>();
  @Output() public onFilterCleared = new EventEmitter<void>();
  @Output() public onFilterCancelled = new EventEmitter<SvcFilterEvent>();
  @Output() public onSearchClicked = new EventEmitter<SvcFilterEvent>();

  isFiltersLoading: boolean;
  
  public get isEmpty() { return this.svcFilterSidebar?.isEmpty ?? true; }
  public get isValid() { return this.svcFilterSidebar?.isValid ?? true; }
  public get fields() { return this.svcFilterSidebar?.fields ?? []; }
  public get isOpened() { return this.svcDrawer.isOpened; }

  public clearFilters(options?: { clearDisabled: boolean, preventEmit: boolean }) {
    return this.svcFilterSidebar?.clearFilters(options);
  }

  public toggle() {
    this.svcDrawer.toggle();
  }
}
