import { CommonCounter } from 'projects/lib-shared-model/src/public-api';
import { SvcDelimiterType } from '../../../settings/enums/svc-delimiter-type.enum';

export interface User {
  fullName: string;
  firstLastName: string;
  userName: string;
  email: string;
  areaId: number;
  areaName: string;
  departmentId: number;
  departmentName: string;
  employeePositionId: number;
  employeePositionName: string;
  typeId: number;
  typeName: string;
  pictureFile: string;
  languageId: number;
  languageTag: string;
  isSyncOutlook: boolean;
  enableOfflineMode: boolean;
  viewMode: number;
  dateFormat: string;
  delimiter: SvcDelimiterType;
  isFederated: boolean;
  isSolvaceAdmin: boolean;
  initialsColor: string;
  originSiteId: number;
  lastSiteId?: number;
  supplierId?: number;
  roles?: {
    applicationId: string;
    isInternal?: string;
    roleId: string;
    roleName: string;
    roleOrder?: number;
  }[]
}

export interface UserEnvironment {
  colorBase: string;
  backgroundImage: string;
  logoImageNegative: string;
  logoImage: string;
}

export interface UserIndicator extends CommonCounter {
  loading: boolean;
  error: any;
}

export interface UserIndicators {
  checkList: UserIndicator;
  lil: UserIndicator;
  actionPlan: UserIndicator;
  defectTag: UserIndicator;
}

export interface All {
  userId: number;
  name: string;
}

export interface Area {
  id: number;
  name: string;
}

export interface Time {
  active?: boolean;
  groupId: number;
  groupName: string;
}
