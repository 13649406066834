import { Component, Inject, Input, OnInit, forwardRef } from '@angular/core';
import { App, AppItem } from '../services/apps.types';
import { Router } from '@angular/router';
import { HttpErrorService, generateApplicationURL } from 'projects/lib-shared-common/src/public-api';
import { AppsService } from '../services/apps.service';
import { AppsComponent } from '../apps.component';
import { catchError, finalize, tap } from 'rxjs';
import { AvailableModule } from '../services/available-module.enum';
import { TranslocoService } from '@ngneat/transloco';
import { SvcToastService } from 'projects/lib-shared-component/src/lib/svc-toast/svc-toast.service';

@Component({
  selector: 'app-panel-item',
  templateUrl: './app-group.component.html',
  styleUrls: ['./app-group.component.scss'],
})
export class AppGroupComponent implements OnInit {
  @Input() app: App;
  @Input() disabled: boolean = false;
  @Input() expanded = false;

  constructor(
    private _router: Router,
    private _appsService: AppsService,
    private _svcToastService: SvcToastService,
    private _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
    @Inject(forwardRef(() => AppsComponent)) public parent: AppsComponent,
  ) { }

  ngOnInit(): void { }

  toggle() {
    this.expanded = !this.expanded;
  }

  goTo(item: AppItem) {
    if (!this.disabled && !item.locked) {
      this._openAfterCheckIsAvailable(item, () => {
        this._router.navigate([]).then(() => {
          generateApplicationURL(item.codeLanguage, item.url)();
        });
      });
    }
  }

  private _openAfterCheckIsAvailable(item: AppItem, callback: () => void) {
    this.parent.isPanelItemLoading = true;
    item.loading = true;
    this._appsService.checkModuleByApplicationId(item.id).pipe(
      tap((res) => {
        if (res === AvailableModule.ACCESSIBLE) {
          callback();
        }
        else {
          let msg = 'Ocorreu uma falha ao abrir o módulo';
          if (res === AvailableModule.MODULE_NOT_FOUND) {
            msg = 'Módulo não encontrado';
          }
          else if (res === AvailableModule.PRIVATE_MODULE_LOCKED) {
            msg = 'Você não tem permissão de acesso a esse módulo';
          }
          else if (res === AvailableModule.UNCONTRACTED_MODULE) {
            msg = 'Módulo não contratado. Favor acionar o administrador do sistema.';
          }

          this._svcToastService.error(this._translocoService.translate(msg));
        }
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => {
        this.parent.isPanelItemLoading = false;
        item.loading = false;
      }),
    ).subscribe();
  }
}
