export interface ISvcChartMonthInfo {
  title: string;
}

export interface ISvcChartMonthDefinitions {
  stacked: boolean;
  seriesAndValues: ISvcChartMonthSeries[];
}

export interface ISvcChartMonthSeries {
  color: string;
  name: string;
  values: ISvcChartMonthData[];
}

export interface ISvcChartMonthData {
  month: number;
  year: number;
  value: number;
}
