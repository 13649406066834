<svc-dialog #dialog fullscreen="XS">
  <div svc-dialog-title class="flex gap-2 items-center text-default">
    <mat-icon svgIcon="heroicons_solid:printer" class="icon-size-6 text-current"></mat-icon>
    <div class="flex-auto">
      {{ 'Imprimir' | transloco }}
    </div>
  </div>
  <div svc-dialog-content class="p-3">
    <div class="printer-container flex flex-col" [class.hidden]="isLoading">
      <ng-container *ngFor="let option of (data?.options ?? []); let optionIndex = index">
        <div *ngIf="option.show ?? true" class="printer-option flex flex-col">
          <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option, disabled: option.disabled ?? false, fromAddtional: false }"></ng-container>
          <div *ngFor="let childOption of (option.options ?? [])" class="printer-option flex flex-col gap-2 pt-2 pl-9">
            <ng-container *ngIf="childOption.show ?? true" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option: childOption, disabled: option.disabled || !option.selected || childOption.disabled ?? false, fromAddtional: false }"></ng-container>
          </div>
        </div>
      </ng-container>
      <div *ngIf="data?.additional?.options?.length" class="border-t mt-1">
        <div class="flex items-center pt-2 pb-1 text-sm">
          <div class="text-current font-semibold">
            {{ data?.additional.title ?? ('Itens adicionais' | transloco) }}
          </div>
          <div
            class="flex text-default opacity-60 p-1"
            matTooltipPosition="above"
            [matTooltip]="data?.additional?.titleTip ?? ('Os itens selecionados a seguir serão impressos novamente em uma escala ampliada ao final.' | transloco)"
          >
            <mat-icon class="icon-size-4 text-current" svgIcon="fontawesome_solid:circle-info"></mat-icon>
          </div>
        </div>
        <ng-container *ngFor="let option of (data?.additional?.options ?? []); let optionIndex = index">
          <div *ngIf="option.show ?? true" class="printer-option flex flex-col">
            <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option, disabled: option.disabled ?? false, fromAddtional: true }"></ng-container>
            <div *ngFor="let childOption of (option.options ?? [])" class="printer-option flex flex-col gap-2 pt-2 pl-9">
              <ng-container *ngIf="childOption.show ?? true" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option: childOption, disabled: option.disabled || !option.selected || childOption.disabled ?? false, fromAddtional: true }"></ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isLoading" class="flex items-center justify-center py-50">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
  <div svc-dialog-actions class="flex gap-2 justify-end">
    <svc-button mode="stroked" type="button" (click)="dialog?.closeDialog()" [disabled]="isLoading">
      {{ 'Fechar' | transloco }}
    </svc-button>
    <svc-button mode="flat" color="primary" type="button" [disabled]="isLoading || !someOptionSeleted" (click)="!isLoading && someOptionSeleted && print()" cdkFocusInitial>
      {{ 'Imprimir' | transloco }}
    </svc-button>
  </div>
</svc-dialog>

<ng-template #optionTemplate let-option="option" let-disabled="disabled" let-fromAddtional="fromAddtional">
  <div class="flex items-center gap-2">
    <div>
      <mat-checkbox class="flex -mr-2" [checked]="option.selected ?? false" (change)="!disabled && changeOptionSelected(option)" [disabled]="disabled"></mat-checkbox>
    </div>
    <div #elementContainer *ngIf="option.miniature && option.element" [ngClass]="{ 'cursor-pointer': !disabled, 'opacity-50': disabled }" (click)="!disabled && changeOptionSelected(option, fromAddtional)">
      <svc-printer-miniature [option]="option" [printAreaWidth]="data.printAreaWidth"/>
    </div>
    <div class="flex-auto text-sm font-semibold text-current" [ngClass]="{ 'cursor-pointer': !disabled, 'opacity-50': disabled }" (click)="!disabled && changeOptionSelected(option, fromAddtional)">
      {{ option.name }}
    </div>
  </div>
  <ng-container *ngIf="option.selected && option.elements?.length && option.areAllImageElements">
    <svc-printer-img-carousel class="w-full max-w-80 mt-2" [option]="option"/>
  </ng-container>
</ng-template>