import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil, tap } from 'rxjs';
import { NotificationsService } from './services/notifications.service';
import { Notification } from './models/notifications.types';
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { environment } from 'projects/environments/environment';
import { AuthService } from 'projects/lib-shared-core/src/public-api';
import { NewsletterService } from './services/newsletter.service';
import { CommunicationService } from './services/communication.service';
import { MatTabGroup } from '@angular/material/tabs';
import { NewsletterService as NewsletterModalService } from '../../modals/modal-insights/modals/newsletter/newsletter.service';

@Component({
  selector: 'svc-notifications',
  templateUrl: './svc-notifications.component.html',
  styleUrls: ['./svc-notifications.component.scss']
})
export class SvcNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  protected totalUnreadCommunication: number = 0;
  protected totalUnredNotifications: number = 0;
  protected totalUnredNewsletter: number = 0;
  protected totalUnread: number = 0;
  protected env = environment;

  private _timeStartNotificationHubConnection: number = 5000;
  private _timeStartToastHubConnection: number = 5000;
  private _notificationHubConnection: HubConnection;
  private _toastHubConnection: HubConnection;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll = new Subject<any>();
  private _selectedTabIndex = 0;


  constructor(
    private _authService: AuthService,
    private _notificationsService: NotificationsService,
    private _communicationService: CommunicationService,
    private _newsletterService: NewsletterService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _appConfig: AppEnvironmentConfig,
    private _toastService: SvcToastService,
    private _newsletterModalService: NewsletterModalService
  ) { }

  ngOnInit(): void {
    this._notificationsService.totalUnread$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((totalUnread) => {
        this.totalUnredNotifications = totalUnread;
        this._updateUnreadTotals();
      }),
    ).subscribe();
    this._newsletterService.totalUnread$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((totalUnread) => {
        this.totalUnredNewsletter = totalUnread;
        this._updateUnreadTotals();
      }),
    ).subscribe();
    this._communicationService.totalUnread$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((totalUnread) => {
        this.totalUnreadCommunication = totalUnread;
        this._updateUnreadTotals();
      }),
    ).subscribe();

    this._newsletterService.getTotalUnread();
    this._newsletterService.getAllNewsletter();
    this._communicationService.getTotalUnread();
    this._notificationsService.getAll();
    this._manageSignalHubs();
  }

  protected onTabIndexChanged(index: number) {
    this._selectedTabIndex = index;
    if (index === 0) {
      this._notificationsService.tryMarkAllAsRead();
    }
  }

  private _updateUnreadTotals() {
    this.totalUnread = this.totalUnredNewsletter + this.totalUnredNotifications + this.totalUnreadCommunication;
  }

  private _manageSignalHubs() {
    this.prepareListenNotificationHub();
    this.startListenNotificationHub();

    this.prepareListenToastHub();
    this.startListenToastHub();
  }

  private prepareListenNotificationHub() {
    this._notificationHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlNotification}/notificationHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this._notificationHubConnection.onclose(async () => {
      await this.startListenNotificationHub();
    });

    this._notificationHubConnection.onreconnecting((error) => {
      console.log(`[NotificationSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this._notificationHubConnection.on('SendNotification', (notification: Notification) => {
      this._notificationsService.setNotifications(notification);
    });
  }

  private async startListenNotificationHub() {
    try {
      if (this._notificationHubConnection) {
        await this._notificationHubConnection.start();
      }
    }
    catch(e) {
      console.log(e);
      setTimeout(() => this.startListenNotificationHub(), this._timeStartNotificationHubConnection);
      this._timeStartNotificationHubConnection += 5000;
    }
  }

  private prepareListenToastHub() {
    this._toastHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlNotification}/toastHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this._toastHubConnection.onclose(async () => {
      await this.startListenNotificationHub();
    });

    this._toastHubConnection.onreconnecting((error) => {
      console.log(`[ToastSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this._toastHubConnection.on('SendToast', (toast: { title: string }) => {
      this._toastService.success(
        toast.title,
        null,
        {
          hideIcon: true,
          timeout: 10000,
        }
      );
    });
  }

  private async startListenToastHub() {
    try {
      if (this._toastHubConnection) {
        await this._toastHubConnection.start();
      }
    }
    catch(e) {
      console.log(e);
      setTimeout(() => this.startListenToastHub(), this._timeStartToastHubConnection);
      this._timeStartToastHubConnection += 5000;
    }
  }

  protected openPanel(): void {
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    if (!this._overlayRef) {
      this._createOverlay();
    }

    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
    this.onTabIndexChanged(this._selectedTabIndex);

    setTimeout(() => {
      const tabHeader = this.tabGroup._tabHeader;
      tabHeader._scrollHeader = (direction: string) => {
        const scrollAmount = ((direction == 'before' ? -1 : 1) * tabHeader._tabListContainer.nativeElement.offsetWidth) / 1.5;
        tabHeader.scrollDistance = this.tabGroup._tabHeader.scrollDistance + scrollAmount;
        return { maxScrollDistance: 0, distance: 0};
      }
    })
  }

  protected closePanel(): void {
    this._overlayRef.detach();
  }

  openNewsletter(event) {
    this.closePanel();
    this.markAsRead(event.versionId);
    this._newsletterService.getEnvironmentById(event.versionId).subscribe(item => {
      this._newsletterModalService.showModalWithData({...item});
    });
  }

  private markAsRead(id: number) {
    this._newsletterService.markAsRead(id).subscribe(() => {
      this._newsletterService.getTotalUnread();
    });
  }

  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'svc-backdrop-on-mobile',
      panelClass: 'svc-notifications-panel',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    this._notificationHubConnection.stop();
    this._toastHubConnection.stop();
    this._notificationHubConnection = null;
    this._toastHubConnection = null;
  }
}
