import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvcIconComponent } from './svc-icon/svc-icon.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";


@NgModule({
	declarations: [
		SvcIconComponent
	],
	exports: [
		SvcIconComponent
	],
	imports: [
		MatIconModule,
		NgSwitchCase,
		NgSwitch,
		NgIf,
		NgClass
	],
	providers: [
		provideHttpClient(withInterceptorsFromDi())
	],
})
export class LibSharedIconsModule {

    constructor(
      private _domSanitizer: DomSanitizer,
      private _matIconRegistry: MatIconRegistry
  )
  {
      const getUrl = this._domSanitizer.bypassSecurityTrustResourceUrl.bind(this._domSanitizer);
      // Register default icons library
      this._matIconRegistry.addSvgIconSet(getUrl('assets/icons/material-twotone.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', getUrl('assets/icons/material-outline.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', getUrl('assets/icons/material-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('feather', getUrl('assets/icons/feather.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', getUrl('assets/icons/heroicons-outline.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', getUrl('assets/icons/heroicons-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', getUrl('assets/icons/heroicons-mini.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_regular', getUrl('assets/icons/fontawesome-regular.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_solid', getUrl('assets/icons/fontawesome-solid.svg'));
      this._matIconRegistry.addSvgIconSetInNamespace('fontawesome_brands', getUrl('assets/icons/fontawesome-brands.svg'));

      // Register solvace custom icons
      this._matIconRegistry.addSvgIconSetInNamespace('svc_solid', getUrl('assets/icons/solvace-solid.svg'));
  }
}
