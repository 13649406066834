import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { HttpErrorService, SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import { CommonPagination } from 'projects/lib-shared-model/src/public-api';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, finalize, forkJoin, lastValueFrom, map, tap, throwError } from 'rxjs';
import { FeedItem, FeedItemFromSignalR, SvcFeedNoticationType } from '../models/feed-item.model';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AuthService } from 'projects/lib-shared-core/src/public-api';
import { Application } from '../models/applications.model';
import { TranslocoService } from '@ngneat/transloco';

const DEFAULT_PAGE_SIZE = 30;

export interface SvcFeedResponse {
  feeds: FeedItem[];
  dataAreOver: boolean;
}

@Injectable()
export class SvcFeedService extends SvcHttpClient {

  /** FEED PROPERTIES - BEGIN */
  private _feed = new BehaviorSubject<SvcFeedResponse>({
    feeds: [],
    dataAreOver: false,
  });
  public get feed$() { return this._feed.asObservable(); }

  private _feedIsLoading = new BehaviorSubject<boolean>(true);
  public get feedIsLoading$() { return this._feedIsLoading.asObservable(); }

  private _hasErrorFeed = new Subject<boolean>();
  hasErrorFeed$ = this._hasErrorFeed.asObservable();

  private _hasErrorTotalUnread = new Subject<boolean>();
  hasErrorTotalUnread$ = this._hasErrorTotalUnread.asObservable();

  private _feedPageIndex: number = 1;
  public get feedPageIndex() { return this._feedPageIndex; }

  private _feedPageSize: number = DEFAULT_PAGE_SIZE;
  public get feedPageSize() { return this._feedPageSize; }

  private _feedParameters: {
    filter?: string,
    applicationIds?: string[],
    startDate?: string,
    endDate?: string,
  } = {};
  private _feedSubscription: Subscription;

  private _recentlyChangedFeedIds: number[] = [];
  /** FEED PROPERTIES - END */

  /** TOTAL PROPERTIES - BEGIN */
  private _totalUnread = new BehaviorSubject<number>(null);
  public get totalUnread$() { return this._totalUnread.asObservable(); }

  private _totalUnreadIsLoading = new BehaviorSubject<boolean>(true);
  public get totalUnreadIsLoading$() { return this._totalUnreadIsLoading.asObservable(); }

  private _totalUnreadSubscription: Subscription;
  /** TOTAL PROPERTIES - END */

  /** SIGNAL R - BEGIN */
  private _feedHubConnection: HubConnection;
  private _newFeedReceived = new Subject<FeedItem>();
  public get newFeedReceived$() { return this._newFeedReceived.asObservable(); }
  /** SIGNAL R - END */

  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
    protected _authService: AuthService,
    protected _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
  ) {
    super(
      _appConfig.APIs.apiUrlCommentManager,
      _httpClient
    );
  }

  /** FEED METHODS - BEGIN */
  public getFeed(
    params?: {
      filter?: string,
      applicationIds?: string[],
      startDate?: string,
      endDate?: string,
    },
    options?: {
      preservePrevParams?: boolean
    }
  ) {
    params = params ? { ...params } : {};
    this._feedIsLoading.next(true);
    for (const key in params ?? {}) {
      const value = params[key];
      if (value == null || value === '' || (Array.isArray(value) && !value.length)) {
        delete params[key];
        continue;
      }
      if (Array.isArray(value)) {
        params[key] = value.join();
      }
    }
    this._feedParameters = {
      ...((options?.preservePrevParams ?? false) ? this._feedParameters : {}),
      ...params,
    };

    this._resetFeeds();

    this._feedSubscription?.unsubscribe();
    this._feedSubscription = this.get<CommonPagination<FeedItem[]>>('/Manager', {
      params: {
        ...this._feedParameters,
        pageSize: this._feedPageSize,
        pageIndex: this._feedPageIndex,
      }
    }).pipe(
      tap((response) => {
        this._feed.next({
          feeds: this.handleFeeds(response.data),
          dataAreOver: response.data.length < this.feedPageSize,
        });
        this._hasErrorFeed.next(false);
      }),
      catchError((error) => {
        this._hasErrorFeed.next(true);
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => this._feedIsLoading.next(false)),
    ).subscribe();
  }

  public feedNextPage() {
    if (!this._feedIsLoading.value && !this._feed.value.dataAreOver) {
      this._feedIsLoading.next(true);
      this._feedSubscription = this.get<CommonPagination<FeedItem[]>>('/Manager', {
        params: {
          ...this._feedParameters,
          pageSize: this._feedPageSize,
          pageIndex: ++this._feedPageIndex,
        }
      }).pipe(
        tap((response) => {
          this._feed.next({
            feeds: [...this._feed.value.feeds, ...this.handleFeeds(response.data)],
            dataAreOver: response.data.length < this.feedPageSize,
          });
        }),
        finalize(() => this._feedIsLoading.next(false)),
      ).subscribe();
    }
  }

  private _resetFeeds() {
    this._feedPageIndex = 1;
    this._feedPageSize = DEFAULT_PAGE_SIZE;
    this._feed.next({
      feeds: [],
      dataAreOver: false,
    });
  }

  private handleFeeds(feeds: FeedItem[]) {

    return feeds.map((feed) => ({
      ...feed,
      commentContent: feed.commentContent
    }));
  }
  /** FEED METHODS - END */

  /** TOTAL METHODS - BEGIN */
  public getTotalUnread() {
    this._totalUnreadIsLoading.next(true);
    this._totalUnreadSubscription?.unsubscribe();
    this._totalUnreadSubscription = this.get<number>('/Manager/TotalNoRead').pipe(
      tap((response) => {
        this._totalUnread.next(response);
        this._hasErrorTotalUnread.next(false);
      }),
      catchError((error) => {
        this._hasErrorTotalUnread.next(true);
        return error;
      }),
      finalize(() => this._totalUnreadIsLoading.next(false)),
    ).subscribe();
  }
  /** TOTAL METHODS - END */

  /** SIGNAL R - BEGIN */
  private timeStartHubConnection: number = 5000;
  public stopListenSignalR() {
    this._feedHubConnection?.stop();
    this._feedHubConnection = null;
  }

  public async startListenSignalR() {
    try {
      if (await lastValueFrom(this._authService.check()) && this._feedHubConnection) {
        await this._feedHubConnection.start();
      }
    }
    catch (e) {
      console.log(e);
      setTimeout(() => this.startListenSignalR(), this.timeStartHubConnection);
      this.timeStartHubConnection += 5000;
    }
  }

  public prepareListenSignalR() {
    this._feedHubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlCommentManager}/notificationHub`, {
        accessTokenFactory: () => this._authService.accessToken,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this._feedHubConnection.onclose(async () => {
      await this.startListenSignalR();
    });

    this._feedHubConnection.onreconnecting((error) => {
      console.log(`[FeedSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this._feedHubConnection.on('CommentCreated', (feed: FeedItemFromSignalR) => {
      if (this._isRecentlyChangedFeedId(feed.commentHeaderId)) {
        this._removeRecentlyChangedFeedId(feed.commentHeaderId);
        return;
      }
      if ([SvcFeedNoticationType.PINNING].includes(feed.typeNotification)) {
        this.getFeed(null, { preservePrevParams: true });
        return;
      }

      const allCurrentFeeds = this._feed.value.feeds;
      const pinnedCurrentFeeds = allCurrentFeeds.filter((f) => f.isPinned ?? false);
      const unpinnedCurrentFeeds = allCurrentFeeds.filter((f) => !(f.isPinned ?? false));
      const feedIndex = allCurrentFeeds.findIndex(f => f.commentHeaderId === feed.commentHeaderId);
      feed = <FeedItemFromSignalR>this.handleFeeds([feed])[0];
      let newFeeds: FeedItem[];
      if (feedIndex >= 0) {
        if (feed.typeNotification === SvcFeedNoticationType.NEW_COMMENT) {
          newFeeds = [
            ...pinnedCurrentFeeds,
            feed,
            ...unpinnedCurrentFeeds.slice(0, feedIndex),
            ...unpinnedCurrentFeeds.slice(feedIndex + 1),
          ];
        }
        else if ([SvcFeedNoticationType.PINNING, SvcFeedNoticationType.MARK_UN_READ].includes(feed.typeNotification)) {
          newFeeds = allCurrentFeeds.map((item) => {
            if (item.commentHeaderId === feed.commentHeaderId) {
              if (feed.typeNotification === SvcFeedNoticationType.MARK_UN_READ) item.isMarkedUnRead = feed.isMarkedUnRead;
              if (feed.typeNotification === SvcFeedNoticationType.PINNING) item.isPinned = feed.isPinned;
            }
            return item;
          });
        }
      }
      else if (feed.typeNotification === SvcFeedNoticationType.NEW_COMMENT) {
        this._feedPageSize++;
        newFeeds = [
          ...pinnedCurrentFeeds,
          feed,
          ...unpinnedCurrentFeeds
        ]
      }

      this._feed.next({
        feeds: newFeeds,
        dataAreOver: this._feed.value.dataAreOver,
      });
      this._newFeedReceived.next(feed);
      this.getTotalUnread();
    });

  }
  /** SIGNAL R - END */

  /** OTHERS ENDPOINTS - BEGIN */
  public getFilters() {
    return forkJoin({
      applications: this.get<Application[]>('/Application').pipe(
        map((response) => response.map((app) => ({
          ...app,
          description: this._translocoService.translate(app.description),
        })))
      )
    });
  }

  public getUserHasPermission(params: { commentHeaderId: number, registryId: number | string }) {
    const isString = typeof params.registryId === 'string';
    const path = `/Manager/${params.commentHeaderId}/` + (isString ? 'registryUId' : 'registryId') + `/${params.registryId}/userHasPermission`;
    return this.get<boolean>(path);
  }

  public markAsReadByCommentHeaderId(commentHeaderId: number) {
    return this.put<boolean>(`/Manager/${commentHeaderId}/MarkAsRead`, null);
  }

  public changeFeedPin(commentHeaderId: number, params: { value: boolean }) {
    this._addRecentlyChangedFeedId(commentHeaderId);
    return this.post<boolean>(`/Manager/${commentHeaderId}/PinFeed`, null, {
      params: {
        isPinned: params.value,
      }
    }).pipe(
      catchError((error) => {
        this._removeRecentlyChangedFeedId(commentHeaderId);
        return throwError(() => error);
      })
    );
  }

  public markFeedAsUnread(commentHeaderId: number) {
    this._addRecentlyChangedFeedId(commentHeaderId);
    return this.post<boolean>(`/Manager/${commentHeaderId}/MarkFeedUnRead`, null, {
      params: {
        isMarkedUnRead: true,
      }
    }).pipe(
      catchError((error) => {
        this._removeRecentlyChangedFeedId(commentHeaderId);
        return throwError(() => error);
      })
    );
  }

  public markFeedAsRead(commentHeaderId: number) {
    this._addRecentlyChangedFeedId(commentHeaderId);
    return this.post<boolean>(`/Manager/${commentHeaderId}/MarkFeedUnRead`, null, {
      params: {
        isMarkedUnRead: false,
      }
    }).pipe(
      catchError((error) => {
        this._removeRecentlyChangedFeedId(commentHeaderId);
        return throwError(() => error);
      })
    );
  }

  private _isRecentlyChangedFeedId(feedId: number) {
    return this._recentlyChangedFeedIds.includes(feedId);
  }

  private _addRecentlyChangedFeedId(feedId: number) {
    if (!this._recentlyChangedFeedIds.includes(feedId)) {
      this._recentlyChangedFeedIds.push(feedId);
    }
  }

  private _removeRecentlyChangedFeedId(feedId: number) {
    this._recentlyChangedFeedIds = this._recentlyChangedFeedIds.filter((id) => id !== feedId);
  }
  /** OTHERS ENDPOINTS - END */

}
