<div class="flex flex-col border border-inherit height-inherit bg-card rounded-lg shadow">
  <div class="flex flex-col divide-y height-inherit cursor-pointer" (click)="onCardClick.emit()">

    <!--HEADER INFO-->
    <div class="flex flex-col space-y-3 p-3"  [id]="'praise-open-'+item.id">
      <div class="flex flex-row justify-between">
        <div class="text-sm text-black">
          <span class="font-bold">{{ item.user.name }}</span>
          <span>
            {{ 'enviou um elogio' | transloco }}
          </span>
        </div>
        <div class="text-sm whitespace-nowrap">
          <span class="text-secondary">
            {{ item.date | timeHasPassed }}
          </span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-row items-center space-x-3">
          <div class="flex -space-x-4" [style.minWidth.px]="item.avatar.data.length > 1 ? 70 : null">
            <svc-avatar-multiple [info]="item.avatar"></svc-avatar-multiple>
          </div>
          <div class="inline-grid leading-tight">
            <p class="text-base font-semibold text-default truncate">
              {{ item.avatar.data | getProperty:'name' | join }}
            </p>
            <p class="text-xl font-semibold text-primary truncate" *ngIf="item.title">
              {{ item.title }}
            </p>
          </div>
        </div>
        <div>
          <svg width="60" viewBox="0 0 100 112" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M90.5467 87.9107L57.2113 107.38C52.7469 109.988 47.2468 109.988 42.7825 107.38L9.44702 87.9107C4.98267 85.3035 2.23242 80.4848 2.23242 75.2701V36.3312C2.23242 31.1165 4.98267 26.298 9.44702 23.6907L42.7825 4.22114C47.2468 1.61379 52.7469 1.61379 57.2113 4.22114L90.5467 23.6907C95.011 26.298 97.7611 31.1165 97.7611 36.3312V75.2701C97.7611 80.4848 95.011 85.3035 90.5467 87.9107Z"
              fill="#9D8ABB" class="fill-primary-200" />
            <path
              d="M86.4003 84.6232L56.4729 102.102C52.4651 104.443 47.5273 104.443 43.5193 102.102L13.5921 84.6232C9.5841 82.2825 7.11523 77.9566 7.11523 73.2749V38.3173C7.11523 33.6357 9.5841 29.3096 13.5921 26.969L43.5193 9.49002C47.5273 7.14916 52.4651 7.14916 56.4729 9.49002L86.4003 26.969C90.4081 29.3096 92.8772 33.6357 92.8772 38.3173V73.2749C92.8772 77.9566 90.4081 82.2825 86.4003 84.6232Z"
              fill="#451E81" class="fill-primary" />
            <path
              d="M84.3899 83.0338L56.1207 99.5442C52.3351 101.755 47.6707 101.755 43.8849 99.5442L15.6158 83.0338C11.83 80.8226 9.49805 76.7364 9.49805 72.3141V39.2935C9.49805 34.8714 11.83 30.785 15.6158 28.5741L43.8849 12.0636C47.6707 9.85249 52.3351 9.85249 56.1207 12.0636L84.3899 28.5741C88.1755 30.785 90.5077 34.8714 90.5077 39.2935V72.3141C90.5077 76.7364 88.1755 80.8226 84.3899 83.0338Z"
              fill="white" />
            <path
              d="M53.0458 22.4636L60.1307 38.362C60.6166 39.4521 61.6358 40.2011 62.8108 40.3317L79.947 42.2351C82.8069 42.5529 83.9578 46.136 81.8286 48.0935L69.0712 59.8226C68.1964 60.627 67.8071 61.8391 68.0475 63.0097L71.5534 80.0845C72.1383 82.934 69.1253 85.1486 66.627 83.705L51.6577 75.056C50.6312 74.4627 49.3713 74.4627 48.3448 75.056L33.3755 83.705C30.8772 85.1486 27.8639 82.934 28.4491 80.0845L31.955 63.0097C32.1954 61.8391 31.8061 60.627 30.9313 59.8226L18.1739 48.0935C16.0447 46.136 17.1956 42.5529 20.0555 42.2351L37.1917 40.3317C38.3667 40.2011 39.386 39.4521 39.8718 38.362L46.9567 22.4636C48.1391 19.8103 51.8634 19.8103 53.0458 22.4636Z"
              fill="#451E81" class="fill-primary" />
            <path
              d="M75.2811 54.1016C59.1879 61.0423 43.4457 63.8993 31.5409 65.0049L28.4468 80.0751C27.8618 82.9245 30.8749 85.1391 33.3731 83.6956L48.3425 75.0465C49.369 74.4533 50.6289 74.4533 51.6553 75.0465L66.6247 83.6956C69.1229 85.1391 72.136 82.9245 71.551 80.0751L68.0451 63.0003C67.8047 61.8296 68.1941 60.6175 69.0688 59.8131L75.2811 54.1016Z"
              fill="#9D8ABB" class="fill-primary-200" />
            <path
              d="M50.5047 30.7637C49.7896 32.8054 48.3917 34.1675 47.3824 33.8057C46.3733 33.4442 46.1347 31.4957 46.85 29.4538C47.5651 27.4119 48.963 26.0499 49.9721 26.4117C50.9815 26.7733 51.2197 28.7218 50.5047 30.7637Z"
              fill="#FFF7E1" />
            <path
              d="M47.5431 36.3242C47.5431 37.0713 46.9445 37.6767 46.2061 37.6767C45.4678 37.6767 44.8691 37.0713 44.8691 36.3242C44.8691 35.5773 45.4678 34.9717 46.2061 34.9717C46.9445 34.9717 47.5431 35.5773 47.5431 36.3242Z"
              fill="#FFF7E1" />
            <path
              d="M72.1375 47.1233C70.0035 46.9449 68.3449 45.9251 68.433 44.8457C68.5213 43.7663 70.3229 43.0359 72.4569 43.2143C74.591 43.3928 76.2495 44.4125 76.1612 45.4919C76.0731 46.5713 74.2716 47.3018 72.1375 47.1233Z"
              fill="#FFF7E1" />
            <path
              d="M66.0595 45.643C65.3454 45.8336 64.6139 45.4026 64.4255 44.6805C64.237 43.9582 64.6631 43.2181 65.3771 43.0275C66.0909 42.8369 66.8225 43.268 67.0109 43.9903C67.1994 44.7124 66.7733 45.4524 66.0595 45.643Z"
              fill="#FFF7E1" />
          </svg>
        </div>
      </div>
    </div>
    <!--/HEADER INFO-->

    <!--MAIN INFO-->
    <div class="flex flex-col flex-auto pt-3 px-3 space-y-3 text-default">
      <div class="text-base flex flex-auto line-clamp-2 break-words" *ngIf="item.description">
        <p class="line-clamp-2 break-words break-all">
          {{ item.description }}
        </p>
      </div>
      <div class="flex flex-row items-center justify-end gap-4 pb-1">
        <div class="flex flex-auto justify-start" (click)="$event.stopPropagation()">
          <svc-total-reactions [registryUniqueId]="item.id" [reaction]="svcReaction" [applicationId]="applicationId"></svc-total-reactions>
        </div>

        <svc-totals-status #svcTotalsStatus
          [ngClass]="{ 'hidden': !svcTotalsStatus.isLoading && svcTotalsStatus.total <= 0 }"
          [referenceId]="item.id"
          [applicationId]="applicationId"
          type="text"
          [configTypeId]="configTypeId"
          [featureName]="featureName"
          [available]="{ views: true, comments: true }"
        ></svc-totals-status>
      </div>
    </div>
    <!--/MAIN INFO-->

    <!--BUTTONS-->
    <div class="flex flex-row justify-center gap-3 sm:gap-4 pb-2" (click)="$event.stopPropagation()">
      <svc-reaction class="flex w-fit" #svcReaction
        [registryUniqueId]="item.id"
        [applicationId]="applicationId"
        [disabled]="isReactionLoading"
        [dontEmitFirstTime]="true"
        positionX="left"
        (onLoadingChange)="isReactionLoading = $event"
      >
        <button class="flex min-h-10 px-1.5 rounded-none cursor-pointer rounded-t-none rounded-b-lg" mat-flat-button type="button"
          [ngClass]="svcReaction.hasReacted ? 'text-primary' : 'text-gray-600'"
          (click)="!isReactionLoading && (svcReaction.hasReacted ? svcReaction?.removeCurrentReaction() : svcReaction?.makeReactionByEnum('LIKE'))">
          <ng-container *ngIf="!isReactionLoading">
            <mat-icon class="align-middle icon-size-6" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
            <span class="align-middle ml-2">{{ 'Reagir' | transloco }}</span>
          </ng-container>
          <div class="flex items-center justify-center" *ngIf="isReactionLoading">
            <ngx-skeleton-loader [theme]="{'margin-bottom': 0,width: '60px',height: '24px'}"></ngx-skeleton-loader>
          </div>
        </button>
      </svc-reaction>
      <button mat-flat-button class="flex w-fit px-1.5 min-h-10 text-gray-600 rounded-t-none rounded-b-lg" type="button" (click)="onCardClick.emit({ comments: true })" [id]="'praise-comment-'+item.id">
        <mat-icon class="icon-size-6" svgIcon="mat_solid:chat"></mat-icon>
        <span class="ml-2">{{ 'Comentar' | transloco }}</span>
      </button>
    </div>
    <!--/BUTTONS-->

  </div>
</div>
