import { Injectable } from '@angular/core';
import {BehaviorSubject, finalize, Observable, Subject, Subscription, tap} from 'rxjs';
import {AppEnvironmentConfig} from 'projects/config/model/environment.config.model';
import {HttpClient} from '@angular/common/http';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import { CommonPagination } from 'projects/lib-shared-model/src/public-api';

export interface Communication {
  communicationId:  number;
  title:            string;
  message:          string;
  isRead:           boolean;
  daysCreated:      number;
  createUserId:     string;
  originatorAvatar: {
    userId:       string;
    name:         string;
    pictureFile:  string;
    initialColor: string;
  }
}

export interface CommunicationsParams {
  pageSize: number,
  pageIndex: number,
  filter?: string,
  typeIds?: string[],
  statusIds?: string[],
  originatorUserIds?: string[],
  participantUserIds?: string[],
  isBookMark?: boolean,
  isNotRead?: boolean,
  createDate?: string,
  sendDate?: string,
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationService extends SvcHttpClient {

  private _getAllSubscription: Subscription;
  private _pageIndex = 1;
  private _pageSize = 20;
  private _areGone: boolean = false;
  private _communicationItems: Communication[] = [];

  private _isCommunicationsLoading: BehaviorSubject<boolean>  = new BehaviorSubject(false);
  public isCommunicationsLoading$: Observable<boolean> = this._isCommunicationsLoading.asObservable();

  private _communications: BehaviorSubject<{communications: Communication[], areGone: boolean}>  = new BehaviorSubject({
    communications: this._communicationItems,
    areGone: this._areGone
  });
  public communications$: Observable<{communications: Communication[], areGone: boolean}> = this._communications.asObservable();

  private _isTotalUnreadLoading: BehaviorSubject<boolean>  = new BehaviorSubject(false);
  public isTotalUnreadLoading$: Observable<boolean> = this._isTotalUnreadLoading.asObservable();

  private _totalUnread: BehaviorSubject<number>  = new BehaviorSubject(0);
  public totalUnread$: Observable<number> = this._totalUnread.asObservable();


  constructor(appConfig: AppEnvironmentConfig, httpClient: HttpClient) {
    super(appConfig.APIs.apiUrlCommunication, httpClient);
  }

  public getTotalUnread() {
    this._isTotalUnreadLoading.next(true);
    return this.get<number>('/Communication/UnreadByUser').pipe(
      tap((unread) => {
        this._totalUnread.next(unread);
      }),
      finalize(() => this._isTotalUnreadLoading.next(false))
    ).subscribe();
  }

  public getAllCommunications() {
    this._getAllSubscription?.unsubscribe();
    this._isCommunicationsLoading.next(true);
    this._getAllSubscription = this.get<CommonPagination<Communication[]>>(`/Communication/received`,{
      params: {
          pageIndex: this._pageIndex,
          pageSize: this._pageSize,
      }
      }).pipe(
      tap((response) => {
        this._communicationItems = this._pageIndex <= 1 ? response.data : [
          ...this._communicationItems,
          ...response.data
        ];
        this._areGone = response.data.length < this._pageSize;
        this._communications.next({
          communications: this._communicationItems,
          areGone: response.data.length < this._pageSize
        });
      }),
      finalize(() => this._isCommunicationsLoading.next(false))
    ).subscribe();
  }

  public nextPage(): void {
    this._pageIndex++;
    this.getAllCommunications();
  }
}
