import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable, Subject } from 'rxjs';

import { SvcDialogService } from 'projects/lib-shared-component/src/lib/svc-dialogs/svc-dialog.service';
import { SvcModalBosType } from '../enums/svc-modal-bos.enum';
import { SvcModalBosComponent } from '../../svc-modal-bos.component';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SvcModalBosService {

  private readonly _apiBos: string = this._appConfig.APIs.apiUrlBOS;
  public bosAdd$ = new Subject<void>();

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _dialogService: SvcDialogService,
    private _http: HttpClient,
    private _userService: UserService,
  ) { }

  public openModalBos(mode = SvcModalBosType.add, id?: number): void {
    this._dialogService.open(SvcModalBosComponent, {
      data: { mode, id },
      width: '100%',
      maxWidth: '500px',
      autoFocus: false,
    });
  }

  public getTypes(): Observable<ISvcSelectOption[]> {
    return this._http.get<{typeId: number, typeName: string}[]>(
      `${this._apiBos}/Type/BySiteId`, {
        params: {
          siteId: this._userService.user.lastSiteId
        }
      }
    ).pipe(
      map(types =>
        types?.map(type => (
          {
            text: type.typeName,
            value: type.typeId
          }
        ))
      )
    );
  }
}
