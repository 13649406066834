import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { ISvcStatusBadge } from './interfaces/svc-status-badge.interface';

@Component({
  selector: 'svc-status-badge',
  templateUrl: './svc-status-badge.component.html',
  styleUrls: ['./svc-status-badge.component.scss'],
  host: {
    '[class.w-full]': 'fullWidth',
  }
})
export class SvcStatusBadgeComponent implements OnChanges {
  @Input() label = '';
  @Input() textClass = null;
  @Input() badgeData: ISvcStatusBadge = { description: '-', type: 'none' };
  @Input() truncate: boolean = true;
  @Input() fullWidth: boolean = false;
  
  protected badgeClass = '';

  public ngOnChanges() {
    this.getBadgeColor();
  }

  getBadgeColor() {
    let color: string;
    switch (this.badgeData?.type) {
      case 'none':
        color = 'bg-slate-50';
        break;

      case 'waiting':
      case 'inactive':
      case 'rejected':
        color = 'bg-[#A6A6A6] text-white';
        break;

      case 'disaproved':
      case 'delayed':
      case 'late':
        color = 'bg-[#E22A2E] text-white';
        break;

      case 'expired':
        color = 'bg-[#CC3300] text-white';
        break;

      case 'opened':
      case 'pending':
      case 'revision':
      case 'analysis':
      case 'investigation':
      case 'ontime':
      case 'elaboration':
      case 'inElaboration':
        color = 'bg-[#FFC000] text-white';
        break;

      case 'planned':
      case 'revised':
      case 'resolution':
      case 'liberation':
      case 'investigated':
      case 'confirmation':
      case 'inAttention':
        color = 'bg-[#BF8F00] text-white';
        break;

      case 'homologation':
      case 'execution':
      case 'classification':
      case 'scheduled':
        color = 'bg-[#806000] text-white';
        break;

      case 'planned-without-delay':
      case 'avaliation':
      case 'released':
      case 'approval':
      case 'validated':
      case 'validation':
      case 'inProcess':
      case 'onTrack':
        color = 'bg-[#A9D08E] text-white';
        break;

      case 'active':
      case 'completed':
      case 'published':
      case 'resolved':
      case 'executed':
      case 'sent':
      case 'sended':
        color = 'bg-[#466900] text-white';
        break;

      case 'cmms':
        color = 'bg-[#715500] text-white';
        break;

      case 'subscribe':
        color = 'bg-[#2196F3] text-white';
        break;

      case 'closed':
        color = 'bg-[#9BA0A3] text-white';
        break;
    }

    this.badgeClass = color ?? '';
  }
}
