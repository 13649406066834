import {ModalInsightsDefault} from "./modal-insights-default";
import {ElementRef, inject} from "@angular/core";
import { SvcToastService } from "projects/lib-shared-component/src/public-api";
import {TranslocoService} from "@ngneat/transloco";

export abstract class ModalInsights<S extends ModalInsightsDefault<any>> {

  protected elementRef: ElementRef;
  protected toast: SvcToastService;
  protected transloco: TranslocoService;

  protected constructor(
    protected service: S,
  ) {
    this.elementRef = inject(ElementRef);
    this.toast = inject(SvcToastService);
    this.transloco = inject(TranslocoService);
  }
}
