import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, Subject, catchError, map, tap } from 'rxjs';

import { SvcDialogService, UserAvatarSize, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { Alert, AlertBodyCreate, AlertData, AlertDrilldownData, AlertDrilldownFilter, AlertListFilter, AlertResponseCreate } from '../interfaces/alert.interface';
import { DialogAlertType } from '../enums/dialog-alert-type.enum';
import { SvcModalAlertComponent } from '../../svc-modal-alert.component';
import { SitesService, StaticApplicationId } from 'projects/lib-shared-core/src/public-api';
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { AlertList } from '../interfaces/alert.interface';
import { AlertAttachmentMedias } from '../interfaces/alert-attachment.interface';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialogRef } from '@angular/material/dialog';
import { SvcTotalsStatusComponent } from 'projects/lib-shared-feature/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alertAdd$ = new Subject<void>();
  public activeCustomProperty: 'alert-list' | 'alert-card' = 'alert-list';
  public alertUpdated$ = new Subject<number>();

  private readonly _apiUrlAlert: string = this._appConfig.APIs.apiUrlAlert;
  private readonly _apiBookmark = this._appConfig.APIs.apiUrlBookmark;
  private _totalsStatusComponents: SvcTotalsStatusComponent[] = [];

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _dialogService: SvcDialogService,
    private _http: HttpClient,
    private _sitesService: SitesService,
    private _toastService: SvcToastService,
    private _httpErrorService: HttpErrorService,
    private _translocoService: TranslocoService
  ) {
    this._monitorAlertUpdates()
  }

  public registerTotalsStatus(components: SvcTotalsStatusComponent[]): void {
    this._totalsStatusComponents = components;
  }

  public updateTotalsStatus(components: SvcTotalsStatusComponent[]): void {
    this._totalsStatusComponents = components;    
  }

  private _monitorAlertUpdates(): void {    
    this.alertUpdated$    
    .subscribe((alertId) => {
      this._totalsStatusComponents
        ?.find((svcTotalStatus) => svcTotalStatus.referenceId === alertId)
        ?.refresh();
    });
  }

  private getParams(filter: AlertListFilter, params: HttpParams, key: string): HttpParams {
    const value = filter?.[key];

    if (Array.isArray(value))
      value.forEach((val: any) =>
        params = params.append(`${key}`, val)
      );
    else
      params = params.append(key, value);

    return params;
  }

  public getAlertsDataListParams(filter: AlertListFilter | AlertDrilldownFilter): HttpParams {
    let params = new HttpParams();
    Object.keys(filter)?.forEach((key: string) => {
      if (filter?.[key] || key === 'pageIndex')
        params = this.getParams(filter, params, key);
    });

    params = params.append('siteId', this._sitesService.currentSite.siteId);

    return params;
  }

  private mapDataAlerts(alert: Alert): Alert {
    alert.alerts = alert?.alerts.map((alertList: AlertList) => {
      return {
        ...alertList,
        svcUserAvatar: {
          name: alertList?.originator,
          picture: alertList?.userAvatar?.pictureUrl,
          initialsColor: alertList?.userAvatar?.preferenceColor,
          size: UserAvatarSize.Table
        }
      }
    });

    return alert;
  }

  public getAlerts(filter: AlertListFilter): Observable<Alert> {
    const params = this.getAlertsDataListParams(filter);

    return this._http.get<Alert>(`${this._apiUrlAlert}/Alert`, {
      params
    }).pipe(
      map((alert: Alert) => this.mapDataAlerts(alert))
    );
  }

  public getAlertsByType(type: 'alert-list' | 'alert-card', filter: AlertListFilter): Observable<Alert> {
    return type === 'alert-list' ? this.getAlerts(filter) : this.getAlertsCard(filter);
  }

  private mapDataAlertsCard(alert: Alert): Alert {
    alert.alerts = alert?.alerts?.map((alertList: AlertList) => {
      return {
        ...alertList,
        slides: alertList?.attachments?.map((attachment: AlertAttachmentMedias) => (
          {
            id: attachment?.attachmentId,
            title: alertList?.failure,
            url: attachment?.signedUrl,
            type: ['.png', '.jpg', '.jpeg', 'png', 'jpg', 'jpeg'].includes(attachment?.extension?.toLowerCase()) ? 'image' : 'video'
          }
        ))
      }
    });

    return alert;
  }

  public getAlertsCard(filter: AlertListFilter): Observable<Alert> {
    const params = this.getAlertsDataListParams(filter);

    return this._http.get<Alert>(`${this._apiUrlAlert}/Alert/Card`, {
      params
    })
      .pipe(
        map((alert: Alert) => this.mapDataAlertsCard(alert))
      );
  }


  public openAlert(mode: DialogAlertType, id?: number): void {
    this._dialogService.open(SvcModalAlertComponent, {
      data: { mode, id },
      width: '100%',
      maxWidth: '500px',
      autoFocus: false,
      panelClass: 'app-dialog-alert'
    });
  }

  public createOrUpateAlert(alert: AlertBodyCreate): Observable<AlertResponseCreate> {
    return alert?.alertId ? this.updateAlert(alert) : this.createAlert(alert);
  }

  public createAlert(alert: AlertBodyCreate): Observable<AlertResponseCreate> {
    return this._http.post<AlertResponseCreate>(`${this._apiUrlAlert}/Alert`, {
      ...alert,
      siteId: this._sitesService.currentSite.siteId
    });
  }

  public updateAlert(alert: AlertBodyCreate): Observable<AlertResponseCreate> {
    return this._http.put<AlertResponseCreate>(`${this._apiUrlAlert}/Alert`, {
      ...alert,
      siteId: this._sitesService.currentSite.siteId
    });
  }

  public getAlertById(alertId: number): Observable<AlertData> {
    return this._http.get<AlertData>(`${this._apiUrlAlert}/Alert/ById`, {
      params: {
        alertId,
        siteId: this._sitesService.currentSite.siteId
      }
    }).pipe(
      map((alert) => ({
        ...alert,
        productId: alert.productId ?? alert['productlId'],
      }))
    );
  }

  public exportFile(typeFile: 'pdf' | 'excel'): Observable<HttpResponse<Blob>> {
    return this._http.get(`${this._apiUrlAlert}/alert/download/${typeFile}`, {
      observe: 'response',
      responseType: 'blob',
      params: {
        siteId: this._sitesService.currentSite.siteId
      }
    });
  } 
  
  public changeFavorite(isBookmark: boolean, alertId: number): Observable<any> {
    return this.favoriteQuiz(isBookmark, alertId)
      .pipe(
        tap(() => {
          const messageKey = isBookmark ? 'adicionado aos' : 'removido dos';
          this._toastService.success(this._translocoService.translate(`Este quiz foi ${messageKey} favoritos com sucesso`));
        }),
        catchError(err => {
          this._httpErrorService.showErrorInToast(err)
          return err;
        })
      ) as Observable<any>;
  }

  public favoriteQuiz(isBookmark: boolean, alertId: number): Observable<any> {
    const type = isBookmark ? 'post' : 'delete';

    return this._http.request<any>(type, `${this._apiBookmark}/registryId`, {
      body: {
        registryUniqueId: alertId,
        applicationId: StaticApplicationId.alert,
        siteId: this._sitesService.currentSite.siteId
      }
    });
  }

  public getAlertDrilldown(filter: AlertDrilldownFilter): Observable<AlertDrilldownData> {
    const params = this.getAlertsDataListParams(filter);

    return this._http.get<AlertDrilldownData>(`${this._apiUrlAlert}/Alert/Drilldown`, {
      params
    });
  }

  public getAlertsByComment(filter: AlertListFilter): Observable<Alert> {
    const params = this.getAlertsDataListParams(filter);
    return this._http.get<Alert>(`${this._apiUrlAlert}/Alert/ByComment`, { params });
  }

}
