export interface ISvcStatusBadge {
    description: string;
    type:
      'none'
    | 'inactive'
    | 'waiting'
    | 'disaproved'
    | 'delayed'
    | 'late'
    | 'expired'
    | 'opened'
    | 'pending'
    | 'revision'
    | 'analysis'
    | 'investigation'
    | 'planned'
    | 'revised'
    | 'resolution'
    | 'liberation'
    | 'investigated'
    | 'confirmation'
    | 'homologation'
    | 'execution'
    | 'classification'
    | 'scheduled'
    | 'avaliation'
    | 'released'
    | 'approval'
    | 'validated'
    | 'validation'
    | 'active'
    | 'closed'
    | 'completed'
    | 'published'
    | 'resolved'
    | 'executed'
    | 'cmms'
    | 'subscribe'
    | 'ontime'
    | 'planned-without-delay'
    | 'elaboration'
    | 'inProcess'
    | 'sent'
    | 'inAttention'
    | 'onTrack'
    | 'inElaboration'
    | 'sended'
    | 'closed'
    | 'rejected'
}
