import {
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Directive({
  selector: '[longPress]',
})
export class LongPressDirective implements OnInit, OnDestroy {
  private _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  
  @Output() onLongPress = new EventEmitter();
  @Input() multiple = false;

  private eventSubscribe: Subscription;
  private threshold = 500;
  private alreadyEmitted = false;

  ngOnInit(): void {
    const mousedown = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mousedown').pipe(
      filter((event) => event.button == 0),
      map(() => true),
    );
    const touchstart = fromEvent(this._elementRef.nativeElement, 'touchstart').pipe(
      map(() => true),
    );
    const touchEnd = fromEvent(this._elementRef.nativeElement, 'touchend').pipe(
      map(() => false),
      tap(() => this.alreadyEmitted = false),
    );
    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button == 0),
      map(() => false),
      tap(() => this.alreadyEmitted = false),
    );
    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd).pipe(
      switchMap((state) => (!this.alreadyEmitted && state ? timer(this.threshold, 100) : of(null))),
      filter((value) => !!(value)),
      tap(() => {
        this.alreadyEmitted = !(this.multiple ?? false);
        this.onLongPress.emit();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.eventSubscribe?.unsubscribe();
  }
}