import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcModalBosComponent } from './svc-modal-bos.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from 'projects/lib-shared-component/src/lib/lib-shared-component.module';
import { SvcDialogsModule } from 'projects/lib-shared-component/src/lib/svc-dialogs/svc-dialogs.module';
import { SvcModalBosTypeComponent } from './shared/components/svc-modal-bos-type/svc-modal-bos-type.component';
import { SvcControlsModule } from 'projects/lib-shared-component/src/lib/svc-controls/svc-controls.module';
import { SvcButtonsModule, SvcSearchFieldModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcModalBosTabBehaviorsComponent } from './shared/components/svc-modal-bos-tab-behaviors/svc-modal-bos-tab-behaviors.component';
import { SvcAudioToTextButtonModule, SvcLocationFieldModule } from '../../../public-api';
import { DateFormatPipe } from 'projects/lib-shared-common/src/lib/pipes/date-format.pipe';
import { SvcFormCustomFieldModule } from '../../general/svc-form-custom-field/svc-form-custom-field.module';
import { SvcActionplanModule} from '../../general/svc-actionplan/svc-actionplan.module';
import { BosHttpClient } from './shared/services/bos-http-client';

@NgModule({
  declarations: [
    SvcModalBosComponent,
    SvcModalBosTypeComponent,
    SvcModalBosTabBehaviorsComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDialogsModule,
    SvcControlsModule,
    SvcUserAvatarModule,
    SvcButtonsModule,
    SvcSearchFieldModule,
    SvcAudioToTextButtonModule,
    SvcFormCustomFieldModule,
    SvcLocationFieldModule,
		SvcActionplanModule,
  ],
  exports: [
    SvcModalBosComponent,
  ],
  providers: [
    DatePipe,
    DateFormatPipe,
    BosHttpClient
  ]
})
export class SvcModalBosModule { }
