import { Component, OnInit } from '@angular/core';
import {User, UserIndicator} from '../services/user.types';
import { UserService } from '../services/user.service';
import {UserIndicatorsService} from "../services/user-indicators.service";
import {Subject, takeUntil} from "rxjs";
import { ISvcUserAvatar, UserAvatarSize } from 'projects/lib-shared-component/src/lib/svc-user-avatar/interfaces/svc-user-avatar.interface';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  user: User;
  userAvatar: ISvcUserAvatar;
  indicators: UserIndicator[];

  constructor(
    private _userService: UserService,
    private _userIndicatorsService: UserIndicatorsService
  )
  {}

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    // Subscribe to user changes
    this.user = this._userService.user;
    this.userAvatar = {
      name: this.user.firstLastName,
      picture: this.user.pictureFile,
      initialsColor: this.user.initialsColor,
      size: UserAvatarSize.Workspace
    }

    //Subscribe to user indicators
    this._userIndicatorsService._userIndicators$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((indicators) => {
        this.indicators = Object.keys(indicators).map((key) => {
          return indicators[key];
        });
      });
  }
}
