<section #draggingArea [class]="minYear && maxYear && !isLoading ? 'block' : 'hidden'">
  <div #leftLimit class="left-limit" [style.left]="leftLimitControl.position + '%'">
    <p
      id="left-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && leftLimitControl.currentYear && (!isOverlapping || isDraggingLeft)"
    >
      {{ leftLimitControl.currentYear }}
    </p>
  </div>

  <div #rightLimit class="right-limit" [style.right]="rightLimitControl.position + '%'">
    <p
      id="right-limit-text"
      class="text-sm"
      *ngIf="mouseDragging.isDragging && rightLimitControl.currentYear && (!isOverlapping || isDraggingRight)"
    >
      {{ rightLimitControl.currentYear }}
    </p>
  </div>
</section>
<div [class]="selectedRangeDate && !isLoading ? 'flex' : 'hidden'">
  <p class="flex-auto text-base font-light">{{ minYear }}</p>
  <p class="flex-auto text-base text-right font-light">{{ maxYear }}</p>
</div>

<div class="flex flex-col gap-0.5" *ngIf="isLoading">
  <ngx-skeleton-loader [theme]="{ width: '100%', height: '30px', borderRadius: '8px' }">
  </ngx-skeleton-loader>

  <div class="flex justify-between">
    <ngx-skeleton-loader [theme]="{ width: '36px', height: '24px', borderRadius: '0' }">
    </ngx-skeleton-loader>

    <ngx-skeleton-loader [theme]="{ width: '36px', height: '24px', borderRadius: '0' }">
    </ngx-skeleton-loader>  
  </div>
</div>