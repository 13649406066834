import { Injectable } from '@angular/core';
import {
  ActionPlanDrillDownStatusBadge,
  LibServiceActionplanAnalyticsService
} from 'projects/lib-service-actionplan-analytics/src/public-api';
import {
  InspectionDrillDownStatusBadge as ChecklistInspectionDrillDownStatusBadge,
  LibServiceChecklistAnalyticsService
} from 'projects/lib-service-checklist-analytics/src/public-api';
import {
  DefectTagDrillDownStatusBadge,
  LibServiceDefecttagAnalyticsService
} from 'projects/lib-service-defecttag-analytics/src/public-api';
import {
  InspectionDrillDownStatusBadge as LilInspectionDrillDownStatusBadge,
  LibServiceCilAnalyticsService
} from 'projects/lib-service-cil-analytics/src/public-api';
import { CommomAnalyticsIndicator } from "./models/commom-analytics-indicator";
import { Subject, Subscription, catchError, finalize, tap, throwError } from "rxjs";
import { TasksModule } from "./models/tasks-module";
import { TasksFilter } from "./models/tasks-filter";
import { TasksCategory } from './models/tasks-category';
import { getCustomThemeColor, HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SettingsService } from '../settings/services/settings.service';
import { StaticApplicationId } from '../../Constants/static-application-id.enum';

@Injectable({
  providedIn: 'root'
})
export class MainTasksService {

  hasFilter: boolean = false;
  _allTasks: any[][] = [];

  private set allTasks(value: any) {
    const moduleOrder = [TasksModule.ACTIONPLAN, TasksModule.DEFECTTAG, TasksModule.CIL, TasksModule.CHECKLIST];
    const sortAsc = (tasks) => tasks?.sort((a, b) => a.deadline - b.deadline || moduleOrder.indexOf(a.moduleName) - moduleOrder.indexOf(b.moduleName)) || [];
    const sortDesc = (tasks) => tasks?.sort((a, b) => b.deadline - a.deadline || moduleOrder.indexOf(a.moduleName) - moduleOrder.indexOf(b.moduleName)) || [];
    value[TasksCategory.LATE] = sortDesc(value[TasksCategory.LATE]);
    value[TasksCategory.TODAY] = sortDesc(value[TasksCategory.TODAY]);
    value[TasksCategory.TOMORROW_UP_TO_7_DAYS] = sortAsc(value[TasksCategory.TOMORROW_UP_TO_7_DAYS]);
    value[TasksCategory.MORE_THAN_7_DAYS] = sortAsc(value[TasksCategory.MORE_THAN_7_DAYS]);
    this._allTasks = value;
  }
  public get allTasks() {
    return this._allTasks;
  }

  public isUserIndicators = true;
  private _allTasksUpdated = new Subject<any>();
  allTasksUpdated$ = this._allTasksUpdated.asObservable();

  private _isAllTasksLoading = new Subject<any>();
  isAllTasksLoading$ = this._isAllTasksLoading.asObservable();

  private _hasError = new Subject<{ hasError: boolean, apiName?: string }>();
  hasError$ = this._hasError.asObservable();

  private filter: TasksFilter;

  private _taskFiltering = new Subject<TasksFilter>();
  taskFiltering$ = this._taskFiltering.asObservable();


  constructor(
    private _settingsService: SettingsService,
    private _checklistAnalyticsService: LibServiceChecklistAnalyticsService,
    private _actionPlanAnalyticsService: LibServiceActionplanAnalyticsService,
    private _defectTagAnalyticsService: LibServiceDefecttagAnalyticsService,
    private _cilAnalyticsService: LibServiceCilAnalyticsService,
  ) {
  }

  filterTasks(filter: TasksFilter) {
    this.filter = filter;
    if (filter) {
      let filtered = {};
      Object.keys(this.allTasks).forEach((key) => {
        filtered = {
          ...filtered,
          [key]: [...(this.allTasks[key])
            .filter((x: any) => x.moduleName == filter.module)
          ]
        }
      });
      this.hasFilter = true;
      this._allTasksUpdated.next({ ...filtered });
    } else {
      this.hasFilter = false;
      this._allTasksUpdated.next(this.allTasks);
    }

    this._taskFiltering.next(this.filter);
  }

  getUserDrillDowns(only?: { checklist?: boolean, lil?: boolean, actionPlan?: boolean, defectTag?: boolean }) {
    this.isUserIndicators = true;
    if (only && Object.keys(only).some((key) => only[key])) {
      only = {
        checklist: only.checklist ?? false,
        lil: only.lil ?? false,
        actionPlan: only.actionPlan ?? false,
        defectTag: only.defectTag ?? false,
      }
    }
    const onlyModules: TasksModule[] = only ? Object.keys(only).reduce((modules, key) => {
      if (only[key]) {
        if (key === 'checklist') modules.push(TasksModule.CHECKLIST);
        if (key === 'lil') modules.push(TasksModule.CIL);
        if (key === 'actionPlan') modules.push(TasksModule.ACTIONPLAN);
        if (key === 'defectTag') modules.push(TasksModule.DEFECTTAG);
      }
      return modules;
    }, []) : [];
    const isGeAll = !(onlyModules.length);

    if (isGeAll) this._allTasks = [];
    this.allTasks[TasksCategory.LATE] = isGeAll ? [] : (this._allTasks[TasksCategory.LATE]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.TODAY] = isGeAll ? [] : (this._allTasks[TasksCategory.TODAY]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.TOMORROW_UP_TO_7_DAYS] = isGeAll ? [] : (this._allTasks[TasksCategory.TOMORROW_UP_TO_7_DAYS]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.MORE_THAN_7_DAYS] = isGeAll ? [] : (this._allTasks[TasksCategory.MORE_THAN_7_DAYS]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasksUpdated.next(this.allTasks);

    let requests: Subscription[] = [];
    const checkIfAllDone = () => {
      if (requests.every(x => x.closed)) {
        this._isAllTasksLoading.next(false);
      }
    }
    this._isAllTasksLoading.next(true);

    if (only?.actionPlan ?? true)
      requests.push(this._actionPlanAnalyticsService.getActionPlanCategorizedDrillDown().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addActionPlanTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        catchError((error) => {
          this.handleError(error, 'Action Plan');
          return error;
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.checklist ?? true)
      requests.push(this._checklistAnalyticsService.getInspectionCategorizedDrillDown().pipe(
        tap((drillDown: any) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addChecklistTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        catchError((error) => {
          this.handleError(error, 'Checklist');
          return error;
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.lil ?? true)
      requests.push(this._cilAnalyticsService.getInspectionCategorizedDrillDown().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addLilTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        catchError((error) => {
          this.handleError(error, 'CIL');
          return error;
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.defectTag ?? true)
      requests.push(this._defectTagAnalyticsService.getDefectTagCategorizedDrillDown().pipe(
        tap((drillDown: any) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addDefectTagTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        catchError((error) => {
          this.handleError(error, 'Defect Tag');
          return error;
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());
  }

  getSubordinatesDrillDowns(only?: { checklist?: boolean, lil?: boolean, actionPlan?: boolean, defectTag?: boolean }) {
    this.isUserIndicators = false;
    if (only && Object.keys(only).some((key) => only[key])) {
      only = {
        checklist: only.checklist ?? false,
        lil: only.lil ?? false,
        actionPlan: only.actionPlan ?? false,
        defectTag: only.defectTag ?? false,
      }
    }
    const onlyModules: TasksModule[] = only ? Object.keys(only).reduce((modules, key) => {
      if (only[key]) {
        if (key === 'checklist') modules.push(TasksModule.CHECKLIST);
        if (key === 'lil') modules.push(TasksModule.CIL);
        if (key === 'actionPlan') modules.push(TasksModule.ACTIONPLAN);
        if (key === 'defectTag') modules.push(TasksModule.DEFECTTAG);
      }
      return modules;
    }, []) : [];
    const isGeAll = !(onlyModules.length);

    if (isGeAll) this._allTasks = [];
    this.allTasks[TasksCategory.LATE] = isGeAll ? [] : (this._allTasks[TasksCategory.LATE]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.TODAY] = isGeAll ? [] : (this._allTasks[TasksCategory.TODAY]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.TOMORROW_UP_TO_7_DAYS] = isGeAll ? [] : (this._allTasks[TasksCategory.TOMORROW_UP_TO_7_DAYS]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasks[TasksCategory.MORE_THAN_7_DAYS] = isGeAll ? [] : (this._allTasks[TasksCategory.MORE_THAN_7_DAYS]?.filter((item) => !onlyModules.includes(item.moduleName)) ?? []);
    this._allTasksUpdated.next(this.allTasks);

    let requests: Subscription[] = [];
    const checkIfAllDone = () => {
      if (requests.every(x => x.closed)) {
        this._isAllTasksLoading.next(false);
      }
    }

    this._isAllTasksLoading.next(true);

    if (only?.actionPlan ?? true)
      requests.push(this._actionPlanAnalyticsService.getActionPlanCategorizedDrillDownSubordinates().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addActionPlanTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.checklist ?? true)
      requests.push(this._checklistAnalyticsService.getInspectionCategorizedDrillDownSubordinates().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addChecklistTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.lil ?? true)
      requests.push(this._cilAnalyticsService.getInspectionCategorizedDrillDownSubordinates().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addLilTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

    if (only?.defectTag ?? true)
      requests.push(this._defectTagAnalyticsService.getDefectTagCategorizedDrillDownSubordinates().pipe(
        tap((drillDown: any[]) => {
          drillDown.map((ddlItem: any) => this.allTasks = this.addDefectTagTasks(ddlItem));
          this._allTasksUpdated.next(this.allTasks);
        }),
        finalize(() => checkIfAllDone()),
      ).subscribe());

  }

  private handleError(error: any, source: string) {
    this._hasError.next({ hasError: true, apiName: source });
    return throwError(() => error);
  }

  private addChecklistTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.checkListId,
            date: item.checkListCreateDate,
            label: 'CHK',
            inspectionId: item.inspectionId,
            registryNumber: item.inspectionRegistryNumber,
            description: item.checkListName,
            moduleName: TasksModule.CHECKLIST,
            status: ChecklistInspectionDrillDownStatusBadge(item.checkListStatus),
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.ownerId,
              userName: owner.ownerName,
              picture: owner.userPictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
            itemsData: {
              total: item.inspectionItemsNotOk + item.inspectionItemsOk + item.inspectionItemsPending,
              totalPerformed: item.inspectionItemsOk + item.inspectionItemsNotOk,
              data: [
                { label: 'OK', value: item.inspectionItemsOk, color: '#466900' },
                { label: 'NOK', value: item.inspectionItemsNotOk, color: '#f44336' },
                { label: 'PENDENTE', value: item.inspectionItemsPending, color: '#d4d4d4' }
              ]
            }
          }))
      ]
    }
  }

  private addActionPlanTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.actionPlanId,
            date: item.createDate,
            label: 'PLA',
            description: item.actionPlanName,
            moduleName: TasksModule.ACTIONPLAN,
            status: ActionPlanDrillDownStatusBadge(item.status),
            hasBookmark: true,
            isBookmark: undefined,
            applicationId: StaticApplicationId.actionPlan,
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.userId,
              userName: owner.userName,
              picture: owner.pictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
          } as CommomAnalyticsIndicator))
      ]
    }
  }

  private addLilTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.cilId,
            date: item.cilCreateDate,
            label: 'LIL',
            inspectionId: item.inspectionId,
            registryNumber: item.inspectionRegistryNumber,
            description: item.cilName,
            moduleName: TasksModule.CIL,
            status: LilInspectionDrillDownStatusBadge(item.cilStatus),
            locked: item.isPrivate,
            owners: item.owners?.map((owner) => ({
              id: owner.ownerId,
              userName: owner.ownerName,
              picture: owner.userPictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,

            itemsData: {
              total: item.inspectionItemsNotOk + item.inspectionItemsOk + item.inspectionItemsPending,
              totalPerformed: item.inspectionItemsOk + item.inspectionItemsNotOk,
              data: [
                { label: 'OK', value: item.inspectionItemsOk, color: '#466900' },
                { label: 'NOK', value: item.inspectionItemsNotOk, color: '#f44336' },
                { label: 'PENDENTE', value: item.inspectionItemsPending, color: '#d4d4d4' }
              ]
            }
          }))
      ]
    }
  }

  private addDefectTagTasks(ddlItem: any) {
    return {
      ...this.allTasks,
      [ddlItem.indicatorEnum]: [
        ...(this.allTasks[ddlItem.indicatorEnum] || []),
        ...ddlItem.indicatorItems
          .map((item) => ({
            id: item.defectTagId,
            date: item.inclusionDate,
            label: 'DFT',
            hasBookmark: true,
            applicationId: StaticApplicationId.defectTag,
            description: item.defect,
            moduleName: TasksModule.DEFECTTAG,
            status: DefectTagDrillDownStatusBadge(item.status),
            owners: item.owners?.map((owner) => ({
              id: owner.userId,
              userName: owner.userName,
              picture: owner.pictureFile,
              ownerType: owner.ownerType ?? 1,
              initialsColor: owner.initialColor ? owner.initialColor : getCustomThemeColor(this._settingsService.theme$, 500),
            })),
            deadline: item.deadline ?? item.age,
          } as CommomAnalyticsIndicator))
      ]
    }
  }
}
