import { Injectable } from '@angular/core';
import { PraiseItem, PraiseTitle } from "./modal-praise-new-models";
import { ModalPraiseNewHttpClient } from "./modal-praise-new-http-client";

@Injectable({
  providedIn: 'root'
})
export class ModalPraiseNewService {

  constructor(
    private _http: ModalPraiseNewHttpClient
  ) {}

  addPraise(item: PraiseItem) {
    return this._http.post<{ praiseId: string }>('/Praise', {
      praiseId: item.praiseId,
      originatorId: item.userOriginatorId,
      recipientTeamIds: item.recipientTeams,
      recipientUserIds: item.recipientUsers,
      titleId: item.titleId,
      text: item.text,
      copyImmediateSupervisor: item.copyImmediateSupervisor,
    });
  }

  updatePraise(item: PraiseItem) {
    return this._http.put<{ praiseId: string }>(`/Praise/${item.praiseId}`, {
      praiseId: item.praiseId,
      originatorId: item.userOriginatorId,
      recipientTeamIds: item.recipientTeams,
      recipientUserIds: item.recipientUsers,
      titleId: item.titleId,
      text: item.text,
      copyImmediateSupervisor: item.copyImmediateSupervisor,
    });
  }

  listTitlesActive() {
    return this._http.get<PraiseTitle[]>('/Title/descriptions')
  }
}
