import { Component, OnInit, HostBinding, ContentChildren, QueryList, AfterViewInit, Input, OnChanges, ElementRef, SimpleChanges, EventEmitter, Output, ViewChild, AfterContentChecked, OnDestroy, HostListener, Renderer2, ContentChild, AfterContentInit } from '@angular/core';
import { SvcDataHeaderComponent } from '../svc-data-header/svc-data-header.component';
import { SvcDataBodyComponent } from '../svc-data-body/svc-data-body.component';
import { SvcDataChangeAction } from '../enums/svc-data-change-action.enum';
import { SvcDataTableEvent } from '../models/svc-filter-data.model';
import { SvcDataPrepareService } from '../services/svc-data-prepare.service';
import { SvcDataVirtualScrollService } from '../services/svc-data-virtual-scroll.service';
import { SvcDataRenderEvent } from '../events/svc-data-render.event';
import { SvcDataTableSettingsService } from '../services/settings/svc-data-table-settings.service';
import { PAGINATE_OPTIONS, SvcDataPaginateComponent } from '../svc-data-paginate/svc-data-paginate.component';
import { SvcFunctionsHelper, isNullOrUndefined, offsetToParent } from 'projects/lib-shared-common/src/public-api';
import { debounceTime, fromEvent, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { SvcDataVirtualScrollModel } from '../models/svc-data-virtual-scroll.model';
import { SvcDataRowComponent } from '../svc-data-row/svc-data-row.component';
import { SvcDraggingReorderEvent } from '../models/svc-dragging-reorder-event.model';

@Component({
	selector: 'svc-data-table',
	templateUrl: './svc-data-table.component.html',
	styleUrls: ['./svc-data-table.component.scss'],
	host: {
		'(window:mousedown)': 'onMouseDown($event)',
		'(window:mousemove)': 'onMouseMove($event)',
		'(window:mouseup)': 'onMouseUp($event)',
		'[class.svc-data-table]': 'true',
		'[class.svc-no-pagination]': `noPagination || infinite || paginate === null`,
		'[class.svc-dt-left-scroll]': `scrollPositionX === 'left'`,
		'[class.svc-dt-is-loading]': `loading`,
		'[class.overflow-hidden]': `loading && (!infinite || currentPage === 1)`,
	},
})
export class SvcDataTableComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit, AfterContentChecked {
	@ContentChild(SvcDataHeaderComponent, { static: false }) public header: SvcDataHeaderComponent;
	@ContentChild(SvcDataBodyComponent, { static: false }) public body: SvcDataBodyComponent;

	@ViewChild('headerShadowEl', { static: false }) private headerShadowEl: ElementRef<HTMLElement>;
	@ViewChild(SvcDataPaginateComponent, { static: false }) public paginateComp: SvcDataPaginateComponent;

	/** THAT WILL DEFINE THE DATA TABLE HEIGHT, AND IT WILL HAS SCROLL BAR IN CASE OF OVERFLOW. */
	@Input() public height: string = 'auto';

	/** IF THE ROWS IT WILL HAVE CLICK (JUST ENABLED HOVER EFFECT AND CURSOR AS POINTER) */
	@Input() public clickable: boolean;

  /** IF THE ROWS HAVE BACKGROUND COLOR ON MOUSE OVER */
  @Input() public hoverable: boolean;

	/** THIS DEFINE IF WILL GO BRING DATA BASED ON SCROLLING WHEN TO ARRIVE ON BOTTOM. (BUT USE THIS ONLY WITH SERVER-SIDE) */
	@Input() public infinite: number = 0;

	/** SHOW LOADING SPINNER ABOVE THE DATA TABLE WHEN LOADING IS TRUE. */
	@Input() public loading: boolean = null;

	/** THE TOTAL DATA LENGTH. THIS IS USED FOR MAKE THE PAGINATION. */
	@Input() public dataLength: number = 0;

	/** DETERMINES WHETHER WILL CONSUME DATA FROM SERVER */
	@Input('server-side') public serverSide: boolean = false;

	/** CALL REQUEST DATA ON INITIALIZATION DATA TABLE */
	@Input('get-data-init') public getDataInit: boolean = true;

	/** SET THE CURRENT PAGE WHEN USE PAGINATION */
	@Input() public currentPage: number = 1;

	/** SET IF IT WILL PAGINATE OR NOT */
	@Input() public noPagination: boolean = false;

	/** SET IF CAN CHANGE PAGE SIZE OR NOT */
	@Input() public canChangePageSize: boolean = true;

	/** Notifies the table when the server returned all data. */
	@Input() public dataAreOver: boolean = false;

	/** Sets a minimum width in ag-table. When the minimum width is reached, the side table will scroll horizontally. */
	@Input() public minWidth: string = '';

	/** Sets a minimum height in ag-table. */
	@Input() public minHeight: string = '';

	@Input('items') allItems: any[] = [];

	/** WILL FIND FIRST PARENT ELEMENT THAT HAS SCROLLING AND USE IT TO CONTROL THE VIRTUAL SCROLL (DON'T USE DYNAMIC HEIGHT) */
	@Input() useParentScroll: boolean = false;

	/** PUT VERTICAL SCROLL IN LEFT OR RIGHT */
	@Input() scrollPositionX: 'left' | 'right' = 'right';

	/** PUT VERTICAL SCROLL IN LEFT OR RIGHT */
	@Input('pageSize') public paginate: number = PAGINATE_OPTIONS[1];

	/** CALL/EMIT EVENT TO GET DATA (SERVER-SIDE) */
	@Output() public onGetData = new EventEmitter<SvcDataTableEvent>();

	/** EMIT EVENT WHEN ITEMS HAVEN BEEN RENDERED */
	@Output() public onDataRender = new EventEmitter<SvcDataRenderEvent<any>>();

	/** EMIT EVENT WHEN SB-DATA-TABLE HAS BEEN FULLY RENDERED */
	@Output() public onTableRender = new EventEmitter<void>();

	@Output() public filterActivated = new EventEmitter<boolean>();

	public set _height(value: string) {
		this.el.style.height = value;

		if (this.header) {
			if (value === '0' || value === '0px') this.header.visible = false;
			else this.header.visible = true;
		}
	}
	public get _height() {
		return this.el && this.el.style.height ? this.el.style.height : 'auto';
	}

	public set _minHeight(value: string) {
		this.el.style.minHeight = value;
	}

	public get _minHeight() {
		return this.el && this.el.style.minHeight ? this.el.style.minHeight : '';
	}

	public items: any[] = [];
	public filteredItems: any[] = [];

	private _filterIsActivated: boolean = false;
	public get filterIsActivated(): boolean {
		return this._filterIsActivated;
	}
	public set filterIsActivated(value: boolean) {
		this._filterIsActivated = value;
		this.filterActivated.emit(value);
	}

	private _parentScrollingElement: HTMLElement = null;
	public get parentScrollingElement(): HTMLElement {
		if (this.useParentScroll) {
			if (!this._parentScrollingElement) {
				let parent = this.el?.parentElement;
				while (parent) {
					if (parent.scrollHeight > parent.clientHeight) {
						this._parentScrollingElement = parent;
						break;
					}
					parent = parent.parentElement;
				}
			}
			return this._parentScrollingElement;
		}
		return null;
	}

	public get topShadow() {
		if (this.height !== 'auto') {
			let body = this.body ? this.body.el : null;
			let header = this.header ? this.header.el : null;
			if (body && header) return this.el?.scrollTop >= header.clientHeight / 2;
		}
		return false;
	}

	public get bottomShadow() {
		if (this.height !== 'auto') {
			let body = this.body ? this.body.el : null;
			let header = this.header ? this.header.el : null;
			if (body && header) return this.el?.scrollTop + body.clientHeight < body.scrollHeight;
		}
		return false;
	}

	public get isPaging() {
		return this.paginate;
	}

	public get isOrdering() {
		return this.header && this.header.colSorting;
	}

	public get isFiltering() {
		return this.header && this.header.cols ? this.header.cols.some(x => x.canFilter) : false;
	}

	public get numPages() {
		if (this.dataLength && this.isPaging) {
			let pages = Math.floor(this.dataLength / this.paginate);
			return pages + (this.dataLength / this.paginate > pages ? 1 : 0);
		} else return 0;
	}

	public get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}

	public get colSorting() {
		return this.header?.colSorting;
	}

	public get itDoesntPaginate() {
		return this.noPagination || this.infinite || this.paginate === null;
	}

	public get isShowingSkeletons() {
		return this.loading && !this.items?.length;
	}

	public initialized: boolean = false;

	public isDataEmpty: boolean = false;

	public dataPaginatedLength: number = 0;
	public actionChange: SvcDataChangeAction = SvcDataChangeAction.INITIALIZE;

	private DOMisVisible: boolean = false;
	private DOMcountVisibleChange: number = 0;

	public lastBodyWidth: string = null;
	public lastBodyHeight: string = null;

	public virtualScroll: SvcDataVirtualScrollModel;
	public scrollInBottom: boolean = false;
	private lastScrollTop: number = -1;
	public dragging: {
		state?: 'START' | 'DRAGGING' | 'NONE';
		rowIndex: number;
		row: SvcDataRowComponent;
		targetRow: SvcDataRowComponent;
		lastMouseY: number;
		initMouseY: number;
		initElementY: number;
		initScrollTop: number;
		initScrollPadding: number;
		fakeElement: HTMLElement;
		toDown: boolean;
	};

	private destroy$ = new Subject<void>();

	constructor(
		public elRef: ElementRef<HTMLElement>,
		private renderer: Renderer2,
		private helper: SvcFunctionsHelper,
		private dataPrepareService: SvcDataPrepareService,
		private dataVirtualScrollService: SvcDataVirtualScrollService,
		private settings: SvcDataTableSettingsService
	) {
	}

	public ngOnInit() {
		this.ngOnChanges({});
		this.resetDragging();
		this.virtualScroll = new SvcDataVirtualScrollModel({
			containerSrollElRef: () => {
				if (this.useParentScroll) {
					const element = this.parentScrollingElement;
					if (element) {
						return new ElementRef(element);
					}
				}
				return this.elRef;
			},
		});
	}

	public ngAfterContentInit(): void {
		this.applyScrollingListeners();

		setTimeout(() => this.onTableRender.emit());

		if (this.getDataInit) this.emitGetData();

		if (this.infinite && !this.heightIsValid() && !this.useParentScroll)
			console.warn(`The infinite scroll mode not working, because data table height has not been set.`);

		if (!this.infinite) this.backToTheTop();

		this.filterIsActivated = false;
		this.initialized = true;
		
		this.refreshRender();
	}

	private applyScrollingListeners() {
		let element = this.virtualScroll.containerSrollElRef()?.nativeElement;
		if (element && (!this.useParentScroll || element !== this.el)) {
			if (element.tagName === 'HTML') {
				element = window as any;
			}
			fromEvent(element, 'scroll').pipe(
				takeUntil(this.destroy$),
				tap(() => this.onScroll())
			).subscribe();
		}
	}

	private onScroll(forceChange: boolean = false) {
		if (!this.loading) {
			if (this.dataTableInBottom()) {
				if (!this.scrollInBottom) {
					this.scrollInBottom = true;
					if (this.infinite && !this.dataAreOver) {
						this.currentPage++;
						this.emitGetData();
					}
				}
			} else this.scrollInBottom = false;
		}

		if (this.dataVirtualScrollService.canApplyVirtualScroll(this))
			if (forceChange || this.lastScrollTop !== this.virtualScroll.currentSrollTop) {
				this.lastScrollTop = this.virtualScroll.currentSrollTop;
				this.dataVirtualScrollService.onScrollChange(this);
			}

		if (this.dragging.state === 'DRAGGING') {
			const posY = this.dragging.lastMouseY - this.dragging.initMouseY;
			const scrollTop = this.virtualScroll.currentSrollTop - this.dragging.initScrollTop;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			this.dragging.fakeElement.style.top = `${this.dragging.initElementY + scrollTop + posY - scrollPedding}px`;
		}
	}

	private onMouseWheel() {
		if (!this.dataVirtualScrollService.canApplyVirtualScroll(this)) this.onScroll();
	}

	public dataTableInBottom() {
		const elementScrolling = this.virtualScroll.containerSrollElRef()?.nativeElement;

		if (!elementScrolling || elementScrolling === this.el) {
			return this.el ? this.el.clientHeight + this.el.scrollTop >= this.el.scrollHeight : false;
		} else {
			const bodyToParentOffset = offsetToParent(this.el, elementScrolling);
			const heightToScroll = this.el.clientHeight + bodyToParentOffset;
			const scrolled = elementScrolling.scrollTop + elementScrolling.clientHeight;
			const inBottom = scrolled >= heightToScroll;

			return inBottom;
		}
	}

	public backToTheTop() {
		const element = this.virtualScroll?.containerSrollElRef()?.nativeElement;
		if (element) {
			const bodyToParentOffset = this.useParentScroll
				? offsetToParent(this.el, element)
				: 0;
			if (bodyToParentOffset > 0) {
				if (element.scrollTop > bodyToParentOffset) {
					element.scrollTo({
						left: 0,
						top: bodyToParentOffset,
						behavior: 'smooth',
					});
				}
			} else {
				element.scrollTop = 0;
				element.scrollLeft = 0;
			}
		}
	}

	private onMouseDown(event: MouseEvent) {
		if (this.body.dragAndReorder) {
			if (event.button === 0 && this.dragging.state === 'NONE' && this.dragging.row === null) {
				const targetEl = event.target as HTMLElement;
				const row = this.body?.getRowIfMatchToDrag(targetEl);
				if (row) {
					event.preventDefault();
					this.dragging.fakeElement = row.el.cloneNode(true) as HTMLElement;
					this.dragging.state = 'START';
					this.dragging.rowIndex = row.index;
					this.dragging.row = row;
					this.dragging.lastMouseY = event.pageY;
					this.dragging.initMouseY = event.pageY;
					this.dragging.initElementY = row.el.offsetTop;
					this.dragging.initScrollTop = this.virtualScroll.currentSrollTop;
					this.dragging.initScrollPadding = this.virtualScroll.paddingScroll;
					this.dragging.fakeElement.classList.add('row-fake-dragging');
					this.dragging.fakeElement.style.top = `${this.dragging.initElementY}px`;
					this.body.itemsContainerElRef.nativeElement.appendChild(this.dragging.fakeElement);
					this.dragging.row.el.style.opacity = '0';
				}
			}
		}
	}

	private onMouseMove(event: MouseEvent) {
		if (this.dragging.state !== 'NONE') {
			this.dragging.state = 'DRAGGING';
			this.dragging.lastMouseY = event.pageY;
			const posY = event.pageY - this.dragging.initMouseY;
			const scrollTop = this.virtualScroll.currentSrollTop - this.dragging.initScrollTop;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			this.dragging.fakeElement.style.top = `${this.dragging.initElementY + scrollTop + posY - scrollPedding}px`;
			this.dragging.toDown = posY + scrollTop > 0;

			this.checkMatchAndPreReorder();
		}
	}

	private onMouseUp(event: MouseEvent) {
		if (this.dragging.state !== 'NONE') {
			this.dragging.state = 'NONE';

			const targetRow = this.dragging.targetRow;
			const rowIsVisible = !!this.dragging.row?.el.parentElement;
			const scrollPedding = this.virtualScroll.paddingScroll - this.dragging.initScrollPadding;
			let top = this.dragging.initElementY - scrollPedding;
			if (!rowIsVisible && !targetRow) {
				top = parseFloat(this.dragging.fakeElement.style.top) - this.dragging.fakeElement.clientHeight;
			} else if (targetRow) {
				top = targetRow.el.offsetTop;
			}

			this.dragging.fakeElement.style.transitionDuration = '200ms';
			this.dragging.fakeElement.style.top = `${top}px`;

			if (targetRow) {
				setTimeout(() => {
					if (rowIsVisible && this.dragging.row?.el != null) this.dragging.row.el.style.opacity = '';
					this.body?.rows.forEach(row => {
						row.el.style.transform = '';
					});
					this.reorderItem(targetRow.index, this.dragging.rowIndex);
					this.resetDragging();
				}, 200);
			} else {
				this.dragging.fakeElement.style.animationDuration = rowIsVisible ? '100ms' : '200ms';
				this.dragging.fakeElement.style.animationDelay = rowIsVisible ? '100ms' : '0';

				setTimeout(() => {
					if (rowIsVisible && this.dragging.row?.el != null) this.dragging.row.el.style.opacity = '';
					this.resetDragging();
				}, 200);
			}
			this.dragging.fakeElement.classList.add('fadeOut');
		}
	}

	private reorderItem(itemCurrentIndex: number, itemPreviousIndex: number): void {
		const directionIsDown = itemCurrentIndex > itemPreviousIndex;
		const item = this.allItems[itemPreviousIndex];

		const itemsBefore = this.allItems;
		let itemsAfter = this.allItems.filter((x, i) => i !== itemPreviousIndex);
		itemsAfter.splice(itemCurrentIndex + (directionIsDown ? 0 : 0), 0, item);

		this.allItems = itemsAfter;
		this.prepareItemsPagingAndFilter(false);

		this.body?.onDragReorder.emit(
			new SvcDraggingReorderEvent({
				currentIndex: itemCurrentIndex,
				previousIndex: itemPreviousIndex,
				items: {
					before: itemsBefore,
					after: itemsAfter,
				},
				cancelFn: () => {
					this.allItems = itemsBefore;
					this.prepareItemsPagingAndFilter(false);
				},
			})
		);
	}
	
	private checkMatchAndPreReorder() {
		if (!this.body) return;

		const draggingEl = this.dragging.fakeElement;
		const draggingTopPos =
			draggingEl.offsetTop +
			this.virtualScroll.paddingScroll +
			(this.dragging.toDown ? draggingEl.clientHeight : 0);

		let draggingRowTopPos = 0;
		if (this.dragging.row) {
			draggingRowTopPos =
				this.dragging.row.el.offsetTop +
				this.virtualScroll.paddingScroll +
				(this.dragging.toDown ? 0 : this.dragging.row.el.clientHeight);
		}

		const ratioToMatch = 0.4;

		let rowTarget: SvcDataRowComponent = null;

		this.body.rows.forEach(row => {
			if (this.dragging.rowIndex !== row.index) {
				let canBeTarget = false;
				const height = row.el.clientHeight;
				const ratioHeight = this.dragging.toDown ? ratioToMatch * height : (1 - ratioToMatch) * height;
				const rowTopPos =
					row.el.offsetTop +
					this.virtualScroll.paddingScroll +
					(this.dragging.toDown ? 0 : row.el.clientHeight);
				const rowTopPosToGoDownOrUp = row.el.offsetTop + this.virtualScroll.paddingScroll + ratioHeight;
				if (this.dragging.toDown) {
					canBeTarget = rowTopPos > draggingRowTopPos && draggingTopPos > rowTopPosToGoDownOrUp;
					row.el.style.transform = canBeTarget ? `translateY(-${draggingEl.clientHeight}px)` : '';
					if (canBeTarget) {
						rowTarget = row;
					}
				} else {
					canBeTarget = rowTopPos < draggingRowTopPos && draggingTopPos < rowTopPosToGoDownOrUp;
					row.el.style.transform = canBeTarget ? `translateY(${draggingEl.clientHeight}px)` : '';
					if (canBeTarget && !rowTarget) {
						rowTarget = row;
					}
				}
			}
		});

		if (this.dragging.targetRow !== rowTarget) {
			this.dragging.targetRow = rowTarget;
		}
	}

	private resetDragging(): void {
		this.dragging?.fakeElement.remove();
		this.dragging = {
			rowIndex: null,
			row: null,
			targetRow: null,
			lastMouseY: 0,
			initMouseY: 0,
			initElementY: 0,
			initScrollTop: 0,
			initScrollPadding: 0,
			fakeElement: null,
			toDown: true,
		};
		const table = this;
		Object.defineProperty(this.dragging, 'state', {
			set(value) {
				this._state = value ?? 'NONE';
				if (value === 'START') {
					table.body.el?.classList.add('is-dragging');
				}
				if (value === 'NONE') {
					table.body.el?.classList.remove('is-dragging');
				}
			},
			get() { return this._state ?? 'NONE'; },
		});
	}

	ngAfterContentChecked() {
		if (this.elRef && this.elRef.nativeElement) {
			let elem = this.elRef.nativeElement;
			let visible = !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
			if (visible !== this.DOMisVisible) {
				this.DOMisVisible = visible;

				if (this.DOMisVisible && this.DOMcountVisibleChange > 0) this.onScroll();

				this.DOMcountVisibleChange++;
			}
		} else this.DOMisVisible = false;

		this.onCheckBodyDimensions();
	}

	ngOnChanges(changes: SimpleChanges) {
		const formOnInit = JSON.stringify(changes) == '{}';
		if ('serverSide' in changes)
			this.serverSide =
				typeof this.serverSide === 'string' && (this.serverSide === '' || this.serverSide === 'true')
					? true
					: this.serverSide;

		if ('clickable' in changes) {
			this.clickable =
				typeof this.clickable === 'string' && (this.clickable === '' || this.clickable === 'true')
					? true
					: this.clickable;
			if (this.body) this.body.clickable = this.clickable;
		}

    if ('hoverable' in changes) {
      this.hoverable =
        typeof this.hoverable === 'string' && (this.hoverable === '' || this.hoverable === 'true')
          ? true
          : this.hoverable;
      if (this.body) this.body.hoverable = this.hoverable;
    }

		if ('useParentScroll' in changes) {
			if (typeof this.useParentScroll === 'string') {
				if (this.useParentScroll === '' || this.useParentScroll === 'true') {
					const contentInner = document.querySelector(this.settings.parentSrollSelector) as HTMLElement;
					this._parentScrollingElement = contentInner ?? document.body;
					this.useParentScroll = true;
				} else {
					this._parentScrollingElement = document.querySelector(this.useParentScroll);
					this.useParentScroll = !!this._parentScrollingElement;
				}
			} else if ((this.useParentScroll as any) instanceof Element) {
				this._parentScrollingElement = (this.useParentScroll as any) as HTMLElement;
				this.useParentScroll = true;
			} else {
				this.useParentScroll = !!this.useParentScroll;
			}
		}

		if ('noPagination' in changes) {
			this.paginate = 999999;
			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
		}
		else if ('paginate' in changes) {
			if (this.initialized) this.onPageSizeChange(this.paginate, false);
		}

		if ('height' in changes) {
			if (this.height !== 'auto' && !this.heightIsValid()) {
				console.warn(`The height of table is invalid.`);
				this.height = 'auto';
			}

			this._height = this.height;

			this.prepareItemsPagingAndFilter();

			if (!this.infinite) this.backToTheTop();
		}

		if ('minHeight' in changes) {
			if (this.minHeight !== 'auto' && !this.minHeightIsValid()) {
				console.warn(`The minHeight of table is invalid.`);
				this.minHeight = '';
			}

			this._minHeight = this.minHeight;

			this.prepareItemsPagingAndFilter();

			if (!this.infinite) this.backToTheTop();
		}

		if (formOnInit || 'minWidth' in changes) {
			if ('minWidth' in changes && !this.minWidthIsValid()) {
				console.warn(`The min-width of table is invalid.`);
				this.minWidth = '';
			}

			this.checkMinWidthCanApply();
		}

		if ('currentPage' in changes) {
			if (typeof this.currentPage === 'string') {
				if (!isNaN(parseInt(this.currentPage))) this.currentPage = parseInt(this.currentPage);
				else {
					console.warn(
						`The current-page value of table is invalid. The current-page value has been defined as 1.`
					);
					this.currentPage = 1;
				}
			}

			if (typeof this.currentPage !== 'number' || this.currentPage <= 0) {
				console.warn(
					`The current-page value of table is invalid. The current-page value has been defined as 1.`
				);
				this.currentPage = 1;
			}

			if (this.currentPage > this.paginate && this.currentPage < 1) this.currentPage = 1;

			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
		}

		if ('infinite' in changes) {
			if (typeof this.infinite === 'string') {
				if (!isNaN(parseInt(this.infinite))) this.infinite = parseInt(this.infinite);
				else {
					console.warn(
						`The [infinite] value of table is invalid. The infinite scroll has not been applied.`
					);
					this.infinite = null;
				}
			}

			if (this.infinite ) {
				this.paginate = null;
			}

			if (this.initialized) this.onPageChange(this.currentPage, !this.serverSide);
		}

		if ('dataLength' in changes) {
			if (isNullOrUndefined(this.dataLength)) this.dataLength = 0;
		}

		if ('getDataOnInit' in changes)
			this.getDataInit =
				typeof this.getDataInit === 'string' && (this.getDataInit === '' || this.getDataInit === 'true') ? true : this.getDataInit;

		if ('loading' in changes) {

			if (this._height.replace('px', '') === '0' && this.loading)
				this.elRef.nativeElement.style.height = '50px';

			if (this.header && this.header.frmFilter) {
				if (this.loading) this.header.frmFilter.disable();
				else {
					this.header.enableForm();
				}
			}

			if (this.loading && (!this.infinite || this.currentPage === 1)) {
				this.backToTheTop();
			}
		}

		if ('allItems' in changes) {
			if (!this.infinite && this.currentPage !== 1) {
				let maxPages = Math.floor(this.dataLength / this.paginate);
				if (this.dataLength % this.paginate !== 0) maxPages++;

				if (this.currentPage > maxPages || !this.currentPage) {
					this.currentPage = this.currentPage > maxPages ? maxPages : 1;

					if (this.initialized && this.serverSide) {
						this.onPageChange(this.currentPage);
						return;
					}
				}
			}

			let currentItems = changes.allItems.currentValue ?? [];
			let previousItems = changes.allItems.previousValue ?? [];
			let decreasedData = currentItems.length < previousItems.length;

			this.refreshRender({ forceScrollToTop: decreasedData });
		}
	}

	private onCheckBodyDimensions() {
		this.checkBodyWidthChange();
		this.checkBodyHeightChange();
	}

	private checkBodyWidthChange() {
		if (this.el) {
			let currentWidth = '100%';
			if (this.body && this.body.el) currentWidth = this.body.el.clientWidth + 'px';

			if (currentWidth !== this.lastBodyWidth) {
				this.lastBodyWidth = currentWidth;
				this.header.onBodyWidthChange();
			}
		}
	}

	private checkBodyHeightChange() {
		if (this.el && this._height !== 'auto') {
			let currentHeight = this.el.clientHeight + 'px';
			if (currentHeight !== this.lastBodyHeight) {
				this.lastBodyHeight = currentHeight;
				this.refreshRender();
			}
		}
	}

	public heightIsValid() {
		return (
			!isNullOrUndefined(this.height) &&
			typeof this.height === 'string' &&
			this.height !== '' &&
			this.height !== 'auto'
		);
	}

	public minHeightIsValid() {
		return (
			!isNullOrUndefined(this.minHeight) &&
			typeof this.minHeight === 'string' &&
			this.minHeight !== '' &&
			this.minHeight !== 'auto'
		);
	}

	private minWidthIsValid() {
		return (
			!isNullOrUndefined(this.minWidth) &&
			typeof this.minWidth === 'string' &&
			this.minWidth !== '' &&
			this.minWidth !== 'auto'
		);
	}

	public checkMinWidthCanApply(): void {
		if (this.minWidthIsValid()) {
			if (this.body?.el) this.body.el.style.minWidth = this.minWidth;
			if (this.header?.el) this.header.el.style.minWidth = this.minWidth;
		} else {
			if (this.body?.el) this.body.el.style.minWidth = '';
			if (this.header?.el) this.header.el.style.minWidth = '';
		}
	}

	public setDataLength(length: number, paginatedLength: number) {
		this.dataLength = length;
		this.dataPaginatedLength = paginatedLength;
	}

	public onPageChange(currentPage: number, emit: boolean = true) {
		if (this.isPaging) {
			this.notifyChanges(SvcDataChangeAction.PAGINATE);

			if (emit && this.serverSide) {
				this.setCurrentPage(currentPage);
				this.emitGetData();
			} else if (!this.serverSide) {
				this.setCurrentPage(currentPage);
				this.prepareItemsPagingAndFilter()
				this.backToTheTop();
			}
		}
	}

	public onPageSizeChange(pageSize: number, emit: boolean = true) {
		if (this.isPaging) {
			this.paginate = pageSize;
			this.onPageChange(1);
		}
	}

	public onSortChange() {
		if (!this.initialized) return;
		this.notifyChanges(SvcDataChangeAction.ORDER);

		if (this.serverSide) {
			this.setCurrentPage(1);
			this.emitGetData(this.infinite > 0);
		} else {
			this.prepareItemsPagingAndFilter();
			this.backToTheTop();
		}
	}

	public onFilterChange() {
		this.notifyChanges(SvcDataChangeAction.FILTER);

		if (this.header && this.header.cols) {
			this.filterIsActivated = this.header.cols.some(x => x.filterActive);
		}

		if (this.serverSide) {
			this.setCurrentPage(1);
			this.emitGetData(this.infinite > 0);
		} else {
			this.prepareItemsPagingAndFilter();
			this.backToTheTop();
		}
	}

	public prepareItemsPagingAndFilter(emit: boolean = true) {
		this.filteredItems = this.dataPrepareService.apply(this.allItems, this);

		if (this.virtualScroll && this.dataVirtualScrollService.canApplyVirtualScroll(this)) {
			this.dataVirtualScrollService.defineItens(this);
		} else {
			this.items = this.filteredItems;
			this.isDataEmpty = this.items.length === 0;
			if (emit) {
				this.emitDataRender();
			}
		}
	}

	public emitGetData(resetData: boolean = false) {
		if (resetData) {
			this.currentPage = 1;
			this.dataVirtualScrollService.reset(this);
		}

		this.onGetData.emit(
			new SvcDataTableEvent({
				page: this.currentPage,
				pageSize: this.paginate ? this.paginate : this.infinite,
				filters: this.header?.getFormDataModel() ?? {},
				order: this.colSorting ? { field: this.colSorting.col.field, asc: this.colSorting.asc } : null,
				resetData: resetData,
			})
		);
	}

	public emitDataRender() {
		this.onDataRender.emit(
			new SvcDataRenderEvent<any>({
				items: this.items,
				length: this.items.length,
				startIndex: 0,
				endIndex: this.items.length - 1,
			})
		);
	}

	public setCurrentPage(page: number) {
		this.currentPage = page;
	}

	public notifyChanges(action: SvcDataChangeAction = SvcDataChangeAction.INITIALIZE) {
		this.actionChange = action;
	}

	public resetFilters(getData: boolean = false) {
		for (let ctrlName in this.header.filterCtrls) {
			this.header.filterCtrls[ctrlName].reset();
		}
		if (getData) this.emitGetData(true);
	}

	public refreshRender(options: { forceScrollToTop: boolean } = {} as any) {
		if (this.virtualScroll) {
			this.dataVirtualScrollService.preparePreviousItemAfterDataChange(this);
			this.prepareItemsPagingAndFilter();
	
			if (options?.forceScrollToTop && this.body) {
				this.backToTheTop();
			}
		}
	}

	public refreshGetData(resetFilters: boolean = true) {
		if (resetFilters) this.resetFilters(true);
		else this.emitGetData(true);
	}

	ngOnDestroy() {
		this.destroy$?.next();
		this.destroy$?.complete();
	}
}
