import { AfterViewInit, Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService, SvcMediaQuery, getCustomThemeColor } from 'projects/lib-shared-common/src/public-api';
import { SvcDialogComponent, SvcDialogService, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { SettingsService } from 'projects/lib-shared-core/src/public-api';
import { CommonPagination, CommonPaginationRequest } from 'projects/lib-shared-model/src/public-api';
import { Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { transformPraiseItemToCard } from '../../functions/transform-praise-item-to-card';
import { PraiseItemCard } from '../../models/praise-item-card.model';
import { PraiseItem } from '../../models/praise-item.model';
import { PraiseItemCardComponent } from '../../praise-item-card/praise-item-card.component';
import { ModalPraiseDetailComponent } from '../modal-praise-item-detail/modal-praise-item-detail.component';
import { SearchService } from './services/search.service';

export interface SearchRequest extends CommonPaginationRequest {
  recipientUserIds?: string[];
  recipientTeamIds?: string[];
  originatorIds?: string[];
}

@Component({
  selector: 'app-praise-received',
  templateUrl: './modal-praise-received.component.html',
  styleUrls: ['./modal-praise-received.component.scss']
})
export class ModalPraiseReceivedComponent implements AfterViewInit {

  @ViewChild(SvcDialogComponent) protected dialog: SvcDialogComponent;

  #httpErrorService = inject(HttpErrorService);
  #svcDialogService = inject(SvcDialogService);
  #svcToastService = inject(SvcToastService);
  #translocoService = inject(TranslocoService);
  #settingsService = inject(SettingsService);
  #svcMediaQuery = inject(SvcMediaQuery);

  public praises: PraiseItemCard[];
  public currentData: PraiseItem[] = [];
  public loadingData = false;

  #searchRequest: SearchRequest = { pageIndex: 1, pageSize: 6 };
  #totalPraises: number;

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public userId: string,
    private _searchService: SearchService,
  ) { }

  ngAfterViewInit() {
    this.#calculatePageSize();
    this.#getPraiseReceived();
  }

  public openPraise(praiseItemCard: PraiseItemCardComponent, id: string, openComments: boolean) {
    const item = this.currentData.find((i) => i.praiseId === id);
    if (!item) return;

    const modalPraise = this.#svcDialogService.open(ModalPraiseDetailComponent, {
      data: {
        openComments,
        readonly: true,
        praise: item
      },
      width: '100%',
      maxWidth: '690px',
      autoFocus: false,
    });

    modalPraise.afterClosed().subscribe((response) => {
      if (response?.deleted) {
        this.#searchRequest.pageIndex = 1;
        this.#getPraiseReceived();
      }
    });
    modalPraise.componentInstance.onViewsChange.subscribe(() => praiseItemCard.refreshTotalStatus())
    modalPraise.componentInstance.onReactionChange.subscribe(() => praiseItemCard.refreshReactions())
    modalPraise.componentInstance.onCommentChange.subscribe(() => praiseItemCard.refreshComments())
  }

  public loadMorePraises(): void {
    if (this.praises?.length < this.#totalPraises && !this.loadingData) {
      this.#searchRequest.pageIndex++;
      this.#getPraiseReceived();
    }
  }

  #calculatePageSize() {
    const isXS = this.#svcMediaQuery.currentSize.isXS;
    const availableHeight = window.innerHeight - ((this.dialog?.dialogTitleContainer?.nativeElement?.clientHeight ?? 0) + (isXS ? 0 : 32));
    this.#searchRequest.pageSize = (Math.floor(availableHeight / 250) * (isXS ? 1 : 2)) + (4 * (isXS ? 1 : 2));
  }

  #getPraiseReceived() {
    this.loadingData = true;
    this.praises = this.#searchRequest.pageIndex > 1 ? this.praises : [];
    this._searchService.getPraisesByUser(this.userId, this.#searchRequest).pipe(
      tap((praises: CommonPagination<PraiseItem[]>) => {
        this.currentData = [...this.currentData, ...praises.data];
        const data = transformPraiseItemToCard(praises.data, getCustomThemeColor(this.#settingsService.theme$, 500))
        this.praises = [...this.praises, ...data];
        this.#totalPraises = praises.total;
      }),
      catchError((error) => {
        this.#svcToastService.error(this.#translocoService.translate(this.#httpErrorService.getErrorMessage(error)));
        this.loadingData = false;
        return error;
      }),
      takeUntil(this.destroy$),
      finalize(() => this.loadingData = false),
    ).subscribe();
  }
}
