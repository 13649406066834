<div class="flex items-center justify-end py-2.5 px-3.5">
	<p class="pr-1 text-xs">
		{{ 'Itens por página' | transloco }}:
	</p>
	<mat-form-field *ngIf="canChangePageSize" class="svc-mat-no-subscript svc-mat-dense px-2">
		<mat-select (selectionChange)="optionChanged($event)" [(ngModel)]="pageSize" [disabled]="loading || disabled" class="text-xs" id="table-data-paginate-page">
			<mat-option class="svc-data-paginate-option" *ngFor="let option of options" [value]="option">{{option}}</mat-option>
		</mat-select>
		<button class="text-default -mr-1" matSuffix mat-icon-button>
			<mat-icon class="text-current icon-size-3" svgIcon="fontawesome_solid:chevron-down"></mat-icon>
		</button>
	</mat-form-field>
	<p class="px-1 text-xs">
		{{startFrom}} - {{endFrom}} {{ 'de' | transloco }} {{from}}
	</p>
	<div class="flex items-center text-default">
		<mat-icon class="icon-size-6 text-current" svgIcon="mat_solid:chevron_left" [ngClass]="hasPrevPage && !loading && !disabled ? 'cursor-pointer' : 'opacity-50'" (click)="prevPage()" id="table-data-paginate-prev"></mat-icon>
		<mat-icon class="icon-size-6 text-current" svgIcon="mat_solid:chevron_right" [ngClass]="hasNextPage && !loading && !disabled ? 'cursor-pointer' : 'opacity-50'" (click)="nextPage()" id="table-data-paginate-next"></mat-icon>
	</div>
</div>
