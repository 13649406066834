<div class="flex relative">
  <quill-editor
    #editor
    class="copilot-editor w-full"
    [class.readonly]="readOnly"
    [class.cursor-force]="cursorForce"
    [class.small-size]="parent.smallSize"
    classes="copilot-container"
    [modules]="modules"
    [(ngModel)]="messageCurrent"
    [readOnly]="readOnly || isLoading"
    [placeholder]="!readOnly ? placeholder : null"
    (onContentChanged)="onContentChanged()"
    (onEditorCreated)="onEditorCreated($event)"
  ></quill-editor>

  <svc-audio-to-text-button
      class="absolute right-14"
      [ngClass]="{'top-2.5': parent.smallSize, 'top-2': !parent.smallSize }"
      [small]="smallSize"
      [canChooseRecording]="false"
      [isThemeSolvace]="true"
      (onText)="setMessageByAudio($event)"
  ></svc-audio-to-text-button>

  <button 
    matSuffix mat-mini-fab
    [class.small-button]="parent.smallSize"
    class="bg-[#17457A] absolute right-2.5 mat-elevation-z1 no-hover-shadow"
    [ngClass]="{'top-2.5': parent.smallSize, 'top-2': !parent.smallSize }"
    [disabled]="isLoading || buttonDisabled"
    (click)="!isLoading && !buttonDisabled && sendMessage()"
  >
    <div *ngIf="!isLoading" class="text-white">
      <mat-icon class="text-current icon-size-5 -rotate-90" svgIcon="heroicons_solid:paper-airplane"></mat-icon>
    </div>
    <mat-spinner *ngIf="isLoading" diameter="22"></mat-spinner>
  </button>
</div>
