export interface ISvcTable<T> {
  label: string;
  property: keyof T | string;
  actionProperty?: keyof T | string;
  propertyArray?: keyof T[] | string[];
  type:
    | 'text'
    | 'link'
    | 'user'
    | 'date'
    | 'checkbox'
    | 'actions'
    | 'boolean'
    | 'array'
    | 'textArray'
    | 'currency'
    | 'enum'
    | 'simpleProgress'
    | 'multiColorProgress'
    | 'legend'
    | 'status'
    | 'active'
    | 'image'
    | 'reactions'
    | 'priorityButton'
    | 'lockButton'
    | 'settings';
  visible?: boolean;
  readonly?: boolean;
  enumData?: { key?: number; value?: string; data?: any }[];
  cssClasses?: string[] | any;
  cssDetailClass?: string[];
  width?: string;
}

export interface ISvcTableOrdering {
  [key:string]: number
}
