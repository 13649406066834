export interface GetStatusByIdParams {
  applicationId: string;
  siteId: number;
}

export interface GetStatusByIdResponse {
  registryBookmarkUniqueId: number;
  registryUniqueId?: number;
  registryUniqueUId?: string;
  siteId: number;
  userId: string;
  createDate: Date;
}