<mat-form-field #formField *ngIf="!readonly" class="flex-auto w-full svc-mat-no-subscript svc-mat-dense" [id]="inputId ?? null" [class.mat-form-field-disable]="control?.disabled ?? false" [class.cursor-default]="control?.disabled || loading" [class.collapsed]="collapsed" role="button" tabindex="0" (focus)="openOptions()" (keydown.tab)="closeOptions()" (keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? closeOptions() : null">
	<mat-icon *ngIf="icon" matPrefix [svgIcon]="icon"></mat-icon>
	<mat-label *ngIf="label" class="inline-flex input-label-size input-label-weight text-default">
		<div class="flex items-center">
			<mat-icon *ngIf="label.icon" class="icon-size-3 text-current mr-1" [svgIcon]="label.icon"></mat-icon>
			{{ label?.text ?? label }}
		</div>
	</mat-label>
	<div class="flex h-full gap-2 w-full items-center">
		<mat-chip-grid #chipGrid class="flex-auto h-full" [class.py-1]="!collapsed" [style.width]="(collapsed && (selectedOptions ?? []).length > 1) ? 'calc(100% - 32px)' : '100%'" (click)="openOptions()">
			<div class="flex justify-center truncate" *ngFor="let option of selectedOptions" [ngClass]="collapsed ? 'h-10 py-1.5' : 'h-8 py-0.5'" [class.invisible]="loading">
				<mat-chip-row class="my-0 font-medium" (removed)="selected(option)" [disabled]="control?.disabled">
					<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
					<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option, rederingInList: false }"></ng-container>
					<button matChipRemove [attr.aria-label]="'remove option ' + option.label" [disabled]="control?.disabled">
						<mat-icon class="icon-size-3 text-white rounded-full" [ngClass]="inPrimaryAppearance ? 'bg-primary' : 'bg-text-default'" svgIcon="heroicons_solid:x-mark"></mat-icon>
					</button>
				</mat-chip-row>
			</div>
			<input
				class="ml-0 cursor-default self-center h-7 flex-0 max-w-50"
				[class.w-0]="loading || !placeholder || (selectedOptions ?? []).length > 0"
				[matChipInputFor]="chipGrid"
				[placeholder]="placeholder"
				[disabled]="control?.disabled ?? false"
				tabindex="-1"
        inputmode="none"
				(focus)="openOptions()"
				(input)="$event.target.value = ''"
			>
		</mat-chip-grid>
		<div [class.invisible]="!undisplayedSelectedOptions" [class.hidden]="!collapsed || (selectedOptions ?? []).length <= 1">
			<button class="mat-mdc-chip mat-mdc-chip-row cursor-pointer text-sm rounded-full h-6 w-6" (click)="collapsed = false">
				+{{ undisplayedSelectedOptions }}
			</button>
		</div>
	</div>
  <div matSuffix class="-mr-1.5" [ngClass]="inPrimaryAppearance ? 'text-primary' : 'text-default'">
		<mat-icon class="dropdown-icon text-current" [svgIcon]="'fontawesome_solid:' + (optionsListIsOpened? 'chevron-up' : 'chevron-down')" (click)="!control?.disabled && openOptions()"></mat-icon>
	</div>
	<ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
</mat-form-field>

<ng-template #optionsListTemplate *screenSizes="let size">
  <div #containerEl @optionsContainer (@optionsContainer.done)="containerEl.style.opacity = '1'" class="shadow-lg border relative w-full flex flex-col bg-white rounded-md overflow-x-hidden">
    <div *ngIf="canShowSearchInput" class="flex items-center w-full gap-2 py-1 px-2 border-b border-gray-200">
      <div class="flex-auto">
        <input
          #searchInput
          type="text"
          class="w-full text-base sm:text-sm"
          id="app-svc-chip-search-input-options"
          [(ngModel)]="searchText"
          (ngModelChange)="filterOptions(searchText)"
          [placeholder]="'Pesquisar' | transloco"
          (keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? $event.preventDefault() : null"
          (keydown.arrowdown)="forceFocusItemWhenDownPressed($event)"
        />
      </div>
      <div class="flex items-center justify-center text-gray-200 w-8 h-8 cursor-pointer" (click)="searchInput.focus()">
        <mat-icon class="text-current icon-size-5" svgIcon="mat_solid:search"></mat-icon>
      </div>
    </div>
    <div class="flex-auto">
      <ng-container *ngIf="type === 'single' && selectedOptions.length > 0">
        <div class="py-2 px-3 text-sm font-normal" [style.word-break]="'break-word'">
          {{ 'Você pode selecionar apenas 1 item' | transloco }}
        </div>
      </ng-container>
      <ng-container *ngIf="(type === 'single' && selectedOptions.length == 0) || type != 'single'">
				<ag-virtual-scroll *ngIf="filteredOptions?.length" #vs [items]="filteredOptions" [height]="(canShowSearchInput ? (maxOptionsListHeight - 2 - 41) : (maxOptionsListHeight - 2)) + 'px'" [min-row-height]="48">
					<div *ngFor="let option of vs.items" [value]="option.value" class="chip-option flex p-2 cursor-pointer focus:bg-gray-100 hover:bg-gray-100" (click)="selected(option)" role="button" tabindex="0" (keydown.space)="$event.preventDefault(); selected(option)" (keydown.enter)="$event.preventDefault(); selected(option)" (keydown.arrowdown)="$event.preventDefault(); $event.target.nextElementSibling?.focus()" (keydown.arrowup)="$event.preventDefault(); $event.target.previousElementSibling?.focus()">
						<div *ngIf="type === 'multiple'" class="flex flex-col justify-center">
							<mat-checkbox [checked]="option.selected" (click)="selected(option, $event)" class="-m-1 -ml-2 -mr-3" tabindex="-1"></mat-checkbox>
						</div>
						<div class="flex-auto flex flex-col justify-center text-default text-base" [style.word-break]="'break-word'">
							<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
							<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option, rederingInList: true }"></ng-container>
						</div>
						<div *ngIf="type !== 'multiple' && option.selected" class="text-default flex flex-col justify-center">
							<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:done"></mat-icon>
						</div>
					</div>
				</ag-virtual-scroll>

        <div class="chip-option flex p-2" *ngIf="!filteredOptions?.length">
          <p class="text-default text-sm font-semibold">
            {{ 'Nenhum Resultado encontrado' | transloco }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>

<ng-container *ngIf="readonly">
	<div class="relative flex flex-col flex-auto">
		<div *ngIf="label" class="flex flex-row items-center input-label-size input-label-weight text-default">
			<mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
			{{ label }}
		</div>
		<span *ngIf="readonlyStyle === 'default' || !(selectedOptions?.length)" class="text-default font-medium text-base" [class.truncate]="truncateTextValue" [class.invisible]="loading">
			{{ (valueText ?? '') !== '' ? valueText : (fallbackTextValue ?? '') }}
		</span>
		<div *ngIf="(readonlyStyle === 'chip' || readonlyStyle === 'transparent-chip') && selectedOptions?.length" class="w-full overflow-y-hidden" [class.h-10]="collapsed" [class.invisible]="loading">
			<div class="flex gap-2 items-center w-full height-inherit relative">
				<div #readonlyOptionsContainer class="flex max-w-full gap-x-2 self-start" [style.flex-flow]="'wrap'" [style.width]="(collapsed && (selectedOptions ?? []).length > 1) ? 'calc(100% - 32px)' : '100%'">
					<div *ngFor="let option of selectedOptions" class="flex justify-center truncate" [ngClass]="collapsed ? 'h-10 py-1.5' : 'h-8 py-0.5'">
						<div class="mat-mdc-chip cursor-default py-0.5 text-base truncate" [class.px-1.5]="readonlyStyle === 'chip'" [class.bg-transparent]="readonlyStyle === 'transparent-chip'">
							<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
							<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option, rederingInList: false }"></ng-container>
						</div>
					</div>
				</div>
				<div [class.invisible]="!undisplayedSelectedOptions" [class.hidden]="!collapsed || (selectedOptions ?? []).length <= 1">
					<button class="mat-mdc-chip cursor-pointer text-sm rounded-full h-6 w-6" (click)="collapsed = false">
						+{{ undisplayedSelectedOptions }}
					</button>
				</div>
			</div>
		</div>
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
	</div>
</ng-container>

<ng-template #loadingTemplate>
  <div *ngIf="loading" class="flex items-center absolute left-0 right-0 bottom-0 rounded-md text-default" [ngClass]="[(readonly ? 'top-0' : 'h-10 pl-2'), ((label && readonly) ? 'pt-4' : 'pt-0')]">
    <div class="absolute inset-0 bg-hite opacity-40"></div>
    <div class="droplist-shimmer"></div>
  </div>
</ng-template>
