import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { SvcToastService } from 'projects/lib-shared-component/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AlertErrorService {

  constructor(
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService
  ) { }

  private translateErrorMessages(errorMessages: any): string {
    return Array.isArray(errorMessages) ?
      errorMessages?.map((message: string) =>
        this._translocoService.translate(message)
      )?.join('\n') : this._translocoService.translate(errorMessages);
  }

  private extractErrorMessage(err: HttpErrorResponse): string {
    let _message: string = '';
    const _errors = err?.error?.errors;

    if (_errors && typeof _errors === 'object')
      for (const key in _errors)
        _message += this.translateErrorMessages(_errors[key]);

    return _message;
  }

  public showErrorInToast(error: HttpErrorResponse): void {
    const _message: string = this.extractErrorMessage(error);
    const _messageDefault = this._translocoService.translate('Ocorreu um erro, tente novamente em outro momento');
    const _messageError: string = _message || _messageDefault;
    this._toastService.error(_messageError);
  }
}
