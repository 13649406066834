import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SvcFilterField, SvcFilterFields } from './classes/svc-filter-field';
import { FormControl, Validators } from '@angular/forms';
import { SvcFilterEvent, SvcFilterEventItem, SvcFilterValueEvent } from './classes/svc-filter-event';
import { Subject, debounceTime, filter, takeUntil, tap } from 'rxjs';
import { dateIsValid, dateStringToDate } from 'projects/lib-shared-common/src/public-api';

const _debounceTime = 300;

@Component({
  selector: 'svc-filters',
  templateUrl: './svc-filters.component.html',
  styleUrls: ['./svc-filters.component.scss']
})
export class SvcFiltersComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public filters: SvcFilterField[] = [];
  @Output() public onFilterChanged = new EventEmitter<SvcFilterEvent>();
  @Output() public onValueChanged = new EventEmitter<SvcFilterValueEvent>();
  @Output() public onInit = new EventEmitter();

  public controls: FormControl[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _isCleaningControls = false;
  private _isPatchingValues = false;
  public isValid = true;

  public get isEmpty() {
    return this.controls.every((x, i) => {
      const filter = this.filters[i];
      return !filter.cleanable || (filter.disabled ?? x.disabled) || x.value == null || x.value === '' || (Array.isArray(x.value) && x.value.length === 0);
    });
  }

  public get fields(): SvcFilterFields[] {
    return this.filters?.map((f, index) => ({
      field: f,
      id: f.id,
      control: this.controls[index],
      name: f.name,
      value: this.controls[index].value,
    })) ?? [];
  }

  public get values() {
    return this.fields.reduce((ac, obj) => {
      ac[obj.name] = obj.value;
      return ac;
    }, {});
  }

  constructor() { }

  public ngOnInit() {
    this._prepareFieldControls();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onInit.emit();
    })
  }

  public ngOnChanges(changes: SimpleChanges) {
    this._prepareFieldControls();
  }

  private _prepareFieldControls() {
    this.filters = (this.filters ?? []).map((filter) => ({
      ...filter,
      cleanable: filter.cleanable ?? true,
    }));

    const getDependsAnotherFieldAndControl = (field: SvcFilterField): { otherField?: SvcFilterField, otherControl?: FormControl } => {
      if (field.dependsAnother) {
        const otherIndex = this.filters.findIndex(_f => _f.name === field.dependsAnother);
        if (otherIndex >= 0) {
          return {
            otherField: this.filters[otherIndex],
            otherControl: this.controls[otherIndex],
          };
        }
      }
      return {};
    }

    const checkIfNeedToApplyDateFieldRules = (field: SvcFilterField, control: FormControl) => {
      let validators = field.validators ?? [];
      if (field.dependsAnother) {
        const fieldIndex = this.filters.indexOf(field);
        const anotherFieldIndex = this.filters.findIndex(_f => _f.name === field.dependsAnother);
        const anotherField = anotherFieldIndex >= 0 ? this.filters[anotherFieldIndex] : null;
        const anotherControl = anotherFieldIndex >= 0 ? this.controls[anotherFieldIndex] : null;
        if (fieldIndex > anotherFieldIndex && anotherField?.type === 'date' && field.type === 'date') {
          const value = anotherControl ? anotherControl.value : anotherField.initialValue;
          const isValid = dateIsValid(value, 'yyyy-MM-dd');
          const minDate = isValid ? new Date(`${value}T00:00:00`) : null;
          field.disabled = !isValid;
          field.config = {
            ...(field.config ?? {}),
            minDate: minDate,
          };
          if (isValid && !validators.includes(Validators.required)) {
            validators.push(Validators.required);
            control.markAsUntouched();
          }
          if (field.disabled || (minDate && dateIsValid(control.value, 'yyyy-MM-dd') && dateStringToDate(control.value, 'yyyy-MM-dd').isBefore(minDate))) {
            (control.value ?? '') != '' && control.setValue(null);
          }
        }
      }

      const currentValidators = control['_rawValidators'] ?? [];
      if (validators.length !== currentValidators.length || validators.some(v => !currentValidators.includes(v))) {
        control.setValidators(validators);
      }

      if ((field.disabled ?? false) !== control.disabled) {
        field.disabled ? control.disable({ emitEvent: false }) : control.enable({ emitEvent: false });
      }
    };

    this.controls = (this.filters ?? []).map((f) => {
      let control = new FormControl(f.initialValue, f.validators);
      setTimeout(() => {
        checkIfNeedToApplyDateFieldRules(f, control);
        control.valueChanges.pipe(
          debounceTime(_debounceTime),
          takeUntil(this._unsubscribeAll),
          filter(() => !this._isPatchingValues),
          tap((value) => {
            const { otherField, otherControl } = getDependsAnotherFieldAndControl(f);
            if (otherField && otherControl) {
              checkIfNeedToApplyDateFieldRules(otherField, otherControl);
            }

            this.validateFields({ specificField: f });

            this.onValueChanged.emit({ value: value, field: f });
            if (!this._isCleaningControls && this._checkFieldsIsValid())
              this.onFilterChanged.emit({ fields: this.fields });
          })
        ).subscribe();
      });
      return control;
    });
    setTimeout(() => this.validateFields({ markAsTouched: false }));
  }

  public clearFiltersField(options?: { clearDisabled?: boolean, preventEmit?: boolean }) {
    this._isCleaningControls = true;
    for (let i = 0; i < this.controls.length; i++) {
      const control = this.controls[i];
      const field = this.filters[i];
      if (field.cleanable && (control.enabled || options?.clearDisabled)) {
        control.setValue(null);
      }
    }
    setTimeout(() => {
      this._isCleaningControls = false;
      if (!options?.preventEmit) {
        this.onFilterChanged.emit({
          fields: this.fields,
        });
      }
    }, _debounceTime);
  }

  public validateFields(options?: { specificField?: SvcFilterField, markAsTouched?: boolean }): boolean {
    this.isValid = this.fields.every((f) => {
      const c = f.control;
      if ((!options?.specificField || options?.specificField === f.field) && (options?.markAsTouched ?? true) && c.enabled) {
        c.updateValueAndValidity({ emitEvent: false });
      }
      return c.disabled || c.valid;
    });

    return this.isValid;
  }

  public markAllFieldAsTouched() {
    this.controls.forEach((c) => c.markAsTouched());
  }

  public patchValues(fieldAndValues: { [key: string]: any }) {
    this._isPatchingValues = true;
    for (let fieldName in fieldAndValues) {
      const index = this.filters.findIndex(f => f.name === fieldName);
      if (index >= 0) {
        const control = this.controls[index];
        control.setValue(fieldAndValues[fieldName]);
      }
    }
    this._isPatchingValues = false;
  }

  private _checkFieldsIsValid(specificField?: SvcFilterField): boolean {
    const fields = specificField ? [specificField] : this.filters;
    for (let filter of fields) {

      const field = this.fields.find(x => x.name === filter.name);

      field.control.updateValueAndValidity({ emitEvent: false });
      if (field.control.invalid && !filter.dependsAnother) {
        return false;
      }

      if (filter.dependsAnother) {
        const anotherIndex = fields.findIndex(x => x.name === filter.dependsAnother);
        if (anotherIndex >= 0) {
          const anotherField = this.fields[anotherIndex];
          const anotherFilter = fields[anotherIndex];
          if (!this._hasValueInField(anotherField) && (filter.dependsAnother === anotherFilter.name && this._hasValueInField(field))) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private _hasValueInField(field: SvcFilterEventItem) {
    return Array.isArray(field.value) ? field.value.length > 0 : !!(field.value);
  }

  public ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
