export interface IUserAvatar {
  bucketS3: string;
  keyS3: string;
  pictureFile: string;
  userId: string;
  firstLastName: string;
  preferenceColor: string;
  pictureUrl: string;
  role: string;
  function: string;
}
