import { ISvcChipOption } from "projects/lib-shared-component/src/public-api";

export interface SvcCustomFieldTypeResponse {
  fieldTypeId: number;
  fieldType: string;
}

export interface SvcCustomFieldList {
  applicationId?: string;
  siteId?: number;
  /**
    * Name of the feature, such as "bos-type" or "rca-type",
    * which corresponds to the type displayed in the registration form.
  */
  featureName: string;
  /**
   * Unique identifier for the type created in the type registration screen
   * (e.g., BOS type, RCA type).
  */
  registryId: number;
  fields: SvcCustomField[];
}

export interface SvcCustomField {
  customFieldListId?: number;
  fieldName: string;
  customFieldListValueId?: number;
  fieldValue?: any;
  fieldTypeId: number;
  mandatory: boolean;
  options: string[];
  optionsCombo?: ISvcChipOption[];
}

export interface SvcCustomFieldAdd {
  featureName: string;
  registryId: number;
  fields: SvcCustomField[];
}
