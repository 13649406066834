import {PostTeam} from "./post-team";
import {Post} from "./post";
import {SvcUserAvatarService, User, UserService } from "projects/lib-shared-core/src/public-api";
import {
  ISvcCarousel,
  ISvcUserAvatar,
  SvcToastService,
  UserAvatarSize
} from "projects/lib-shared-component/src/public-api";
import {Injectable, Injector, OnInit} from "@angular/core";
import {PostService} from "../services/post.service";
import {PostMedia} from "./post-media";
import {tap} from "rxjs";
import {PostErrorMsg} from "./post-error-msg";
import {TranslocoService} from "@ngneat/transloco";

@Injectable()
export class PostForm implements OnInit {

  protected _avatar: ISvcUserAvatar;
  protected _avatarSize = UserAvatarSize.Posts;
  protected _isOwner: boolean;
  protected _mediasCarousel: ISvcCarousel[];
  protected _post: Post;
  protected _user: User;
  protected _userId: string;
  protected _medias: PostMedia[];
  protected text: string;
  protected textTranslated: string;
  protected translatingText: boolean;

  protected _userService: UserService
  protected _userAvatarService: SvcUserAvatarService
  protected _postService: PostService;
  protected _toast: SvcToastService;
  protected _transloco: TranslocoService;

  protected imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  protected videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'webm'];

  constructor(
    protected injector: Injector
  ) {
    this._userService = injector.get(UserService);
    this._userAvatarService = injector.get(SvcUserAvatarService);
    this._postService = injector.get(PostService);
    this._toast = injector.get(SvcToastService);
    this._transloco = injector.get(TranslocoService);
  }

  ngOnInit() {
    this._user = this._userService.user;
    this._userId = this._userService.userId$;
    this._isOwner = !!this._userService.userId$
      && !!this._post?.originator?.originatorId
      && this._userService.userId$.toUpperCase() === this._post.originator.originatorId.toUpperCase();
  }


  protected setPostUsersInfo() {

    const ids: string[] = [
      ...this._post.userMentions?.map(p => p.userId) ?? [],
      this._post.originator.originatorId,
    ];

    if (!ids || ids.length <= 0) return;

    this._userAvatarService.getUserInfoByUserIds(ids).subscribe((userInfos) => {

      // Define originator info
      const orgUser = userInfos.find(u => u.id === this._post.originator.originatorId);
      if (orgUser) this._post.originator.user = {
        ...orgUser,
        userId: orgUser.id as string,
        site: this._post.site?.siteName,
        isGlobal: false,
      };

      // Define userMentions info
      this._post.userMentions?.forEach((um, i) => {
        const userInfo = userInfos.find(ui => ui.id === um.userId);
        if (userInfo) this._post.userMentions[i] = {
          ...userInfo,
          userId: userInfo.id as string,
          site: this._post.site?.siteName,
          isGlobal: false,
        };
      })
    })
  }

  protected setPostTeamInfo() {
    const ids = this._post.teamMentions?.map(t => t.teamId);
    if (!ids || ids.length <= 0) return;
    this._userService.getTeamInfo(ids).subscribe((teamInfos: PostTeam[]) => {
      this._post.teamMentions.forEach((tm, i) => {
        const teamInfo = teamInfos.find(ti => ti.teamId === tm.teamId)
        if (teamInfo) this._post.teamMentions[i] = teamInfo;
      })
    })
  }

  protected setPostMediaCarousel() {
    this._mediasCarousel = this._post.mediaUrls?.map((u, i) => ({
      title: u.awsMediaName,
      url: u.signedUrl,
      type: this.getUrlExtensionMediaType(u.awsMediaName)
    }))
  }

  protected getUrlExtensionMediaType(url: string): 'image' | 'video' {
    const extension = url.split('.').pop().toLowerCase();

    if (this.imageExtensions.includes(extension)) {
      return 'image';
    }
    if (this.videoExtensions.includes(extension)) {
      return 'video';
    }

    return null;
  }

  protected translatePost(text: string) {
    let textBreak = text;
    const matches = textBreak.match(/<div class="mention".*?<\/div>/g);

    if (matches)
      for (let i = 0; i < matches.length; i++)
        textBreak = textBreak.replace(matches[i], `<span class="notranslate">[%${i + 1}%]</span>`);

    this.translatingText = true;
    this._postService.translatePost(textBreak)
      .subscribe({
        next: (result) => {
          let textTranslated = result.terms[0]
          if (matches)
            for (let i = 0; i < matches.length; i++)
              textTranslated = textTranslated.replace(`<span class="notranslate">[%${i + 1}%]</span>`, matches[i]);
          this.textTranslated = textTranslated;
          this.translatingText = false;
        },
        error: () => {
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError));
          this.textTranslated = null;
          this.translatingText = false;
        }
      })
  }

}
