import {PostUser} from "./post-user";
import {PostTeam} from "./post-team";
import {PostMedia} from "./post-media";
import {PostOriginator} from "./post-originator";
import { Hashtag } from "projects/lib-shared-core/src/lib/features/user/user-hashtags/services/user-hashtags.types";
import { PostApplicationRegistry } from "./post-application-registry";

export class Post {

  public id?: string;
  public originator?: PostOriginator;
  public textContent?: string;
  public site?: { siteId: number, siteName?: string };
  public reactions?: any;
  public hashtags?: Hashtag[];
  public userMentions?: PostUser[];
  public teamMentions?: PostTeam[];
  public mediaUrls?: PostMedia[];
  public inclusionDate?: Date;
  public lastUpdateDate?: Date;
  public sharedTimes?: number;
  public canEdit?: boolean;
  public canRemove?: boolean;
  public isUpdated?: boolean;
  public applicationRegistry?: PostApplicationRegistry;
  public applicationTagName?: string;
  public wasMarkedAsSeen?: boolean;
  public commentConfigTypeId?: string;
  public featureName?: string;
  public lastInteraction?: {
    interactionDate: Date;
    interactionDescription: string;
    interactionType: number;
    userId: string;
  }
}
