import {Component, OnInit, Renderer2} from '@angular/core';
import {Survey, SurveyService} from "./survey.service";
import {ModalInsights} from "../../models/modal-insights";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { take } from 'rxjs';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent extends ModalInsights<SurveyService> implements OnInit {

  survey: Survey;
  url: SafeResourceUrl;
  height: number = 300;
  loading: boolean;

  showClose: boolean;

  constructor(
    private surveyService: SurveyService,
    private renderer: Renderer2,
    private sanitizer : DomSanitizer
  ) {
    super(surveyService);
  }

  ngOnInit(): void {
    this.survey = this.service.model;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.survey.surveyUrl);
    this.removeDialogScroll();
    setTimeout(() => {
      this.showClose = true;
    }, 5000);
  }

  confirm() {
    this.loading = true;
    this.service.saveChange.pipe(
      take(1),
    ).subscribe(saved => {
      if (!saved) this.loading = false;
    })
    this.service.onFinishInsight.emit();
  }

  private removeDialogScroll(){
    const dialogContainer = this.elementRef?.nativeElement?.closest('.mat-mdc-dialog-surface');
    if (dialogContainer)
      this.renderer.setStyle(dialogContainer, 'overflow', 'visible');
  }

}
