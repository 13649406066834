import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs';
import { Navigation, SvcDefaultNavigationItem } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { BaseLayoutComponent } from '../base-layout.component';
import { MainTasksService } from 'projects/lib-shared-core/src/lib/features/main-tasks/main-tasks.service';

@Component({
  selector: 'svc-custom-layout',
  templateUrl: './solvace-custom-layout.component.html',
  styleUrls: ['./solvace-custom-layout.component.scss'],
})
export class SolvaceCustomLayoutComponent extends BaseLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public navigation: Navigation;
  public urlHome = `${location.origin}/myworkspace/`;
  public currentNavigationItem: SvcDefaultNavigationItem;

  private _mainTasksService = inject(MainTasksService);

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
        if (this.navigation.default.length) {
          for (let item of this.navigation.default) {
            for (let childItem of item.children) {
              if (typeof childItem.url === 'string') {
                if (this._router.isActive(childItem.url, true)) {
                  item['expanded'] = true;
                  this.currentNavigationItem = item;
                  break;
                }
              }
            }
            if (this.currentNavigationItem != null) {
              break;
            }
          }
        }
      });

    this._mainTasksService.getUserDrillDowns();
  }

  /**
   * AfterViewInit
   */
  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  protected toggleNavigation(): void {
    if (this._mediaQuery.currentSize.isBelowMD) {
      this.verticalNavigation.toggle();
    }
  }

  protected toggleNavigationRetraction() {
    this.verticalNavigation?.toggleRetraction();
    if (this.currentNavigationItem) {
      this.currentNavigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
  }

  protected toggleCurrentNavigationItem(navigationItem: SvcDefaultNavigationItem) {
    if (navigationItem != this.currentNavigationItem) {
      if (this.currentNavigationItem != null) {
        this.currentNavigationItem['expanded'] = false;
      }
      navigationItem['expanded'] = true;
      this.currentNavigationItem = navigationItem;
    }
    else if (!this.verticalNavigation.isRetracted) {
      navigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
    if (this.verticalNavigation.isRetracted) {
      this.verticalNavigation.toggleRetraction();
    }
  }
}
