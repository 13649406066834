import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslocoModule } from "@ngneat/transloco";
import { ModalPraiseNewHttpClient } from "./modal-praise-new-http-client";
import { ModalPraiseNewComponent } from './modal-praise-new.component';
import { SvcControlsModule, SvcDialogsModule } from 'projects/lib-shared-component/src/public-api';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SvcAudioToTextButtonModule } from "../../general/svc-audio-to-text-button/svc-audio-to-text-button.module";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcUserPickerModule } from '../../general/svc-user-picker/svc-user-picker.module';

@NgModule({
  declarations: [
    ModalPraiseNewComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SvcDialogsModule,
    SvcControlsModule,
    SvcAudioToTextButtonModule,
    SvcUserPickerModule,
    TranslocoModule,
  ],
  providers: [
    ModalPraiseNewHttpClient
  ],
})
export class ModalPraiseNewModule { }
