<svc-dialog #dialog [hideCloseButton]="true" [matDialogFullscreen]="['XS', 'SM']" *screenSizes="let size">
  <div svc-dialog-title class="flex items-center justify-end w-full">
    <button class="-mr-2" mat-icon-button (click)="close()">
      <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>

  <div svc-dialog-content class="flex flex-col p-5">
    <div>
      <img src="https://solvacelabs-webcomponents.s3.amazonaws.com/version-task-picture/banner.png" />
    </div>

    <h2 class="text-center text-[#0288d1] text-2xl font-medium my-2">
      {{ 'Novos Lançamentos' | transloco }}
    </h2>

    <div class="flex justify-between">
      <span>{{news?.versionDate | dateFormat}}</span>
      <span>{{'Traduzido pelo Google Tradutor' | transloco }}</span>
    </div>

    <div class="my-2" [innerHTML]="news?.versionDescription | htmlSanitizer"></div>

    <div class="flex flex-col gap-2 my-2">

      <div
        *ngFor="let item of news?.versionItems"
        class="grid grid-cols-4 bg-[#ebfaff] p-2"
      >
        <div class="col-span-3">
          <span
            *ngFor="let appName of item.applicationNames; let i = index"
            class="font-medium"
          >{{i > 0 ? '/' : ''}} {{ appName | transloco }}</span>
          <div [innerHTML]="item.taskName | htmlSanitizer"></div>
        </div>

        <div *ngIf="item.taskImage">
          <img
            [src]="item.taskImage"
            class="cursor-pointer"
            (click)="openImage(item.taskImage)"
          />
        </div>

      </div>

    </div>

    <div
      #footer
      class="version-footer"
      [innerHTML]="news?.versionFooter | htmlSanitizer"
    ></div>

  </div>

  <div
    class="flex justify-end items-center gap-2 w-full"
    svc-dialog-actions
  >

    <button class="button-more-options w-7 h-7 min-h-7 min-w-7" mat-icon-button [matMenuTriggerFor]="moreOptionsMenu">
      <mat-icon class="text-current text-black icon-size-5" svgIcon="heroicons_solid:ellipsis-vertical"></mat-icon>
    </button>

    <mat-menu xPosition="before" #moreOptionsMenu="matMenu">
      <button class="flex items-center gap-2" mat-menu-item (click)="downloadPdf()">
        <mat-icon class="icon-size-5" svgIcon="heroicons_solid:arrow-down-tray">
        </mat-icon>

        <span class="text-default text-base font-normal">
          {{ 'Baixar' | transloco }}
        </span>
      </button>
    </mat-menu>

    <svc-button
      *ngIf="!data"
      mode="stroked"
      (click)="seeLater()"
      [disabled]="downloading"
    >{{ 'Ver depois' | transloco}}</svc-button>

    <svc-button
      color="primary"
      (click)="close()"
      mode="flat"
      [disabled]="downloading"
    >{{ 'Fechar' | transloco }}</svc-button>

  </div>

</svc-dialog>
