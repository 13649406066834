export enum DialogAlertTabs {
  Alert = 0,
  ActionPlan = 1,
  Feed = 2
}

export enum DialogAlertType {
  view,
  add,
  edit
}
