<svc-dialog #dialog dialogStyle="form" [fullscreen]="['XS','SM']">

  <div svc-dialog-title>
    <div class="flex flex-row items-center gap-2 -mx-1" [class.hidden]="!userAvatar || userAvatar.isLoading">
      <svc-smart-user-avatar #userAvatar [size]="_avatarSize" [ids]="_userId"></svc-smart-user-avatar>
      <div *ngIf="userAvatar && !(userAvatar?.isLoading ?? true)">
        <span class="text-base font-bold">{{userAvatar.info?.name}}</span>
        <span class="text-base font-normal block" *ngIf="userAvatar.info?.role">{{userAvatar.info?.role | transloco}}</span>
      </div>
    </div>
  </div>

  <div svc-dialog-content class="flex flex-col bg-white gap-2 p-3">

    <div class="flex min-h-72 items-center justify-center my-2" *ngIf="loading || (userAvatar?.isLoading ?? true)">
      <mat-spinner diameter="28"></mat-spinner>
    </div>

    <form class="flex flex-col gap-2" *ngIf="!loading  && !(userAvatar?.isLoading ?? true)"[formGroup]="form">

      <div>
        <svc-post-mention
          #postMention
          formControlName="textContent"
          [placeholder]="'O que você está pensando?' | transloco"
          inputId="post-text"
        ></svc-post-mention>

        <svc-validation-message [control]="form.controls.textContent"></svc-validation-message>
      </div>

      <div class="bg-gray-50">
        <svc-carousel
					#carousel
          *ngIf="_mediasCarousel?.length > 0"
          [slides]="_mediasCarousel"
          fitContent="contain"
          [height]="400"
          [imageViewer]="false"
          (removeSlide)="removeMediaFile($event)"
        ></svc-carousel>
      </div>

      <div class="flex flex-row gap-1 absolute bottom-[60px]">

        <svc-button mode="simple" [disabled]="loading || !addPhotoEnabled" (click)="canAddPhoto() && imageInput.click()" buttonId="post-add-photo">
          <div class="flex gap-2">
            <mat-icon class="flex self-center text-current icon-size-5" svgIcon="heroicons_solid:photo"></mat-icon>
            <div class="flex self-center text-sm font-normal">{{ 'Foto' | transloco }}</div>
          </div>
        </svc-button>

        <input
          #imageInput
          type="file"
          (change)="addMediaFile('image', $event)"
          [multiple]="false"
          accept="image/png, image/jpg, image/jpeg"
          hidden
        />

        <svc-button mode="simple" [disabled]="loading || !addVideoEnabled" (click)="canAddVideo() && videoInput.click()" buttonId="post-add-video">
          <div class="flex gap-2">
            <mat-icon class="flex self-center text-current icon-size-5" svgIcon="heroicons_solid:film"></mat-icon>
            <div class="flex self-center text-sm font-normal">{{ 'Vídeo' | transloco }}</div>
          </div>
        </svc-button>

        <input
          #videoInput
          type="file"
          (change)="addMediaFile('video', $event)"
          [multiple]="false"
          accept="video/mp4, video/3gpp, video/quicktime"
          hidden
        />

      </div>

    </form>

  </div>
  <div svc-dialog-actions>
    <div
      class="flex flex-row gap-2"
    >
      <svc-button
        mode="stroked"
        type="button"
        (click)="!loading ? dialog.closeDialog() : null"
        [disabled]="loading"
        buttonId="post-cancel"
      >{{ 'Cancelar' | transloco }}</svc-button>

      <svc-button
        *ngIf="!loading"
        mode="flat"
        type="button"
        color="primary"
        [disabled]="loading || !hasTextOrMedia"
        (click)="!loading && hasTextOrMedia && submitForm()"
        buttonId="post-submit"
        cdkFocusInitial
      >
        <div *ngIf="loading" class="px-3">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            [diameter]="24"
          ></mat-progress-spinner>
        </div>
        <span *ngIf="!loading">{{ (mode === 'new' ? 'Publicar' : 'Salvar') | transloco }}</span>
      </svc-button>

    </div>
  </div>

</svc-dialog>
