import { Injectable } from '@angular/core';
import { NewsletterResource } from '../resources/newsletter.resource';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { Version } from '../models/newsletter.model';

@Injectable({ providedIn: 'root' })
export class NewsletterService {

  private _isNewslettersLoading: BehaviorSubject<boolean>  = new BehaviorSubject(false);
  public isNewslettersLoading$: Observable<boolean> = this._isNewslettersLoading.asObservable();
  private _newsletters: BehaviorSubject<Version[]>  = new BehaviorSubject([]);
  public newsletters$: Observable<Version[]> = this._newsletters.asObservable();

  private _isTotalUnreadLoading: BehaviorSubject<boolean>  = new BehaviorSubject(false);
  public isTotalUnreadLoading$: Observable<boolean> = this._isTotalUnreadLoading.asObservable();
  private _totalUnread: BehaviorSubject<number>  = new BehaviorSubject(0);
  public totalUnread$: Observable<number> = this._totalUnread.asObservable();

  constructor(private newsletterResource: NewsletterResource) { }

  getAllNewsletter() {
    this._isNewslettersLoading.next(true);
    return this.newsletterResource.getAll().pipe(
      tap((response) => {
        this._newsletters.next(response.versions);
      }),
      finalize(() => this._isNewslettersLoading.next(false))
    ).subscribe();
  }

  getTotalUnread() {
    this._isTotalUnreadLoading.next(true);
    return this.newsletterResource.getCurrentEnvironmentUnreadCount().pipe(
      tap((response) => {
        this._totalUnread.next(response);
      }),
      finalize(() => this._isTotalUnreadLoading.next(false))
    ).subscribe();
  }

  getEnvironmentById(id: number) {
    return this.newsletterResource.getEnvironmentById(id);
  }

  markAsRead(id: number) {
    return this.newsletterResource.markAsRead(id);
  }
}
