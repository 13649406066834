import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcFaqComponent } from './svc-faq.component';
import {RouterModule} from "@angular/router";
import {faqRoutes} from "./svc-faq.routing";
import {LibSharedComponentModule, SvcButtonsModule, SvcDialogsModule} from 'projects/lib-shared-component/src/public-api';
import {TranslocoModule} from "@ngneat/transloco";
import {MatDialogModule} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import {SvcFaqHttpClient} from "./svc-faq-http-client";
import {SvcFaqService} from "./svc-faq.service";
import { SvcFaqAnswersComponent } from './svc-faq-answer/svc-faq-answers.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    SvcFaqComponent,
    SvcFaqAnswersComponent,
  ],
  exports: [
    SvcFaqComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedIconsModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcDialogsModule,
    RouterModule.forChild(faqRoutes),
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    SvcButtonsModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    SvcFaqHttpClient,
    SvcFaqService
  ]
})
export class SvcFaqModule { }
