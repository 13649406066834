import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { SvcFormCustomFieldComponent } from './svc-form-custom-field.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcControlsModule } from 'projects/lib-shared-component/src/public-api';
import { SvcCustomFieldService } from '../svc-custom-fields/svc-custom-field.service';
import { SvcCustomFieldHttpClient } from '../svc-custom-fields/svc-custom-field-http-client';
import { SvcFormCustomFieldValueService } from './svc-form-custom-field-value.service';

@NgModule({
  declarations: [
    SvcFormCustomFieldComponent
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcControlsModule,
  ],
  exports: [
    SvcFormCustomFieldComponent
  ],
  providers: [
    SvcCustomFieldService,
    SvcCustomFieldHttpClient,
    SvcFormCustomFieldValueService
  ]
})
export class SvcFormCustomFieldModule { }
