<div class="sticky top-0 h-0 z-[999]">
  <mat-icon
    *ngIf="showClose && !loading"
    class="absolute right-1 top-1 text-current cursor-pointer icon-size-3 print:hidden"
    [svgIcon]="'heroicons_solid:x-mark'"
    (click)="confirm();"
  ></mat-icon>
</div>

<div class="flex flex-auto ps p-4 h-[85vh]">
  <iframe
    [src]="url"
    class="w-full h-full"
    sandbox="allow-forms allow-scripts allow-same-origin"
  ></iframe>
</div>

