import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { AgVirtualScrollModule } from 'ag-virtual-scroll';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../../lib-shared-component.module';
import { SvcValidationMessageModule } from '../../svc-validation-message/svc-validation-message.module';
import { SvcChipGroupedComponent } from './svc-chip-grouped/svc-chip-grouped.component';
import { SvcChipComponent } from './svc-chip/svc-chip.component';


@NgModule({
  declarations: [
    SvcChipComponent,
    SvcChipGroupedComponent
  ],
  exports: [
    SvcChipComponent,
    SvcChipGroupedComponent
  ],
  imports: [
    LibSharedCommonModule, 
    LibSharedComponentModule,
    AgVirtualScrollModule,
    TranslocoModule,
    SvcValidationMessageModule,
  ]
})
export class SvcChipModule { }
