export interface Month {
	number: number;
	name: string;
}

export interface MonthYear {
	month: Month;
	year: number;
}

export const MonthList: Month[] = [
	{ number: 1, name: 'Janeiro' },
	{ number: 2, name: 'Fevereiro' },
	{ number: 3, name: 'Março' },
	{ number: 4, name: 'Abril' },
	{ number: 5, name: 'Maio' },
	{ number: 6, name: 'Junho' },
	{ number: 7, name: 'Julho' },
	{ number: 8, name: 'Agosto' },
	{ number: 9, name: 'Setembro' },
	{ number: 10, name: 'Outubro' },
	{ number: 11, name: 'Novembro' },
	{ number: 12, name: 'Dezembro' },
];