import { Injectable } from '@angular/core';
import { SvcDataTableComponent } from '../svc-data-table/svc-data-table.component';
import { SvcDataRenderEvent } from '../events/svc-data-render.event';
import { SvcFunctionsHelper, offsetToParent } from 'projects/lib-shared-common/src/public-api';

@Injectable()
export class SvcDataVirtualScrollService {

	constructor(private helper: SvcFunctionsHelper) {}

	public defineItens(table: SvcDataTableComponent) {
		const vs = table.virtualScroll;
		const dimensions = this.defineDimensions(table);
		const qttyCanAppear = this.howManyCanAppear(table);

		vs.paddingScroll = dimensions.paddingTop;
		vs.totalHeightContent = dimensions.heightAllItems;
		vs.currentStartIndex = dimensions.itemsThatAreGone;
		vs.currentEndIndex = Math.min(vs.currentStartIndex + qttyCanAppear, table.filteredItems.length);
		table.items = table.filteredItems.slice(vs.currentStartIndex, vs.currentEndIndex);
		table.isDataEmpty = table.items.length === 0;

		table.onDataRender.emit(
			new SvcDataRenderEvent<any>({
				items: table.items,
				length: table.items.length,
				startIndex: vs.currentStartIndex,
				endIndex: vs.currentEndIndex,
			})
		);
	}

	public onScrollChange(table: SvcDataTableComponent) {
		this.definepreviousItemsHeight(table);
		this.defineItens(table);
	}

	public definepreviousItemsHeight(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;
		const rows = Array.from(table.body?.itemsContainerEl?.querySelectorAll('svc-data-row') ?? []);
		for (let i = 0; i < rows.length; i++) {
			let children = rows[i];
			let realIndex = vs.currentStartIndex + i;
			if (!vs.previousItemsHeight[realIndex] || vs.previousItemsHeight[realIndex] !== children.clientHeight) {
				vs.previousItemsHeight[realIndex] = children.getBoundingClientRect().height;
			}
		}
	}

	public reset(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;
		vs.previousItemsHeight = [];
		vs.totalHeightContent = 0;
		vs.paddingScroll = 0;
	}

	defineDimensions(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;
		let rowHeight = parseInt(this.helper.onlyNumbers(table.body?.rowHeight ?? '40'));
		let firstVisibleRowHeight = rowHeight;

		if (vs.currentStartIndex < vs.previousItemsHeight.length) {
			let height = vs.previousItemsHeight[vs.currentStartIndex];
			firstVisibleRowHeight = height ? height : rowHeight;
		}

		const elementScrolling = vs.containerSrollElRef()?.nativeElement;
		const bodyToParentOffset = table.useParentScroll
			? offsetToParent(table.el, elementScrolling)
			: 0;
		const currentSrollTop = vs.currentSrollTop - bodyToParentOffset;

		let obj = {
			paddingTop: 0,
			itemsThatAreGone: 0,
			heightAllItems: table.filteredItems.reduce((prev, curr, i) => {
				let height = vs.previousItemsHeight[i];
				return prev + (height ? height : rowHeight);
			}, 0),
		};

		if (currentSrollTop >= firstVisibleRowHeight) {
			let paddingTop = 0;
			let itemsThatAreGone = 0;
			let initialScroll = currentSrollTop;

			for (let h of vs.previousItemsHeight) {
				let height = h ? h : rowHeight;
				if (initialScroll >= height) {
					paddingTop += height;
					initialScroll -= height;
					itemsThatAreGone++;
				} else break;
			}

			obj.paddingTop = paddingTop;
			obj.itemsThatAreGone = itemsThatAreGone;
		}

		return obj;
	}

	public preparePreviousItemAfterDataChange(table: SvcDataTableComponent) {
		let vs = table.virtualScroll;

		if (!table.infinite) vs.previousItemsHeight = new Array(table.filteredItems.length).fill(null);
		else {
			let anothers = table.filteredItems.length - vs.previousItemsHeight.length;
			if (anothers > 0)
				vs.previousItemsHeight = [...vs.previousItemsHeight, ...new Array<number>(anothers).fill(null)];
		}
	}

	public canApplyVirtualScroll(table: SvcDataTableComponent): boolean {
		const tableInputHeight = table._height ?? 'auto';

		const vs = table.virtualScroll;
		if (tableInputHeight !== 'auto' || table.useParentScroll) {
			return true;
		}
		else if (!table.useParentScroll && vs.height > 0) {
			const topHeight = table.header.el?.clientHeight ?? 0;
			const bottomHeight = table.paginateComp?.el?.clientHeight ?? 0;
			const availableHeight = Math.max(0, vs.height - (topHeight + bottomHeight));
			if (availableHeight > 0) {
				const rowHeight = parseInt(this.helper.onlyNumbers(table.body?.rowHeight ?? '40'));
				const avaiableScrollHeight =  Math.max(0, vs.scrollHeight - (topHeight + bottomHeight));
				const scrollableHeight = avaiableScrollHeight - availableHeight;
	
				if (scrollableHeight > rowHeight) {
					return (scrollableHeight / rowHeight) >= 1.5;
				}
			}
		}
		return false;
	}

	private howManyCanAppear(table: SvcDataTableComponent): number {
		const vs = table.virtualScroll;
		const rowHeight = parseInt(this.helper.onlyNumbers(table.body?.rowHeight ?? '40'));
		const topHeight = table.header.el?.clientHeight ?? 0;
		const bottomHeight = table.paginateComp?.el?.clientHeight ?? 0;
		const availableHeight = Math.max(0, vs.height - (topHeight + bottomHeight));
		let height = availableHeight ? availableHeight : parseInt(this.helper.onlyNumbers(table._height));

		let decimalQtty = (isNaN(height) ? 0 : height) / rowHeight;
		let intQtty = Math.floor(decimalQtty);
		return intQtty + (decimalQtty - intQtty > 0 ? 2 : 1);
	}
}
