import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { BehaviorSubject, catchError, finalize, map, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { AvailableHashtags, Hashtag, PostHashtag, UserHashtags } from './user-hashtags.types';
import { ISvcChipGroupedOption, ISvcChipOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-chip/interfaces/svc-chip.interface';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class UserHashtagsService {

  private userHashtags: Hashtag[] = [];
  public userHashtagsHasError = false;
  private allHashtags: AvailableHashtags[] = [];
  
  private _hashtags: BehaviorSubject<Hashtag[]> = new BehaviorSubject<Hashtag[]>(this.userHashtags);
  public hashtags$ = this._hashtags.asObservable();

  private _filteredHashtagClicked = new BehaviorSubject<Hashtag[]>([]);
  public filteredHashtagClicked$ = this._filteredHashtagClicked.asObservable();
  public get currentFilteredHashtags() { return this._filteredHashtagClicked.value; }

  private _clearHashtagPreferenceFilter = new ReplaySubject<boolean>(1);
  public clearHashtagPreferenceFilter$: Observable<boolean> = this._clearHashtagPreferenceFilter.asObservable();

  private _currentHashtagRequest: Subject<Hashtag[]>;

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _translocoService: TranslocoService
  ) {
  }

  filteredHashtagClicked(hashTagId: Hashtag[]) {
    this._filteredHashtagClicked.next(hashTagId);
  }

  clearHashtagPreferenceFilter(clean: boolean) {
    this._clearHashtagPreferenceFilter.next(clean);
  }

  getUserHashtags(): Observable<Hashtag[]> {
    if (this._currentHashtagRequest) {
      return this._currentHashtagRequest;
    }
    
    this._currentHashtagRequest = new Subject<Hashtag[]>();
    this._httpClient.get<UserHashtags[]>(`${this._appConfig.APIs.apiUrlHashtag}/Hashtag/ByUser`).pipe(
      map((response) => response.map((group) => <UserHashtags>({
        siteName: group.siteName,
        hashtags: <Hashtag[]>group.hashtags.map((h, i) => ({
          ...h,
          name: '#' + h.name.replace(/^#/g, ''),
          order: i + 1,
        }))
      }))),
      tap(response => {
        this.userHashtagsHasError = false;
        this.userHashtags = this.formatHashtags(response);
        this._currentHashtagRequest.next(this.userHashtags);
        this._hashtags.next(this.userHashtags)
      }),
      catchError((error) => {
        this.userHashtagsHasError = true;
        this._currentHashtagRequest?.next(this.userHashtags);
        this._hashtags.next(this.userHashtags)
        return error;
      }),
      finalize(() => {
        this._currentHashtagRequest.complete();
        this._currentHashtagRequest = null;
      }),
    ).subscribe();
    return this._currentHashtagRequest;
  }

  getAvailableHashtags(siteId?: number): Observable<AvailableHashtags[]> {
    const params: { [key: string]: any } = {
      globalhashtags: true,
      active: true
    };
    if (siteId) {
      params.siteId = siteId;
    }
    return this._httpClient.get<AvailableHashtags[]>(`${this._appConfig.APIs.apiUrlHashtag}/Hashtag/DropList`, {
      params,
    })
  }

  addHashtag(hashtagIds: number[]): Observable<any> {
    return this._httpClient.post(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe/Subscribe`, hashtagIds).pipe(
      tap(r => {
        hashtagIds.forEach(h => {
          let hashtag = null
          this.allHashtags.forEach(v => {
            v.types.forEach(t => {
              const tempHash = t.hashtags.find(x => x.hashtagId === h)
              if (tempHash) {
                hashtag = tempHash
              }
            })
          })
          if (hashtag) {
            this.userHashtags.push(hashtag)
            this._hashtags.next(this.userHashtags)
          }
        })
      })
    )
  }

  removeHashtag(hashtagId: number): Observable<any> {
    return this._httpClient.delete(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe/RemoveHashtagUserSubscribeByHashtagIdAndUserId/${hashtagId}`).pipe(
      tap(r => {
        const index = this.userHashtags.findIndex(i => i.hashtagId === hashtagId)
        if (index >= 0) {
          this.userHashtags.splice(index, 1)
          this._hashtags.next(this.userHashtags)
        }
      }
      )
    )
  }

  private formatHashtags(items: UserHashtags[]): Hashtag[] {
    const hashtags = [];
    items.forEach((item) => item.hashtags.forEach((hashtag) => {
      hashtags.push({ ...hashtag, isGlobal: item.siteName === 'Global' })
    })
    );
    return hashtags;
  }

  getPostHashtags(term: string): Observable<PostHashtag> {
    return this._httpClient.get<PostHashtag>(`${this._appConfig.APIs.apiUrlHashtag}/Post/Paginated`, {
      params: {
        pageIndex: 0,
        pageSize: 8,
        name: term,
        active: true
      }
    })
  }

  updateHashtagsOrder(hashtags: { id: number, order: number }[]): Observable<PostHashtag> {
    return this._httpClient.put<PostHashtag>(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe`, {
      hashtags
    })
  }

  private _transformHashtgsIntoChipGrouped(dropList: AvailableHashtags[]): ISvcChipGroupedOption[] {
    return dropList?.map(siteGroup => (<ISvcChipGroupedOption>{
      group: this._translocoService.translate(siteGroup.siteName),
      options: siteGroup.types.map((typeGroup) => (<ISvcChipGroupedOption>{
        group: typeGroup.typeName,
        options: typeGroup.hashtags.map((hashtag) => (<ISvcChipOption>{
          label: hashtag.name,
          value: hashtag.hashtagId,
        }))
      })).filter(group => group.options?.length > 0)
    })).filter(group => group.options?.length > 0) ?? [];
  }

  public getGroupedHashtags(siteId: number, active: boolean = true, globalhashtags: boolean = true): Observable<ISvcChipGroupedOption[]> {
     return this._httpClient.get<AvailableHashtags[]>(`${this._appConfig.APIs.apiUrlHashtag}/dropList`, {
        params: {
          active,
          globalhashtags,
          siteId,
        }
     })
      .pipe(
        map((dropList: AvailableHashtags[]) => 
          this._transformHashtgsIntoChipGrouped(dropList)
        )
      );
  }
}

