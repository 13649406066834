import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcControlsModule } from '../svc-controls/svc-controls.module';
import { SvcFiltersComponent } from './svc-filters.component';
import { SvcFilterDialogComponent } from './components/svc-filter-dialog/svc-filter-dialog.component';
import { SvcDialogsModule } from '../svc-dialogs/svc-dialogs.module';
import { SvcButtonsModule } from '../svc-buttons/svc-buttons.module';
import { SvcFiltersDrawerComponent } from './components/svc-filters-drawer/svc-filters-drawer.component';
import { SvcFiltersSidebarComponent } from './components/svc-filters-sidebar/svc-filters-sidebar.component';
import { SvcDrawerModule } from '../svc-drawer/svc-drawer.module';
import { SvcSlideToggleModule } from '../svc-slide-toggle/svc-slide-toggle.module';

@NgModule({
  declarations: [
    SvcFiltersComponent,
    SvcFilterDialogComponent,
    SvcFiltersSidebarComponent,
    SvcFiltersDrawerComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SvcControlsModule,
    SvcSlideToggleModule,
    SvcDialogsModule,
    TranslocoModule,
    SvcButtonsModule,
    SvcDrawerModule,
  ],
  exports: [
    SvcFiltersComponent,
    SvcFilterDialogComponent,
    SvcFiltersSidebarComponent,
    SvcFiltersDrawerComponent,
  ]
})
export class SvcFiltersModule { }
