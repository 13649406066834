import { Component, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { SitesService, SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SvcBookmarkService } from 'projects/lib-shared-core/src/lib/services/svc-bookmark.service';
import { SvcBookmarkListById, SvcBookmarkListByUid } from 'projects/lib-shared-core/src/lib/interfaces/svc-bookmark.interface';
import { HttpErrorService, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';

@Component({
  selector: 'svc-bookmark-button',
  templateUrl: './svc-bookmark-button.component.html',
  styleUrls: ['./svc-bookmark-button.component.scss'],
  host: {
    'matRipple': '',
    '[matRippleDisabled]': 'readonly || isLoading',
    '(click)': '$event.stopPropagation() || toggleValue()'
  },
})
export class SvcBookmarkButtonComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public referenceId!: number | string;
  @Input() public applicationId: string;
  @Input() public siteId: number;
  @Input() public initialValue: boolean;
  @Input() public readonly = false;
  @Input() public iconSizeClass: string;
  @Input() public mockMode: boolean = false;
  @Input() public dark: boolean = false;
  @Input() public iconAlwaysFilled: boolean = false;

  @Output() public onValueChanged = new EventEmitter<boolean>()
  @Output() public onToggleError = new EventEmitter<boolean>()
  @Output() public onInitialValueObtained = new EventEmitter<boolean>();

  @HostBinding('class.bookmark-clickable') private bookmarkClickable = false;
  @HostBinding('class.bookmark-dark') private bookmarkDark = false;
  @HostBinding('class.star-shimmer') private shimmer = false;

  #httpErrorService = inject(HttpErrorService);
  #sitesService = inject(SitesService);
  #bookmarkService = inject(SvcBookmarkService);
  #appSettings = inject(SvcAppSettings);
  #translocoService = inject(TranslocoService);
  #toastService = inject(SvcToastService);

  #wasInitialized = false;
  #requestSubscription: Subscription;
  #destroy$ = new Subject<void>();

  protected value: boolean;

  protected isLoading$ = new BehaviorSubject(false);
  protected set isLoading(value: boolean) { this.isLoading$.next(value); }
  protected get isLoading() { return this.isLoading$.value; }

  public ngOnInit(): void {
    this.isLoading$.pipe(
      takeUntil(this.#destroy$),
      tap((value) => {
        this.shimmer = value;
        this.bookmarkClickable = !value && !(this.readonly ?? false);
      }),
    ).subscribe();
    if (isNullOrUndefined(this.initialValue)) {
      this._get();
    }
    this.#wasInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.#wasInitialized && ('referenceId' in changes || 'applicationId' in changes || 'siteId' in changes)) {
      this._get();
    }
    if ('initialValue' in changes) {
      this.value = this.initialValue;
    }
    if ('readonly' in changes) {
      this.bookmarkClickable = !this.isLoading && !(this.readonly ?? false);
    }
    if ('dark' in changes) {
      this.bookmarkDark = this.dark ?? false;
    }
  }

  public refresh() {
    this._get();
  }

  private _get() {
    if (!this.mockMode && this.referenceId) {
      this.#requestSubscription?.unsubscribe();
      this.isLoading = true;
      const request: Observable<(SvcBookmarkListByUid | SvcBookmarkListById)[]> = typeof this.referenceId === 'string'
        ? this.#bookmarkService.getBookmarkListByUIds(
          this.applicationId ?? this.#appSettings.applicationId,
          this.siteId ?? this.#sitesService.currentSite.siteId,
          [this.referenceId],
        )
        : this.#bookmarkService.getBookmarkListByIds(
          this.applicationId ?? this.#appSettings.applicationId,
          this.siteId ?? this.#sitesService.currentSite.siteId,
          [this.referenceId],
        );
      this.#requestSubscription = request.pipe(
        takeUntil(this.#destroy$),
        tap((response) => {
          this.value = response.some((item) => (item['registryUniqueId'] || item['registryUniqueUId']) === this.referenceId);
          this.onInitialValueObtained.emit(this.value);
        }),
        finalize(() => {
          this.#requestSubscription = null;
          this.isLoading = false;
        }),
      ).subscribe();
    }
    else {
      const currentValue = this.value ?? false;
      this.value = !currentValue;
    }
  }

  public toggleValue() {
    if (!this.readonly && !this.isLoading) {
      const currentValue = this.value ?? false;
      const onSuccess = () => {
        this.value = !currentValue;
        this.onValueChanged.emit(this.value);
        if (!this.onValueChanged.observed) {
          this.#showSuccessFeedback(this.value);
        }
      }
      const onError = (error: any) => {
        if ('UQ_RegistryBookmark' in (error.error?.errors ?? {})) {
          this.value = !currentValue;
          this.onValueChanged.emit(this.value);
          if (!this.onValueChanged.observed) {
            this.#showSuccessFeedback(this.value);
          }
        }
        else {
          this.onToggleError.emit(!currentValue);
          if (!this.onToggleError.observed) {
            this.#httpErrorService.showErrorInToast(error);
          }
        }
        return error;
      }
      const onFinalize = () => {
        this.#requestSubscription = null;
        this.isLoading = false;
      }
      if (currentValue) {
        const request = (typeof this.referenceId === 'string')
          ? this.#bookmarkService.deleteBookmarkRegistryUid(
            this.referenceId,
            this.applicationId ?? this.#appSettings.applicationId,
            this.siteId ?? this.#sitesService.currentSite.siteId,
          )
          : this.#bookmarkService.deleteBookmarkRegistryId(
            this.referenceId,
            this.applicationId ?? this.#appSettings.applicationId,
            this.siteId ?? this.#sitesService.currentSite.siteId,
          );
        this.#requestSubscription?.unsubscribe();
        this.isLoading = true;
        request.pipe(
          takeUntil(this.#destroy$),
          tap(() => onSuccess()),
          catchError((error) => onError(error)),
          finalize(() => onFinalize()),
        ).subscribe();
      }
      else {
        const request = (typeof this.referenceId === 'string')
          ? this.#bookmarkService.addBookmarkRegistryUid(
            this.referenceId,
            this.applicationId ?? this.#appSettings.applicationId,
            this.siteId ?? this.#sitesService.currentSite.siteId,
          )
          : this.#bookmarkService.addBookmarkRegistryId(
            this.referenceId,
            this.applicationId ?? this.#appSettings.applicationId,
            this.siteId ?? this.#sitesService.currentSite.siteId,
          );
        this.#requestSubscription?.unsubscribe();
        this.isLoading = true;
        request.pipe(
          takeUntil(this.#destroy$),
          tap(() => onSuccess()),
          catchError((error) => onError(error)),
          finalize(() => onFinalize()),
        ).subscribe();
      }
    }
  }

  #showSuccessFeedback(value: boolean) {
    const messageKey = value ? 'adicionado aos' : 'removido dos';
    this.#toastService.success(this.#translocoService.translate(`Este item foi ${messageKey} favoritos com sucesso`)+'!');
  }

  ngOnDestroy(): void {
    this.#destroy$?.next();
    this.#destroy$?.complete();
    this.#requestSubscription?.unsubscribe();
  }
}
