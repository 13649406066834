import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcModalAlertComponent } from './svc-modal-alert.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/lib/lib-shared-common.module';
import { LibSharedComponentModule } from 'projects/lib-shared-component/src/lib/lib-shared-component.module';
import { SvcDialogsModule } from 'projects/lib-shared-component/src/lib/svc-dialogs/svc-dialogs.module';
import { SvcButtonsModule, SvcCarouselModule, SvcControlsModule, SvcStatusBadgeModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcAudioToTextButtonModule, SvcBookmarkButtonModule, SvcCommentsModule, SvcReactionModule, SvcTotalsStatusModule } from 'projects/lib-shared-feature/src/public-api';
import { AlertFeedTabComponent } from './alert-feed-tab/alert-feed-tab.component';
import { SvcActionplanModule } from '../../general/svc-actionplan/svc-actionplan.module';

@NgModule({
  declarations: [
    SvcModalAlertComponent,
    AlertFeedTabComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    TranslocoModule,
    SvcControlsModule,
    SvcCarouselModule,
    SvcUserAvatarModule,
    SvcAudioToTextButtonModule,
    SvcButtonsModule,
    SvcStatusBadgeModule,
    SvcActionplanModule,
    SvcCommentsModule,
    SvcTotalsStatusModule,
    SvcReactionModule,
    SvcBookmarkButtonModule,
  ],
  exports: [
    SvcModalAlertComponent
  ],
})
export class SvcModalAlertModule { }
