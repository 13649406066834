import { Injectable} from "@angular/core";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { AppEnvironmentConfig} from "../../../../../config/model/environment.config.model";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SvcPostsHttpClient extends SvcHttpClient {

  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlPosts, httpClient);
  }
}
