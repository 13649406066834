<ng-container *screenSizes="let size">
  <div class="relative flex items-center justify-center m-auto h-[150px] max-h-[150px]">
    <div class="img-container relative">
      <img
        *ngIf="current"
        class="object-contain" 
        [src]="current.src"
        (error)="current.src = null; current.isLoading = false"
        (load)="updateComponentProps(); current.isLoading = false"
      />
    </div>
    <div
      class="carousel-nav absolute rounded-full left-0 top-1/2 -translate-y-1/2 text-white"
      matRipple
      [matTooltip]="hasPrev ? ('Anterior' | transloco) : null"
      [matTooltipPosition]="size.isXS ? 'above' : 'left'"
      (click)="hasPrev && previous()"
      [ngClass]="{ 'cursor-pointer': hasPrev, 'opacity-30': !hasPrev }"
    >
      <mat-icon class="text-current icon-size-7" svgIcon="fontawesome_solid:angle-left"/>
    </div>
    <div
      class="carousel-nav absolute rounded-full right-0 top-1/2 -translate-y-1/2 text-white"
      matRipple
      [matTooltip]="hasNext ? ('Próximo' | transloco) : null"
      [matTooltipPosition]="size.isXS ? 'above' : 'right'"
      (click)="hasNext && next()"
      [ngClass]="{ 'cursor-pointer': hasNext, 'opacity-30': !hasNext }"
    >
      <mat-icon class="text-current icon-size-7" svgIcon="fontawesome_solid:angle-right"/>
    </div>
    <div *ngIf="current?.isLoading" class="absolute inset-0 flex items-center justify-center text-primary">
      <div class="absolute inset-0 bg-white opacity-50"></div>
      <mat-spinner class="relative z-99 text-current" diameter="24"/>
    </div>
  </div>
  <div *ngIf="currentElement" class="flex justify-center items-center w-full">
    <mat-checkbox [(ngModel)]="currentElement.selected" [disabled]="!isSelectable">
      <span class="text-xs font-normal text-current">
        {{ 'Selecionar imagem' | transloco }}
      </span>
    </mat-checkbox>
  </div>
</ng-container>