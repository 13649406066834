import { Observable } from "rxjs";

export interface SvcPrinterData {
  options: SvcPrinterOption[];
  additional?: {
    title?: string,
    titleTip?: string,
    options: SvcPrinterOption[],
  };
  printAreaWidth?: number;
  onPrinting?: {
    printWillStart?: (options: SvcPrinterOption[]) => (void | Observable<void>),
    printDidStart?: (options: SvcPrinterOption[]) => (void | Observable<void>),
    printWillFinish?: (options: SvcPrinterOption[]) => (void | Promise<void>),
    printDidFinish?: (options: SvcPrinterOption[]) => (void | Observable<void>),
  };
}

interface SvcBasePrinterOption {
  name: string;
  selected?: boolean;
  disabled?: boolean;
  show?: boolean;
  miniature?: boolean;
  separatedPage?: boolean;
}

export interface SvcPrinterSubOption extends SvcBasePrinterOption {
  elements: HTMLElement[];
}

export interface SvcPrinterOption extends SvcBasePrinterOption {
  options?: SvcPrinterSubOption[];
  areAllImageElements?: boolean;
  elements: HTMLElement[] | SvcPrinterElement[];
}

export interface SvcPrinterElement {
  element: HTMLElement;
  selected: boolean;
}