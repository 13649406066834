import { inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, finalize, forkJoin, Observable, Subscription, tap } from 'rxjs';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import { Uda } from './uda.model';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';

export type SvcLocationOptions = {
  departments: Uda[];
  areas: Uda[];
  locations: Uda[];
};

@Injectable()
export class SvcLocationsService extends SvcHttpClient implements OnDestroy {
  #initialOptionsIsLoading = new BehaviorSubject<boolean>(true);
  public initialOptionsIsLoading$: Observable<boolean> = this.#initialOptionsIsLoading.asObservable();
  #initialOptions = new BehaviorSubject<SvcLocationOptions>({
    departments: [],
    areas: [],
    locations: [],
  });
  public initialOptions$: Observable<SvcLocationOptions> = this.#initialOptions.asObservable();

  #initialOptionsSubsciprtion: Subscription;

  private _userService = inject(UserService);

  constructor(
    protected httpClient: HttpClient,
    protected appConfig: AppEnvironmentConfig
  ) {
    super(
      appConfig.APIs.apiUrlMasterdata,
      httpClient,
    );
  }

  public getInitialOptions(): void {
    this.#initialOptionsIsLoading.next(true);
    if (!this.#initialOptionsSubsciprtion) {
      this.#initialOptionsSubsciprtion = forkJoin({
        departments: this.get<Uda[]>(`/UDA/department?isActive=true&siteId=${this._userService.user.lastSiteId}`),
        areas: this.get<Uda[]>(`/UDA/area?isActive=true&siteId=${this._userService.user.lastSiteId}`),
        locations: this.get<Uda[]>(`/UDA/location?isActive=true&siteId=${this._userService.user.lastSiteId}`),
      }).pipe(
        tap((response) => {
          this.#initialOptions.next(response);
        }),
        catchError((error) => {
          this.#initialOptions.next(this.#initialOptions.value);
          return error;
        }),
        finalize(() => {
          this.#initialOptionsIsLoading.next(false);
          this.#initialOptionsSubsciprtion = null;
        }),
      ).subscribe();
    }
  }

  public getAreasByDepartmentId(departmentId: number): Observable<Uda[]> {
    return this.get<Uda[]>(`/UDA/area?deptoId=${departmentId}&isActive=true&siteId=${this._userService.user.lastSiteId}`);
  }

  public getLocationsByAreaId(areaId: number): Observable<Uda[]> {
    return this.get<Uda[]>(`/UDA/location?areaId=${areaId}&isActive=true&siteId=${this._userService.user.lastSiteId}`);
  }

  public ngOnDestroy() {
    this.#initialOptionsSubsciprtion?.unsubscribe();
    this.#initialOptionsSubsciprtion = null;
  }
}
