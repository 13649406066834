<div class="relative">
  <svc-chip-grouped
    [label]="label ?? ('Local' | transloco)"
    [formControl]="formControl"
    [options]="options"
    [loading]="isLoading"
    [inputId]="inputId"
    [readonly]="readonly"
    [fallbackTextValue]="fallbackTextValue"
    [placeholder]="placeholder"
    type="single"
  >
    <ng-template let-option="option" let-rederingInList="rederingInList">
      {{ option.label }}
      <p *ngIf="rederingInList" class="text-xs opacity-60">{{ option.data?.groupDescription }}</p>
    </ng-template>
  </svc-chip-grouped>

  <button
    *ngIf="!readonly"
    mat-icon-button
    id="svc-location-field-button-open-modal-locations"
    class="absolute top-8 right-7 w-7 min-w-7 h-7 min-h-7 text-primary"
    [disabled]="isLoading || formControl?.disabled"
    (click)="openDialog()"
  >
    <mat-icon class="text-current icon-size-5" svgIcon="mat_solid:search"></mat-icon>
  </button>
</div>
