import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { environment } from 'projects/environments/environment';

import { StaticApplicationId } from 'projects/lib-shared-core/src/public-api';
import { PraiseItemCard } from '../models/praise-item-card.model';
import { SvcReactionComponent } from 'projects/lib-shared-feature/src/lib/general/svc-reaction/svc-reaction.component';
import { SvcTotalReactionsComponent } from 'projects/lib-shared-feature/src/lib/general/svc-reaction/svc-total-reactions/svc-total-reactions.component';
import { SvcCommentsTotalComponent } from 'projects/lib-shared-feature/src/lib/general/svc-comments/components/svc-comments-total/svc-comments-total.component';
import { SvcTotalsStatusComponent } from 'projects/lib-shared-feature/src/lib/general/svc-totals-status/svc-totals-status.component';

@Component({
  selector: 'praise-item-card',
  templateUrl: './praise-item-card.component.html',
  styleUrls: ['./praise-item-card.component.scss'],
})

export class PraiseItemCardComponent {
  @Input() public item: PraiseItemCard;
  @Output() public onCardClick = new EventEmitter<void>();

  @ViewChild(SvcReactionComponent) public svcReaction: SvcReactionComponent;
  @ViewChild(SvcTotalReactionsComponent) public svcTotalReaction: SvcTotalReactionsComponent;
  @ViewChild(SvcCommentsTotalComponent) public svcCommentsTotal: SvcCommentsTotalComponent;
  @ViewChild(SvcTotalsStatusComponent) public svcTotalsStatus: SvcTotalsStatusComponent;

  public env = environment;
  public isReactionLoading = false;
  public isReactionDone = false;
  public applicationId = StaticApplicationId.praise;
  public configTypeId = '26B0FC9D-AE9D-477F-BC14-E4D68D6E4DAC';
  public featureName = 'views-praise';

  public refreshReactions() {
    this.svcReaction?.refresh();
    this.svcTotalReaction?.refresh();
  }
  public refreshComments() {
    this.svcCommentsTotal?.refresh();
  }
  public refreshTotalStatus() {
    this.svcTotalsStatus?.refresh();
  }
}
