import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvcPrinterData, SvcPrinterElement, SvcPrinterOption, SvcPrinterSubOption } from './svc-printer-classes';
import { delay, lastValueFrom, Observable, of } from 'rxjs';

@Component({
  selector: 'svc-printer-modal',
  templateUrl: './svc-printer-modal.component.html',
  styleUrls: ['./svc-printer-modal.component.scss'],
})
export class SvcPrinterModalComponent implements OnInit, OnDestroy {

  protected someOptionSeleted = false;
  protected isLoading = false;

  private set _isLoading(value: boolean) {
    this.isLoading = value;
    this._dialogRef.disableClose = this.isLoading;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: SvcPrinterData,
    private _dialogRef: MatDialogRef<SvcPrinterModalComponent>,
  ) {
  }

  public ngOnInit(): void {
    this.checktIfThereIsSomeOptionSeleted();
  }

  protected async print() {
    let printWillStart = await this.data?.onPrinting?.printWillStart?.(this.data.options);
    if (printWillStart instanceof Observable) await lastValueFrom(printWillStart);
    await this._takeBreak();

    const elementsToPrint: HTMLElement[] = []
    const elementHandling = (element: HTMLElement, option: SvcPrinterOption | SvcPrinterSubOption) => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden');
      }
      if (element instanceof HTMLImageElement) {
        element.classList.add('w-full', 'h-auto');
      }
    };
    const addSeparation = () => {
      const separation = document.createElement('div');
      separation.classList.add('page-break');
      elementsToPrint.push(separation);
    };
    const processOptions = (options: SvcPrinterOption[]) => {
      for (const option of (options ?? [])) {
        if ((!(option.show ?? true) || option.selected) && option.elements?.length) {
          if (option.separatedPage && elementsToPrint.length > 0) addSeparation();
          for (const element of option.elements) {
            if (!(element instanceof HTMLElement) && !(<SvcPrinterElement>element).selected) continue;
            const clonedElement = (!(element instanceof HTMLElement)
              ? (<SvcPrinterElement>element).element
              : element
            ).cloneNode(true) as HTMLElement;
            elementHandling(clonedElement, option);
            elementsToPrint.push(clonedElement);
          }

          for (const optionChild of (option.options ?? [])) {
            if ((!(optionChild.show ?? true) || optionChild.selected) && optionChild.elements.length > 0) {
              for (const elementChild of optionChild.elements) {
                const clonedChildElement = elementChild.cloneNode(true) as HTMLElement;
                elementHandling(clonedChildElement, optionChild);
                if (optionChild.separatedPage) addSeparation();
                elementsToPrint.push(clonedChildElement);
                if (optionChild.separatedPage) addSeparation();
              }
            }
          }

          if (option.separatedPage) addSeparation();
        }
      }
    }

    processOptions(this.data.options);
    processOptions(this.data.additional?.options);

    const containerElement = this._showElementsToRender(elementsToPrint);
    const imgs = containerElement.querySelectorAll('img');
    if (imgs.length > 0) {
      this._isLoading = true;
      await this._waitImgsBeLoaded(Array.from(imgs));
      this._isLoading = false;
    }

    let printDidStart = this.data?.onPrinting?.printDidStart?.(this.data.options);
    if (printDidStart instanceof Observable) await lastValueFrom(printDidStart);
    await this._takeBreak();
    window.print();
    setTimeout(async () => {
      let printWillFinish = this.data?.onPrinting?.printWillFinish?.(this.data.options);
      if (printWillFinish instanceof Observable) await lastValueFrom(printWillFinish);
      await this._takeBreak();
      this._hideElementsToRender();
      let printDidFinish = this.data?.onPrinting?.printDidFinish?.(this.data.options);
      if (printDidFinish instanceof Observable) await lastValueFrom(printDidFinish);
    }, 1000);
  }

  protected changeOptionSelected(option: SvcPrinterOption, fromAdditional: boolean = false) {
    option.selected = !(option.selected ?? false);
    if (!option.selected && option.options?.length) {
      option.options.forEach((op) => op.selected = false);
    }
    this.checktIfThereIsSomeOptionSeleted();
  }

  protected checktIfThereIsSomeOptionSeleted() {
    this.someOptionSeleted = (this.data?.options ?? []).some(x => x.selected) || (this.data?.additional?.options ?? []).some(x => x.selected);
  }

  private _showElementsToRender(elements: HTMLElement[]): HTMLElement {
    const printContainer = document.createElement('div');
    printContainer.id = 'printable';
    printContainer.classList.add('printable-container');
    for (const element of elements) {
      printContainer.appendChild(element);
    }
    document.body.prepend(printContainer);
    return printContainer;
  }

  private _hideElementsToRender() {
    const printContainer = document.querySelector('#printable');
    if (printContainer) {
      document.body.removeChild(printContainer);
    }
  }

  private _waitImgsBeLoaded(imgs: HTMLImageElement[]): Promise<void> {
    return new Promise<void>((resolve) => {
      const imgsLoading = Array.from(imgs).map(() => true);
      const checkIfLoadingWasDone = () => {
        if (imgsLoading.every((loading) => !loading)) {
          resolve();
        }
      }
      imgs.forEach((img, i) => {
        img.onload = () => {
          imgsLoading[i] = false;
          checkIfLoadingWasDone();
        }
        img.onerror = () => {
          imgsLoading[i] = false;
          checkIfLoadingWasDone();
        }
      });
    });
  }

  private async _takeBreak() {
    return lastValueFrom(of([]).pipe(delay(0)));
  }

  ngOnDestroy(): void {
    this._hideElementsToRender();
  }
}
