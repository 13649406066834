<div class="flex justify-center py-2 gap-3">
  <div class="flex items-center">
    <svc-button
      mode="simple"
      class="button-month"
      [icon-only]="true"
      (click)="disabled || prevMonth()"
      [disabled]="disabled"
    >
      <mat-icon class="icon-size-4 text-default" svgIcon="heroicons_mini:chevron-left"></mat-icon>
    </svc-button>

    <div class="relative min-w-20">
      <svc-button
        mode="simple"
        class="button-year"
        [fullWidth]="true"
        [disabled]="disabled"
        (click)="disabled || toggleMonths()"
      ><span class="text-default">{{ activeMonth?.name | transloco }}</span></svc-button>

      <div
        *ngIf="showMonths"
        class="absolute left-0 min-w-28 bg-gray-100 z-10 shadow-md"
      >
        <div
          *ngFor="let month of months"
          (click)="selectMonth(month.number)"
          class="text-center p-2 hover:bg-gray-200 font-bold cursor-pointer"
          [ngClass]="{
            'bg-primary text-on-primary': month.number === activeMonth?.number,
            'hover:bg-gray-200' : month.number !== activeMonth?.number,
          }"
        >{{month.name | transloco}}</div>
      </div>
    </div>
    <svc-button
      mode="simple"
      class="button-month"
      [fullWidth]="true"
      [icon-only]="true"
      [disabled]="disabled"
      (click)="disabled || nextMonth();"
    >
      <mat-icon class="icon-size-4 text-default" svgIcon="heroicons_mini:chevron-right"></mat-icon>
    </svc-button>
  </div>

  <div class="relative min-w-20">

    <svc-button
      mode="simple"
      (click)="toggleYears()"
    ><span class="text-default">{{activeYear}}</span></svc-button>

    <div
      *ngIf="showYears"
      class="absolute left-0 right-0 bg-gray-100 z-10 shadow-md"
    >
      <div
        *ngFor="let year of years"
        class="text-center p-2 cursor-pointer"
        [ngClass]="{
          'bg-primary text-on-primary': year === activeYear,
          'hover:bg-gray-200' : year !== activeYear
        }"
        (click)="selectYear(year)"
      >
        {{year}}
      </div>
    </div>
  </div>

</div>