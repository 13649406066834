<div class="flex flex-row" [ngClass]="customClass">
	<ng-content></ng-content>	
	<div *ngIf="showVoiceToTextButton" class="flex pl-2 items-center">
		<svc-audio-to-text-button
			[small]="small"
			[disabled]="disabled"
			[canChooseRecording]="canChooseRecording"
			[shadow]="shadow"
			(onText)="control.setValue($event)"
		></svc-audio-to-text-button>
	</div>
</div>
