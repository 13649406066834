import { SvcFilterMode, SvcFilterType } from "../../../svc-data-table/public-api";

export interface SvcDrilldownConfig {
  title: string;
  columns: SvcDrilldownColumns[];

  /**
   * Inputs of svc-data-table
   */
  height?: string;
  clickable?: boolean;
  hoverable?: boolean;
  infinite?: number;
  loading?: boolean;
  dataLength?: number;
  serverSide?: boolean;
  getDataInit?: boolean;
  currentPage?: number;
  noPagination?: boolean;
  canChangePageSize?: boolean;
  dataAreOver?: boolean;
  minWidth?: string;
  minHeight?: string;
  useParentScroll?: boolean;
}

export interface SvcDrilldownColumns {
  type: 'id' | 'string' | 'status';
  label?: string;
  textBeforeValue?: string;

  /**
   * Inputs of svc-data-col
   */
  field?: string;
  width?: string;
  customFilter?: { field: string, mode?: SvcFilterMode, value?: any } |
  { field: string, mode?: SvcFilterMode, value?: any }[] | string | string[];
  filter?: SvcFilterType;
	mode?: SvcFilterMode;
	placeholder?: string;
	readOnly?: boolean;
	minDate?: Date;
	maxDate?: Date;
	dateFormat?: string;
	dateSeparator?: string;
	optionAllLabel?: string ;
	options?: { text: string, value: any }[] | any[];
	title?: string;
	align?: 'left' | 'right' | 'center';
	noSort?: boolean ;
	noTruncate?: boolean;
	sort?: string;
  disableFilter?: boolean;
  filterValue?: any;
}
