import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { scaleOrdinal } from 'd3-scale';
import { schemeBlues, schemeCategory10, schemeGreens, schemePastel1, schemePastel2 } from 'd3-scale-chromatic';
import { WordCloudData } from './interfaces/svc-word-cloud.interface'
import { AngularD3Word } from './d3-encapsulation-component/interfaces';

@Component({
  selector: 'svc-word-cloud',
  templateUrl: './svc-word-cloud.component.html',
  styleUrls: ['./svc-word-cloud.component.scss']
})
export class SvcWordCloudComponent implements OnChanges {
  @Output() onWordClickEvent = new EventEmitter<any>()
  @Output() onWordMouseOverEvent = new EventEmitter<any>()
  @Output() onWordMouseMoveEvent = new EventEmitter<any>()
  @Output() onWordMouseOutEvent = new EventEmitter<any>()

  @Input() isLoading: boolean = true;
  @Input() data: WordCloudData[];
  public processedData: AngularD3Word[] = [];

  public schemas: any[] = [
    { id: 0, name: 'Category10', schema: schemeCategory10 },
    { id: 1, name: 'Blues', schema: schemeBlues[9] },
    { id: 2, name: 'Greens', schema: schemeGreens[9] },
    { id: 3, name: 'Pastel1', schema: schemePastel1 },
    { id: 4, name: 'Pastel2', schema: schemePastel2 },
  ];
  public fonts: string[] = [
    'Arial',
    'Verdana',
    'Impact',
    'Times New Roman',
    'Georgia',
    'Courier',
    'Lucida',
    'Monaco',
    'Comic Sans MS',
  ];
  public weights: any[] = [
    { text: 'Thin', value: 100 },
    { text: 'Extra Light', value: 200 },
    { text: 'Light', value: 300 },
    { text: 'Normal', value: 400 },
    { text: 'Medium', value: 500 },
    { text: 'Semi Bold', value: 600 },
    { text: 'Bold', value: 700 },
    { text: 'Extra Bold', value: 800 },
    { text: 'Black ', value: 900 },
  ];
  public styles: string[] = ['normal', 'italic'];
  public paddings: number[] = [0, 1, 2, 3, 4, 5];
  public speeds: number[] = [100, 300, 600, 1000];

  public rotate!: number | ((word: AngularD3Word, index: number) => number);
  public fillMapper!: (word: AngularD3Word, index: number) => string;
  public animations: boolean = true;
  public speed: number = 600;
  public autoFill: boolean = true;
  public font: string = 'Arial Black';
  public weight: number = 100;
  public style: string = 'normal';
  public padding: number = 20;
  public tooltip: boolean = true;
  public hover: boolean = true;
  public selection: boolean = true;

  private _rotation: boolean = true;

  public get rotation(): boolean {
    return this._rotation;
  }

  public set rotation(value: boolean) {
    this._rotation = value;
    this.applyRotation();
  }
  private _fillScheme: number = 0;
  public get fillScheme(): number {
    return this._fillScheme;
  }
  public set fillScheme(value: number) {
    this._fillScheme = value;
    this.applyFillMapper();
  }

  private fillSchema = (schema: readonly string[]) => scaleOrdinal(schema);  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.processData(changes.data.currentValue);
    }
  }

  private processData(data: WordCloudData[]): void {
    this.processedData = this.applyLogScale(data).map(item => {
      return {
        text: item.text.toLowerCase(),
        value: item.repetitions,
        tooltip: '',
      } as AngularD3Word;
    });

    this.applyRotation();
    this.applyFillMapper();
  }

  applyLogScale(data: WordCloudData[]): WordCloudData[] {
    const maxRepetitions = Math.max(...data.map(item => item.repetitions));

    if (maxRepetitions === 1) {
      return data.map(item => ({
        ...item,
        repetitions: 3
      }));
    }

    return data.map(item => {
      const scaledValue = Math.log(item.repetitions) / Math.log(maxRepetitions);
      const adjustedValue = Math.max(scaledValue, 0.15);

      return {
        ...item,
        repetitions: adjustedValue * 120
      };
    });
  }


  onWordClick(data: { event: MouseEvent; word: AngularD3Word }): void {
    this.onWordClickEvent.emit(data.event?.type);
  }

  onWordMouseOver(data: { event: MouseEvent; word: AngularD3Word }): void {
    this.onWordMouseOverEvent.emit(data.event?.type);
  }

  onWordMouseMove(data: { event: MouseEvent; word: AngularD3Word }): void {
    this.onWordMouseMoveEvent.emit(data.event?.type);
  }

  onWordMouseOut(data: { event: MouseEvent; word: AngularD3Word }): void {
    this.onWordMouseOutEvent.emit(data.event?.type);
  }

  private applyRotation(): void {
    const allowedAngles = [0, 45, -45, 90, -90];
    this.rotate = this._rotation ? () => {
      return allowedAngles[Math.floor(Math.random() * allowedAngles.length)];
    } : 0;
  }


  private applyFillMapper(): void {
    const schema = this.schemas[this._fillScheme].schema;
    const fillFunction = this.fillSchema(schema);
    this.fillMapper = (word: AngularD3Word, index: number): string => {
      return fillFunction(index.toString());
    };
  }
}
