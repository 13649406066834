import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { generateModuleMenuURL } from 'projects/lib-shared-common/src/public-api';
import {FloatAddButtonService} from "./service/float-add-button.service";
import {SvcMenuNavigationItemType} from "../navigation/services/navigation.types";
import {animate, style, transition, trigger} from "@angular/animations";
import {Router} from "@angular/router";

@Component({
  selector: 'float-add-button',
  templateUrl: './float-add-button.component.html',
  styleUrls: ['./float-add-button.component.scss'],
  animations: [
    trigger('menuAnimation', [
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'scale3d(.3,.3,.3)',
          transformOrigin: 'bottom'
        }),
        animate('200ms ease-in', style({
          opacity: 1,
          transformOrigin: 'scale3d(1, 1, 1)'
        }))
      ]),
      transition('* => void', [
        animate('200ms ease-in', style({
          opacity: 0,
          transform: 'scale3d(.0, .0, .0)',
          transformOrigin: 'bottom',
        }))
      ])
    ]),
  ]
})
export class FloatAddButtonComponent implements  OnInit, OnDestroy {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  @Input() bottomCssClass: string;

  visible: boolean = true;
  opened: boolean = false;
  menuList: any[];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _floatMenuService: FloatAddButtonService,
    private _router: Router,
    private eRef: ElementRef
  ) {}

  ngOnInit() {
    this._floatMenuService.get().subscribe(res => {
      this.menuList = [...res].reverse();
    })
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  openMenu(menu) {

    if(!menu.isActived){
      return
    }

    this.opened = false;

    let url = generateModuleMenuURL(
      SvcMenuNavigationItemType[menu.menuPath.applicationType],
      menu.menuPath.url,
      menu.menuPath.route,
      menu.menuPath.params
    );

    if (typeof url === 'function') url();
    if (typeof url === 'string') {
      this._router.navigate([url]);
    }
  }

  toggle(): void {
    this.visible = !this.visible;
  }

}
