import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { SvcPrinterElement, SvcPrinterOption } from '../svc-printer-classes';

@Component({
  selector: 'svc-printer-miniature',
  templateUrl: './svc-printer-miniature.component.html',
  styleUrls: ['./svc-printer-miniature.component.scss'],
})
export class SvcPrinterMiniatureComponent implements OnInit, AfterViewInit {

  @HostBinding('class.horizontal') protected isHorizontal = true;
  @HostBinding('class.vertical') protected isVertical = false;
  @HostBinding('class.disabled') protected isDisabled = false;

  @Input() public option: SvcPrinterOption;
  @Input() public printAreaWidth: number;

  @ViewChild('container') private containerEl: ElementRef<HTMLElement>;
  protected miniatureElement: HTMLElement;
  protected element: HTMLElement;
  protected ableToShow = false;
  protected miniatureScaledHeight: number;

  constructor(
    private _elementRef: ElementRef<HTMLElement>
  ) {
  }

  public ngOnInit(): void { }

  public ngAfterViewInit() {
    if (this.option?.elements?.length) {
      this.isDisabled = this.option.disabled ?? false;
      this.element = this._elementRef.nativeElement;
      let element = this.option.elements[0];
      this.miniatureElement = ((typeof element === 'object')
        ? (<SvcPrinterElement>element).element
        : element
      ).cloneNode(true) as HTMLElement;
      if (this.miniatureElement.classList.contains('hidden'))
        this.miniatureElement.classList.remove('hidden');
      this.containerEl?.nativeElement.appendChild(this.miniatureElement);
      this.ableToShow = true;
      setTimeout(() => {
        this.isHorizontal = this.miniatureElement.clientWidth > this.miniatureElement.clientHeight;
        this.isVertical = !this.isHorizontal;
        this.miniatureScaledHeight = ((this.isVertical ? 122 : 192) / this.miniatureElement.clientWidth) * this.miniatureElement.clientHeight;
      });
    }
  }
}
