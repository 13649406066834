<svc-dialog dialogStyle="form" fullscreen="XS" [disabledConfirm]="forDelete.length === 0" (confirmed)="save()" [ngClass]="{ 'opacity-60': isLoading }">

  <mat-icon svc-dialog-title-icon svgIcon="heroicons_solid:pencil-square"></mat-icon>

  <div svc-dialog-title>
    {{ 'Editar Hashtag' | transloco }}
  </div>

  <!--FORM CONTENT-->
  <div svc-dialog-content >
    <div class="flex-col">

      <mat-tab-group class="w-full" (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngFor="let hashtagType of [hashtagTypeEnum.Local, hashtagTypeEnum.Global]" mat-stretch-tabs="true">
          <ng-template mat-tab-label>

            <ng-container *ngIf="hashtagType == hashtagTypeEnum.Global">
              <mat-icon class="mr-1" [ngClass]="{ 'text-primary': this.typeActive == hashtagType }"
                [svgIcon]="'heroicons_solid:globe-europe-africa'"></mat-icon>
            </ng-container>

            <ng-container *ngIf="hashtagType == hashtagTypeEnum.Local">
              <mat-icon class="mr-1" [ngClass]="{ 'text-primary': this.typeActive == hashtagType }"
                [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
            </ng-container>

            {{ (hashtagType == hashtagTypeEnum.Global ? 'Global' : 'Local') | transloco }}
          </ng-template>

          <div class="flex grow p-2">
            <mat-form-field class="svc-majt-no-subscript svc-mat-dense grow">
              <input type="search" matInput [(ngModel)]="hashtagSearch" placeholder="{{ 'Procurar' | transloco}}..." (ngModelChange)="filter($event)">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            </mat-form-field>
          </div>

          <svc-wrapped-layout #wrappedLayout
            class="items-center justify-center px-2"
            [items]="filteredHashtags"
            [sort]="!isFiltering"
            (onSortingChanged)="onSortingChanged($event)"
          >
            <div wrapped-layout-item *ngFor="let hashtag of wrappedLayout.items" [ignoreClick]="iconButton._elementRef?.nativeElement" class="flex rounded-lg bg-primary-200 text-primary-700 items-center px-1">
              <span class="text-base font-medium mr-1">{{ hashtag.name }}</span>
              <mat-icon #iconButton class="cursor-pointer icon-size-4 text-primary-700"
                svgIcon="heroicons_solid:x-circle"
                (click)="toggleDelete(hashtag.hashtagId);"
              ></mat-icon>
            </div>
          </svc-wrapped-layout>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div svc-dialog-actions class="w-full">
    <div class="flex w-full">
      <div class="flex flex-auto items-center pr-2 text-xs">
        {{ (filteredHashtags?.length ?? 0) | formatNumberDefault }} {{'Resultados' | transloco}}
      </div>
      <div class="flex flex-row gap-2">
        <svc-button
          mode="stroked"
					type="button"
					(click)="closeDialog()"
				>{{ 'Cancelar' | transloco }}</svc-button>

        <svc-button
          mode="flat"
          type="button"
          color="primary"
          (click)="save()"
          cdkFocusInitial
        >{{ 'Salvar' | transloco }}</svc-button>
      </div>
    </div>
  </div>

</svc-dialog>
<div *ngIf="isLoading" class="absolute flex items-center justify-center inset-0 w-full h-full">
  <mat-spinner diameter="30"></mat-spinner>
</div>
