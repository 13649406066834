<ng-container *screenSizes="let size">
  <div class="relative flex-auto overflow-y-auto" [style.height]="size.isBelowMD ? 'auto' : '400px'">
    <ng-container *ngIf="(communications?.length ?? 0) > 0">
      <ag-virtual-scroll
        #vs
        [items]="communications"
        [height]="size.isBelowMD ? ((elRef?.nativeElement?.offsetHeight ?? 0) + 'px') : '400px'"
        [min-row-height]="96"
        (scrollend)="nextPage()"
      >
        <div
          *ngFor="let communication of vs.items;"
          class="border-b border-solid border-slate-200"
          (click)="emitClickItem(communication)"
        >
          <div class="flex group hover:bg-gray-50" [ngClass]="{ unread: communication.unread }">
            <div class="flex flex-col flex-auto py-3 px-4 max-w-full" [ngClass]="{ 'cursor-pointer': true }">
              <div class="flex flex-col gap-1">
                <div class="flex flex-wrap justify-between items-center">
                <span class="text-center rounded p-1 text-sm font-medium bg-gray-200 text-default">
                  {{ 'comunicado' | transloco | uppercase }}
                </span>
                  <div class="flex text-xs px-2 leading-none text-primary font-bold">
                    {{ communication.daysCreated }} {{ (communication.daysCreated <= 0 ? 'dia': 'dias') | transloco }}
                  </div>
                </div>
                <div class="flex flex-auto items-center break-all">
                  <svc-user-avatar [info]="{name: communication.originatorAvatar.name, picture: communication.originatorAvatar.pictureFile, initialsColor: communication.originatorAvatar.initialColor, size: avatarSize}"></svc-user-avatar>
                  <div class="grow">
                    <div
                      class="line-clamp-1 text-primary font-bold text-sm leading-tight mb-1 ml-2"
                      [innerHTML]="(communication.title ?? '')"
                      [title]="(communication.title ?? '') | removeHtml"
                    ></div>
                    <div
                      class="line-clamp-3 text-sm leading-tight ml-2"
                      [innerHTML]="(communication.message ?? '')"
                      [title]="(communication.message ?? '') | removeHtml"
                    ></div>
                  </div>

                  <button *ngIf="!communication.isRead" class="w-6 h-6 min-h-6" mat-icon-button>
                    <span class="w-2 h-2 rounded-full bg-primary"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ag-virtual-scroll>
      <div *ngIf="isLoading$ | async" class="absolute w-full bottom-0 flex justify-center items-center pb-5">
        <mat-spinner diameter="30"></mat-spinner>
      </div>

    </ng-container>

    <ng-container *ngIf="!(isLoading$ | async) && ((communications?.length ?? 0) <= 0)">
      <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
          <mat-icon class="text-primary-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
        </div>
        <div class="mt-5 text-2xl font-semibold tracking-tight">
          {{ 'Sem comunicados' | transloco }}
        </div>
        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
          {{ 'Quando você tiver comunicados, eles aparecerão aqui.' | transloco }}
        </div>
      </div>
    </ng-container>

    <div *ngIf="(isLoading$ | async) && (communications?.length ?? 0) === 0" class="flex flex-auto justify-center p-10">
      <mat-spinner diameter="30"></mat-spinner>
    </div>


  </div>
</ng-container>
