import { Component, Inject, OnInit, ViewChild} from '@angular/core';
import { Newsletter} from "./newsletter.model";
import { NewsletterService} from "./newsletter.service";
import { ModalInsights } from "../../models/modal-insights";
import { environment } from 'projects/environments/environment';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent extends ModalInsights<NewsletterService> implements OnInit {

  @ViewChild('footer') footer: any;

  news: Newsletter;
  downloading: boolean;
  env = environment;
  onClose = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _newsletterService: NewsletterService,
    ) {
    super(_newsletterService);
  }

  ngOnInit(): void {
    this.news = this.data || this.service.model;
    setTimeout(() => {
      this.elementRef?.nativeElement
        ?.querySelector('.version-footer')
        ?.querySelector('img')
        ?.setAttribute('style', 'width: 100%');
    },500)
  }

  close() {
    if (this.downloading) return;
    if (!this.data) this.service.markAsRead(this.news.versionId).subscribe();
    this.service.onFinishInsight.emit();
    this.onClose.next(true);
  }

  openImage(src: string) {
    this.service.dialog().openImage(src);
  }

  downloadPdf() {
    if (this.downloading) return;
    this.downloading = true;
    this._newsletterService.downloadPdf().subscribe( {
      next: response => {
        let a = document.createElement('a');
        a.download = 'Newsletter';
        a.href = URL.createObjectURL(response.body);
        a.click();
        this.downloading = false;
      },
      error: () => {
        this.toast.error(this.transloco.translate('Ocorreu um erro durante a solicitação. Por favor, tente novamente; se o erro persistir, entre em contato com o administrador do sistema.'));
        this.downloading = false;
      }
    });
  }

  seeLater() {
    if (this.downloading) return;
    this.service.onFinishInsight.emit();
  }
}
