<div>
  <div class="svc-gd-data" [ngClass]="gapClass ?? 'gap-4'" [ngStyle]="styleTemplateColumnsGrid">
    <ng-content select="[svc-grid-data]"></ng-content>
  </div>

  <div *ngIf="isLoading" class="svc-gd-skeleton opacity-70" [ngClass]="gapClass ?? 'gap-4'" [ngStyle]="styleTemplateColumnsGrid">
    <div *ngFor="let card of [].constructor(10)" class="bg-white rounded-lg shadow border">
      <div class="border-b">
        <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, borderRadius: '6px 6px 0 0', width: '100%', height: '100px'}"/>
      </div>
      <div class="border-b">
        <div class="flex flex-col gap-2 px-3 py-2">
          <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '70%', maxWidth: '250px', height: '22px' }"/>
          <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '90%', height: '12px' }"/>
          <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '70%', height: '12px' }"/>
          <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '50%', height: '12px' }"/>
        </div>
      </div>
      <div>
        <div class="flex px-3 py-2 gap-2">
          <div class="w-2/3">
            <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '100%', height: '18px' }"/>
          </div>
          <div class="w-1/3">
            <ngx-skeleton-loader class="flex" [theme]="{ 'margin-bottom': 0, width: '100%', height: '18px' }"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="svc-gd-spinner">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
</div>
