import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { catchError, finalize, Subject, tap } from 'rxjs';

import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcBosCombosService } from '../../services/svc-bos-combos.service';
import { SvcBosErrorService } from '../../services/svc-bos-error.service';
import { ISvcChipOption } from 'projects/lib-shared-component/src/public-api';


@Component({
  selector: 'svc-modal-bos-type',
  templateUrl: './svc-modal-bos-type.component.html',
  styleUrls: ['./svc-modal-bos-type.component.scss']
})
export class SvcModalBosTypeComponent implements OnInit {

  @Input() public isAddMode: boolean;

  @Output() public typeSelected = new EventEmitter<ISvcChipOption>();
  @Output() public closeModal = new EventEmitter<void>()
  @Output() public finishLoadType = new EventEmitter<void>();

  @AutoDestroy public destroy$ = new Subject<void>();

  public isLoadingTypes: boolean = true;
  public types: ISvcChipOption[];
  public formControlType = new FormControl(null, Validators.required);

  constructor (
    private _combosService: SvcBosCombosService,
    private _errorService: SvcBosErrorService,
  ) { }

  public ngOnInit(): void {
    this._getTypes();
  }

  private _getTypes(): void {
    if (this.isAddMode) {
      this.isLoadingTypes = true;
      this._combosService.getTypes(true).pipe(
        tap(types => this.types = types),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          this.isLoadingTypes = false;
          this.finishLoadType.emit();
        })
      ).subscribe();
    }
  }

  public addType(): void {
    if (this.formControlType?.valid) {
      const type = this.types.find(type => type.value === this.formControlType.value?.[0]);
      this.typeSelected.emit(type);
    }
  }
}
