export interface FeedItem {
  commentHeaderId: number;
  configTypeId: string;
  registryUid: string;
  registryId: number;
  registryInternalId: number;
  title: string;
  subtitle: string;
  commentContent: string;
  commentId?: number;
  hasAttachment: boolean,
  total: number;
  totalNoRead: number;
  jsonCallback: string;
  applicationId: string;
  siteId: number;
  isGlobalModule: boolean;
  isPinned: boolean;
  isMarkedUnRead: boolean;
}

export interface FeedItemFromSignalR extends FeedItem {
  typeNotification: SvcFeedNoticationType;
}

export enum SvcFeedNoticationType {
  NEW_COMMENT = 0,
  PINNING = 1,
  MARK_UN_READ = 2,
}