import { Component, Input, OnChanges } from '@angular/core';

import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MediaQueryBreakpointStart } from 'projects/lib-shared-common/src/public-api';

@UntilDestroy()
@Component({
  selector: 'svc-grid-data',
  templateUrl: './svc-grid-data.component.html',
  styleUrls: ['./svc-grid-data.component.scss'],
  host: {
    '[class.svc-gd-auto-spreadding]': 'autoSpreading',
  },
})
export class SvcGridDataComponent implements OnChanges {

  @Input() public columnsDefault = 1;
  @Input() public columnsScreenSm = 2;
  @Input() public columnsScreenMd = 3;
  @Input() public columnsScreenLg = 4;
  @Input() public columnsScreenXl = 4;
  @Input() public autoSpreading = false;
  @Input() public basedOnWidth: number;
  @Input() public gapClass: string;
  @Input() public isLoading = false;

  public styleTemplateColumnsGrid: { [propName: string]: string };

  private mediaQueries = {
    isXL: MediaQueryBreakpointStart.XL,
    isLG: MediaQueryBreakpointStart.LG,
    isMD: MediaQueryBreakpointStart.MD,
    isSM: MediaQueryBreakpointStart.SM,
  };

  constructor() {
    this.setDynamicTemplateColumnsGrid();
    this.resizeScreen();
  }

  public ngOnChanges(): void {
    this.setDynamicTemplateColumnsGrid();
  }

  private resizeScreen(): void {
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setDynamicTemplateColumnsGrid());
  }

  private getColumnsGrid(columns: number): string {
    return `repeat(${columns}, minmax(0, 1fr))`;
  }

  private setDynamicTemplateColumnsGrid(): void {
    const width = document.body?.parentElement?.clientWidth ?? window.innerWidth;
    if (this.autoSpreading) {
      const repeatValue = width <= 500 ? '100%' : `minmax(${this.basedOnWidth ?? 218}px, 1fr)`;
      this.styleTemplateColumnsGrid = {
        'grid-template-columns': `repeat(auto-fill, ${repeatValue})`,
      };
    }
    else {
      const columnMappings = {
        isSM: this.getColumnsGrid(this.columnsScreenSm),
        isMD: this.getColumnsGrid(this.columnsScreenMd),
        isLG: this.getColumnsGrid(this.columnsScreenLg),
        isXL: this.getColumnsGrid(this.columnsScreenXl),
      };
  
      const sizeScreen: string = Object.keys(this.mediaQueries).find((condition: string) => width >= this.mediaQueries[condition]);
  
      const columnsSize: string = columnMappings[sizeScreen] || this.getColumnsGrid(this.columnsDefault);
  
      this.styleTemplateColumnsGrid = {
        'grid-template-columns': columnsSize,
      };
    }
  }
}
