import {Injectable} from '@angular/core';
import {Newsletter} from "./newsletter.model";
import {catchError, map, Observable, of, pipe, take} from "rxjs";
import {NewsletterComponent} from "./newsletter.component";
import {isMobile} from "projects/lib-shared-common/src/public-api";
import {ModalInsightsDefault} from "../../models/modal-insights-default";
import {MatDialogRef} from "@angular/material/dialog";

@Injectable()
export class NewsletterService extends ModalInsightsDefault<Newsletter> {

  check(): Observable<boolean> {
    return this._httpClient.get<Newsletter>(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/CurrentEnvironment`)
      .pipe(
        map(news => {
          if (news) {
            this.model = news;
            return true;
          }
          return false;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  markAsRead(versionId: number) {
    return this._httpClient.put<Newsletter>(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/${versionId}`, null)
  }

  showModal(): MatDialogRef<NewsletterComponent> {
    return this._dialogService.open(NewsletterComponent, {
      maxWidth: isMobile() ? "90vw" : "590px",
      maxHeight: "95vh",
      disableClose: true,
      autoFocus: "dialog"
    });
  }

  showModalWithData(data: any) {
    const modal =  this._dialogService.open(NewsletterComponent, {
      data,
      maxWidth: isMobile() ? "90vw" : "590px",
      maxHeight: "95vh",
      disableClose: true,
      autoFocus: "dialog"
    });

    modal.componentInstance.onClose
    .pipe(take(1))
    .subscribe(() => {
      modal.close();
    })
  }

  saveOnClose() {
    return of(true);
  }

  downloadPdf() {
    return this._httpClient.get(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/download`, { observe:'response', responseType:'blob'})
  }
}

