import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatAccordion, MatExpansionPanelHeader } from '@angular/material/expansion';

import { catchError, finalize, Subject, takeUntil, tap } from 'rxjs';

import { SvcBosBehaviorService } from '../../services/svc-bos-behavior.service';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcBosErrorService } from '../../services/svc-bos-error.service';
import { GetBehaviorListResponse, GetBehaviorResponse } from '../../interfaces/svc-bos-behavior.interface';
import { ISvcSelectOption, SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { SvcBosAddFeedbackBehavior, SvcBosFeedbackBehaviors, SvcBosFeedbackBehaviorsData, SvcBosFeedbackCategories } from '../../interfaces/svc-bos-feedback.interface';
import { SvcGlobalDataPrefetching } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'svc-modal-bos-tab-behaviors',
  templateUrl: './svc-modal-bos-tab-behaviors.component.html',
  styleUrls: ['./svc-modal-bos-tab-behaviors.component.scss']
})
export class SvcModalBosTabBehaviorsComponent implements OnInit, OnChanges {

  @AutoDestroy public destroy$ = new Subject<void>();

  @Input() public isViewMode: boolean;
  @Input() public typeId: number;

  @ViewChild(MatAccordion) public set setAccordion(accordion: MatAccordion) {
    if (accordion) {
      this.accordion = accordion;

      if (this.isViewMode)
        this.openBehaviors();
    }
  };

  public accordion: MatAccordion;
  public isLoading: boolean = true;
  public behaviors: GetBehaviorResponse[];
  public formBehaviors: FormGroup = this._fb.group({
    categories: this._fb.array([])
  });
  public comboOptions: ISvcSelectOption[] = [];
  public isAllBehaviorsOpen: boolean;
  public totalFilledBehaviors: number = 0;
  public totalFilledCategory: number[] = [];
  public noneResultsSearch = false;
  public behaviorFeedbackData: SvcBosFeedbackBehaviorsData;
  public showVoiceToTextButton: boolean;
  public loadedBehaviors: boolean;

  private _destroyValueChanges$ = new Subject<void>();

  constructor(
    private _behaviorService: SvcBosBehaviorService,
    private _errorService: SvcBosErrorService,
    private _fb: FormBuilder,
    private _dialogService: SvcDialogService,
    private _globalDataPrefetching: SvcGlobalDataPrefetching,
  ) {
    this._setComboOptions();
  }

  public ngOnInit(): void {
    this._getShowVoiceToText();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.typeId?.previousValue !== changes?.typeId?.currentValue)
      this._getBehaviorList();

    if (changes?.isViewMode?.previousValue !== changes?.isViewMode?.currentValue && this.isViewMode && this.accordion)
      this.openBehaviors();
  }

  private _getShowVoiceToText(): void {
    const prefetchingItem = this._globalDataPrefetching.data.voiceToText;
    if (prefetchingItem.isLoading)
      prefetchingItem.isLoading$.pipe(
        tap((showVoiceToTextButton: boolean) => {
          this.showVoiceToTextButton = showVoiceToTextButton;
        }),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        takeUntil(this.destroy$),
      ).subscribe();
    else {
      this.showVoiceToTextButton = prefetchingItem.value;
    }
  }

  private _setComboOptions(): void {
    const options: ISvcSelectOption[] = [];
    for (let i = 1; i < 11; i++)
      options.push({
        text: i.toString(),
        value: i
      });
    this.comboOptions = options;
  }

  private _getFormArrayCategories(): FormArray {
    return this.formBehaviors?.get?.('categories') as FormArray;
  }

  private _resetDataForm(): void {
    this.formBehaviors?.reset();
    this._getFormArrayCategories()?.clear();
    this.totalFilledCategory = [];
    this.totalFilledBehaviors = 0;
    this._destroyValueChanges$.next();
    this._destroyValueChanges$.complete();

  }

  private _addFormGroup(): void {
    this._resetDataForm();
    this.behaviors?.forEach(info => {
      const categorie: FormGroup = this._fb.group({
        behaviors: this._fb.array([])
      });

      info?.behaviors?.forEach(behavior => {
        const _behavior: FormGroup = this._fb.group({
          behaviorId: [behavior?.behaviorId],
          realizedOkTotal: [null],
          realizedNOkTotal: [null],
          comment: ['']
        });

        (categorie?.get('behaviors') as FormArray).push(_behavior);
      })
      this.totalFilledCategory.push(0);
      this._getFormArrayCategories().push(categorie);
    });
    this._patchFormData();
    this._subscribeToValueChanges();
  }

  private _subscribeToValueChanges(): void {
    const categories = this._getFormArrayCategories();

    categories?.controls?.forEach((categoryControl, categoryIndex) => {
      const behaviors = categoryControl.get('behaviors') as FormArray;

      behaviors?.controls.forEach((behaviorControl) => {
        const realizedOkTotalControl = behaviorControl.get('realizedOkTotal');
        const realizedNOkTotalControl = behaviorControl.get('realizedNOkTotal');

        realizedOkTotalControl.valueChanges.pipe(
          takeUntil(this._destroyValueChanges$)
        ).subscribe(value => {
          if (!behaviorControl.get('realizedNOkTotal')?.value && !this.isViewMode) {
            this._setTotalFilledBehaviors(value);
            this._setTotalFilledCategory(value, categoryIndex);
          }
        });

        realizedNOkTotalControl.valueChanges.pipe(
          takeUntil(this._destroyValueChanges$)
        ).subscribe(value => {
          if (!behaviorControl.get('realizedOkTotal')?.value && !this.isViewMode) {
            this._setTotalFilledBehaviors(value);
            this._setTotalFilledCategory(value, categoryIndex);
          }
        });
      });
    });
  }

  private _setTotalFilledCategory(value: number, index: number): void {
    if (value)
      this.totalFilledCategory[index] += 1;
    else if (this.totalFilledCategory[index] > 0)
      this.totalFilledCategory[index] -= 1;
  }

  private _setTotalFilledBehaviors(value: number): void {
    if (value)
      this.totalFilledBehaviors += 1;
    else if (this.totalFilledBehaviors > 0)
      this.totalFilledBehaviors -= 1;
  }

  private _getBehaviorList(): void {
    if (this.typeId && !this.loadedBehaviors)
      this._behaviorService.getBehaviorList(true, this.typeId, true).pipe(
        tap((behaviors: GetBehaviorListResponse) => {
          this.behaviors = behaviors?.categories;
          this._addFormGroup();
        }),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          this.isLoading = false;
          this.loadedBehaviors = true;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  public openBehaviors(): void {
    if (this.isLoading || this.behaviors?.length === 0 || this.noneResultsSearch)
      return;

    this.isAllBehaviorsOpen = !this.isAllBehaviorsOpen;
    if (this.accordion)
      this.isAllBehaviorsOpen ? this.accordion.openAll() : this.accordion.closeAll();
  }

  private _showWarningObservation(noneFilledBehavior: boolean): void {
    if (noneFilledBehavior)
      this._dialogService.openWarning('É necessário realizar ao menos uma observação.');
  }

  private _getAllBehaviors(): any[] {
    return this.formBehaviors?.value?.categories?.flatMap(categorie => categorie?.behaviors);
  }

  public formValid(): boolean {
    const behaviors = this._getAllBehaviors();

    const hasFilledBehavior: boolean = behaviors?.some(behavior =>
      behavior?.realizedNOkTotal || behavior?.realizedOkTotal
    );

    this._showWarningObservation(!hasFilledBehavior);

    return hasFilledBehavior;
  }

  public getFormValue(): SvcBosAddFeedbackBehavior[] {
    return this._getAllBehaviors()?.filter(behavior =>
      behavior?.realizedNOkTotal || behavior?.realizedOkTotal
    );
  }

  private _setNoneResultsSearch(): void {
    this.noneResultsSearch = this.behaviors?.every(behavior => behavior?.isHidden);
  }

  public searchBehaviors(text: string): void {
    const _text = text?.toLowerCase();

    this.behaviors?.forEach(behavior => {
      const hasCategoryText = behavior?.categoryName?.toLowerCase()?.includes(_text);

      behavior?.behaviors?.forEach(info =>
        info.isHidden = hasCategoryText ? false : !info?.behaviorName?.toLowerCase()?.includes(_text)
      );

      const hasBehaviorHidden = behavior?.behaviors?.every(info =>
        info?.isHidden
      );

      behavior.isHidden = !hasCategoryText && hasBehaviorHidden;
    });
    this._setNoneResultsSearch();
  }

  public showAllBehaviors(): void {
    this.behaviors?.forEach(info => {
      info.isHidden = false;
      info?.behaviors?.forEach(behavior =>
        behavior.isHidden = false
      )
    });
    this.isAllBehaviorsOpen = false;

    if (this.accordion)
      this.accordion.closeAll();
  }

  public setBehaviorsHidden(): void {
    this.accordion?.closeAll();
    const categories = this._getFormArrayCategories();

    categories?.controls?.forEach((categoryControl, categoryIndex: number) => {
      const behaviors = categoryControl.get('behaviors') as FormArray;
      let allBehaviorsHidden = true;

      behaviors?.controls?.forEach((behaviorControl, behaviorIndex: number) => {
        const realizedOkTotal = behaviorControl.get('realizedOkTotal')?.value;
        const realizedNOkTotal = behaviorControl.get('realizedNOkTotal')?.value;
        const isHidden = !realizedOkTotal && !realizedNOkTotal;
        this.behaviors[categoryIndex].behaviors[behaviorIndex].isHidden = isHidden;

        if (!isHidden)
          allBehaviorsHidden = false;
      });

      this.behaviors[categoryIndex].isHidden = allBehaviorsHidden;
    });
  }

  private _getBehaviorsData(): SvcBosFeedbackBehaviors[] {
    return this.behaviorFeedbackData?.categories?.flatMap(categorie =>
      categorie?.behaviors
    );
  }

  private _hiddenBehaviors(): void {
    const behaviorsIds: number[] = this._getBehaviorsData()?.flatMap(behavior => behavior.behaviorId);

    this.behaviors?.forEach(category => {
      category?.behaviors?.forEach(behavior =>
        behavior.isHidden = !behaviorsIds?.includes(behavior.behaviorId)
      );

      category.isHidden = category?.behaviors?.every(behavior => behavior.isHidden);
    });
  }

  private _setTotalFilledCategoryByInfo(index: number, behaviorControl: AbstractControl): void {
    if (!this.totalFilledCategory?.[index]) {
      const categorie: SvcBosFeedbackCategories =
        this.behaviorFeedbackData?.categories?.find(categorie => (
          categorie?.behaviors?.find(behavior => (
            behavior.behaviorId === behaviorControl?.value?.behaviorId
          )
          )
        ));

      if (categorie?.behaviorsTotal && index + 1 <= this.totalFilledCategory?.length)
        this.totalFilledCategory[index] = categorie?.behaviorsTotal ?? 0;
    }
  }

  private _patchValues(categoryControl: AbstractControl, behaviorsData: SvcBosFeedbackBehaviors[], index: number): void {
    const behaviors = categoryControl.get('behaviors') as FormArray;

    behaviors?.controls?.forEach((behaviorControl) => {
      const behaviorData = behaviorsData?.find(data => data.behaviorId === behaviorControl?.value?.behaviorId);
      this._setTotalFilledCategoryByInfo(index, behaviorControl);

      behaviorControl.patchValue({
        ...behaviorControl?.value,
        realizedOkTotal: behaviorData?.realizedOkTotal,
        realizedNOkTotal: behaviorData?.realizedNOkTotal,
        comment: behaviorData?.comment
      });
    });
  }

  private _patchFormData(): void {
    if (this.isViewMode && this.behaviorFeedbackData) {
      this.totalFilledBehaviors = this.behaviorFeedbackData.behaviorsTotal;
      this._hiddenBehaviors();
      const categories = this._getFormArrayCategories();
      const behaviorsData: SvcBosFeedbackBehaviors[] = this._getBehaviorsData();

      categories?.controls?.forEach((categoryControl, index: number) =>
        this._patchValues(categoryControl, behaviorsData, index)
      );
    }
  }

  public patchBehaviors(behaviors: SvcBosFeedbackBehaviorsData): void {
    this.behaviorFeedbackData = behaviors;
    this.totalFilledBehaviors = behaviors.behaviorsTotal;

    if (this.isLoading) {
      this.behaviors = behaviors.categories?.map(categorie => {
        return {
          ...categorie,
          behaviors: categorie?.behaviors?.map(behavior => {
            return {
              ...behavior
            };
          })
        } as GetBehaviorResponse;
      });
      this._addFormGroup();
    }
    else
      this._patchFormData();
  }

  public checkHasAccordionOpen(): void {
    if (this.isAllBehaviorsOpen && this.accordion) {
      const hasAccordionOpen = Array.from(this.accordion?._headers).some((header: MatExpansionPanelHeader) =>
        header.panel.expanded
      );
      
      if (!hasAccordionOpen)
        this.isAllBehaviorsOpen = false;
    }
  }
}
