import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, AfterViewInit, inject } from '@angular/core';
import {ECharts, EChartsOption, TooltipComponentOption} from 'echarts';
import {FormatNumberDefaultPipe} from 'projects/lib-shared-common/src/public-api';
import {
  ISvcChartBarDefinitions,
  ISvcChartBarInfo,
  ISvcChartBarOrientation,
  ISvcChartBarSerie,
  SvcChartBarConfig,
  SvcChartTooltipOption,
} from './interfaces/svc-chart-bar.interface';
import {XAXisOption, YAXisOption} from 'echarts/types/dist/shared';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Subject, takeUntil, tap } from 'rxjs';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { MediaQuerySize, SvcMediaQuery } from 'projects/lib-shared-common/src/lib/services/svc-media-query.service';

@Component({
  selector: 'svc-chart-bar',
  templateUrl: './svc-chart-bar.component.html',
  styleUrls: ['./svc-chart-bar.component.scss'],
})
export class SvcChartBarComponent implements AfterViewInit, OnChanges {
  instance: ECharts;

  @Input() height: string = '280px';
  @Input() tooltip?: SvcChartTooltipOption | SvcChartTooltipOption[];
  @Input() orientation: ISvcChartBarOrientation = ISvcChartBarOrientation.HORIZONTAL;
  @Input() chartConfig: Partial<SvcChartBarConfig>;
  @Input() chartInfo: ISvcChartBarInfo = {} as ISvcChartBarInfo;
  @Input() chartDefinitions: ISvcChartBarDefinitions | null = null;
  @Input() errorMsg: string;
  @Input() isLoading: boolean;
  @Input() smallSkeleton: boolean;
  @Output() chartClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();

  @AutoDestroy public destroy$ = new Subject<void>();
  
  private formatNumberDefault = new FormatNumberDefaultPipe(inject(SvcAppSettings));

  chartOptions: Partial<EChartsOption>;
  chartTooltipDefault: TooltipComponentOption | TooltipComponentOption[] = {
    borderWidth: 0,
    backgroundColor: '#334155',
    textStyle: {
      color: '#fff',
      fontSize: 12,
    },
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: function (chartInfo: any) {
      const params = chartInfo.filter((p: any) => p.value != null && p.value > 0);
      if (params.length == 0) return null;
      const text = params.map((p: any) => (
        `<div class="flex flex-row space-x-3 items-center">
              <div class="grow">${p.marker}${p.seriesName}</div>
              <div>${this.formatNumberDefault.transform(p.value)}</div>
            </div>`
      ));
      return `<div class="flex flex-col min-w-[130px]">
            <div>${params[0]?.axisValue}</div>
            <hr class="mt-1 mb-2"/>
            <div class="flex flex-col gap-1">${text.join('')}</div>
          </div>`;
    },
  };

  public barsSkeleton: string[];
  public hasDataChart: boolean;

  private _mediaQuery = inject(SvcMediaQuery);

  onChartInit(evt: ECharts) {
    this.instance = evt;
  }

  onChartClick(evt: any){
    this.chartClick.emit({
      name: evt.name,
      serieName: evt.seriesName,
      color: evt.color,
      value: evt.value,
      data: evt.data,
    });
  }

  
  public ngAfterViewInit(): void {
    this.resize();
    this.setBarsSkeleton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.height?.previousValue !== changes?.height?.currentValue)
      this.setBarsSkeleton();

    this.generateOptions();

    if (this.chartConfig)
      this.updateChartConfig();

    if (this.chartDefinitions) {
      this.updateOptions();
      this.generateCategories();
      this.generateSeries();
      if(this.instance) {
        this.instance.resize();
        this.instance.setOption(this.chartOptions);
        this.hasDataChart = (this.instance.getOption() as any)?.series?.some(serie => serie?.data?.length > 0);
      }
    }
  }

  private generateOptions() {
    this.chartOptions = {
      tooltip: this.chartConfig?.tooltip ?? this.chartTooltipDefault,
      legend: {
        show: true,
        orient: 'horizontal',
        left: 'left',
        top: 'bottom',
        icon: 'circle',
        padding: [0, 7],
        itemGap: 5,
        itemHeight: 10,
        itemWidth: 10,
        textStyle: {
          color: '#898989',
          fontSize: 11,
        },
      },
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: false,
      },
      series: [],
    };
  }

  private updateChartConfig(): void {
    if (this.chartConfig) {
      this.chartOptions.grid = {
        ...this.chartOptions.grid,
        ...this.chartConfig?.grid,
      };
    }
  }

  private updateOptions(){
    // @ts-ignore
    (<LegendComponent>this.chartOptions.legend).show = this.chartDefinitions?.showLegend ?? true;
  }

  private updateCategories(definitions: Partial<EChartsOption>) {
    this.chartOptions.yAxis = definitions.yAxis;
    this.chartOptions.xAxis = definitions.xAxis;
  }

  private updateSeries(definitions: Partial<EChartsOption>) {
    this.chartOptions.series = definitions.series;
  }

  private generateCategories() {
    if (this.orientation == ISvcChartBarOrientation.HORIZONTAL) {
      this.updateCategories({
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.chartDefinitions.categories.map(c => c.label),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 0,
            fontSize: 14,
            fontWeight: 300,
            color: '#898989',
            align: 'right',
            margin: 20
          },
        },
      });
    } else {
      this.updateCategories({
        xAxis: {
          type: 'category',
          data: this.chartDefinitions.categories.map(c => c.label),
          axisLine: {
            show: false,
            ...(this.chartConfig?.xAxis as XAXisOption)?.axisLine
          },
          ...(this.chartConfig?.xAxis as XAXisOption)?.splitLine,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            fontWeight: 300,
            color: (v, i) => {
              return this.chartDefinitions?.categories[i]?.error
                ? 'red'
                : '#898989';
            },
            verticalAlign: 'middle',
            align: 'left',
            margin: 5,
            ...(this.chartConfig?.xAxis as YAXisOption)?.axisLabel ?? null
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            ...(this.chartConfig?.yAxis as YAXisOption)?.axisLabel ?? null
          },
          axisLine: {
            ...(this.chartConfig?.yAxis as YAXisOption)?.axisLine,
          },
          splitLine: {
            show: false,
            ...(this.chartConfig?.yAxis as YAXisOption)?.splitLine,
          },
        },
      });
    }
  }

  private generateSeries() {
    const items: any[] = [];

    this.chartDefinitions.series.forEach((serie: ISvcChartBarSerie, index: number) => {

      items.push({
        name: serie.name,
        type: 'bar',
        stack: index.toString(),
        barMaxWidth: serie?.maxWidth ?? 60,
        barMinWidth: serie?.minWidth ?? 'auto',
        barMinHeight: serie?.minHeight ?? 30,
        label: {
          show: true,
          inside: true,
          fontSize: 14,
          fontWeight: 'bolder',
          position: 'insideTop',
          formatter: (p) => (this.formatNumberDefault.transform(p?.value)),
          ...serie?.label,
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: serie.color,
          borderColor: 'transparent',
          borderWidth: 5,
          borderRadius: [8,8,0,0],
        },
        data: serie.values.map((x) => x == 0 ? null : x),

      });
    });

    this.updateSeries({ series: items });
  }

  public refresh(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.onRefresh.emit();
  }

  public resize(): void {
    this._mediaQuery.size$
      .pipe(
        tap(() => this.setBarsSkeleton()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getBarsQuantity(size: MediaQuerySize): number {
    if (!this.smallSkeleton)
      return size?.isXS ? 3 : size?.isSM ? 6 : size?.isMD ? 8 : 10;
    return size?.isXS ? 3 : size?.isMD ? 4 : 5;
  } 

  public setBarsSkeleton(): void {
    const size: MediaQuerySize = this._mediaQuery.currentSize
    this.barsSkeleton = Array.from({ length: this.getBarsQuantity(size) }, () => (
      this.getRandomHeight()
    ));
  }

  public getRandomHeight(): string {
    const maxHeight = Number(this.height?.match(/\d+/g)?.[0]) - 40;
    const randomHeight = Math.random() * maxHeight;
    return `${Math.min(randomHeight, maxHeight)}px`;
  }
}
