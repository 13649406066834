import { FormControl } from "@angular/forms";
import { SvcFilterField } from "./svc-filter-field";


export interface SvcFilterEvent {
    fields: SvcFilterEventItem[];
}

export interface SvcFilterValueEvent {
    value: any;
    field: SvcFilterField;
}

export interface SvcFilterEventItem {
    field: SvcFilterField;
    control: FormControl;
    name: string;
    value: any;
}