import { Injectable, OnDestroy } from '@angular/core';
import { SvcCacheName } from './svc-cache-name.enum';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { SvcDataCache } from './svc-data-cache.model';

const STORAGE_KEY_NAME = 'SvcTemporaryCacheData';

@Injectable({
  providedIn: 'root',
})
export class SvcDataCacheService implements OnDestroy {
  #destroy$: Subject<void>;
  #cache: { [key: string]: SvcDataCache<any> };

  public initializeFromAuth(
    signIn$: Observable<{ success: boolean }>,
    signOut$: Observable<{ success: boolean }>,
  ): void {
    this.ngOnDestroy();
    this.#destroy$ = new Subject<void>();
    [signIn$, signOut$].forEach((observ$) => {
      observ$.pipe(
        takeUntil(this.#destroy$),
        tap(() => this.clearData()),
      ).subscribe();
    });
    this.#tryToLoadStoredData();
  }

  public set<T>(name: SvcCacheName, data: T, expiresAfter?: { hours?: number }): void {
    if (typeof this.#cache === 'object') {
      this.#cache = this.#cache ?? {};
      this.#cache[name] = new SvcDataCache({
        name,
        data,
        expiresAfter,
      });
      localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(this.#cache));
    }
  }

  public remove(name: SvcCacheName): void {
    if (typeof this.#cache === 'object') {
      this.#cache = this.#cache ?? {};
      delete this.#cache[name];
      localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(this.#cache));
    }
  }

  public get<T>(name: SvcCacheName): T {
    if (typeof this.#cache === 'object') {
      const cached = this.#cache[name];
      if (!(cached?.isValid)) {
        this.remove(name);
        return null;
      }
      return cached.data;
    }
    return null;
  }

  public has(name: SvcCacheName): boolean {
    if (typeof this.#cache === 'object') {
      return name in this.#cache; 
    }
    return false;
  }

  public hasValue(name: SvcCacheName): boolean {
    if (typeof this.#cache === 'object') {
      const cached = this.#cache[name];
      return cached?.isValid && cached?.data != null; 
    }
    return false;
  }

  public clearData() {
    localStorage.removeItem(STORAGE_KEY_NAME);
    this.#cache = {};
  }

  #tryToLoadStoredData(): void {
    const currentData = localStorage.getItem(STORAGE_KEY_NAME) ?? '{}';
    try {
      const cached = /^{(.*)}$/g.test(currentData) ? JSON.parse(currentData) : {};
      const isValidObject = (object: any) => {
        if (Array.isArray(object) || typeof object !== 'object') return false;
        const keys = Object.keys(object ?? {});
        return keys.length > 0 && ['name','data'].every((key) => keys.includes(key));
      }
      for (const name in cached) {
        const value = cached[name];
        if (isValidObject(value)) {
          cached[name] = SvcDataCache.fromCachedObject(value);
        }
        else {
          cached[name] = new SvcDataCache({
            name: name as SvcCacheName,
            data: value,
          });
        }
      }
      this.#cache = cached;
    }
    catch {
      this.#cache = {};
    }
  }

  public ngOnDestroy(): void {
    this.#destroy$?.next();
    this.#destroy$?.complete();
  }
}

