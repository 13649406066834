<ng-container *ngIf="isLoading || !hashtagHasError; else refresh">
  <div class="border-t border-on-primary"></div>
  <div *ngIf="!isLoading" class="flex-auto flex flex-col h-full pt-2">
    <div class="flex justify-between items-center space-x-2">
      <div class="flex grow">
        <mat-form-field class="svc-mat-no-subscript text-xs svc-mat-dense grow">
          <input
            type="search"
            matInput
            [(ngModel)]="hashtagSearch"
            [placeholder]="'Digite aqui' | transloco"
            (ngModelChange)="!filteredhashtagByPreference && filter($event)"
            (input)="filteredhashtagByPreference && $event.target.value = ''"
          >
          <mat-icon
            class="icon-size-3"
            [svgIcon]="'heroicons_solid:magnifying-glass'">
          </mat-icon>
        </mat-form-field>
      </div>

      <div class="flex items-center space-x-2">
        <!--hashtag zones-->
        <button mat-icon-button [matMenuTriggerFor]="hashtagZone" class="bg-primary text-on-primary min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px] mat-elevation-z0">
          <ng-container *ngIf="toggleType == hashtagTypeEnum.Global">
            <mat-icon class="text-current icon-size-3"  [svgIcon]="'heroicons_solid:globe-europe-africa'"></mat-icon>
          </ng-container>
          <ng-container *ngIf="toggleType == hashtagTypeEnum.Local">
            <mat-icon class="text-current icon-size-3" [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
          </ng-container>
        </button>
        <mat-menu [xPosition]="'before'" #hashtagZone="matMenu">
          <ng-container>
            <button
              mat-menu-item
              (click)="onToggleTypeEventClicked(hashtagTypeEnum.Local)"
            >
              <mat-icon [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
              <span>{{ 'Local' | transloco }}</span>
            </button>

            <button
              mat-menu-item
              (click)="onToggleTypeEventClicked(hashtagTypeEnum.Global)"
            >
              <mat-icon [svgIcon]="'heroicons_solid:globe-europe-africa'"></mat-icon>
              <span>{{ 'Global' | transloco }}</span>
            </button>
          </ng-container>
        </mat-menu>

        <!--hashtag actions-->
        <button mat-icon-button [matMenuTriggerFor]="hashtagActions" class="bg-primary text-on-primary min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px] mat-elevation-z0">
          <ng-container >
            <mat-icon class="text-current icon-size-3" [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
          </ng-container>
        </button>
        <mat-menu [xPosition]="'before'" #hashtagActions="matMenu">
          <ng-container>
            <button
              mat-menu-item
              (click)="onAddEventClicked()">
              <mat-icon [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
              <span>{{ 'Adicionar' | transloco }}</span>
            </button>

            <button
              mat-menu-item
              (click)="onEditEventClicked()">
              <mat-icon [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
              <span>{{ 'Editar' | transloco }}</span>
            </button>

            <button
              mat-menu-item
              (click)="onSeeAllEventClicked()">
              <mat-icon [svgIcon]="'heroicons_solid:eye'"></mat-icon>
              <span>{{ 'Ver todos' | transloco }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <mat-spinner diameter="18" *ngIf="isLoading"></mat-spinner>
    </div>

    <div
      #containerHashtags
      class="flex flex-wrap items-start pt-4 gap-x-3 gap-y-4 justify-center content-start h-full box-border relative"
      [ngClass]="{ 'overflow-y-scroll overflow-x-hidden': seeMoreRevealed, 'overflow-y-hidden overflow-x-hidden': !seeMoreRevealed }"
    >
      <ng-container *ngIf="postIsEnabled; else notFilterPostsByHashtag">
        <div *ngFor="let hashtag of filteredHashtags; let i = index" class="flex rounded-lg bg-primary-300 text-primary-700 px-1">
          <a class="text-sm md:text-base font-medium cursor-pointer" *ngIf="!filteredhashtagByPreference" (click)="filterByHashtagClicked(hashtag.name)">{{ hashtag.name }}</a>

          <a class="flex items-center gap-x-1 text-sm md:text-base font-medium" *ngIf="filteredhashtagByPreference">
            {{ hashtag.name }} <mat-icon class="icon-size-3 bg-primary text-primary-300 rounded-full cursor-pointer" svgIcon="heroicons_solid:x-mark" (click)="filterByHashtagClicked()"></mat-icon>
          </a>
        </div>
      </ng-container>
      <ng-template #notFilterPostsByHashtag>
        <div *ngFor="let hashtag of filteredHashtags" class="flex rounded-lg bg-primary-300 text-primary-700 px-1">
          <a class="text-sm md:text-base font-medium">{{ hashtag.name }}</a>
        </div>
      </ng-template>
    </div>

    <a *ngIf="seeMore && !seeMoreRevealed" class="pt-4 text-primary-600 text-center text-sm font-medium cursor-pointer" (click)="seeMoreRevealed = true">
      {{'Ver mais'| transloco }}...
    </a>
  </div>
  <div *ngIf="isLoading" class="flex text-primary pt-8 justify-center">
    <mat-spinner class="text-current" diameter="25"></mat-spinner>
  </div>
</ng-container>
<ng-template #refresh>
  <div class="flex justify-center items-center p-1 rounded-lg h-52" [ngClass]="{'bg-white': !isLoading}">
    <div *ngIf="!isLoading" class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="refreshHashtags()">
      <mat-icon class="icon-size-4 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
      <span class="text-base text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
    </div>
    <mat-spinner diameter="18" *ngIf="isLoading"></mat-spinner>
  </div>
</ng-template>
