import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxEchartsModule } from 'ngx-echarts';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { SvcChartMonthFilterComponent } from './svc-chart-month-filter/svc-chart-month-filter.component';
import { SvcChartMonthComponent } from './svc-chart-month.component';
import { SvcChartYearFilterComponent } from './svc-chart-year-filter/svc-chart-year-filter.component';
import { SvcChartDayFilterComponent } from './svc-chart-day-filter/svc-chart-day-filter.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    LibSharedCommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    TranslocoModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    SvcChartMonthComponent,
    SvcChartMonthFilterComponent,
    SvcChartYearFilterComponent,
    SvcChartDayFilterComponent
  ],
  exports: [
    SvcChartMonthComponent,
    SvcChartMonthFilterComponent,
    SvcChartYearFilterComponent,
    SvcChartDayFilterComponent
  ],
})
export class SvcChartMonthModule {}
