<div #scrollerEl class="svc-fsb-scroller" (scroll)="onScroll($event)">
  <ng-content/>
</div>
<div class="svc-fsb-bar-area">
  <div></div>
  <div
    #scrollBarEl
    [style.height.px]="scrollerEl.offsetHeight / scrollerEl.offsetHeight"
  ></div>
  <!-- [style.transform]="'translateY(' + (scrollerEl) + 'px)'" -->
</div>