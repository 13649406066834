import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { SvcAppSettings, UserEnvironment, UserService } from 'projects/lib-shared-core/src/public-api';
import { catchError, tap } from 'rxjs';
import { SvcFaqQuestion } from "./svc-faq";
import { SvcFaqService } from "./svc-faq.service";
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-svc-faq',
  templateUrl: './svc-faq.component.html',
  styleUrls: ['./svc-faq.component.scss'],
})
export class SvcFaqComponent {

  public environment: UserEnvironment;
  public questions: SvcFaqQuestion[] = [];
  public loading = true;

  constructor(
    private _dialogRef: MatDialogRef<SvcFaqComponent>,
    private _userService: UserService,
    private _faqService: SvcFaqService,
    private _appSettings: SvcAppSettings,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
  ) { }

  public ngOnInit() {
    this.environment = this._userService.environment;
    this._faqService.getFaqList(this._appSettings.applicationId).pipe(
      tap((faqs) => {
        this.questions = faqs.length > 0 ? faqs[0].trainingQuestions : [];
        this.loading = false;
      }),
      catchError(() => {
        this.questions = [];
        this.loading = false;
        return [];
      }),
    ).subscribe()
  }

  public toggleShowAnswers(question: SvcFaqQuestion) {
    if (question.answers.length > 0) {
      this.questions.forEach((q) => {
        if (q != question) {
          q['showAnswers'] = false;
        }
      });
      question['showAnswers'] = question['showAnswers'] ? false : true;
      return true;
    }
    else {
      this._toastService.info(this._translocoService.translate('Este tópico não possui questões para serem exibidas'));
      return false;
    }
  }

  public closeDialog() {
    this._dialogRef.close();
  }
}
