import { KeyValue } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { TranslocoService } from "@ngneat/transloco";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcDialogService, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { finalize, Subject, takeUntil, tap } from "rxjs";
import { Post } from "../../models/post";
import { PostApplicationRegistry } from "../../models/post-application-registry";
import { PostPagination } from "../../models/post-pagination";
import { PostService } from "../../services/post.service";
import { SvcPostItemComponent } from '../svc-post-item/svc-post-item.component';
import { SvcPostViewModalComponent } from "../svc-post-view-modal/svc-post-view-modal.component";

@Component({
  selector: 'svc-post-page',
  templateUrl: './svc-post-page.component.html',
  styleUrls: ['./svc-post-page.component.scss']
})
export class SvcPostPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('postsContainer') postsContainer: ElementRef<HTMLElement>;
  @ViewChildren(SvcPostItemComponent) queryPostsItem: QueryList<SvcPostItemComponent>;

  posts: Post[] = [];
  page: PostPagination;
  loadingPost: boolean = true;
  hasNewPosts: boolean;
  hasUpdatedPosts: boolean;

  private timeStartPostHubConnection: number = 5000;
  private hubConnection: HubConnection;
  private linkedPostId: string;
  private scrollListener: () => void;

  private get postsItem() {
    return this.queryPostsItem.toArray();
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _postService: PostService,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer2: Renderer2,
    private _http: HttpClient,
    private _userService: UserService,
    private _appConfig: AppEnvironmentConfig,
    private route: ActivatedRoute,
    private _svcDialog: SvcDialogService,
    private _toast: SvcToastService,
    private _transloco: TranslocoService,
  ) {
  }

  ngOnInit() {

    this._postService.loadingChange.subscribe(status => {
      this.loadingPost = status;
    })

    this.route.params.subscribe(p => {
      if (!p.id) return;
      this.linkedPostId = p.id;
    });

    this.scrollListener = this._renderer2.listen(
      this._elementRef.nativeElement?.parentElement,
      'scroll',
      this.onScroll.bind(this)
    );

    this._postService.postsUpdated$.subscribe(posts => {
      this.posts = posts;
      this.setPostsHashtags(posts);
    });

    this._postService.postsAdded$.subscribe(posts => {
      this.setPostsHashtags(posts);
    });

    this._postService.pageChanged$.subscribe(p => {
      this.page = p;
    });

    if (this.linkedPostId) this.showPost();
    
    this.prepareListenPostHub();
    this.startListenPostHub();
  }

  ngAfterViewInit(): void {
    this.checkPostsVisible();
    this.queryPostsItem.changes.pipe(
      takeUntil(this.destroy$),
      tap(() => this.checkPostsVisible()),
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.scrollListener) {
      this.scrollListener();
    }

    this.hubConnection?.stop()
      .then(() => console.log('[PostSignalR] Connection stopped.'))
      .catch(err => console.log('[PostSignalR] Error while stopping connection: ' + err));
    this.hubConnection = null;

    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getPosts(linkedPostId?: string) {
    this._postService.getPosts()
      .pipe(finalize(() => {
        if (linkedPostId) this.showPost();
      })).subscribe({
      error: () => {
        this._toast.error(this._transloco.translate('Ocorreu um erro ao realizar a consulta. Tente novamente mais tarde.'))
        this.loadingPost = false
      }
    });
  }

  onScroll(event: Event) {
    this.checkPostsVisible();

    const element = event.target as HTMLElement;
    const scrollTargetLoadMore = element.scrollHeight * 0.7;
    const scrolledTop = Math.round(element.scrollTop + element.clientHeight) + 10; // +10 to fix browser bug

    if (scrolledTop < scrollTargetLoadMore || this.loadingPost)
      return;

    this.loadingPost = true;
    this._postService.setNextPage().subscribe({
      complete: () => {
        this.loadingPost = false
      }
    })

  }

  private checkPostsVisible() {
    const element = this._elementRef.nativeElement?.parentElement;
    const initialTop = this.postsContainer.nativeElement.offsetTop;

    for (const item of this.postsItem) {
      const itemTop = initialTop + item.element.offsetTop;
      const itemBottom = itemTop + item.element.clientHeight;
      const viewPortTop = element.scrollTop;
      const viewPortBottom = viewPortTop + element.clientHeight;

      if (viewPortTop <= itemBottom && viewPortBottom >= itemTop) {
        item.startedToBeSeen();
      } else {
        item.stoppedToBeSeen();
      }
    }
  }

  showNewPosts(buttonEl: HTMLElement, refresh?: boolean) {
    this.hasNewPosts = false;
    this.hasUpdatedPosts = false;

    if (refresh) {
      this._postService.resetList();
      this.getPosts();
      return;
    }

    this._elementRef.nativeElement?.parentElement.scrollTo({
      top: Math.max(0, (buttonEl?.parentElement?.parentElement?.offsetTop ?? 0) - 65),
      behavior: 'smooth'
    });
  }

  private groupByApplicationId(postApplicationRegistry: PostApplicationRegistry[]): KeyValue<string, number[]>[] {
    const groupedData: { [key: string]: number[] } = postApplicationRegistry.reduce((previousValue: { [key: string]: number[] }, application: PostApplicationRegistry) => {
      if (application.applicationId && application.applicationRegistryReferenceId !== undefined) {
        const propertyName: string = `${application?.applicationId}-siteId=${application.siteId}`

        if (!previousValue[propertyName]) {
          previousValue[propertyName] = [];
        }
        previousValue[propertyName].push(
          application.applicationRegistryReferenceId
        );
      }
      return previousValue;
    }, {} as { [key: string]: number[] });

    return Object.keys(groupedData).map(key => ({ key, value: groupedData[key] }));
  }

  private setPostsHashtags(posts: Post[]) {

    const allHashtags = Array.from(
      new Set<number>(
        posts.flatMap(post => post.hashtags?.map(hashtag => hashtag.hashtagId) || [])
          .filter(i => !!i)
      )
    );

    if (!allHashtags.length) return;

    this._http.get<{
      hashtagId: number,
      name: string,
      hashtagTypeId: number,
      createUserId: string,
      createDate: Date,
      openToSubscribe: boolean,
      isGlobal: boolean,
      siteId: number,
      active: boolean
    }[]>(`${this._appConfig.APIs.apiUrlHashtag}/Post/ByIds`, {
      params: {
        hashtagId: allHashtags
      }
    }).subscribe(list => {
      posts.forEach(post => {
        post.hashtags?.forEach((hashtag, i) => {
          const item = list.find(h => h.hashtagId === hashtag.hashtagId);
          if (item) post.hashtags[i] = { ...item, name: `#${item.name}` };
        })
      })
    })

  }

  private async startListenPostHub() {
    try {
      if (this.hubConnection) {
        await this.hubConnection.start();
      }
    }
    catch(e) {
      console.log(e);
      setTimeout(() => this.startListenPostHub(), this.timeStartPostHubConnection);
      this.timeStartPostHubConnection += 5000;
    }
  }

  private prepareListenPostHub() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this._appConfig.APIs.apiUrlPosts}/postHub`, {
        withCredentials: false
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();

    this.hubConnection.onclose(async () => {
      await this.startListenPostHub();
    });

    this.hubConnection.onreconnecting((error) => {
      console.log(`[PostSignalR] Connection lost due to error ${error}. Reconnecting.`);
    });

    this.hubConnection.on('BroadcastNewPost', (post: Post) => {

      if (post.lastInteraction) {
        if (post.originator.originatorId !== post.lastInteraction.userId && this._postService.checkPostIsInList(post)) {
          const postToUpdate = this.postsItem.find(p => p.post.id === post.id);
          postToUpdate.post.lastInteraction = post.lastInteraction;
          if (postToUpdate.post.lastInteraction.interactionType === 1) postToUpdate.post.sharedTimes++;
          postToUpdate.postItemFooterComponent.refreshCounters();
          this.hasUpdatedPosts = true;
        }
        return;
      }

      if (post.originator.originatorId === this._userService.userId$)
        return

      if (!this._postService.checkPostIsInList(post)) {
        setTimeout(() => {
          this._postService.getPost(post.id).subscribe(newPost => {
            this._postService.unshiftPost(newPost);
            this.hasNewPosts = true;
          });
        }, 2000);
      }
    });
  }

  private showPost() {
    this._postService.getPost(this.linkedPostId).subscribe({
      next: post => {
        this._svcDialog.open(SvcPostViewModalComponent, {
          data: {
            reload: true,
            post: post
          },
          maxWidth: '720px',
          width: '100%',
          panelClass: ['sm:p-4'],
          disableClose: true,
          enterAnimationDuration: '0s',
        });
      },
      error: () => {
        this._toast.error(this._transloco.translate('Ocorreu um erro ao realizar a consulta. Tente novamente mais tarde.'))
      }
    });
  }

}
