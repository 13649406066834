import { ISvcCloudDataZoomOnHoverOptions } from './svc-cloud-data-zoom-on-hover-options';

export interface ISvcCloudOptions {
  width?: number;
  height?: number;
  overflow?: boolean;
  strict?: boolean;
  zoomOnHover?: ISvcCloudDataZoomOnHoverOptions;
  realignOnResize?: boolean;
  randomizeAngle?: boolean;
  step?: number;
  log?: 'warn' | 'debug' | false;
  background?: string;
  font?: string;
  delay?: number;
}
