import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'svc-search-field',
  templateUrl: './svc-search-field.component.html',
  styleUrls: ['./svc-search-field.component.scss']
})
export class SvcSearchFieldComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.force-w-full') private _forceWidthFull = false;

  @Output() public onChanged = new EventEmitter<string>();
  @Input() public initialValue: string = null;
  @Input() public forceWidthFull: boolean = false
  @Input() public disabled: boolean = false
	@Input() public inputId: string;
  protected formControl = new FormControl();
  private lastFormControlValue = null;
  private unsubscribeAll = new Subject();
  private forceNextEmit = false;

  public get value(): string {
    return this.formControl.value;
  }

  public ngOnInit(): void {
    this.formControl.setValue(this.initialValue, { emitEvent: false });
    this.formControl.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.unsubscribeAll),
      tap((value) => {
        const forceNextEmit = this.forceNextEmit;
        this.forceNextEmit = false;
        this.onValueChanged({ value, force: forceNextEmit });
      }),
    ).subscribe()
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('forceWidthFull' in changes) {
      this._forceWidthFull = this.forceWidthFull ?? false;
    }
    if ('disabled' in changes) {
      if (this.disabled && this.formControl.enabled) this.formControl.disable();
      if (!this.disabled && this.formControl.disabled) this.formControl.enable();
    }
  }

  protected onValueChanged(options?: { value?: string, force?: boolean }) {
    if (this.formControl.enabled) {
      const value = options?.value ?? this.formControl.value;
      const valueLength = (value ?? '').length;
      if ((options?.force ?? false) || (value !== this.lastFormControlValue && (!valueLength || valueLength >= 3))) {
        this.onChanged.emit(value);
        this.lastFormControlValue = value;
      }
    }
  }

  public enable(options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    if (options?.emitEvent ?? false) this.forceNextEmit = true;
    this.disabled = false;
    this.formControl.enable(options);
  }
  
  public disable(options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    if (options?.emitEvent ?? false) this.forceNextEmit = true;
    this.disabled = true;
    this.formControl.disable(options);
  }

  public setValue(value: string, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    if (options?.emitEvent ?? false) this.forceNextEmit = true;
    this.formControl.setValue(value, options);
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll?.next(null);
    this.unsubscribeAll?.complete();
  }
}
