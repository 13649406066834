export interface GetCounterReactionsParams {
  applicationId: string;
  siteId: number;
}

export interface GetCounterReactionResponse {
  reactionUniqueId: number;
  registryUniqueId: string;
  registryUniqueUId: string;
  animatedImageUrl: string;
  count: number
  description: string;
  enumKey: string;
  staticImageUrl: string;
  userHasReacted: boolean;
}