import { SvcFishboneType } from "../svc-fishbone.component";
import { SvcFishboneItem } from "./svc-fishbone-item.interface";

export interface SvcFishboneAddEvent {
  type: SvcFishboneType;
  setLoading: (value: boolean) => void;
  onAdded: (values: { item: SvcFishboneItem, onTop?: boolean }) => void;
}

export interface SvcFishboneEditEvent {
  item: SvcFishboneItem;
  type: SvcFishboneType;
  setLoading: (value: boolean) => void;
  onEdited: (item: SvcFishboneItem) => void;
}

export interface SvcFishboneDeleteEvent {
  item: SvcFishboneItem;
  type: SvcFishboneType;
  setLoading: (value: boolean) => void;
  onDeleted: () => void;
}

export interface SvcFishboneEffectEditEvent {
  effect: string;
  setLoading: (value: boolean) => void;
  onEdited: (effect: string) => void;
}