import { ISvcStatusBadge } from "projects/lib-shared-component/src/public-api";
import { CategorizedDrillDown } from "projects/lib-shared-model/src/public-api";
import { SvcPage } from "projects/lib-shared-core/src/lib/interfaces/svc-page.interface";

export interface ActionPlanDrillDownItem {
    actionPlanId: number;
    actionPlanName: string;
    createDate: Date;
    status: number;
    isPrivate: boolean;
    owners: ActionPlanDrilldownOwner[];
    deadline: string;
}

export interface ActionPlanDrilldownOwner{
  userId: string;
  userName: string;
  pictureFile: string;
}

export class ActionPlanCategorizedDrillDown implements CategorizedDrillDown<ActionPlanDrillDownItem> {
    indicatorEnum: number;
    indicatorItems: ActionPlanDrillDownItem[];
}

export enum ActionPlanDrillDownStatus {
    COMPLETED = 40,
    IN_APPROVAL = 30,
    ON_TIME = 20,
    DELAYED = 10
}

export const ActionPlanDrillDownStatusBadge = <ActionPlanDrillDownStatus>(
    enumDefinition: ActionPlanDrillDownStatus
  ): ISvcStatusBadge => {
    switch (enumDefinition) {
      case ActionPlanDrillDownStatus.COMPLETED:
        return { description: 'Cumprido', type: 'completed' };
      case ActionPlanDrillDownStatus.IN_APPROVAL:
        return { description: 'Em Aprovação', type: 'approval' };
      case ActionPlanDrillDownStatus.ON_TIME:
        return { description: 'No Prazo', type: 'ontime' };
      case ActionPlanDrillDownStatus.DELAYED:
        return { description: 'Atrasado', type: 'delayed' };
    }
}

export const ActionplanStatusBadgeByText = (text: string): ISvcStatusBadge => {
  switch (text) {
    case 'Cumprido':
      return { description: 'Cumprido', type: 'completed' };
    case 'Em Aprovação':
      return { description: 'Em Aprovação', type: 'approval' };
    case 'No Prazo':
      return { description: 'No Prazo', type: 'ontime' };
    case 'Atrasado':
      return { description: 'Atrasado', type: 'delayed' };
  }
}

export interface ActionplanAnalyticsDrilldown {
  actionPlans: ActionplanAnalytics[];
  page: SvcPage;
}

export interface ActionplanAnalytics {
  planId: number;
  planName: string;
  statusName: string;
  registerId: number;
  statusBadge?: ISvcStatusBadge;
}