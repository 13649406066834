<ng-container *ngIf="length > 0">
	<!-- SINGLE -->
	<ng-container *ngIf="isSingle">
		<ng-container *ngIf="isLoading">
			<ngx-skeleton-loader 
					appearance="circle"
					[theme]="{
						'min-height': size + 'px',
						height: size + 'px',
						width: size + 'px'		
						}"
					/>
		</ng-container>
		<ng-container *ngIf="!isLoading">
			<svc-user-avatar *ngIf="avatars.length > 0" [info]="avatars[0]"></svc-user-avatar>
			<div *ngIf="avatars.length === 0" class="rounded-full bg-gray-200" [style.height.px]="size" [style.width.px]="size" [style.min-height.px]="size" [style.min-width.px]="size"></div>
		</ng-container>
	</ng-container>

	<!-- MULTIPLE -->
	<ng-container *ngIf="isMultiple">
		<ng-container *ngIf="isLoading">
			<div class="flex flex-col items-center flex-shrink-0 basis-1/4">
				<div class="flex -space-x-7">
					<ngx-skeleton-loader 
					*ngFor="let _ of [].constructor(maxSize ?? 1)" 
					appearance="circle"
					[theme]="{
						'min-height': size + 'px',
						height: size + 'px',
						width: size + 'px'		
						}"
					/>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!isLoading">
			<svc-avatar-multiple *ngIf="avatars.length > 0"
				[info]="{ size: size, initialsColor: '#F0F0F0', type: 1, data: avatars }"
				[max-size]="maxSize"
				[border-color]="borderColor"
			></svc-avatar-multiple>
			<div *ngIf="avatars.length === 0" class="rounded-full bg-gray-200" [style.height.px]="size" [style.width.px]="size" [style.min-height.px]="size" [style.min-width.px]="size"></div>
		</ng-container>
	</ng-container>
</ng-container>
<ng-container *ngIf="length <= 0">
	<div [style.height.px]="size" [style.width.px]="size"></div>
</ng-container>