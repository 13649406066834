import { Component, Input, OnInit } from '@angular/core';
import { SvcPrinterElement, SvcPrinterOption } from '../svc-printer-classes';

interface SvcPrinterOptionImages extends SvcPrinterOption {
  elements: HTMLImageElement[];
}

@Component({
  selector: 'svc-printer-img-carousel',
  templateUrl: './svc-printer-img-carousel.component.html',
  styleUrls: ['./svc-printer-img-carousel.component.scss'],
})
export class SvcPrinterImgCarouselComponent implements OnInit {

  @Input() public option: SvcPrinterOptionImages;

  protected currentIndex: number;
  protected current: {
    isLoading: boolean,
    src: string,
  };
  protected currentElement: HTMLImageElement | SvcPrinterElement;
  protected hasNext: boolean;
  protected hasPrev: boolean;
  protected isSelectable: boolean = false;

  public ngOnInit(): void {
  }

  public ngOnChanges(): void {
    if (!this.current || this.currentIndex == null || this.currentIndex >= this.option?.elements?.length) {
      this.#setFromIndex(0);
    }
    this.updateComponentProps();
  }

  protected next() {
    this.#setFromIndex(this.currentIndex + 1);
    this.updateComponentProps();
  }

  protected previous() {
    this.#setFromIndex(this.currentIndex - 1);
    this.updateComponentProps();
  }

  protected updateComponentProps() {
    this.hasNext = (this.currentIndex + 1) < this.option.elements.length;
    this.hasPrev = this.currentIndex > 0;
  }

  #setFromIndex(index: number) {
    if (index >= 0 && index < this.option?.elements?.length) {
      this.currentIndex = index;
      this.currentElement = this.option.elements[index];
      const src = (this.currentElement instanceof HTMLElement)
        ? this.currentElement.src
        : (<HTMLImageElement>(<SvcPrinterElement>this.currentElement).element).src;
      this.current = {
        src,
        isLoading: true,
      };
      this.isSelectable = !(this.currentElement instanceof HTMLElement);
    }
  }
}
