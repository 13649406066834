<ng-container *ngFor="let item of navigation">
  <a class="text-primary text-sm leading-tight font-normal" (click)="goTo(item)">
    <div class="relative flex p-1.5 h-[38px] items-center justify-between gap-4 bg-primary-100 rounded-lg">

      <div class="flex gap-2 items-center">
        <app-user-navigation-images [title]="item?.title"></app-user-navigation-images>

        <span>{{ item.title | transloco }}</span>
      </div>

      <ng-container *ngIf="item?.title !== 'Meu Personal Book'">
        <ng-container *ngIf="item?.title === 'Minhas Qualificações'">
          <ng-container *ngIf="!loadingState.myQualifications || errorState.myQualifications; else loadingMyQualifications">
            <div
              *ngIf="accessibilityState.myQualifications; else withoutQualifications"
              [ngClass]="{'relative flex items-center justify-center bg-primary rounded-full p-1 min-w-[28px] min-h-[27px]': !errorState.myQualifications }"
            >
              <div *ngIf="!errorState.myQualifications; else reloadQuatlifationsView" class="text-white text-base font-bold flex items-center justify-center">
                {{ counters.qualifications + '%' }}
              </div>
              <ng-template #reloadQuatlifationsView>
                <div class="flex items-center font-normal">
                  <mat-icon *ngIf="!loadingState.myQualifications"
                    class="icon-size-5 text-primary cursor-pointer"
                    svgIcon="heroicons_solid:arrow-path"
                    [matTooltip]="'Recarregar' | transloco"
                    (click)="getCounterMyQualifications()"
                  />

                  <mat-spinner diameter="18" *ngIf="loadingState.myQualifications"/>
                </div>
              </ng-template>
            </div>
            <ng-template #withoutQualifications>
              <div class="pr-3 text-primary text-2xl font-bold">
                {{ '-' }}
              </div>
            </ng-template>
          </ng-container>
          <ng-template #loadingMyQualifications>
            <mat-spinner diameter="18"></mat-spinner>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="item?.title === 'Elogios'">
          <ng-container *ngIf="!loadingState.praiseReceived || errorState.praiseReceived; else loadingPraiseReceived">
            <div
              *ngIf="accessibilityState.praiseReceived; else withoutPraiseReceived"
              [ngClass]="{'relative flex items-center justify-center bg-primary rounded-full p-1 min-w-[28px] min-h-[27px]': !errorState.praiseReceived }"
            >
                <div *ngIf="!errorState.praiseReceived; else reloadPraiseReceivedView" class="text-white text-base font-bold flex items-center justify-center">
                  {{ counters.compliments | formatNumberDefault }}
                </div>
                <ng-template #reloadPraiseReceivedView>
                  <div class="flex items-center font-normal">
                    <mat-icon *ngIf="!loadingState.praiseReceived"
                      class="icon-size-5 text-primary cursor-pointer"
                      svgIcon="heroicons_solid:arrow-path"
                      [matTooltip]="'Recarregar' | transloco"
                      (click)="getPraiseReceivedCounter()"
                    />
  
                    <mat-spinner diameter="18" *ngIf="loadingState.praiseReceived"/>
                  </div>
                </ng-template>
            </div>
            <ng-template #withoutPraiseReceived>
              <div class="pr-3 text-primary text-2xl font-bold">
                {{ '-' }}
              </div>
            </ng-template>
          </ng-container>
          <ng-template #loadingPraiseReceived>
            <mat-spinner diameter="18"></mat-spinner>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </a>
</ng-container>
