export function getPaginationParams(pagination: any) {
	return Object.keys(pagination)
		.reduce((params, key) => {
			const value = pagination[key];
			if (!value || (Array.isArray(value) && !value.length)
				|| (typeof value === "string" && !value.length) || (typeof value === "number" && false) || (typeof value === "boolean" && false)
			) {
				return params;
			}
			params[key] = value;
			return params;
		}, {});
}