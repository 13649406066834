import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { LanguagesService } from '../../languages/services/languages.service';
import { UserService } from '../services/user.service';
import { User } from '../services/user.types';
import { catchError, tap } from 'rxjs';
import { HttpErrorService, updateDateFormatCookie, updateLanguageIdCookie } from 'projects/lib-shared-common/src/public-api';
import { environment } from 'projects/environments/environment';
import { SvcDelimiterType } from '../../../settings/enums/svc-delimiter-type.enum';
import { SvcFeatureToggleService } from 'projects/lib-shared-core/src/public-api';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  selectedFile: File;
  user: User;
  availableLangs: { text: string, value: any }[];

  settingsForm: FormGroup;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('fileInput') fileInput: any;

  urlImg: string;
  removeFile = false;
  activeLang: LangDefinition;
  isAppOffilineEnabled = false;
  env = environment;

  private get isInsideIframe() {
    return (top || parent) !== window;
  }

  dateFormatOptions: ISvcSelectOption[] = [
    { text: 'DD/MM/YYYY', value: 'DDMMYYYY' },
    { text: 'MM/DD/YYYY', value: 'MMDDYYYY' },
    { text: 'YYYY-MM-DD', value: 'YYYYMMDD' }
  ];
  delimiterSymbolOptions: ISvcSelectOption[] = [
    { text: this._translocoService.translate('Ponto') + ' (.)', value: SvcDelimiterType.DOT },
    { text: this._translocoService.translate('Vírgula') + ' (,)', value: SvcDelimiterType.COMMA },
  ];
  constructor(
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _languageService: LanguagesService,
    public _dialogRef: MatDialogRef<UserSettingsComponent>,
    private _httpErrorService: HttpErrorService,
    private _svcFeatureToggleService: SvcFeatureToggleService,

    ) {
    this.user = this._userService.user;
    this.urlImg = this.user.pictureFile;
    this.isAppOffilineEnabled = this._svcFeatureToggleService.appOffilineEnabled;
  }

  ngOnInit() {
    this.settingsForm = this._formBuilder.group({
      pictureFile: [this.user.pictureFile],
      enableOutlookSync: [ this.user.isSyncOutlook ],
      enableOfflineMode: [ this.user.enableOfflineMode ],
      dateFormat: [ this.user.dateFormat, [Validators.required] ],
      fontSize: [1],
      languageId: [this.user.languageId, [Validators.required]],
      delimiter: [this.user.delimiter ?? 0, [Validators.required]]
    });

    this._languageService.languages$.subscribe(res => {
      this.availableLangs = res.map(x => ({
        text: x.languageTranslated,
        value: x.languageId,
      }));
    })

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe((selectedLang) => {

      // Get the active lang
      const lang = this.availableLangs.find((l: { text: string, value: any }) => l.value == selectedLang);
      if (lang) {
        this.activeLang = {
          id: lang.value,
          label: lang.text,
        };
      }
    });

  }

  onSubmit() {

    this.settingsForm.markAllAsTouched();
    if (!this.settingsForm.valid)
      return;

    if (this.activeLang != this.settingsForm.get('languageId').value){
      this._languageService.updateUserLanguage(this.settingsForm.get('languageId').value)
    }

    let payload = {
      enableOutlookSync: this.settingsForm.value.enableOutlookSync,
      enableOfflineMode: this.settingsForm.value.enableOfflineMode,
      viewMode: this.settingsForm.value.viewMode,
      dateFormat: this.settingsForm.value.dateFormat,
      fontSize: this.settingsForm.value.fontSize,
      languageId: this.settingsForm.value.languageId,
      delimiter: this.settingsForm.value.delimiter,
    } as any;

    if (this.settingsForm.value.pictureFile) {
      payload.pictureFile = this.settingsForm.value.pictureFile;
    }

    this.settingsForm.disable();
    this._userService.update(payload).pipe(
      tap(res => {
        if(res.isSucess){
          this._userService.user = {
            ...(this._userService.user ?? {}),
            ...this.settingsForm.value,
          };
          if(file.length == 0 && !this.removeFile){

            //legacy (c#/asp) session reload cookies
            updateDateFormatCookie(payload.dateFormat);
            updateLanguageIdCookie(payload.languageId);

            if (this.isInsideIframe) (top ?? parent)?.location.reload();
            else window?.location.reload();
          }
        }
      }),
      catchError((error) => {
        this._httpErrorService.showErrorInToast(error);
        this.settingsForm.enable();
        return error;
      }),
    ).subscribe();

    const file = this.fileInput.nativeElement.files;

    if(file.length > 0){
      this._userService.updateUserImg(file[0]).pipe(
        tap(res => {
          this.user.pictureFile = res.urlAssign;
          if (this.isInsideIframe) (top ?? parent)?.location.reload();
          else window?.location.reload();
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          this.settingsForm.enable();
          return error;
        }),
      ).subscribe()
    }

    if(file.length == 0 && this.removeFile){
      this._userService.deleteImg(this.settingsForm.get('pictureFile').value).pipe(
        tap(res => {
          if (this.isInsideIframe) (top ?? parent)?.location.reload();
          else window?.location.reload();
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          this.settingsForm.enable();
          return error;
        }),
      ).subscribe();
    }
  }

  uploadFile(): void {
    this.fileInput.nativeElement.click();
  }

  onfileSelected(e: any){
    if(e.target.files){
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0])

      reader.onload = (event: any)=>{
        this.urlImg = event.target.result
      }
    }
  }

  onDeleteImg(){
    this.removeFile = true;
    this.urlImg = null;
    this.settingsForm.get('pictureFile').setValue('');
  }

  closeDialog(){
    this._dialogRef.close();
  }
}
