import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { getBeforeYears, getNextYears } from 'projects/lib-shared-common/src/public-api';
import { Month, MonthList, MonthYear } from './svc-month-year';

@Component({
  selector: 'svc-month-year',
  templateUrl: './svc-month-year.component.html',
  styleUrls: ['./svc-month-year.component.scss']
})
export class SvcMonthYearComponent implements OnInit, OnChanges {

  @Input() public yearsLimit: number = 3;
  @Input() public filterDate: {start: Date; end: Date};
  @Input() public year: number;
  @Input() public month: number;
  @Input() public minYear: number = new Date().getFullYear();
  @Input() public minMonth: number = new Date().getUTCMonth() + 1;
  @Input() public disabled: boolean;

  @Output() public onDateChange: EventEmitter<MonthYear> = new EventEmitter();

  public activeYear: number;
  public activeMonth: Month;
  public years: number[];
  public months: Month[];
  public showMonths: boolean = false;
  public showYears: boolean = false;

  /** Actual Month */
  #month: number;
  /** Actual Year */
  #year: number;
  /** Years list, based on years limit */
  #years: number[];

  #started: boolean = false;

  constructor() {
    const date = new Date();
    this.#month = new Date().getMonth() + 1;
    this.#year = date.getFullYear();
    this.#years = [
      ...getBeforeYears(this.#year, this.yearsLimit),
      this.#year,
      ...getNextYears(this.#year, this.yearsLimit)
    ];
  }

  ngOnInit(): void {
    this.years = this.#years;
    this.activeYear = isNaN(this.minYear) ? this.#year : Math.min(this.minYear, this.#year);
    this.months = MonthList;
    this.#setActiveMonth(isNaN(this.minMonth) ? this.#month : this.minMonth);
    this.#started = true;
  }

  ngOnChanges(changes: any): void {
    if (!this.#started) return;

    if (changes.filterDate && changes.filterDate.currentValue) {
      const startDate = new Date(changes.filterDate.currentValue.start);
      const endDate = new Date(changes.filterDate.currentValue.end);
      this.months = MonthList.filter(m =>
        m.number >= startDate.getUTCMonth() + 1
        && m.number <= endDate.getUTCMonth() + 1
      );
      this.years = this.#years.filter(y =>
        y >= startDate.getUTCFullYear() && y <= endDate.getUTCFullYear()
      );
    } else if (!this.filterDate) {
      this.months = MonthList;
      this.years = this.#years;
    }

    if (changes.year && !this.disabled) {
      this.activeYear = this.years.find(y => y === changes.year.currentValue) || this.#year;
    }

    if (changes.month && !this.disabled) {
      this.activeMonth = MonthList.find(m => m.number === changes.month.currentValue);
    }

  }

  toggleMonths() {
    this.showMonths = !this.showMonths;
  }

  toggleYears() {
    this.showYears = !this.showYears;
  }

  selectYear(year: number) {
    this.activeYear = year;
    this.showYears = false;
    if (!this.months.some(m => m.number === this.activeMonth.number))
      this.activeMonth = this.months[0];
    this.#emitDateChange();

  }

  prevMonth() {
    if (!this.activeMonth) return;
    this.#setActiveMonth(this.activeMonth.number - 1);
    this.showMonths = false;
  }

  nextMonth() {
    if (!this.activeMonth) return;
    this.#setActiveMonth(this.activeMonth.number + 1)
  }

  selectMonth(month: number) {
    this.#setActiveMonth(month);
  }

  #emitDateChange() {
    this.onDateChange.emit({month: this.activeMonth, year: this.activeYear})
  }

  #setActiveMonth(month: number) {
    this.showMonths = false;
    this.showYears = false;
    if (month > 12) { // go to next year
      if (this.activeYear + 1 > this.#year + this.yearsLimit) return;
      this.activeYear++;
      this.activeMonth = MonthList.find(m => m.number === 1);
      this.#emitDateChange();
      return;

    }
    if (month < 1) { // go to past year
      if (this.activeYear - 1 < this.#year - this.yearsLimit) return;
      this.activeYear--;
      this.activeMonth = MonthList.find(m => m.number === 12);
      this.#emitDateChange();
      return;
    }

    if (!this.months.some(m => m.number === month)) {
      return;
    }

    this.activeMonth = MonthList.find(m => m.number === month);
    this.#emitDateChange();
  }

}
