<div *ngIf="!isLoading">
  <div class="flex items-center gap-3.5 mx-2 mb-[18px]">

    <mat-icon
      class="icon-size-6 text-primary cursor-pointer"
      id="svc-actionplan-button-add"
      svgIcon="heroicons_solid:plus-circle"
      *ngIf="showButtonAddActionplan && !isViewMode"
      (click)="openDialogActionPlan()"
    >
    </mat-icon>

    <div class="w-full max-w-[420px]">
      <svc-search-field [forceWidthFull]="true" (onChanged)="filterActionsPlans($event)">
      </svc-search-field>
    </div>

  </div>

  <div class="flex flex-col gap-2">
    <div
      class="action-plan ml-[4.7px] flex gap-1 justify-between items-center pb-0.5 cursor-pointer"
      id="svc-actionplan-card"
      *ngFor="let actionPlan of filteredActionsPlans"
      (click)="openDialogActionPlan(actionPlan, false)"
    >

      <div class="flex gap-1.5 items-center">
        <div [matTooltip]="actionPlan?.senderName">
          <svc-user-avatar
            [info]="actionPlan?.svcUserAvatar"
          ></svc-user-avatar>
        </div>

        <div class="flex flex-col gap-0.5">
          <div class="flex gap-1">
            <svc-lock-button
              class="h-4 w-4"
              *ngIf="actionPlan?.classified"
              iconSizeClass="icon-size-4"
              [locked]="!actionPlan?.allowed"
              [readonly]="true"
            >
            </svc-lock-button>

            <svc-button
              class="action-plan-id"
              mode="simple"
              color="primary"
              size="sm"
              buttonId="svc-actionplan-button-id"
            >
              <p class="font-medium text-sm uppercase">
                {{ ((tempId ? 'Rascunho' : 'Pla') | transloco).toUpperCase() }}-{{id ? actionPlan?.planId : '' }}
              </p>
            </svc-button>
          </div>

          <p class="text-default w-fit text-sm break-words line-clamp-2" [matTooltip]="actionPlan?.planName">
            {{ actionPlan?.planName }}
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-1 items-end justify-center">
        <div class="flex items-center gap-0.5">
          <svc-totals-status
            classColor="text-default"
            [referenceId]="actionPlan.planId"
            [configTypeId]="'7D0932EA-8C0E-4E24-A4AF-7B952D369142'"
            [available]="{ comments: true }"
          ></svc-totals-status>
        </div>

        <svc-status-badge
          class="status-badge items-start"
          [badgeData]="{
            description: actionPlanStatusName[actionPlan?.statusId],
            type: actionPlanStatusType[actionPlan?.statusId],
            size: 'small'
          }"
        >
        </svc-status-badge>

        <p class="text-default text-xs">
          {{ actionPlan?.delayedDays }} {{ actionPlan?.delayedDays > 1 ? ('dias' | transloco) : ('dia' | transloco) }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="flex items-center justify-center py-30" *ngIf="isLoading">
  <mat-spinner diameter="30"></mat-spinner>
</div>
