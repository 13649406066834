<div *ngIf="loading && (!infinite || currentPage === 1) && items?.length" class="svc-data-loading" [style.width.px]="el.offsetWidth ?? 35" [style.height.px]="el.offsetHeight ?? 35">
	<mat-spinner diameter="35"></mat-spinner>
</div>

<ng-content></ng-content>

<svc-data-paginate
	*ngIf="!(noPagination || infinite || paginate === null)"
	[totalRegisters]="dataLength"
	[totalPages]="isPaging && numPages ? numPages : 1"
	[current]="isPaging && numPages ? currentPage : 1"
	[pageSize]="paginate"
	[loading]="loading"
	[canChangePageSize]="canChangePageSize"
	[disabled]="loading"
	(onPageChanged)="onPageChange($event)"
	(onPageSizeChanged)="onPageSizeChange($event)"
>
</svc-data-paginate>

<div
	*ngIf="infinite" class="svc-data-infinite"
	[ngClass]="{ 'svc-data-infinite-show': loading && currentPage > 1 }"
	[style.width.px]="el?.offsetWidth"
	[style.transform]="'translateY(calc(-100% + ' + el?.offsetHeight + 'px))'"
>
	<mat-spinner diameter="30"/>
</div>
