import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BosHttpClient } from './bos-http-client';
import { SvcBosShift } from '../interfaces/svc-bos-shift.interface';

@Injectable({
  providedIn: 'root'
})
export class SvcBosShiftService {

  constructor(
    private _http: BosHttpClient
  ) { }

  public getShift(): Observable<SvcBosShift[]> {
    return this._http.get('/Shift');
  }
}
