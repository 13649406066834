<ng-content></ng-content>

<ng-template #reactionsTemplate>
  <div [hidden]="disabled" class="svc-reaction-list-wrapper">
    <section class="shadow bg-white" [ngClass]="{ 'rounded-full': (loadingList || loadingCurrent) && !reactionsType.length }">
      <div class="flex" [style.width.px]="reactionsType.length * 30">
        <div
          *ngFor="let reactionType of reactionsType"
          class="svc-reaction-item"
          [ngClass]="{ 'active': reactionType.reactionUniqueId == currentReactionType?.reactionUniqueId }"
          (click)="makeReaction(reactionType)"
        >
          <img [src]="reactionType.staticImageUrl" />
          <img [src]="reactionType.animatedImageUrl" />
        </div>
      </div>
      <div *ngIf="loadingList || loadingCurrent" class="flex justify-center items-center svc-reaction-loading">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </section>
  </div>
</ng-template>
