export interface ISvcChipOption {
    value: number | string;
    label: number  | string;
    selected?: boolean;
    data?: any;
}

export interface ISvcChipGroupedOption {
    group: string;
    options: (ISvcChipGroupedOption | ISvcChipOption)[];
}