import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";

@Injectable()
export class ModalPraiseNewHttpClient extends SvcHttpClient {
  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlPraise, httpClient);
  }
}
