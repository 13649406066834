<div class="flex px-8 py-3 items-center justify-between border-b border-inherit">

  <div class="text-xl font-bold tracking-tight">
    {{ 'Filtro' | transloco }}
  </div>

  <button mat-icon-button (click)="close()" class="-mr-2">
    <mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
  </button>

</div>

<div class="overflow-y-auto flex-auto p-5">
  <svc-filters
    #svcFilters
    [hidden]="isLoading"
    [filters]="filters"
    (onValueChanged)="onFilterValueChanged.emit($event)"
    (onFilterChanged)="checkIfHasFiltersToDo($event)"
  ></svc-filters>

  <div *ngIf="isLoading" class="flex flex-col p-10 items-center">
    <mat-spinner diameter="35"></mat-spinner>
  </div>

</div>

<div class="flex flex-row px-7 py-4 items-center justify-center border-t border-inherit gap-2">
  <svc-button
    color="primary"
    mode="simple"
    buttonId="filter-sidebar-clear"
    [disabled]="isLoading || (!hasFiltersDefined && svcFilters?.isEmpty)"
    (click)="internalClearFilters()"
  >
    {{ 'Limpar filtros' | transloco }}
  </svc-button>
  <svc-button
    color="primary"
    mode="flat"
    buttonId="filter-sidebar-search"
    [disabled]="isLoading || (searchOnlyWhenHaveNewValues && !hasFiltersPending) || !svcFilters?.isValid"
    (click)="search()"
  >
    {{ 'Buscar' | transloco }}
  </svc-button>
</div>