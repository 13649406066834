import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {TranslocoModule} from "@ngneat/transloco";
import { SvcButtonsModule, SvcCarouselModule, SvcFiltersModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcCommentsInputComponent } from './components/svc-comments-input/svc-comments-input.component';
import { SvcCommentsChatComponent } from './components/svc-comments-chat/svc-comments-chat.component';
import { SvcCommentsViewerComponent } from './components/svc-comments-viewer/svc-comments-viewer.component';
import { SvcCommentsListComponent } from './components/svc-comments-list/svc-comments-list.component';
import { SvcReactionModule } from '../svc-reaction/svc-reaction.module';
import { SvcCommentsMessageComponent } from './components/svc-comments-message/svc-comments-message.component';
import { SvcCommentsService } from './services/svc-comments.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvcAudioToTextButtonModule } from '../svc-audio-to-text-button/svc-audio-to-text-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillEditorComponent } from "ngx-quill";
import { SvcCommentsTotalComponent } from './components/svc-comments-total/svc-comments-total.component';
import { SvcCommentsFeedComponent } from './components/svc-comments-feed/svc-comments-feed.component';
import { SvcFeedService } from './services/svc-feed.service';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    SvcCommentsInputComponent,
    SvcCommentsTotalComponent,
    SvcCommentsMessageComponent,
    SvcCommentsViewerComponent,
    SvcCommentsChatComponent,
    SvcCommentsListComponent,
    SvcCommentsFeedComponent,
  ],
  imports: [
    LibSharedCommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    QuillEditorComponent,
    TranslocoModule,
    SvcAudioToTextButtonModule,
    SvcUserAvatarModule,
    SvcButtonsModule,
    SvcReactionModule,
    SvcCarouselModule,
    SvcFiltersModule,
    MatMenuModule,
  ],
  exports: [
    SvcCommentsViewerComponent,
    SvcCommentsTotalComponent,
    SvcCommentsChatComponent,
    SvcCommentsListComponent,
    SvcCommentsFeedComponent
  ],
  providers: [
    SvcCommentsService,
    SvcFeedService,
  ]
})
export class SvcCommentsModule { }
