export interface App {
  id: number;
  name: string;
  icon: string;
  backgroundColor: string;
  textActiveColor: string;
  textInactiveColor: string;
  items: AppItem[];
  highlight: boolean;
  expanded: boolean;
}

export interface AppItem {
  id: string;
  name: string;
  locked: boolean;
  codeLanguage: string;
  url: string;
  loading: boolean;
}

export interface MenuGroup {
  groupId: number;
  groupName: string;
  imagePath: string;
  backgroundColor: string;
  textColor: string;
  inactiveColor: string;
  isMasterApplication: boolean;
  menuItems: MenuItem[];
}

export interface MenuItem {
  applicationId: string;
  applicationName: string;
  applicationAlias: string;
  description: string;
  aspxLanguageResourceKey: string;
  subUrlPath: string;
  isActive: boolean;
}
