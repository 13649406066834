import { Component, Input, OnChanges, Output, ViewChild, ElementRef, HostListener, AfterViewInit, HostBinding, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';

export interface SvcChartDayFilterChange {
  start: { day: number, month: number, year: number };
  end: { day: number, month: number, year: number };
}

@Component({
  selector: 'svc-chart-day-filter',
  templateUrl: './svc-chart-day-filter.component.html',
  styleUrls: ['./svc-chart-day-filter.component.scss'],
})
export class SvcChartDayFilterComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() public selectedRangeDate: SvcChartDayFilterChange;
  @Input() public data!: { year: number, month: number, day: number }[];
  @Input() public minDate!: Date;
  @Input() public maxDate!: Date;
  @Input() public isLoading: boolean;
  @Output() public onRangeChange = new EventEmitter<SvcChartDayFilterChange>();

  @ViewChild('draggingArea') public set setDraggingArea(area: ElementRef<HTMLElement>) {
    if (area) {
      this.draggingArea = area;
      this._updateLimitsFromSelectedRange();
      this._resizeObserver.observe(this.draggingArea.nativeElement);
    }
  };
  @ViewChild('leftLimit') public leftLimit: ElementRef<HTMLElement>;
  @ViewChild('rightLimit') public rightLimit: ElementRef<HTMLElement>;

  @HostBinding('class.is-dragging') private isDragging: boolean = false;

  public mouseDragging = new MouseDragging();
  public leftLimitControl = new LimitControl({ isLeft: true });
  public rightLimitControl = new LimitControl({ isRight: true });
  public dates: { day: number, month: number, year: number }[];
  public isDraggingLeft: boolean;
  public isDraggingRight: boolean;
  public dateFormat: string = this._appSettings.dateFormat;
  public draggingArea: ElementRef<HTMLElement>;
  public isOverlapping: boolean;

  private rightTextRectX: number;
  private rightTextWidth: number;
  private leftTextRectX: number;
  private leftTextWidth: number;

  private _resizeObserver = new ResizeObserver(() =>
    this._updateLimitsFromSelectedRange(false)
  );

  constructor (
    private _appSettings: SvcAppSettings,
    private _cd: ChangeDetectorRef
  ) { }

  public ngAfterViewInit(): void {
    this.leftLimitControl.element = this.leftLimit.nativeElement;
    this.rightLimitControl.element = this.rightLimit.nativeElement;
    this._updateLimitsFromSelectedRange();
  }

  public ngOnChanges(): void {
    this._defineDates();
    this._updateLimitsFromSelectedRange();
  }

  public ngOnDestroy(): void {
    this._resizeObserver.disconnect();
  }

  @HostListener('window:mousedown', ['$event'])
  public onMouseDown(event: MouseEvent) {
    if (event.button == 0)
      this.startDrag(event.target as HTMLElement, event.screenX);
  }

  @HostListener('window:touchstart', ['$event'])
  public onTouchStart(event: TouchEvent): void {
    const touch = event.touches[0];
    this.startDrag(event.target as HTMLElement, touch.screenX);
  }

  private startDrag(target: HTMLElement, position: number): void {
    if (this.leftLimitControl.clickedInside(target)) {
      this.mouseDragging.currentLimitControl = this.leftLimitControl;
      this.mouseDragging.otherLimitControl = this.rightLimitControl;
    }
    else if (this.rightLimitControl.clickedInside(target)) {
      this.mouseDragging.currentLimitControl = this.rightLimitControl;
      this.mouseDragging.otherLimitControl = this.leftLimitControl;
    }

    if (this.mouseDragging.currentLimitControl) {
      this.isDragging = true;
      this.isDraggingLeft = this.leftLimitControl.clickedInside(target);
      this.isDraggingRight = this.rightLimitControl.clickedInside(target);
      this._updateIsOverlapping();
      this.mouseDragging.currentLimitControl.initialPositionPixels = this.mouseDragging.currentLimitControl.offset;
      this.mouseDragging.isDragging = true;
      this.mouseDragging.initialX = position;
      this.mouseDragging.currentX = position;
    }
  }

  @HostListener('window:mouseup', ['$event'])
  public onMouseUp() {
    this.isDragging = false;
    this.mouseDragging.isDragging = false;
    this.isDraggingLeft = false;
    this.isDraggingRight = false;
    this.mouseDragging.currentLimitControl = null;
    this.mouseDragging.otherLimitControl = null;
    this._checkDateRangeToEmit();
  }

  @HostListener('window:touchend', ['$event'])
  public onTouchEnd(): void {
    this.stopDrag();
  }

  private stopDrag(): void {
    this.isDragging = false;
    this.mouseDragging.isDragging = false;
    this.isDraggingLeft = false;
    this.isDraggingRight = false;
    this.isOverlapping = false;
    this.mouseDragging.currentLimitControl = null;
    this.mouseDragging.otherLimitControl = null;
    this._checkDateRangeToEmit();
  }

  @HostListener('window:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent) {
    this.moveDrag(event.screenX);
  }

  @HostListener('window:touchmove', ['$event'])
  public onTouchMove(event: TouchEvent): void {
    const touch = event.touches[0];
    this.moveDrag(touch.screenX);
  }

  private moveDrag(position: number): void {
    if (this.mouseDragging.isDragging) {
      this.mouseDragging.currentX = position;
      this.mouseDragging.updateLimitControl();
      this._updateIsOverlapping();
    }
  }

  private _updateIsOverlapping(): void {
    const leftRect: DOMRect = document.querySelector('#left-limit-text')?.getBoundingClientRect();
    const rightRect: DOMRect = document.querySelector('#right-limit-text')?.getBoundingClientRect();

    if (rightRect) {
      this.rightTextRectX = rightRect?.x;
      this.rightTextWidth = rightRect?.width;
    }

    if (leftRect) {
      this.leftTextRectX = leftRect?.x;
      this.leftTextWidth = leftRect?.width;
    }

    this.isOverlapping = this.leftTextRectX + this.leftTextWidth >= this.rightTextRectX || this.rightTextRectX + this.rightTextWidth <= this.leftTextRectX;
  }

  private _checkDateRangeToEmit() {
    if (!this.leftLimitControl?.currentDate?.date || !this.rightLimitControl?.currentDate?.date)
      return;

    const current: SvcChartDayFilterChange = {
      start: {
        month: this.leftLimitControl.currentDate.date.getMonth() + 1,
        year: this.leftLimitControl.currentDate.date.getFullYear(),
        day: this.leftLimitControl.currentDate.date.getDate()
      },
      end: {
        month: this.rightLimitControl.currentDate.date.getMonth() + 1,
        year: this.rightLimitControl.currentDate.date.getFullYear(),
        day: this.rightLimitControl.currentDate.date.getDate()
      },
    };
    if (JSON.stringify(this.selectedRangeDate ?? {}) !== JSON.stringify(current)) {
      this.selectedRangeDate = current;
      this.onRangeChange.emit(current);
    }
  }

  private _defineDates(): void {
    if (!this.data?.length)
      return;

    this.dates = this.data.map(item => ({
      day: item.day,
      month: item.month,
      year: item.year,
    }));

    this.mouseDragging.dates = this.dates;

    // Inicialize os limites com a data mínima e máxima dos dados disponíveis
    const minDate = this.dates[0];
    const maxDate = this.dates[this.dates.length - 1];

    this.leftLimitControl.setCurrentDate(minDate);
    this.rightLimitControl.setCurrentDate(maxDate);
  }

  private isSelectedDate(date: { day: number; month: number; year: number }, property: 'start' | 'end'): boolean {
    return date.day === this.selectedRangeDate[property].day && date.month === this.selectedRangeDate[property].month && date.year === this.selectedRangeDate[property].year;
  }

  private _updateLimitsFromSelectedRange(isEmitOnRangeChange = true): void {
    if (this.selectedRangeDate && this.draggingArea?.nativeElement) {
      const startDate = this.dates?.find(date => this.isSelectedDate(date, 'start'));
      const endDate = this.dates?.find(date => this.isSelectedDate(date, 'end'));

      const areaWidth = (this.draggingArea.nativeElement.clientWidth - 28) / this.draggingArea.nativeElement.clientWidth;

      if (startDate) {
        this.leftLimitControl.setCurrentDate(startDate);
        this.leftLimitControl.position = (this.dates.indexOf(startDate) / (this.dates.length - 1)) * areaWidth * 100;
      }

      if (endDate) {
        this.rightLimitControl.setCurrentDate(endDate);
        this.rightLimitControl.position = ((this.dates.length - 1 - this.dates.indexOf(endDate)) / (this.dates.length - 1)) * areaWidth * 100;
      }

      if (startDate || endDate && isEmitOnRangeChange)
        this.onRangeChange.emit(this.selectedRangeDate);

      this._cd.detectChanges();
    }
  }
}

class MouseDragging {
  public isDragging: boolean = false;
  public initialX: number = 0;
  public currentX: number = 0;
  public currentLimitControl: LimitControl;
  public otherLimitControl: LimitControl;
  public dates: { day: number, month: number, year: number }[];

  public get areaDraggingElement(): HTMLElement {
    return this.currentLimitControl?.element.parentElement;
  }

  public get distance(): number {
    const diff = this.currentX - this.initialX;
    return diff * (this.currentLimitControl.isLeft ? 1 : -1);
  }

  constructor(obj?: Partial<MouseDragging>) {
    Object.assign(this, obj ?? {});
  }

  public updateLimitControl(): void {
    if (this.distance != 0) {
      const element = this.currentLimitControl.element;
      const areaWidth = element.parentElement.clientWidth;
      const initialPositionPixel = this.currentLimitControl.initialPositionPixels;
      const maxPosition = areaWidth - this.otherLimitControl.offset - 28;
      let currentPositionPixel = Math.min(Math.min(Math.max(initialPositionPixel + this.distance, 0), areaWidth), maxPosition);
      this.currentLimitControl.position = Math.min(Math.max((currentPositionPixel * 100) / areaWidth, 0), 100);
      this._defineControlCurrentDate();
    }
  }

  private _defineControlCurrentDate() {
    const width = this.areaDraggingElement.clientWidth;
    const division = width / this.dates.length;
    const isLeft = this.currentLimitControl.isLeft;
    const elementOffset = this.currentLimitControl.offset;

    for(let i = 0; i < this.dates.length; i++) {
      const startX = i * division;
      const endX = (i + 1) * division;
      if (elementOffset > startX && elementOffset < endX) {
        const date = this.dates[isLeft ? i : (this.dates.length - i - 1)];
        this.currentLimitControl.setCurrentDate(date);
        break;
      }
    }
  }
}

class LimitControl {
  public position: number = 0;
  public element: HTMLElement;
  public isLeft: boolean = false;
  public isRight: boolean = false;
  public initialPositionPixels: number = 0;
  public currentDate: { date: Date };

  public get offset() {
    if (this.isLeft) {
      return this.element.offsetLeft;
    }
    else {
      return this.element.parentElement.clientWidth - this.element.offsetLeft - this.element.clientWidth;
    }
  }

  public get positionPixels() { return this.offset;}

  constructor(obj?: Partial<LimitControl>) {
    Object.assign(this, obj ?? {});
  }

  public setCurrentDate(date: { day: number, year: number, month: number }): void {
    this.currentDate = {
      date: new Date(date.year, date.month - 1, date.day)
    };
  }

  public clickedInside(targetElement: HTMLElement): boolean {
    return this.element.contains(targetElement);
  }
}
