import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap, Params, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { SvcAppSettings } from '../../settings/svc-app-settings';
import { SvcModule } from '../../settings/enums/svc-module.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard 
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _appSettings: SvcAppSettings,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, route.queryParamMap, state);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, childRoute.queryParamMap, state);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string, snapshotParams?: ParamMap, state?: RouterStateSnapshot): Observable<boolean>
    {
        // Check the authentication status
        return this._authService.check()
                   .pipe(
                       switchMap((authenticated) => {

                           // If the user is not authenticated...
                           if ( !authenticated )
                           {
                                if (location.hostname === 'localhost' || ![SvcModule.Notification, SvcModule.ExternalFeatures].includes(this._appSettings.module)) {
                                    // Redirect to the sign-in page
                                    const redirectAsp = snapshotParams?.get('redirectAsp');
                                    const redirectCore = snapshotParams?.get('redirectCore');
                                    this._router.navigate(['sign-in'], {
                                        queryParams: !redirectAsp && !redirectCore
                                            ? { redirectURL }
                                            : (redirectAsp ? { redirectAsp } : { redirectCore })
                                    });
                                }
                                else {
                                  alert('Your session has expired. Please login again.');
                                  const functionsToCall = ['angularNotificationUnauthorized','angularExternalFeaturesUnauthorized'];
                                  functionsToCall.forEach((funcName) => {
                                    if (window[funcName] && typeof window[funcName] === 'function')
                                      window[funcName]();
                                  });
                                }

                               // Prevent the access
                               return of(false);
                           }

                           if (this._authService.getExpiredToken()
                             && !['/expired-password', '/sign-out'].includes(state?.url)) {
                             this._router.navigate(['expired-password']);
                             return of(false);
                           }

                           // Allow the access
                           return of(true);
                       })
                   );
    }
}
