<div class="flex flex-col flex-auto">
  <!-- Form -->
  <form *ngIf="showInput" [formGroup]="chatForm">
    <div 
      class="flex items-start pb-3" 
      [ngClass]="{
        'pb-6 -mt-4 pl-10 w-[calc(100%+1rem)]': smallSize && isChild,
        'gap-2': smallSize,
        'gap-10': !smallSize
      }"
    >
      <div class="flex flex-col flex-auto" 
        [ngClass]="{ 
          'w-[calc(100%-76px)]': !isChild && smallSize && attachmentFiles?.length,
          'w-[calc(100%-48px)]': isChild && smallSize && attachmentFiles?.length
        }"
      >
        <svc-comments-input
          [placeholder]="placeholder | transloco"
          formControlName="message"
          [loading]="isCreatingCommentLoading"
          [buttonDisabled]="!commentIsValidToSend"
          (onTextChange)="validateSendButton()"
          (onButtonClicked)="sendMessage()"
        ></svc-comments-input>
        <div class="flex flex-wrap" 
          [ngClass]="{
            'w-[calc(100%+40px)]': smallSize && isChild,
            'w-[calc(100%+76px)]': smallSize && !isChild
          }"
        >
          <div class="attachment-item text-default" *ngFor="let attachment of attachmentFiles" [ngClass]="{ 'w-full mr-0': smallSize }">
            <div *ngIf="attachment.type != AttachmentFileType.RECORDED_AUDIO" class="attachment-file">
              <mat-icon class="text-current icon-size-4" svgIcon="mat_solid:attach_file"></mat-icon>
            </div>
            <div class="attachment-content">
              <span *ngIf="attachment.type != AttachmentFileType.RECORDED_AUDIO">
                {{ attachment.file.name }}
              </span>
              <audio *ngIf="attachment.type == AttachmentFileType.RECORDED_AUDIO" controls [ngClass]="{ 'w-full': smallSize }">
                <source [src]="attachment.file | blobToUrl">
              </audio>
            </div>
            <div class="attachment-remove">
              <mat-icon
                class="text-current icon-size-4 cursor-pointer"
                svgIcon="mat_solid:close"
                [matTooltip]="'Remover' | transloco"
                (click)="removeAttachment(attachment.file)"
              ></mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="flex" [ngClass]="smallSize ? 'gap-1' : 'gap-2.5'">
        <button type="button" class="mat-elevation-z1 no-hover-shadow mt-2" color="primary" [ngClass]="{ 'small-button': smallSize }" mat-mini-fab (click)="openAttachmentWindow()" [disabled]="isCreatingCommentLoading" *ngIf="!isChild || !smallSize">
          <mat-icon svgIcon="mat_solid:attach_file"></mat-icon>
        </button>

        <svc-audio-to-text-button
          #audioToText
          class="mt-2"
          [ngClass]="isChild && smallSize ? 'hidden' : 'block'"
          [small]="smallSize"
          [disabled]="isCreatingCommentLoading"
          [canChooseRecording]="true"
          (onText)="chatForm.controls.message.setValue($event)"
          (onAudioRecorded)="addFileToAttachments($event.file, AttachmentFileType.RECORDED_AUDIO)"
        ></svc-audio-to-text-button>

        <button 
          *ngIf="isChild && smallSize"
          type="button" 
          class="mat-elevation-z1 no-hover-shadow mt-2" 
          color="primary" 
          [ngClass]="{ 'small-button': smallSize }" 
          mat-mini-fab 
          [matMenuTriggerFor]="!audioToText.isRecording && audioToText.canChooseRecording ? matMenu : null"
          [disabled]="isCreatingCommentLoading"
          (click)="audioToText.isRecording ? audioToText.recordAudio() : (!audioToText.canChooseRecording && audioToText.recordAudio(ActionType.AUDIO_TO_TEXT))"
        >
          <mat-icon [svgIcon]="!audioToText.loading && audioToText.step == 'RECORDING' ? 'mat_solid:stop' : 'heroicons_solid:ellipsis-vertical'"></mat-icon>
        </button>

        <mat-menu #matMenu xPosition="before">
          <button mat-menu-item (click)="openAttachmentWindow()">
            <mat-icon svgIcon="mat_solid:attach_file" class="icon-size-4 text-default">
            </mat-icon>

            <p class="text-default">
              {{ 'Anexar' | transloco }}
            </p>
          </button>

          <button mat-menu-item (click)="audioToText.recordAudio(actionType.RECORD_AUDIO)">
            <mat-icon svgIcon="heroicons_solid:microphone" class="icon-size-4 text-default">
            </mat-icon>

            <p class="text-default">
              {{ 'Gravar áudio' | transloco }}
            </p>
          </button>

          <button mat-menu-item (click)="audioToText.recordAudio(actionType.AUDIO_TO_TEXT)">
            <mat-icon svgIcon="heroicons_solid:microphone" class="icon-size-4 text-default">
            </mat-icon>

            <p class="text=default">
              {{ 'Voz para texto' | transloco }}
            </p>
          </button>
        </mat-menu>
      </div>
    </div>
  </form>

  <!-- Messages -->
  <div 
    class="flex flex-col flex-auto w-full" 
    [ngClass]="{
      'px-4': smallSize && !isChild,
      'px-0': smallSize && isChild,
      'pl-4': !smallSize && !isChild
    }"
  >
    <svc-comments-message
      *ngFor="let message of messages"
      class="page-avoid-break"
      [class]="smallSize ? 'pb-6' : 'pb-4'"
      [message]="message"
      [configTypeId]="configTypeId"
      [parentMessage]="parentMessage"
      [applicationId]="applicationId"
      [smallSize]="smallSize"
      [siteId]="siteId"
      [external]="external"
      (onCommentRemoved)="commentRemoved(message)"
      (onCommentAdded)="onCommentAdded.emit($event)"
    ></svc-comments-message>
  </div>

  <div *ngIf="!messagesAreGone" class="flex pb-2 mt-0" [ngClass]="{ 'justify-center': !isChild || isLoading }">
    <svc-button *ngIf="!isLoading" mode="simple" [size]="isChild ? 'sm' : 'md'" (click)="nextPage()">
      {{ (isChild ? 'Carregar mais respostas' : 'Carregar mais comentários') | transloco }}
    </svc-button>
    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
  </div>
</div>
