<div class="w-full">
  <form>
    <div class="flex flex-col pb-3">
      <mat-form-field class="svc-mat-no-subscript svc-mat-dense mat-new-disable w-[400px] max-w-[400px]">
        <mat-label>{{ "Área" | transloco }}</mat-label>
        <input
          type="input"
          matInput
          [formControl]="form.controls['area']"
          [disabled]="true"
        />
      </mat-form-field>
    </div>

    <div class="flex pb-3">
      <svc-chip
        [label]="'Responsável' | transloco"
        [icon]="''"
        [formControl]="form.controls['responsibles']"
        [options]="listPermission"
        [type]="'multiple'"
        [disabled]="true"
        [style.maxWidth.px]="400"
      ></svc-chip>
    </div>

    <div class="flex space-x-2" *ngIf="listPermission.length > 0">
      <svc-button type="button" mode="stroked" color="primary" (click)="onClear()">
        {{ 'Limpar' | transloco }}
      </svc-button>

      <svc-button *ngIf="isEditing" (click)="confirmDeletion()" mode="flat" color="warn">
        {{ 'Remover' | transloco }}
      </svc-button>

      <svc-button type="button" mode="flat" color="primary" (click)="onSubmit()">
        {{ 'Alterar' | transloco }}
      </svc-button>
    </div>
  </form>

  <ng-container *ngIf="loading">
    <svc-common-loader class="over-layer"></svc-common-loader>
  </ng-container>
</div>
