import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from "@ngneat/transloco";
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { generateModuleMenuURL, getModuleMenuType } from 'projects/lib-shared-common/src/public-api';
import { Observable, ReplaySubject, catchError, filter, finalize, first, map, merge, of, shareReplay, switchMap, throwError } from 'rxjs';
import { StaticApplicationId } from '../../../Constants/static-application-id.enum';
import { ICONMENU } from './icon-menu-map';
import { Navigation, SvcDefaultNavigationItem, SvcMenuNavigation, SvcMenuNavigationItemType, SvcWorkspaceNavigationItem } from './navigation.types';
import { AuthService } from '../../../auth/auth.service';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { UserService } from '../../user/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private navigation: Navigation;
  public navigation$ = this._navigation.asObservable();
  private _aplicationId: StaticApplicationId;
  public error: any;
  public isLoading: boolean = true;

  private set _navigationCached(value: SvcMenuNavigation[]) {
    this._dataCacheService.set(`MENU_GROUP_${this._aplicationId}` as SvcCacheName, value, { hours: 4 });
  }
  private get _navigationCached(): SvcMenuNavigation[] {
    return this._dataCacheService.get(`MENU_GROUP_${this._aplicationId}` as SvcCacheName);
  }


  constructor(
    private _httpClient: HttpClient,
    private _translocoService: TranslocoService,
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
    private _userService: UserService,
    private _dataCacheService: SvcDataCacheService,
  ) {
    merge(
      this._authService.signIn$,
      this._authService.signOut$,
    ).subscribe(logged => {
      if (logged?.success) this.navigation = null;
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(aplicationId?: StaticApplicationId): Observable<Navigation> {
    const defaultSvc: any[] = [];
    const defaultSvcWorkspace: SvcWorkspaceNavigationItem[] = [];

    const defaultNavigation = {
      default: defaultSvc,
      workspace: defaultSvcWorkspace
    };

    if (!aplicationId) {
      this._navigation.next(defaultNavigation);
      return of(defaultNavigation);
    }

    this._aplicationId = aplicationId;
    if (this.navigation) {
      return of(this.navigation);
    }

    this.error = null;
    this.isLoading = true;
    let fromCached = false;
    return this._userService.user$.pipe(
      filter((user) => !!(user)),
      switchMap(() => {
        fromCached = !!(this._navigationCached);
        return fromCached
          ? of(this._navigationCached)
          : this._httpClient.get<SvcMenuNavigation[]>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}`)
      }),
      first(),
    ).pipe(
      map((items) => {
        if (!fromCached) this._navigationCached = items;

        const menuItems: SvcDefaultNavigationItem[] = this.mapMenuNavigationItem(items);
        const workspaceItems: SvcWorkspaceNavigationItem[] = this.mapMenuToSvcWorkspaceNavigationItem(items);

        this.navigation = {
          default: menuItems,
          workspace: workspaceItems
        };
        this._navigation.next(this.navigation);
        this.isLoading = false

        return this.navigation;
      }),
      catchError((error) => {
        this.error = error;
        this.isLoading = false;
        return throwError(() => error);
      }),
    );
  }
  /**
   * Get all navigation data
   */
  isAvailable(aplicationId: string): Observable<boolean> {
    return this._httpClient.get<boolean>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}/accessible`);
  }

  private mapMenuNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcDefaultNavigationItem[] {
    let navigationItem: SvcDefaultNavigationItem[] = [];

    menuItems.forEach((item) => {
      navigationItem.push(<SvcDefaultNavigationItem>{
        title: this._translocoService.translate(item.menuGroupName),
        icon: ICONMENU.getValue(item.menuGroupId) || '',
        children: this.hasChildren(item)
          ? item.menus.map((c) => {
            const itemType = c.menuCategoryProvider ? getModuleMenuType(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType]) : null;
            return {
              title: c.menuDesc,
              url: (itemType === 'link')
                ? generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
                : null,
            };
          })
          : [],
      });
    });

    return navigationItem;
  }

  private mapMenuToSvcWorkspaceNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcWorkspaceNavigationItem[] {
    let navigationItem: SvcWorkspaceNavigationItem[] = [];

    menuItems.forEach((item) => {
      if (this.hasChildren(item)) {
        item.menus.map((c) => {
          navigationItem.push({
            icon: ICONMENU.getValue(item.menuGroupId) || '',
            url: c.menuCategoryProvider ?
              generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
              : null,
            title: c.menuDesc,
            params: c.menuCategoryProvider.params
          });
        });
      }
    });
    return navigationItem;
  }

  private hasChildren(menuItem: SvcMenuNavigation): boolean {
    return !!menuItem.menus;
  }

}
