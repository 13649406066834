import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SitesService, UserService } from 'projects/lib-shared-core/src/public-api';
import { forkJoin, map, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvcControl } from 'projects/lib-shared-component/src/lib/svc-controls/svc-control';
import { ContentChange, QuillEditorComponent } from 'ngx-quill/lib/quill-editor.component';
import { Mention, MentionBlot } from 'quill-mention';
import { SvcCommentsService } from '../../services/svc-comments.service';
import { TranslocoService } from '@ngneat/transloco';
import { SvcCommentsChatComponent } from '../svc-comments-chat/svc-comments-chat.component';
import Quill from 'quill';
import _ from 'lodash';
import Block from 'quill/blots/block';
import { Delta } from 'quill/core';

Quill.debug('error');
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
Block.tagName = 'DIV';
Quill.register(Block, true);

export enum SvcCommentMentionType {
  USER = 1,
  TEAM = 2,
  HASHTAG = 3,
}

@Component({
  selector: 'svc-comments-input',
  templateUrl: './svc-comments-input.component.html',
  styleUrls: ['./svc-comments-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcCommentsInputComponent),
    multi: true
  }]
})
export class SvcCommentsInputComponent extends SvcControl implements OnInit {

  @Input() readOnly: boolean;
  @Input() placeholder: string;
  @Input() cursorForce: boolean;
  @Input() loading: boolean;
  @Input() buttonDisabled: boolean;

  @ViewChild('editor') editor: QuillEditorComponent;
  @Output() onTextChange = new EventEmitter<string>();
  @Output() onButtonClicked = new EventEmitter<void>();

  private quill: Quill;
  public mentionsSubscription: Subscription;
  public mentionList: any[];
  private mentionIsLoading = false;

  constructor(
    @Inject(forwardRef(() => SvcCommentsChatComponent))
    public parent: SvcCommentsChatComponent,
    private _userService: UserService,
    private _svcCommentsService: SvcCommentsService,
    private _sitesService: SitesService,
    private _transloco: TranslocoService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onContentChanged(change: ContentChange): void {
    this.onTextChange.emit(change?.html ?? '');
  }

  modules = {
    toolbar: false,
    clipboard: {
      matchVisual: false
    },
    mention: {
      minChars: 0,
      maxChars: 31,
      dataAttributes: ['id', 'value', 'denotationChar'],
      positioningStrategy: 'fixed',
      defaultMenuOrientation: 'bottom',
      allowedChars: /(^[A-zÀ-ÿ0-9]+)/,
      mentionDenotationChars: ['@', '#'],
      mentionListClass: 'mention-editor-list',
      onSelect: (item: any, insertItem: any) => {
        let mention = this.mentionList.find(m => m.id && m.id.toString() === item.id.toString())
        mention.denotationChar = item.denotationChar;
        insertItem(mention);
      },
      renderLoading: () => {
        return this._transloco.translate('Digite ao menos 3 caracteres');
      },
      source: async (searchTerm: string, renderList: any, mentionChar: string) => {
        this.mentionsSubscription?.unsubscribe();

        if (searchTerm.length < 3) {
          this.mentionIsLoading = false;
          this.setIsQuillMentionLoading();
          return;
        }

        this.mentionIsLoading = true;
        this.setIsQuillMentionLoading();
        if (mentionChar === '@') {
          this.mentionsSubscription = this.mentionUserAndTeam(searchTerm).pipe(
            tap((results: any) => {
              const mentions = [];
              const teamUsersGrouped = _.groupBy([
                ...results.usersResult.users as any,
                ...results.teamsResult.teams as any,
              ], 'site');
              for (const site of Object.keys(teamUsersGrouped).sort((a) => a === 'Multi-Tenance' ? 1 : -1)) {
                const list = teamUsersGrouped[site];
                mentions.push({
                  value: this._transloco.translate(site == 'Multi-Tenance' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                const grouped = _.groupBy(list, 'type');
                for (const type in grouped) {
                  const list = grouped[type];
                  mentions.push({
                    value: this._transloco.translate(type == 'user' ? 'Usuários' : 'Times'),
                    disabled: true,
                    target: 'level-2'
                  });
                  list.forEach((d) => mentions.push({
                    id: d.userId || d.teamId,
                    value: d.name,
                    type: type == 'user' ? SvcCommentMentionType.USER : SvcCommentMentionType.TEAM,
                  }));
                }
              }
              this.mentionList = mentions;
              renderList(this.mentionList, searchTerm);
            }),
          ).subscribe()
        }
        else {
          this.mentionsSubscription = this._svcCommentsService.getHashtags(searchTerm).pipe(
            tap(list => {
              const mentions = [];
              const hashtagsGrouped = _.groupBy(list.hashtags, 'isGlobal');
              for (const isGlobal in hashtagsGrouped) {
                const list = hashtagsGrouped[isGlobal];
                mentions.push({
                  value: this._transloco.translate(isGlobal == 'true' ? 'Global' : 'Planta'),
                  disabled: true,
                  target: 'level-1'
                });
                list.forEach((h) => mentions.push({
                  id: h.hashtagId,
                  value: h.name.replace('#', ''),
                  siteId: h.siteId
                }));
              }
              this.mentionList = mentions;
              renderList(this.mentionList, searchTerm);
            })
          ).subscribe();
        }
      }
    }
  }

  private setIsQuillMentionLoading() {
    const mentionLoading = document.querySelector('.ql-mention-list-container .ql-mention-loading');
    if (mentionLoading) {
      if (this.mentionIsLoading) {
        mentionLoading.innerHTML = this._transloco.translate('Pesquisando...');
      }
      else {
        mentionLoading.innerHTML = this._transloco.translate('Digite ao menos 3 caracteres');
      }
    }
  }

  get allMentions(): {
    id: any;
    value: string;
    denotationChar: string;
    isGlobal?: boolean;
    type?: string;
    siteId?: string;
  }[] {
    return this.deltas.filter(d => d.insert.hasOwnProperty('mention'))
      .map(d => d.insert['mention'])
  }

  get text() {
    return this.editor.quillEditor.getText();
  }

  get html() {
    return this.editor.quillEditor.root.innerHTML;
  }
  set html(html) {
    this.editor.quillEditor.root.innerHTML = html;
  }

  get deltas(): Delta {
    return this.editor.quillEditor.getContents();
  }
  set deltas(deltas: Delta) {
    this.editor.quillEditor.setContents(deltas, 'silent');
  }

  get mentions(): { type: SvcCommentMentionType, id: string }[] {
    return this.deltas.ops
      .filter(x => x.insert?.['mention']?.id != null)
      .map(x => {
        let id = x.insert?.['mention'].id;
        let type = parseInt(x.insert?.['mention'].type);
        return { type, id };
      });
  }

  focus() {
    setTimeout(() => {
      this.quill.focus();
    });
  }

  onEditorCreated(event: Quill) {
    this.quill = event;
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
  }

  addMention(type: SvcCommentMentionType, id: number | string, name: string) {
    const module = this.editor.quillEditor.getModule('mention') as any;
    module?.insertItem({
      denotationChar: "@",
      id: id,
      value: name,
      type: type,
    }, true);
  }

  private mentionUserAndTeam(name: string) {
    return forkJoin({
      usersResult: this._userService.getUsersInfo({
        name: name,
        active: true,
        pageIndex: 0,
        pageSize: 5
      }),
      teamsResult: this._userService.getTeamsInfo({
        name: name,
        active: true,
        pageIndex: 0,
        pageSize: 5
      })
    }).pipe(
      map((response) => {
        const currentSite = this._sitesService.currentSite;
        return {
          teamsResult: {
            page: response.teamsResult.page,
            teams: response.teamsResult.teams.map(t => ({
              type: 'team',
              teamId: t.teamId,
              name: t.name,
              siteId: t.siteId,
              site: t.siteName !== currentSite.siteName ? 'Multi-Tenance' : t.siteName
            })),
          },
          usersResult: {
            users: response.usersResult.users.map((u) => ({
              type: 'user',
              ...u,
              site: u.site !== currentSite.siteName ? 'Multi-Tenance' : u.site
            })),
          },
        };
      }),
    );
  }

}
