import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BaseLayoutComponent } from '../base-layout.component';
import { Navigation } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { Counters } from 'projects/lib-shared-core/src/lib/features/user/models/counters';
import { StaticApplicationId, tryToRedirectToAspFromQueryParamData, tryToRedirectToCoreFromQueryParamData } from 'projects/lib-shared-core/src/public-api';
import { takeUntil, tap } from 'rxjs';
import { MobileMenuType } from '../components/mobile-menu/mobile-menu.component';
import { ModalPraiseReceivedComponent } from '../../../../praise/modals/modal-praise-received/modal-praise-received.component';

@Component({
  selector: 'svc-workspace-layout',
  templateUrl: './solvace-workspace-layout.component.html',
  styleUrls: ['./solvace-workspace-layout.component.scss'],
})
export class SolvaceWorkspaceLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {

  protected navigation: Navigation;
  protected counters: Counters;

  protected windowWidth: number = window.innerWidth;

  /**
   * Constructor
   */
  constructor() {
    super();
    this._renderer.listen(window, 'resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    super.ngOnInit();

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    this._svcSidebarNavigationService.onOpen.subscribe(module => {
      this.mobileMenuClick('tasks');
    });
  }

  /**
   * AfterViewInit
   */
  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._activatedRoute.queryParamMap.pipe(
      tap((params) => {
        if (params.has('openMenuType')) {
          this.mobileMenuClick(params.get('openMenuType') as MobileMenuType);
        }
        if (params.has('redirectAsp')) {
          const redirectAsp = params.get('redirectAsp');
          if (tryToRedirectToAspFromQueryParamData(redirectAsp)) {
            this.hideApplication();
          }
        }
        if (params.has('redirectCore')) {
          const redirectCore = params.get('redirectCore');
          if (tryToRedirectToCoreFromQueryParamData(redirectCore)) {
            this.hideApplication();
          }
        }
        if (params.has('openNotificationModal')) {
          this._notificationIntegrationService.openIframeByPath(params.get('openNotificationModal'));
        }
      }),
    ).subscribe();
  }

  protected reloadUserNavigation(): void {
    if (!this.navigationIsLoading) {
      this._navigationService.get(StaticApplicationId.myworkspace).subscribe();
    }
  }

  private hideApplication() {
    const splashElement = document.body.querySelector('svc-splash-screen') as HTMLElement;
    if (splashElement) {
      splashElement.style.visibility = 'visible';
      splashElement.style.opacity = '1';
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  protected onKaizenResumeClicked() {
    this._router.navigate(['/board/main/kaizen']);
  }

  protected getCounters(value: Counters) {
    this.counters = { ...this.counters, ...value };
  }

  protected openPraiseReceived() {
    if (this.counters?.compliments > 0) {
      const userId = this._userService.userId$;
      this._dialogService.open(ModalPraiseReceivedComponent, {
        data: userId,
        width: '100%',
        panelClass: ['sm:p-3'],
        maxWidth: '794px',
        autoFocus: false,
        disableClose: true,
      });
    }
  }
}
