import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { BehaviorSubject, Observable, Subject, catchError, filter, finalize, map, of, switchMap, tap } from 'rxjs';
import { AvailableLanguages } from '../model/available-languages.model';
import { AuthService } from '../../../auth/auth.service';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private _authenticatedPendingRequest: Subject<AvailableLanguages[]>;
  private _termsPendingRequest: Subject<any>;

  public _languages = new BehaviorSubject<AvailableLanguages[]>([]);
  public languages$ = this._languages.asObservable();

  private set _authenticatedLanguagesCached(value: AvailableLanguages[]) {
    this._svcDataCacheService.set(SvcCacheName.AUTHENTICATED_LANGUAGES, value);
  }
  private get _authenticatedLanguagesCached() {
    return this._svcDataCacheService.get<AvailableLanguages[]>(SvcCacheName.AUTHENTICATED_LANGUAGES);
  }
  
  private set _authenticatedTermsCached(value: any) {
    this._svcDataCacheService.set(SvcCacheName.TERMS, value);
  }
  private get _authenticatedTermsCached() {
    return this._svcDataCacheService.get(SvcCacheName.TERMS);
  }

  private set _currentLanguageTagCached(value: any) {
    this._svcDataCacheService.set(SvcCacheName.LANGUAGE_TAG, value);
  }
  private get _currentLanguageTagCached() {
    return this._svcDataCacheService.get(SvcCacheName.LANGUAGE_TAG);
  }

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _svcDataCacheService: SvcDataCacheService,
  ) {}

  public getAll(): Observable<AvailableLanguages[]> {
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          if (this._authenticatedLanguagesCached) {
            return of(this._authenticatedLanguagesCached);
          }
          else if (!this._authenticatedPendingRequest) {
            this._authenticatedPendingRequest = new Subject();
            this._httpClient.get<AvailableLanguages[]>(`${this._appConfig.APIs.apiUrlAuth}/Languages/authenticated`).pipe(
              tap((languages) => {
                this._authenticatedLanguagesCached = languages;
                this._authenticatedPendingRequest.next(languages);
              }),
              catchError((error) => {
                this._authenticatedPendingRequest.error(error);
                return error;
              }),
              finalize(() => {
                this._authenticatedPendingRequest.complete();
                this._authenticatedPendingRequest = null;
              }),
            ).subscribe();
          }
          return this._authenticatedPendingRequest;
        } else {
          return this._httpClient.get<AvailableLanguages[]>(`${this._appConfig.APIs.apiUrlAuth}/Languages`);
        }
      }),
      tap((languages) => {
        this._languages.next(languages);
      })
    );
  }

  public getTerms(lang: string): Observable<any> {
    this.checkIfCurrentLanguageTag(lang);
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          if (this._authenticatedTermsCached) {
            return of(this._authenticatedTermsCached);
          }
          else if (!this._termsPendingRequest) {
            this._termsPendingRequest = new Subject();
            this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlAuth}/Languages/terms/authenticated`).pipe(
              tap((terms) => {
                this._authenticatedTermsCached = terms;
                this._termsPendingRequest.next(terms);
              }),
              catchError((error) => {
                this._termsPendingRequest.error(error);
                return error;
              }),
              finalize(() => {
                this._termsPendingRequest.complete();
                this._termsPendingRequest = null;
              }),
            ).subscribe();
          }
          return this._termsPendingRequest;
        }
        else {
          const langId = (this._languages?.value ?? this._authenticatedLanguagesCached)?.find((l) => l.languageTag === lang)?.languageId ?? 2;
          return this._httpClient.get<any>(`${this._appConfig.APIs.apiUrlAuth}/Languages/terms/${langId}`);
        }
      }),
    );

  }

  public updateUserLanguage(lang: string): Observable<boolean> {
    this._svcDataCacheService.remove(SvcCacheName.TERMS);
    const langId = this._authenticatedLanguagesCached?.find((l) => l.languageTag === lang)?.languageId ?? 2;
    return this._httpClient.put<boolean>(`${this._appConfig.APIs.apiUrlAuth}/UserPreference/change-language`, { languageId: langId }).pipe(
      map((response: any) => {
        if (response.isSucess) {
          this._authenticatedTermsCached = null;
        }
        return response.isSucess;
      })
    );
  }

  private checkIfCurrentLanguageTag(currentLanguageTag: string) {
    this._authService.check().pipe(
      filter((authenticated) => authenticated),
      tap(() => {
        const cachedLanguageTag = this._currentLanguageTagCached;
        if (!cachedLanguageTag) {
          this._currentLanguageTagCached = currentLanguageTag;
        }
        else if (currentLanguageTag && cachedLanguageTag && currentLanguageTag !== cachedLanguageTag) {
          this._authenticatedTermsCached = null;
          this._currentLanguageTagCached = currentLanguageTag;
        }
      }),
    ).subscribe();
  }
}
