import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { SvcCustomFieldAdd, SvcCustomFieldList, SvcCustomFieldTypeResponse } from './svc-custom-field.interface';
import { SvcCustomFieldHttpClient } from './svc-custom-field-http-client';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';

@Injectable()
export class SvcCustomFieldService {

  constructor (
    private _http: SvcCustomFieldHttpClient,
  ) { }

  public getCustomFieldTypes(): Observable<ISvcSelectOption[]> {
    return this._http.get<SvcCustomFieldTypeResponse[]>('/CustomFieldType').pipe(
      map((types: SvcCustomFieldTypeResponse[]) =>
        types?.map(type => (
          {
            text: type.fieldType,
            value: type.fieldTypeId
          }
        ))
      )
    );
  }

  public changeCustomFields(customFieldRequest: SvcCustomFieldAdd): Observable<boolean> {
    return this._http.post('/CustomField', {
      ...customFieldRequest,
      applicationId: this._http.applicationId,
      siteId: this._http.siteId
    });
  }

  public getCustomField(featureName: string, registryId: number): Observable<SvcCustomFieldList> {
    return this._http.get('/CustomField', {
      params: {
        applicationId: this._http.applicationId,
        siteId: this._http.siteId,
        featureName,
        registryId
      }
    });
  }
}
