import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'svc-dialog-config',
  templateUrl: './svc-dialog-config.component.html',
  styleUrls: ['./svc-dialog-config.component.scss']
})
export class SvcDialogConfigComponent {

  @Input() fullscreen: string | string[] = null;
  @Input() actionsAlign: 'start' | 'center' | 'end' = 'end';
  @Output()
  onDialogClose: EventEmitter<void> = new EventEmitter<void>();

  public inFullscreen: boolean = false;

  closeDialog() {
    this.onDialogClose.emit();
  }
}
