import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvcControl } from '../svc-control';

@Component({
  selector: 'svc-text',
  templateUrl: './svc-text.component.html',
  styleUrls: ['./svc-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SvcTextComponent),
    multi: true
  }],
})
export class SvcTextComponent extends SvcControl implements OnInit {
  @Input() label: string | { text: string, icon: string };
  @Input() isObscure: boolean = false;
  @Input() type: string = this.isObscure ? 'password' : 'text';
  @Input() icon: string;
  @Input() readonly: boolean = false;
  @Input() placeholder: string;
  @Input() inputId: string;
  @Input() maxLength: string | number;
  @Input() autocomplete = 'on';
  @Input() mask: string;

  @Output() ngModelChange = new EventEmitter<any>();

  constructor(@Inject(Injector) injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected onValueChanged(value): void {
    this.ngModelChange.emit(value);
  }
}
