import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AlertAttachment, AlertAttachmentRemoveReponse } from '../interfaces/alert-attachment.interface';
import { SitesService } from 'projects/lib-shared-core/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AlertAttachmentService {

  private readonly _apiUrlAlert: string = this._appConfig.APIs.apiUrlAlert;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient,
    private _sitesService: SitesService
  ) { }

  private getFormData(alertId: number, files: File[]): FormData {
    const formData: FormData = new FormData();

    formData.append('alertId', alertId.toString());
    formData.append('siteId', this._sitesService.currentSite.siteId.toString());

    files?.forEach((file: File) =>
      formData.append('files', file)
    );

    return formData;
  }

  public updateAlertAttachment(alertId: number, files: File[]): Observable<AlertAttachment> {
    return this._http.put<AlertAttachment>(`${this._apiUrlAlert}/Attachment`, this.getFormData(alertId, files));
  }

  public removeAlertAttachment(alertId: number, attachmentId: number): Observable<AlertAttachmentRemoveReponse> {
    return this._http.delete<AlertAttachmentRemoveReponse>(`${this._apiUrlAlert}/Attachment`, {
      params: {
        alertId,
        attachmentId,
        siteId: this._sitesService.currentSite.siteId
      }
    })
  }
}
