import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceDefecttagAnalyticsService } from './lib-service-defecttag-analytics.service';


@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        ModuleHttpClient,
        LibServiceDefecttagAnalyticsService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class LibServiceDefecttagAnalyticsModule { }
