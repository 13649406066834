<div #modal class="flex flex-col flex-auto bg-white" *transloco="let t" [matDialogFullscreen]="fullscreen">
  <div #titleContainer mat-dialog-title class="flex items-center justify-between text-on-primary bg-primary mb-0 px-4 py-2">
    <ng-content select="[svc-dialog-title]"></ng-content>

		<button class="close-button -mr-1 print:hidden" mat-icon-button (click)="closeDialog()">
			<mat-icon class="text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
		</button>

  </div>

  <div *screenSizes="let size" class="flex-auto overflow-auto" [style.height]="needInternalScroll && scrollInsideContent ? 'calc(100vh - ' +  ((titleContainer?.offsetHeight ?? 0) + (actionsContainer?.offsetHeight ?? 0)) + 'px)' : null">
    <ng-content select="[svc-dialog-content]" mat-dialog-content></ng-content>
  </div>

  <div #actionsContainer mat-dialog-actions align="end" class="bg-primary text-on-primary p-4">

    <ng-content select="[svc-dialog-actions-options]">
    </ng-content>

    <ng-container [ngSwitch]="dialogStyle">

      <div *ngSwitchCase="'form'" class="flex flex-row gap-2">
        <!-- Menu button -->
        <ng-container *ngIf="hasItemsToShowMenu">
          <button mat-icon-button [matMenuTriggerFor]="matMenuActions" aria-label="Ações na visualização quando for administrador">
            <mat-icon class="text-current" [svgIcon]="'heroicons_solid:ellipsis-vertical'"></mat-icon>
          </button>
          <mat-menu [xPosition]="'before'" #matMenuActions="matMenu">
            <button mat-menu-item (click)="remove()" *ngIf="showMatMenu?.delete">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
              <span class="text-base">{{ t('Remover') }}</span>
            </button>
            <button mat-menu-item (click)="resend()" *ngIf="showMatMenu?.resend">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:arrow-path'"></mat-icon>
              <span class="text-base">{{ t('Reenviar') }}</span>
            </button>
            <button mat-menu-item (click)="reschedule()" *ngIf="showMatMenu?.reschedule">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:clock'"></mat-icon>
              <span class="text-base">{{ t('Reprogramar') }}</span>
            </button>
            <button mat-menu-item (click)="close()" *ngIf="showMatMenu?.close">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
              <span class="text-base">{{ t('Encerrar') }}</span>
            </button>
            <button mat-menu-item (click)="setEditMode()" *ngIf="showMatMenu?.edit">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
              <span class="text-base">{{ t('Editar') }}</span>
            </button>
            <button mat-menu-item (click)="view()" *ngIf="showMatMenu?.view">
              <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
              <span class="text-base">{{ t('Ver') }}</span>
            </button>
          </mat-menu>
        </ng-container>

				<svc-button color="on-primary" mode="stroked" type="button" (click)="closeDialog()" [disabled]="disableCancel">
          {{textCancel ?? t('Cancelar')}}
        </svc-button>

        <svc-button color="on-primary" mode="flat" type="button" (click)="confirmed.emit()" cdkFocusInitial [disabled]="disableConfirm" [processing]="processing">
          {{textConfirm ?? t('Salvar')}}
        </svc-button>
      </div>

      <div *ngSwitchCase="'close'">
        <svc-button color="on-primary" mode="flat" type="button" cdkFocusInitial (click)="closeDialog()" [disabled]="disableCancel">
          {{textCancel ?? t('Fechar')}}
        </svc-button>
      </div>
    </ng-container>

  </div>

</div>
