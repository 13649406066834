import {SvcFilterField} from "./svc-filter-field";

export interface SvcFilterConfig {
  title?: string;
  filtersField?: SvcFilterField[];
  loading?: boolean;
  fieldsPerRow?: {
    xs: number,
    sm: number,
    md: number,
    xl: number,
    lg: number,
  }
}
