<div class="right-10 z-99" [ngClass]="[position, position === 'absolute' ? 'bottom-10' : 'bottom-20 md:bottom-10']">
  <button
    class="bg-primary text-on-primary min-w-15 min-h-15 w-15 h-15"
    mat-fab
    (click)="onClick()"
    [id]="buttonId"
  >
    <mat-icon
      class="icon-size-button"
      svgIcon="heroicons_solid:plus-small"
    ></mat-icon>
  </button>
</div>
