import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleHttpClient } from './module-http-client';
import { LibServiceActionplanAnalyticsService } from './lib-service-actionplan-analytics.service';


@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        ModuleHttpClient,
        LibServiceActionplanAnalyticsService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class LibServiceActionplanAnalyticsModule { }
