import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, forwardRef } from '@angular/core';
import { SvcTreeEntryComponent } from '../svc-tree-entry/svc-tree-entry.component';
import { SvcTreeItem, SvcTreeOrientation } from '../svc-tree.component';

@Component({
  selector: 'svc-tree-branch',
  templateUrl: './svc-tree-branch.component.html',
  styleUrls: ['./svc-tree-branch.component.scss'],
  host: {
    '[class.more-than-one-entry]': 'input?.children?.length > 1'
  }
})
export class SvcTreeBranchComponent implements OnInit, OnDestroy {

  @HostBinding('class.svc-tree-branch') protected svcClass = true;
  @HostBinding('class.is-vertical') protected isVertical = true;
  @HostBinding('class.is-horizontal') protected isHorizontal = false;

  @Input() input: SvcTreeItem<any>;
  @Input('orientation') set setOrientation(value: SvcTreeOrientation) {
    this.orientation = value;
    this.#onOrientationChanged(value);
  }

  protected orientation = SvcTreeOrientation.VERTICAL;

  constructor(
    @Optional()
    @Inject(forwardRef(() => SvcTreeEntryComponent))
    public parentEntry: SvcTreeEntryComponent,
  ){}

  public ngOnInit(): void {
  }

  #onOrientationChanged(orientation: SvcTreeOrientation) {
    this.isVertical = orientation === SvcTreeOrientation.VERTICAL;
    this.isHorizontal = orientation === SvcTreeOrientation.HORIZONTAL;
  }

  public ngOnDestroy(): void {
  }
}
