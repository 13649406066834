import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ModuleHttpClient } from './module-http-client';
import { LibServiceKaizenAnalyticsService } from './lib-service-kaizen-analytics.service';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        ModuleHttpClient,
        LibServiceKaizenAnalyticsService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class LibServiceKaizenAnalyticsModule { }
