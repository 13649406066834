<!-- Toast manager -->
<svc-toast></svc-toast>

<!-- Loading bar -->
<loading-bar></loading-bar>

<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <div class="flex flex-row flex-auto overflow-x-auto">
    <!-- Navigation -->
    <vertical-navigation class="bg-primary lg:z-49 overflow-auto text-on-primary" *screenSizes="let size" [mode]="size.isAboveSM ? 'side' : 'over'" [opened]="size.isAboveSM" [retracted]="true">
      <!-- Navigation header hook -->
      <ng-container verticalNavigationContentHeader>
        <div class="flex flex-row items-center pt-6 pb-3">
          <div class="absolute md:hidden top-0 right-0">
            <button mat-icon-button (click)="toggleNavigation()">
              <mat-icon class="text-on-primary-400 icon-size-4" [svgIcon]="'mat_solid:close'"></mat-icon>
            </button>
          </div>

          <!-- User -->
          <div class="user-avatar relative shrink-0 text-on-primary">
            <svc-user-avatar
              [info]="{ name: user.firstLastName, picture: user.pictureFile, initialsColor: user.initialsColor, size: 46}"
            ></svc-user-avatar>
          </div>
          <div *ngIf="!verticalNavigation?.isRetracted" class="flex flex-col items-center ml-2 overflow-hidden user-info">
            <div
              class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-base leading-normal font-medium text-on-primary">
              {{ user.firstLastName }}
            </div>
            <div
              class="w-full -mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-xs leading-normal text-on-primary">
              {{ user.email }}
            </div>
          </div>
        </div>

        <div *ngIf="navigation?.default.length" class="flex flex-col">
          <div *ngFor="let item of navigation.default" class="menu-item" [ngClass]="{ 'expanded': item.expanded }">
            <div class="menu-item-header flex flex-row justify-center" (click)="toggleCurrentNavigationItem(item)">
              <div class="flex items-center" [ngClass]="{ 'pr-3': !verticalNavigation?.isRetracted }">
                <mat-icon class="icon-size-5 text-current" [svgIcon]="item.icon"></mat-icon>
              </div>
              <div *ngIf="!verticalNavigation?.isRetracted" class="flex flex-auto items-center text-sm font-bold">
                {{ item.title | transloco }}
              </div>
              <div *ngIf="!verticalNavigation?.isRetracted" class="flex items-center pl-2">
                <mat-icon class="icon-size-5 text-current"
                  [svgIcon]="item.expanded ? 'mat_solid:keyboard_arrow_up' : 'mat_solid:keyboard_arrow_down'"></mat-icon>
              </div>
            </div>
            <div class="menu-child-content overflow-hidden"
              [style.height.px]="(item.expanded && !verticalNavigation?.isRetracted) ? childrenInnerContent.clientHeight : 0">
              <div #childrenInnerContent class="flex flex-col pt-2">
                <div *ngFor="let childItem of item.children" class="menu-child text-sm">
                  <a [routerLink]="[childItem.url]" routerLinkActive="active" class="block p-1"
                    (click)="toggleNavigation()">
                    {{ childItem.title | transloco }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container verticalNavigationFooter>
        <div class="flex flex-col items-center justify-center mt-2 mb-4 divide-y divide-on-primary">
          <div class="flex mb-3" *ngIf="this.navigation.default.length">
            <!-- Navigation toggle appearance button -->
            <button *ngIf="!size.isBelowMD" class="hidden md:inline-flex" mat-icon-button
              (click)="toggleNavigationRetraction()">
              <mat-icon class="text-current icon-size-7" svgIcon="heroicons_solid:bars-3"></mat-icon>
            </button>
          </div>
          <div class="flex px-2 pt-3">
            <img *ngIf="!verticalNavigation?.isRetracted" class="max-w-40"
              src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg" />
            <img *ngIf="verticalNavigation?.isRetracted" class="max-w-40"
              src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-simple-ng.svg" />
          </div>
        </div>
      </ng-container>
    </vertical-navigation>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto w-full min-w-0 overflow-y-auto" *screenSizes="let size">
      <!-- Header -->
      <div class="relative flex flex-0 items-center w-full h-16 z-49 shadow-sm border-b bg-card">
        <div class="flex w-full px-4 md:px-6">
          <div class="flex items-center pr-2 space-x-0.5 sm:space-x-2 md:space-x-0">
            <!-- Navigation toggle button -->
            <button class="inline-flex md:hidden" mat-icon-button (click)="toggleNavigation()">
              <mat-icon [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
            </button>

            <a [href]="[urlHome]">
              <img class="max-w-28" [src]="environment.logoImage"/>
            </a>
          </div>
          <!-- Components -->
          <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 text-primary">
            <svc-notifications></svc-notifications>
            <ng-container *ngIf="!size.isBelowMD">
              <sites></sites>
            </ng-container>
            <user [showAvatar]="!size.isBelowMD" [showSites]="size.isBelowMD"></user>
            <button *ngIf="!size.isBelowLG" mat-icon-button (click)="appsPanel.toggle()">
              <mat-icon class="text-current" [svgIcon]="'mat_solid:apps'"></mat-icon>
            </button>
            <!-- <settings></settings> -->
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="svc-custom-layout-content flex-auto w-full overflow-y-auto">
        <router-outlet *ngIf="true"></router-outlet>
        <svc-copilot-float-button *ngIf="copilotEnabled" class="absolute left-10 bottom-7 z-99 hidden lg:flex"></svc-copilot-float-button>
      </div>

      <div class="inset-0 flex flex-col min-w-0 overflow-hidden">
        <apps-panel #appsPanel="appsPanel"></apps-panel>
      </div>
    </div>
  </div>
  <mobile-menu
    (menuClick)="mobileMenuClick($event)"
    [totalTasks]="sidebarNavigationComponent?.totalTasks"
    [loadingTasks]="sidebarNavigationComponent?.loadingTasks ?? true"
    [totalFeeds]="sidebarNavigationComponent?.totalFeeds"
    [loadingFeeds]="sidebarNavigationComponent?.loadingFeeds ?? true"
  ></mobile-menu>
  <sidebar-navigation class="absolute top-0 bottom-0 right-0"
    [class.h-auto]="!sidebarNavigationComponent?.opened"
    #sidebarNavigationComponent
    mode="over"
    [opened]="false"
  ></sidebar-navigation>
</div>
