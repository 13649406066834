export function generateDatePickerFormat(dateFormat: string): string {
	return (dateFormat === 'DDMMYYYY') ? 'dd/MM/yyyy' : dateFormat === 'YYYYMMDD' ? 'yyyy-MM-dd' : 'MM/dd/yyyy';
}

export function generateJsonDateFromString(strDate: string, dateFormat: string): Date | null {
	if (strDate) {
		const arrDate: string[] = strDate.split('/');
		if (dateFormat == 'DDMMYYYY') {
			return new Date(parseInt(arrDate[2]), (parseInt(arrDate[1]) - 1), parseInt(arrDate[0]));
		}
		else if (dateFormat == 'MMDDYYYY') {
			return new Date(parseInt(arrDate[2]), (parseInt(arrDate[0]) - 1), parseInt(arrDate[1]));
		}
	}
	return null;
}

export function generateJsonDateTimeFromString(strDate: string, strTime: string, dateFormat: string): Date | null {
	if (strDate && strTime) {
		const arrDate: string[] = strDate.split('/');
		const arrTime: string[] = strTime.split(':');
		if (dateFormat == 'DDMMYYYY') {
			return new Date(parseInt(arrDate[2]), (parseInt(arrDate[1]) - 1), parseInt(arrDate[0]), parseInt(arrTime[0]), parseInt(arrTime[1]), parseInt(arrTime[2]));
		}
		else if (dateFormat == 'MMDDYYYY') {
			return new Date(parseInt(arrDate[2]), (parseInt(arrDate[0]) - 1), parseInt(arrDate[1]), parseInt(arrTime[0]), parseInt(arrTime[1]), parseInt(arrTime[2]));
		}
	}
	return null;
}

export function dateIsValid(dateStr: string, format: string = 'dd/MM/yyyy'): boolean {
	return !!(dateStringToDate(dateStr, format)?.toJSON());
}

export function convertDateStrToSpecificFormat(dateStr: string, fromFormat: string, toFormat: string): { date: string, time: string } {
	if (dateStr?.length == 10) dateStr += 'T00:00:00';
	if (dateStr && (dateStr.length > 10)) {
		const fromFormatParts = fromFormat.split(/[ :\-\/]/g);
		const fromDateParts = dateStr.split(/[ :\-\/T]/g);
		let day: number, month: number, year: number;
		let hours = 0, minutes = 0, seconds = 0;
		const timeStr = dateStr.includes('T') ? dateStr.split('T')[1] : '';
		dateStr = dateStr ? dateStr.split('T')[0] : dateStr;
		for (let i = 0; i < fromFormatParts.length; i++) {
			const formatPart = fromFormatParts[i].toLowerCase();
			const datePart = parseInt(fromDateParts[i]);

			if (formatPart.includes('d')) {
				day = datePart;
				continue;
			}
			if (formatPart.includes('m')) {
				month = datePart;
				continue;
			}
			if (formatPart.includes('y')) {
				year = datePart;
				continue;
			}
		}

    const toSeparator = toFormat.replace(/[aA-zZ]/g, '')[0] ?? '';
		const toFormatParts = toFormat.split(/[ :\-\/]/g);
		let dateArray = [];
		for (const part of toFormatParts) {
			const formatPart = part.toLocaleLowerCase();
			if (formatPart.includes('d')) {
				dateArray.push(day.toString().padStart(2, '0'));
				continue;
			}
			if (formatPart.includes('m')) {
				dateArray.push(month.toString().padStart(2, '0'));
				continue;
			}
			if (formatPart.includes('y')) {
				dateArray.push(year);
				continue;
			}
		}

		if (timeStr) {
			const timeSplitted = timeStr.split(':');
			hours = parseInt(timeSplitted[0]);
			minutes = parseInt(timeSplitted[1]);
			seconds = parseInt(timeSplitted[2]);
		}

		const date = dateArray.join(toSeparator);
		const time = `${hours.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		return { date, time };
	}
	return { date: dateStr, time: '00:00:00' };
}

export function dateStringToDate(dateStr: string, format: string = 'dd/MM/yyyy'): Date {
	if (dateStr?.length == 10) dateStr += 'T00:00:00';
	if (dateStr && (dateStr.length > 10 && format !== 'yyyy/MM/dd')) {
		const formatParts = format.split(/[ :\-\/]/g);
		const dateParts = dateStr.split(/[ :\-\/T]/g);
		let day: number, month: number, year: number;
		let hours = 0, minutes = 0, seconds = 0;
		const timeStr = dateStr.includes('T') ? dateStr.split('T')[1] : '';
		dateStr = dateStr ? dateStr.split('T')[0] : dateStr;
		for (let i = 0; i < formatParts.length; i++) {
			const formatPart = formatParts[i].toLowerCase();
			const datePart = parseInt(dateParts[i]);

			if (formatPart.includes('d')) {
				day = datePart;
				continue;
			}
			if (formatPart.includes('m')) {
				month = datePart;
				continue;
			}
			if (formatPart.includes('y')) {
				year = datePart;
				continue;
			}
		}

		if (timeStr) {
			const timeSplitted = timeStr.split(':');
			hours = parseInt(timeSplitted[0]);
			minutes = parseInt(timeSplitted[1]);
			seconds = parseInt(timeSplitted[2]);
		}

		let date = new Date(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}` + 'T' +
			`${hours.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
		return date;
	}
	if (dateStr && dateStr.length > 10 && format === 'yyyy/MM/dd') {
		return new Date(dateStr);
	}
	return null;
}

export function getBeforeYears(startYear: number, range: number): number[] {
	const yearsArray: number[] = [];
	for (let i = range; i > 0; i--) {
		yearsArray.push(startYear - i);
	}
	return yearsArray;
}

export function getNextYears(startYear: number, range: number): number[] {
	const yearsArray: number[] = [];
	for (let i = 1; i <= range; i++) {
		yearsArray.push(startYear + i);
	}
	return yearsArray;
}