export const SVC_ACTION_PLAN_MESSAGES = new Map<string, string>([
  ['InsertedPlanCallback', 'Plano incluído com sucesso'],
  ['UpdatedPlanCallback', 'Alteração salva com sucesso'],
  ['DeletedPlanCallback', 'Plano removido com sucesso'],
  ['ReschedulatedPlanCallback', 'Reprogramação realizada com sucesso'],
  ['EscalatedPlanCallback', 'Plano escalado com sucesso'],
  ['EndedPlanCallback', 'Plano finalizado com sucesso'],
  ['ApprovedPlanCallback', 'Plano aprovado com sucesso'],
  ['ReopenedPlanCallback', 'Plano reaberto com sucesso'],
  ['CancelPlanCallback', 'Plano cancelado com sucesso'],
  ['UndoCancelPlanCallback', ''],
  ['DisapprovedPlanCallback', ''],
  ['InsertedFeedCallback', ''],
  ['DeletedFeedCallback', ''],
]);
