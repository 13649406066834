<!-- Button -->
<button *ngIf="!onlyImage" type="button" mat-stroked-button [matMenuTriggerFor]="languages" [disabled]="isLoading">
  <span class="mr-2">{{ activeLang.label }}</span>
  <mat-icon iconPositionEnd class="icon-size-3" [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
</button>

<!-- <button mat-icon-button [matMenuTriggerFor]="languages" *ngIf="onlyImage">
  <ng-container
    *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"
  ></ng-container>
</button> -->

<!-- Button -->
<button *ngIf="onlyImage" type="button" mat-icon-button [matMenuTriggerFor]="languages" [disabled]="isLoading">
  <ng-container
    *ngTemplateOutlet="iconImage; context: { $implicit: activeLang }"
  ></ng-container>
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
  <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
    <button mat-menu-item (click)="setActiveLang(lang.id)">
      <span class="flex items-center">
        <!-- <ng-container
          *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"
        ></ng-container> -->
        <span class="ml-3">{{ lang.label }}</span>
      </span>
    </button>
  </ng-container>
</mat-menu>

<div *ngIf="isLoading" class="flex justify-end items-center absolute inset-0 z-99 pr-3">
  <div class="absolute inset-0 bg-white opacity-70"></div>
  <mat-spinner diameter="18" class="text-current"></mat-spinner>
</div>

<!-- Flag image template -->
<ng-template let-lang #flagImage>
  <span class="relative w-6 shadow rounded-sm overflow-hidden">
    <span
      class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"
    ></span>
    <img
      class="w-full"
      [src]="'./../../../../assets/flags/' + flagCodes[lang].toUpperCase() + '.svg'"
      [alt]="'Flag image for ' + lang"
    />
  </span>
</ng-template>

<!-- Flag image template -->
<ng-template let-lang #iconImage>
  <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
    <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-500 text-indigo-50 text-xs font-medium">
        {{ lang.id }}
    </span>
  </span>
  <mat-icon
    class="text-current"
    [svgIcon]="'mat_solid:translate'"
  ></mat-icon>
</ng-template>
