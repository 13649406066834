import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SvcPivotTable, SvcPivotTableFilter } from '../interfaces/svc-pivot-table.interface';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { SvcPivotTableHttpService } from './svc-pivot-table-http.service';

@Injectable()
export class SvcPivotTableService {

  constructor (
    private _userService: UserService,
    private _appSettings: SvcAppSettings,
    private _http: SvcPivotTableHttpService
  ) { }

  public getRequestDownloadList(filter: SvcPivotTableFilter): Observable<SvcPivotTable> {
    return this._http.get('/RequestDownload/List', {
      params: {
        ...filter,
        siteId: this._userService.user.lastSiteId,
        applicationId: this._appSettings.applicationId
      }
    });
  }
}
