import { ISvcCloudDataPosition } from './svc-cloud-data-position';
export interface ISvcCloudData {
  text: string;
  weight: number;
  link?: string;
  external?: boolean;
  color?: string;
  rotate?: number;
  tooltip?: string;
  position?: ISvcCloudDataPosition;
}
