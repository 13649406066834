export enum UserAvatarSize {
  Workspace = -1,
  CustomLayout = 46,
  Profile = 200,
  Header = 24,
  Table = 24,
  Comment = 32,
  Analytics = 40,
  Feed = 34,
  Notification = 34,
  Posts = 48,
  Card = 48,
  RankingFirst = 130,
  RankingOthers = 92,
  Ranking = 28,
  SmallCard = 16,
  Copilot = 32
}

export interface ISvcUserAvatar {
  name: string;
  picture?: string;
  size: UserAvatarSize;
  initialsColor?: string;
  type?: 1 | 2 | 3;
}
