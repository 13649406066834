<svc-dialog class="block" dialogStyle="empty" [style.minWidth.px]="400">
	<div svc-dialog-title>
		{{'Local' | transloco}}
	</div>

	<div svc-dialog-content class="px-5 py-5">
		<form [formGroup]="form" *ngIf="!isLoading">
			<svc-select formControlName="departmentId" [label]="'Departamento' | transloco" [options]="departments" class="block mb-3"></svc-select>
			<svc-select formControlName="areaId" [label]="'Área' | transloco" [options]="areas" [loading]="isAreasLoading" class="block mb-3"></svc-select>
			<svc-select formControlName="locationId" [label]="'Localização' | transloco" [options]="locations" [loading]="isLocationsLoading" class="block mb-3"></svc-select>
		</form>
		<div *ngIf="isLoading" class="flex justify-center py-50">
			<mat-spinner diameter="30"></mat-spinner>
		</div>
	</div>

	<div svc-dialog-actions class="px-3 py-2">
		<div class="flex flex-row gap-2 w-full justify-end">
			<svc-button mode="stroked" (click)="close()">
				{{ 'Cancelar' | transloco }}
			</svc-button>
			<svc-button mode="flat" color="primary" (click)="save()" [disabled]="isLoading || (canSelectArea && !form.controls.areaId.value && !form.controls.locationId.value) || (!canSelectArea && !form.controls.locationId.value)">
				{{ 'Salvar' | transloco }}
			</svc-button>
		</div>
	</div>
</svc-dialog>
