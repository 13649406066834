import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { LibSharedComponentModule } from 'projects/lib-shared-component/src/lib/lib-shared-component.module';
import { SvcControlsModule } from 'projects/lib-shared-component/src/lib/svc-controls/svc-controls.module';
import { SvcCustomFieldsComponent } from './svc-custom-fields.component';
import { SvcCustomFieldService } from './svc-custom-field.service';
import { SvcCustomFieldHttpClient } from './svc-custom-field-http-client';

@NgModule({
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule,
    SvcControlsModule,
    DragDropModule
  ],
  exports: [
    SvcCustomFieldsComponent
  ],
  declarations: [
    SvcCustomFieldsComponent
  ],
  providers: [
    SvcCustomFieldService,
    SvcCustomFieldHttpClient,
  ]
})

export class SvcCustomFieldsModule {}
