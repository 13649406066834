import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SvcGridDataComponent } from './svc-grid-data.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    SvcGridDataComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    SvcGridDataComponent
  ]
})
export class SvcGridDataModule { }
