import { AfterContentInit, AfterViewInit, Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'svc-fake-scrollbar',
  templateUrl: './svc-fake-scrollbar.component.html',
  styleUrls: ['./svc-fake-scrollbar.component.scss'],
  host: {
    '[class.svc-fake-scrollbar]': 'true',
    '[class.fsb-manually-scrolling]': 'dragging.state === \'START\' || dragging.state === \'DRAGGING\'',
    '[class.fsb-hidden]': '(elRef?.nativeElement?.scrollHeight || 0) === 0',
    '[class.fsb-disabled]': 'disabled',
    '(window:resize)': 'onResize()',
  }
})
export class SvcFakeScrollbarComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @Input() protected disabled = false;

  private elRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);
  private renderer = inject(Renderer2);

  @ViewChild('scrollBarEl') protected scrollBarElRef: ElementRef<HTMLElement>;
  @ViewChild('scrollerEl') protected scrollerElRef: ElementRef<HTMLElement>;
  protected dragging: {
    state: 'START' | 'DRAGGING' | 'NONE';
    toDown?: boolean;
    initY?: number;
    prevY?: number;
    currY?: number;
    prevYDistance?: number;
    currYDistance?: number;
  } = { state: 'NONE' };
  private listeningCancellingFn: (() => void)[] = [];

  private get scrollBarEl() { return this.scrollBarElRef?.nativeElement; }
  private get scrollerEl() { return this.scrollerElRef?.nativeElement; }

  get el() { return this.elRef?.nativeElement; }

  public ngOnInit(): void {
    this.listeningCancellingFn = [
      this.renderer.listen(window, 'wheel', (e) => this.onMouseWheel(e)),
      this.renderer.listen(window, 'mousedown', (e) => this.onManuallyScrollStarted(e)),
      this.renderer.listen(window, 'touchstart', (e) => this.onManuallyScrollStarted(e)),
      this.renderer.listen(window, 'mousemove', (e) => this.onManuallyScrolling(e)),
      this.renderer.listen(window, 'touchmove', (e) => this.onManuallyScrolling(e)),
      this.renderer.listen(window, 'mouseup', (e) => this.onManuallyScrollEnded(e)),
      this.renderer.listen(window, 'touchend', (e) => this.onManuallyScrollEnded(e)),
    ];

    // this.updateScrollbarPosition();
    // this.updateScrollbarSize();
  }

  public ngAfterViewInit() {
    // this.updateScrollbarPosition();
    // this.updateScrollbarSize();
  }
  public ngAfterContentInit() {
    // this.updateScrollbarPosition();
    // this.updateScrollbarSize();
  }

  public ngOnChanges(changes: SimpleChanges) { }

  public onResize(): void {
  }

  public onScroll(event: Event): void {
    if (this.dragging.state === 'NONE') {
      this.dragging.state = 'START';
    }
  }

  private onMouseWheel(event: WheelEvent) {
    if (this.dragging.state === 'NONE') {
      console.log(event);
    }
  }

  private onManuallyScrollStarted(event: MouseEvent) {
    if (!this.disabled && this.dragging.state === 'NONE' && event.target === this.scrollBarEl) {
      this.dragging.state = 'START';
      this.dragging.initY = event.clientY;
      this.dragging.prevY = event.clientY;
      this.dragging.currY = event.clientY;
      this.dragging.prevYDistance = 0;
      this.dragging.currYDistance = 0;
    }
  }

  private onManuallyScrolling(event: MouseEvent) {
    if (['START', 'DRAGGING'].includes(this.dragging.state)) {
      this.dragging.state = 'DRAGGING';
      this.dragging.toDown = event.clientY > this.dragging.initY;
      this.dragging.prevY = this.dragging.currY;
      this.dragging.prevYDistance = this.dragging.currYDistance;
      this.dragging.currY = event.clientY;
      this.dragging.currYDistance = this.dragging.currY - this.dragging.prevY;
      // this.updateScrollbarSize();
      // this.updateScrollbarPosition();
    }
  }

  private onManuallyScrollEnded(event: MouseEvent) {
    if (this.dragging.state === 'DRAGGING') {
      this.dragging.state = 'NONE';
    }
  }

  // private updateScrollbarPosition() {
  //   console.log(`scrollTop: ${this.scrollerEl.scrollTop}, will add: ${this.dragging.currYDistance}`)
  //   this.scrollerEl.scrollTop += this.dragging.currYDistance ?? 0;
  //   // const positionY = this.#scrollBarAreaEl.offsetHeight
  //   // this.scrollerEl.style.transform = `translateY(${positionY}px)`;
  // }

  // private updateScrollbarSize() {
  //   const height = 100;
  //   this.scrollBarEl.style.height = `${height}px`;
  // }

  public ngOnDestroy(): void {
    this.listeningCancellingFn.forEach((fn) => fn());
  }
}
