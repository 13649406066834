import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AgVirtualSrollComponent } from 'ag-virtual-scroll';
import { environment } from 'projects/environments/environment';
import { SvcMediaQuery, simpleRedirectURL, externalCommonAspURL, externalNetcoreURL } from 'projects/lib-shared-common/src/public-api';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { NotificationIntegrationService } from '../../../../ui/notification/notification-integration.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { Notification, NotificationItem } from '../../models/notifications.types';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'lib-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss']
})
export class NotificationContentComponent implements OnInit, OnDestroy {

  protected notifications: NotificationItem[] = [];
  protected notificationUnreadCount: number = 0;
  protected notifiticationsAreGone = false;
  protected isLoading = true;
  protected avatarSize: UserAvatarSize = UserAvatarSize.Notification;
  protected env = environment;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Output() clickedItem = new EventEmitter();
  @ViewChild(AgVirtualSrollComponent) agVirtualScroll: AgVirtualSrollComponent;

  constructor(
    private tabGroup: MatTabGroup,
    private tab: MatTab,
    private _notificationsService: NotificationsService,
    private _svcMediaQuery: SvcMediaQuery,
    private _notificationIntegrationService: NotificationIntegrationService,
    public elRef: ElementRef<HTMLElement>,
  ) { }

  ngOnInit(): void {
    this.tabGroup.selectedIndexChange.pipe(
      takeUntil(this._unsubscribeAll),
      tap((index: number) => {
        if (index === this.tabGroup._tabs.toArray().indexOf(this.tab)) {
          setTimeout(() => this.agVirtualScroll?.refreshData());
        }
      })
    ).subscribe();

    this._notificationsService.isLoading$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((value) => this.isLoading = value),
      )
      .subscribe();

    this._notificationsService.notifications$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((notifications: Notification) => {
          this.notifications = notifications.notifications;
          this.notifiticationsAreGone = notifications.notifiticationsAreGone;
        }),
      )
      .subscribe();

    this._svcMediaQuery.size$.pipe(
      takeUntil(this._unsubscribeAll),
      tap(() => {
        setTimeout(() => this.agVirtualScroll?.refreshData(), 1);
      }),
    ).subscribe();
  }

  emitClickItem() {
    this.clickedItem.emit();
  }

  protected nextPage() {
    if (!this.notifiticationsAreGone) {
      this._notificationsService.nextPage();
    }
  }

  protected executeJsonCallback(jsonCallback: string) {
    if (jsonCallback.startsWith('https')) {
      if (jsonCallback.includes('/notification/')) {
        this._notificationIntegrationService.openIframeByPath(jsonCallback);
      }
      else {
        simpleRedirectURL(jsonCallback);
      }
    }
    else if (['openAngularDialog', 'redirectDynamicPageAsp', 'RedirectDynamicPage'].some((x) => jsonCallback?.startsWith(x))) {
      new Function(`this.${jsonCallback}`).bind(this).call();
    }
  }

  private redirectDynamicPageAsp(url: string, params: any) {
    externalCommonAspURL(url, params);
  }

  private RedirectDynamicPage(url: string, params: string) {
    externalNetcoreURL(url, params);
  }

  private openAngularDialog(path: string) {
    this._notificationIntegrationService.openIframeByPath(path);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
