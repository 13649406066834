import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { catchError, finalize, Subscription, tap } from "rxjs";
import { QuillEditorComponent } from "ngx-quill/lib/quill-editor.component";
import Quill from 'quill';
import { Delta } from 'quill/core';
import { SvcCopilotService } from '../../services/svc-copilot.service';
import "quill-mention";
import _ from 'lodash';
import { SvcCopilotChatComponent } from '../svc-copilot-chat/svc-copilot-chat.component';
import { HttpErrorService, SvcFunctionsHelper } from 'projects/lib-shared-common/src/public-api';
import { SvcCopilotMessage } from '../../models/copilot.model';

@Component({
  selector: 'svc-copilot-input',
  templateUrl: './svc-copilot-input.component.html',
  styleUrls: ['./svc-copilot-input.component.scss']
})
export class SvcCopilotInputComponent implements OnInit {

  @Input() readOnly: boolean;
  @Input() placeholder: string;
  @Input() cursorForce: boolean;
  @Input() public smallSize: boolean = false;

  @ViewChild('editor') editor: QuillEditorComponent;

  @Output() public onQuestionAdded = new EventEmitter<SvcCopilotMessage>();

  private quill: Quill;
  public mentionsSubscription: Subscription;
  public mentionList: any[];
  public isLoading: boolean = false;
  public buttonDisabled = true;
  public messageCurrent: string = '';

  constructor(
    @Inject(forwardRef(() => SvcCopilotChatComponent))
    public parent: SvcCopilotChatComponent,
    private _functionsHelper: SvcFunctionsHelper,
    private _svcCopilotService: SvcCopilotService,
    private _httpErrorService: HttpErrorService
  ) {
  }

  ngOnInit(): void {
  }

  public onContentChanged(): void {
    this.buttonDisabled = this._functionsHelper.removeHtml(this.messageCurrent ?? '') == '';
  }

  modules = {
    toolbar: false,
    clipboard: {
      matchVisual: false
    }
  }

  get text() {
    return this.editor.quillEditor.getText();
  }

  get html() {
    return this.editor.quillEditor.root.innerHTML;
  }
  set html(html) {
    this.editor.quillEditor.root.innerHTML = html;
  }

  get deltas(): Delta {
    return this.editor.quillEditor.getContents();
  }
  set deltas(deltas: Delta) {
    this.editor.quillEditor.setContents(deltas, 'silent');
  }

  setMessageByAudio(msg: string){
    this.messageCurrent = msg;
    this.sendMessage();
  }

  sendMessage(){
    this.isLoading = true;
    this.editor.quillEditor.disable();

    let prompt = this._functionsHelper.removeHtml(this.messageCurrent ?? '');
    this.messageCurrent = '';

    this.onQuestionAdded.emit({
      content: prompt,
      isOwnerMessage: true
    });

    this._svcCopilotService.sendPrompt(prompt)
      .pipe(
        tap((response) => {

        this.onQuestionAdded.emit({
            messageId: response.messageId,
            content: response.aiResponse,
            isOwnerMessage: false
          });
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        }),
        finalize(() =>
          {
            this.isLoading = false;
            this.editor.quillEditor.enable();
          }),
      ).subscribe();
  }

  focus() {
    setTimeout(() => {
      this.quill.focus();
    });
  }

  onEditorCreated(event: Quill) {
    this.quill = event;
  }
}
