import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvcBreadcrumbModule, SvcChipModule } from 'projects/lib-shared-component/src/public-api';
import { SvcDistributionManageComponent } from './svc-distribution-manage.component';
import { SvcDistributionManageFormComponent } from './svc-distribution-manage-form/svc-distribution-manage-form.component';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { SvcButtonsModule } from 'projects/lib-shared-component/src/public-api';
import { SvcSelectModule } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.module';
import { SvcDataTableAccordionModule } from 'projects/lib-shared-component/src/public-api';

import { SvcDistributionManageRoutes } from './svc-distribution-manage.routing';

@NgModule({
  declarations: [
    SvcDistributionManageComponent,
    SvcDistributionManageFormComponent
  ],
  imports: [
    CommonModule,
    LibSharedCommonModule,
    LibSharedIconsModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    SvcButtonsModule,
    SvcBreadcrumbModule,
    SvcSelectModule,
    SvcChipModule,
    SvcDataTableAccordionModule,
    RouterModule.forChild(SvcDistributionManageRoutes),
  ]
})
export class SvcDistributionManageModule { }
