import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SvcFilterEvent, SvcFilterValueEvent } from "../../classes/svc-filter-event";
import { SvcFilterField } from "../../classes/svc-filter-field";
import { SvcFiltersComponent } from "../../svc-filters.component";


@Component({
  selector: 'svc-filters-sidebar',
  templateUrl: './svc-filters-sidebar.component.html',
  styleUrls: ['./svc-filters-sidebar.component.scss']
})
export class SvcFiltersSidebarComponent implements OnChanges, AfterViewInit {

  @ViewChild(SvcFiltersComponent)
  public svcFilters: SvcFiltersComponent;

  @Input() public filters: SvcFilterField[] = [];
  @Input() public isLoading: boolean = false;
  @Input() public searchOnlyWhenHaveNewValues: boolean = false;
  @Input() public emitCloseAfterCleared: boolean = true;
  @Input() public emitCloseAfterCancelled: boolean = true;
  @Input() public emitCloseAfterSearchClicked : boolean = true;

  @Output() public onCloseClicked = new EventEmitter<void>();
  @Output() public onFilterChanged = new EventEmitter<SvcFilterEvent>();
  @Output() public onFilterValueChanged = new EventEmitter<SvcFilterValueEvent>();
  @Output() public onFilterCleared = new EventEmitter<void>();
  @Output() public onFilterCancelled = new EventEmitter<SvcFilterEvent>();
  @Output() public onSearchClicked = new EventEmitter<SvcFilterEvent>();

  #lastFilters: { [key: string]: any } = {};

  protected hasFiltersPending = false;
  protected hasFiltersDefined = false;
  public isEmpty = true;
  public isValid = true;

  public get fields() { return this.svcFilters?.fields ?? []; }

  public ngOnChanges(changes: SimpleChanges) {
    if ('filters' in changes && this.svcFilters) {
      setTimeout(() => this.ngAfterViewInit());
    }
  }

  public ngAfterViewInit() {
    this.#lastFilters = this.svcFilters.fields.reduce((filters, field) => {
      filters[field.name] = field?.value ?? null;
      return filters;
    }, {});
    this.hasFiltersPending = false;
    this.hasFiltersDefined = !this.svcFilters.isEmpty;
    this.isEmpty = this.svcFilters.isEmpty;
    this.isValid = this.svcFilters.isValid;
  }

  protected close() {
    this.#internalCancelPendingFilters();
  }

  protected checkIfHasFiltersToDo(event: SvcFilterEvent) {
    const newFilters = event.fields.reduce((filters, field) => {
      filters[field.name] = field?.value ?? null;
      return filters;
    }, {});
    this.hasFiltersPending = JSON.stringify(newFilters) != JSON.stringify(this.#lastFilters);
    this.onFilterChanged.emit(event);
  }

  protected search(fromClear: boolean = false) {
    if (this.svcFilters?.isValid && !this.isLoading) {
      const newFilters = this.svcFilters.fields.reduce((filters, field) => {
        filters[field.name] = field?.value ?? null;
        return filters;
      }, {});
      this.hasFiltersPending = JSON.stringify(newFilters) != JSON.stringify(this.#lastFilters);
      if (!this.searchOnlyWhenHaveNewValues || this.hasFiltersPending) {
        this.#lastFilters = newFilters;
        this.hasFiltersPending = false;
        this.hasFiltersDefined = !this.svcFilters.isEmpty;;
        if (!fromClear) {
          this.#emitSearchClicked();
        }
      }
    }
  }

  protected internalClearFilters(options?: { clearDisabled?: boolean, preventEmit?: boolean }) {
    if (!this.isLoading) {
      this.svcFilters.clearFiltersField(options ?? { preventEmit: true });
      if (this.hasFiltersDefined) {
          this.search(true);
          this.#emitFilterCleared();
      }
    }
  }

  public clearFilters(options?: { clearDisabled?: boolean, preventEmit?: boolean }) {
    this.internalClearFilters(options);
  }

  public patchFiltersValue(fieldAndValues: { [key: string]: any }) {
    fieldAndValues = fieldAndValues ?? {};
    this.svcFilters.patchValues(fieldAndValues);
    this.#lastFilters = this.#lastFilters
      ? Object.keys(this.#lastFilters).reduce((filters, fieldName) => {
        filters[fieldName] = (fieldName in fieldAndValues) ? fieldAndValues[fieldName] : this.#lastFilters[fieldName];
        return filters;
      }, {})
      : (fieldAndValues ?? {});
    this.hasFiltersPending = false;
    this.hasFiltersDefined = !this.svcFilters.isEmpty;
    this.isEmpty = this.svcFilters.isEmpty;
    this.isValid = this.svcFilters.isValid;
  }

  public cancelPendingFilters() {
    this.#internalCancelPendingFilters(false);
  }


  #internalCancelPendingFilters(fromInternal: boolean = true) {
    if (this.hasFiltersPending) {
      this.#emitFilterCancelled(fromInternal);
    }
    else if (fromInternal) {
      this.onCloseClicked.emit();
    }
  }

  #emitSearchClicked() {
    this.isEmpty = this.svcFilters.isEmpty;
    this.isValid = this.svcFilters.isValid;
    this.onSearchClicked.emit({
      fields: this.svcFilters.fields,
    });
    if (this.emitCloseAfterSearchClicked) {
      this.onCloseClicked.emit();
    }
  }

  #emitFilterCleared() {
    this.hasFiltersDefined = false;
    this.hasFiltersPending = false;
    this.isEmpty = this.svcFilters.isEmpty;
    this.isValid = this.svcFilters.isValid;
    this.onFilterCleared.emit();
    if (this.emitCloseAfterCleared) {
      this.onCloseClicked.emit();
    }
  }

  #emitFilterCancelled(fromInternal: boolean) {
    this.svcFilters.patchValues(this.#lastFilters);
    this.hasFiltersPending = false;
    this.hasFiltersDefined = !this.svcFilters.isEmpty;;
    this.isEmpty = this.svcFilters.isEmpty;
    this.isValid = this.svcFilters.isValid;
    this.onFilterCancelled.emit({
      fields: this.svcFilters.fields,
    });
    if (fromInternal || this.emitCloseAfterCancelled) {
      this.onCloseClicked.emit();
    }
  }
}
