import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { BosHttpClient } from './bos-http-client';
import { SvcBosAddFeedback, SvcBosAddFeedbackResponse, SvcBosFeedback, SvcBosFeedbackList, SvcBosFeedbackRegister } from '../interfaces/svc-bos-feedback.interface';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { SvcBosFilterParams } from '../interfaces/svc-modal-bos.interface';

@Injectable({
  providedIn: 'root'
})
export class SvcBosFeedbackService {

  constructor (
    private _http: BosHttpClient
  ) { }

  private _mapFeedbacks(feedbacks: SvcBosFeedback[]): SvcBosFeedback[] {
    return feedbacks?.map((feedback: SvcBosFeedback) => {
      return {
        ...feedback,
        svcUserAvatar: {
          name: feedback?.userAvatar?.firstLastName,
          initialsColor: feedback?.userAvatar?.preferenceColor,
          picture: feedback?.userAvatar?.pictureUrl,
          size: UserAvatarSize.Table
        }
      }
    });
  }

  public getFeedbackList(filter: SvcBosFilterParams): Observable<SvcBosFeedbackList> {
    return this._http.get('/Feedback/List', {
      params: this._http.getRequestParams(filter)
    }).pipe(
      map((feedbackList: SvcBosFeedbackList) => {
        feedbackList.feedbacks = this._mapFeedbacks(feedbackList?.feedbacks);
        return feedbackList;
      })
    );
  }

  public createFeedback(feedback: SvcBosAddFeedback): Observable<SvcBosAddFeedbackResponse> {
    return this._http.post('/Feedback', {
      ...feedback,
      siteId: this._http.siteId
    });
  }

  public updateFeedback(feedback: SvcBosAddFeedback): Observable<SvcBosAddFeedbackResponse> {
    return this._http.put('/Feedback', {
      ...feedback,
      siteId: this._http.siteId
    });
  }

  public getFeedbackById(feedbackId: number): Observable<SvcBosFeedbackRegister> {
    return this._http.get('/Feedback', {
      params: {
        feedbackId,
        siteId: this._http.siteId
      }
    });
  }

  public deleteFeedback(feedbackId: number): Observable<boolean> {
    return this._http.delete('/Feedback', {
      params: {
        feedbackId,
        siteId: this._http.siteId
      }
    });
  }
}
