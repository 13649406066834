import {EventEmitter, Injectable} from '@angular/core';
import {isMobile} from "projects/lib-shared-common/src/lib/functions/is-mobile";
import {SurveyComponent} from "./survey.component";
import {ModalInsightsDefault} from "../../models/modal-insights-default";
import {catchError, map, of} from "rxjs";
import { environment } from 'projects/environments/environment';

export interface Survey {
  surveyId: string;
  surveyTitle: string;
  surveyUrl: string;
  createDate: Date;
  updateDate: Date;
}

@Injectable()
export class SurveyService extends ModalInsightsDefault<Survey> {

  public saveChange = new EventEmitter<boolean>();

  check() {
    return this._httpClient.get<Survey>(`${this._appConfig.APIs.apiUrlAdministration}/SatisfactionSurvey`)
      .pipe(
        map(res => {
          if (!environment.isDEV) {
            // this._cookieKey = `SURVEY_${this._userId}`;
            this.model = res;
            return res.surveyId != null
          }
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  showModal() {
    return this._dialogService.open(SurveyComponent, {
      width: isMobile() ? "90vw" : "590px",
      maxHeight: "95vh",
      disableClose: true,
      autoFocus: "dialog",
    });
  }

  saveOnClose() {
    return this._httpClient.put(
      `${this._appConfig.APIs.apiUrlAdministration}/SatisfactionSurvey/${this.model.surveyId}`,
      {surveyId: this.model.surveyId}
    ).pipe(
      map(res => {
        //this.setCookie(this._cookieKey, this.survey.surveyId);
        this.saveChange.emit(true);
        return true;
      }),
      catchError(() => {
        this.saveChange.emit(false);
        return of(true);
      })
    );
  }
}
