<div [ngClass]="{'external-call': externalCall, 'internal-call': !externalCall}">
  <div class="flex flex-col flex-auto p-2 bg-[#17457A] h-full">
    <div class="flex px-2 py-4 pr-1">
      <div class="flex-auto text-white text-lg font-extrabold">Jack (Copilot)</div>
      <div class="flex flex-col justify-center">
        <button *ngIf="!externalCall" class="flex text-white" (click)="onClose.emit()">
          <mat-icon class="text-white icon-size-6" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
        </button>
      </div>
    </div>

    <div class="flex flex-col flex-auto overflow-y-auto bg-white space-y-2 rounded pb-4">  
      <div #scrollContainer class="flex flex-col flex-auto overflow-y-auto h-100 p-4">
        <div class="flex flex-col flex-auto">
          <svc-copilot-message
            *ngFor="let message of messages"
            class="pb-4"
            [data]="message"
            [smallSize]="smallSize"
            [externalCall]="externalCall"
          ></svc-copilot-message>
        </div>
      
        <div *ngIf="!messagesAreGone && isLoading" class="flex flex-col flex-auto items-center justify-center h-full">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
        <div *ngIf="messagesAreGone && messages.length === 0" class="jack flex flex-col flex-auto items-center justify-center">
          <div class="text-[#17457A] m-5 text-2xl font-bold font-size">
            {{ 'Seu Copilot Jack' | transloco }}
          </div>
          <div class="w-full max-w-140" [innerHTML]="emptyViewContent"></div>
          <div class="max-w-120">
            <img src="https://solvacelabs-webcomponents.s3.us-east-1.amazonaws.com/copilot/empty-view-jack.svg"/>
          </div>
        </div>
      </div>
      <div class="fixed bg-white contents">
        <div class="flex items-start px-2 gap-1">
          <div class="flex flex-col flex-auto">
            
            <svc-copilot-input
              [placeholder]="'Mensagem'| transloco"
              (onQuestionAdded)="setNewInteractions($event)"
            ></svc-copilot-input>
          </div>
        </div>
      </div> 
    </div> 
  </div>
</div>