import { AfterViewInit, Component, inject, OnInit, Renderer2, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { RequestCollectorService, SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';
import { NavigationService, SitesService, SvcFeatureToggleService, User, UserEnvironment, UserService } from 'projects/lib-shared-core/src/public-api';
import { LayoutService } from '../layout.service';
import { SvcSidebarNavigationService } from './components/sidebar-navigation/svc-sidebar-navigation.service';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { NotificationIntegrationService } from '../../notification/notification-integration.service';
import { environment } from 'projects/environments/environment';
import { VerticalNavigationComponent } from './components/vertical-navigation/vertical-navigation.component';
import { SidebarNavigationComponent } from './components/sidebar-navigation/sidebar-navigation.component';
import { MobileMenuType } from './components/mobile-menu/mobile-menu.component';

@Component({ template: '' })
export abstract class BaseLayoutComponent implements OnInit, AfterViewInit {

  @ViewChild(VerticalNavigationComponent) verticalNavigation: VerticalNavigationComponent;
  @ViewChild('appsPanel') appsPanel: any;
  @ViewChild('sidebarNavigationComponent') sidebarNavigationComponent: SidebarNavigationComponent;
  
  protected _activatedRoute = inject(ActivatedRoute);
  protected _router = inject(Router);
  protected _renderer = inject(Renderer2);
  protected _navigationService = inject(NavigationService);
  protected _userService = inject(UserService);
  protected _layoutService = inject(LayoutService);
  protected _requestCollectorService = inject(RequestCollectorService);
  protected _svcSidebarNavigationService = inject(SvcSidebarNavigationService);
  protected _dialogService = inject(SvcDialogService);
  protected _notificationIntegrationService = inject(NotificationIntegrationService);
  protected _featureToggleService = inject(SvcFeatureToggleService);
  protected _mediaQuery = inject(SvcMediaQuery);
  protected _sitesService = inject(SitesService);
  
  protected _unsubscribeAll = new Subject<any>();
  protected env = environment;
  protected user: User;
  protected environment: UserEnvironment;
  protected feedIsEnabled = false;
  protected copilotEnabled = false;

  protected get navigationGotError(): boolean { return this._navigationService.error != null; }
  protected get navigationIsLoading(): boolean { return this._navigationService.isLoading; }

  constructor() {
    this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
    this.copilotEnabled = this._featureToggleService.copilotIsEnabled;
  }

  public ngOnInit(): void {
    this._router.events.pipe(
      takeUntil(this._unsubscribeAll),
      tap((event: any) => {
        if (event instanceof NavigationStart) {
          const currentUrl = this._router.url.replace('/', '');
          const nextUrl = event.url.replace('/', '');
          if (currentUrl !== nextUrl) {
            this._requestCollectorService.cancelAll();
          }
        }
      }),
    ).subscribe();

    this._sitesService.getAll().subscribe();

    this.user = this._userService.user;
    this.environment = this._userService.environment;
  }

  public ngAfterViewInit(): void {
    this._layoutService.setVerticalNavigationComponent(this.verticalNavigation);
  }

  protected toggleNavigation(): void {
    this.verticalNavigation.toggle();
  }

  protected mobileMenuClick(type: MobileMenuType) {
    if (type === 'modules' || type === 'menu') {
      this.toggleMenu();
    }
    else if (type === 'tasks') {
      this.sidebarNavigationComponent.open('tasks');
    }
    else if (type === 'feed') {
      this.sidebarNavigationComponent.open('feed');
    }
  }

  protected toggleMenu(): void {
    this.appsPanel?.toggle();
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}