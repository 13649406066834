<svc-drawer
  #drawer 
  *screenSizes="let size"
  [mode]="mode ?? (size.isBelowMD ? 'over' : 'side')"
  [position]="position ?? 'right'"
>
  <ng-container svcDrawerContent>
    <ng-content></ng-content>
  </ng-container>

  <svc-filters-sidebar svcDrawerSide
    [filters]="filters"
    [isLoading]="isLoading"
    [searchOnlyWhenHaveNewValues]="searchOnlyWhenHaveNewValues"
    (onCloseClicked)="drawer.isOpened && drawer.toggle()"
    (onFilterChanged)="onFilterChanged.emit($event)"
    (onFilterValueChanged)="onFilterValueChanged.emit($event)"
    (onFilterCleared)="onFilterCleared.emit()"
    (onFilterCancelled)="onSearchClicked.emit($event)"
    (onSearchClicked)="onSearchClicked.emit($event)"
  ></svc-filters-sidebar>
</svc-drawer>
