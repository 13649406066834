import {HttpClient, HttpParams} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";

import { SvcHttpClient } from "projects/lib-shared-common/src/lib/classes/svc-http-client";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { UserService } from "projects/lib-shared-core/src/public-api";

@Injectable()
export class BosHttpClient extends SvcHttpClient {

  private userService: UserService = inject(UserService);

  public siteId: number = this.userService.user.lastSiteId;

  constructor(
    appConfig: AppEnvironmentConfig,
    httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlBOS, httpClient);
  }

  private getParams(filter: any, params: HttpParams, key: string): HttpParams {
    const value = filter?.[key];

    if (Array.isArray(value))
      value.forEach((val: any) =>
        params = params.append(`${key}`, val)
      );
    else
      params = params.append(key, value);

    return params;
  }

  public getRequestParams(filter: any): HttpParams {
    let params = new HttpParams();
    Object.keys(filter)?.forEach((key: string) => {
      if (filter?.[key] || key === 'pageIndex')
        params = this.getParams(filter, params, key);
    });

    params = params.append('siteId', this.siteId);

    return params;
  }
}
