import { Injectable } from '@angular/core';

import { Observable, Subject, catchError, finalize, map, tap } from 'rxjs';

import { SvcUserPreferencesFeatures } from '../models/svc-user-preferences.model';
import { UserHttpClient } from '../user-http-client';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private _pendingUserPreferencesRequest: Subject<SvcUserPreferencesFeatures[]>;

  constructor(private http: UserHttpClient) { }

  public getUserPreferencesFeatures(applicationId: string): Observable<SvcUserPreferencesFeatures[]> {
    try {
      if (!this._pendingUserPreferencesRequest) {
        this._pendingUserPreferencesRequest = new Subject<SvcUserPreferencesFeatures[]>();
        const subject = this._pendingUserPreferencesRequest;
        this.http.get<SvcUserPreferencesFeatures[]>(`/userpreference/features/${applicationId}`).pipe(
          tap((response) => {
            this._pendingUserPreferencesRequest = null;
            subject.next(response);
          }),
          catchError((error) => {
            this._pendingUserPreferencesRequest = null;
            subject.error(error);
            return error;
          }),
          finalize(() => {
            this._pendingUserPreferencesRequest = null;
            subject.complete();
          })
        ).subscribe();
      }
    }
    finally {
      return this._pendingUserPreferencesRequest;
    }
  }

  public getByFeatureName(applicationId: string, featureName: string): Observable<SvcUserPreferencesFeatures> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.find(x => x.featureName == featureName))
    );
  }

  public getByFeatureNames(applicationId: string, featureNames: string[]): Observable<SvcUserPreferencesFeatures[]> {
    return this.getUserPreferencesFeatures(applicationId).pipe(
      map((response) => response.filter(x => featureNames.includes(x.featureName)))
    );
  }

  public updateUserPreferences(applicationId: string, featurePreferences: SvcUserPreferencesFeatures): Observable<boolean> {
    return this.http.put(`/userpreference/features`, {
      applicationId,
      featurePreferences
    })
  }

}
