import { Component} from '@angular/core';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import {ISvcUserAvatar, SvcDialogService, UserAvatarSize} from 'projects/lib-shared-component/src/public-api';
import {StarterType, SvcPostFormModalComponent} from "../svc-post-form-modal/svc-post-form-modal.component";

@Component({
  selector: 'svc-post-form',
  templateUrl: './svc-post-form.component.html',
  styleUrls: ['./svc-post-form.component.scss']
})
export class SvcPostFormComponent {

  userAvatar: ISvcUserAvatar;

  constructor(
    private _dialog: SvcDialogService,
    private _userService: UserService
  )
  {}

  ngOnInit(): void {
    const user = this._userService.user;
    this.userAvatar = {
      name: user.firstLastName,
      picture: user.pictureFile,
      initialsColor: user.initialsColor,
      size: UserAvatarSize.Posts
    }
  }

  openPostFormModal(e, type: StarterType) {
    this._dialog.open(SvcPostFormModalComponent, {
      disableClose: true,
      width: '500px',
      data: {
        type: type,
        post: null
      }
    });
  }
}
