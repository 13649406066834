import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { HttpClient } from "@angular/common/http";
import {AppEnvironmentConfig} from "../../../../../config/model/environment.config.model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SvcFaqHttpClient extends SvcHttpClient {
  constructor(
    private _appConfig: AppEnvironmentConfig,
    private httpClient: HttpClient,
  ) {
    super(_appConfig.APIs.apiUrlTraining, httpClient);
  }
}
