import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SvcCopilotMessage } from '../../models/copilot.model';
import { ISvcUserAvatar, SvcToastService, UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { SvcCopilotChatComponent } from '../svc-copilot-chat/svc-copilot-chat.component';
import { UserService } from 'projects/lib-shared-core/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { SvcCopilotService } from '../../services/svc-copilot.service';
import { HttpErrorService, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';
import { catchError, tap } from 'rxjs';

@Component({
  selector: 'svc-copilot-message',
  templateUrl: './svc-copilot-message.component.html',
  styleUrls: ['./svc-copilot-message.component.scss']
})
export class SvcCopilotMessageComponent implements OnInit, OnChanges {
  @Input() public data: SvcCopilotMessage;
  @Input() public smallSize: boolean = false;
  @Input() public externalCall: boolean = false;
 
  @ViewChild(SvcCopilotChatComponent) public svcCopilotChatComponent: SvcCopilotChatComponent;

  public solvaceIAAvatar: ISvcUserAvatar;
  public currentUserAvatar: ISvcUserAvatar;
  public hasImageOrAudioAttachment: boolean = false;
  public removingIsLoading: boolean = false;
  public translatingIsLoading: boolean = false;

  constructor(
    private _toastService: SvcToastService,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _copilotService: SvcCopilotService,
    private _httpErrorService: HttpErrorService
  ) { }

  public ngOnInit(): void {
    const currentUser = this._userService.user;

    this.currentUserAvatar = {
      name: currentUser.firstLastName,
      picture: currentUser.pictureFile,
      initialsColor: currentUser.initialsColor,
      size: UserAvatarSize.Copilot
    };

    this.solvaceIAAvatar = {
      name: 'Jack (Copilot)',
      picture: 'https://solvacelabs-webcomponents.s3.us-east-1.amazonaws.com/copilot/avatar-jack.png',
      size: UserAvatarSize.Copilot,
    };
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  textCopy(msg: string) {
    navigator.clipboard.writeText(msg)
    .then(
      () => this._toastService.success(this._translocoService.translate('Texto copiado para a área de transferência!')),
      (error) => this._toastService.error(error)
    );
  }

  setReaction(id: string, liked: boolean){
    if(isNullOrUndefined(this.data.liked)){
      this._copilotService.sendReaction(id, liked).pipe(
        tap(() => {
          this.data.liked = liked;
        }),
        catchError((error) => {
          this._httpErrorService.showErrorInToast(error);
          return error;
        })
      ).subscribe();
    }
  }
}