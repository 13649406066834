import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { User } from './services/user.types';
import { UserService } from './services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { environment } from 'projects/environments/environment';
import { ISvcUserAvatar, UserAvatarSize } from 'projects/lib-shared-component/src/lib/svc-user-avatar/interfaces/svc-user-avatar.interface';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {

  @Input() showAvatar: boolean = true;
  @Input() showSites: boolean = false;

  user: User;
  userAvatar: ISvcUserAvatar;
  env = environment;
  version: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _matDialog: MatDialog,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this.user = this._userService.user;
    this._userService.version$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((version) => {
        this.version = version;
      }),
    ).subscribe();

    this.userAvatar = {
      name: this.user.firstLastName,
      picture: this.user.pictureFile,
      initialsColor: this.user.initialsColor,
      size: UserAvatarSize.Header
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  changeNotifications(): void {
    this._matDialog.open(UserNotificationsComponent, {
      data: this.user,
      autoFocus: '__non_existing_element__',
      width: '100%',
      maxWidth: '720px',
    });
  }

  changeSettings(): void {
    this._matDialog.open(UserSettingsComponent, {
      data: this.user,
      autoFocus: '__non_existing_element__',
      width: '100%',
      maxWidth: '500px',
    });
  }

  changePassword(): void {
    this._matDialog.open(UserPasswordComponent, {
      autoFocus: '__non_existing_element__',
      width: '100%',
      maxWidth: '400px',
    });
  }
}
