import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject, catchError, finalize, of, tap } from 'rxjs';
import { App, MenuGroup, AppItem } from './apps.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AvailableModule } from './available-module.enum';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private _apps: ReplaySubject<App[]> = new ReplaySubject<App[]>(1);
  public isLoading: boolean = true;
  private _appsPendingRequest: Subject<MenuGroup[]>;
  private set _appsCached(value: MenuGroup[]) {
    this._svcDataCacheService.set<MenuGroup[]>(SvcCacheName.APPS_MENU, value);
  }
  private get _appsCached() {
    return this._svcDataCacheService.get<MenuGroup[]>(SvcCacheName.APPS_MENU);
  }

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private sanitizer: DomSanitizer,
    private _svcDataCacheService: SvcDataCacheService,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for apps
   */
  get apps$(): Observable<App[]> {
    return this._apps.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all apps
   */
  getAll(): Observable<MenuGroup[]> {
    this.isLoading = true;
    if (!this._appsPendingRequest && !this._appsCached) {
      this._appsPendingRequest = new Subject<MenuGroup[]>();
      this._httpClient.get<MenuGroup[]>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup`).pipe(
        tap((languages) => {
          this._appsCached = languages;
          this._appsPendingRequest.next(languages);
        }),
        catchError((error) => {
          this._appsPendingRequest.error(error);
          return error;
        }),
        finalize(() => {
          this._appsPendingRequest.complete();
          this._appsPendingRequest = null;
        }),
      ).subscribe();
    }
    return (this._appsCached ? of(this._appsCached) : this._appsPendingRequest).pipe(
      tap((apps: MenuGroup[]) => {
        const listApps = apps.map((c) => {
          return {
            id: c.groupId,
            name: c.groupName,
            icon: c.imagePath ? this.sanitizer.bypassSecurityTrustResourceUrl(c.imagePath) : null,
            backgroundColor: c.backgroundColor,
            textActiveColor: c.textColor,
            textInactiveColor: c.inactiveColor,
            highlight: c.isMasterApplication,
            items: c.menuItems.map((x) => {
              return {
                id: x.applicationId,
                name: x.applicationName,
                locked: !x.isActive,
                codeLanguage: x.aspxLanguageResourceKey,
                url: x.subUrlPath,
              } as AppItem;
            }),
          } as App;
        });

        this._apps.next(listApps);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  /**
   * Check module by ApplicationId
   */
  checkModuleByApplicationId(applicationId: string): Observable<AvailableModule> {
    return this._httpClient.get<AvailableModule>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${applicationId}/accessible`);
  }
}
