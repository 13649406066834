import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoCommonConfig } from './transloco.config';
import { LanguagesService } from '../languages/services/languages.service';
import { TranslocoApiLoader } from './transloco.api-loader';
import { StartupConfigService } from 'projects/config/startup-config.service';
import { AuthService } from '../../auth/auth.service';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      // Provide the default Transloco configuration
      provide: TRANSLOCO_CONFIG,
      useClass: TranslocoCommonConfig
    },
    {
      // Provide the default Transloco loader
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoApiLoader,
    },
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      deps: [StartupConfigService, TranslocoService, LanguagesService, AuthService],
      useFactory:
        (
          startupConfigService: StartupConfigService,
          translocoService: TranslocoService,
          languagesService: LanguagesService,
          authService: AuthService,
        ): any => () => {
          return new Promise<any>((resolve) => {
            startupConfigService.ready$.subscribe(() => {
              authService.check().subscribe((authenticated) => {
                if (authenticated) {
                  resolve(null);
                  return;
                }
                languagesService.getAll().subscribe((languages) => {
                  const langs = languages.map((language) => ({ id: language.languageTag, label: language.languageTranslated }));
                  translocoService.setAvailableLangs(langs);

                  const defaultLang = translocoService.getDefaultLang();
                  translocoService.setActiveLang(defaultLang);
                  return resolve(translocoService.load(defaultLang));
                });
              });
            })
          });
        },
      multi: true,
    },
  ],
})
export class TranslocoCoreModule { }
