import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { BosHttpClient } from './bos-http-client';
import { TypeResponse, BosResponseTypeCreate, TypeUpdate, TypeCreate, SvcBosTypeCombo, SvcBosListTypeBehaviors, SvcBosTypeFilter } from '../interfaces/svc-bos-type.interface';

@Injectable({
  providedIn: 'root'
})

export class SvcBosTypeService {

  constructor(
    private _http: BosHttpClient
  ) { }

  public getTypeList(filter: SvcBosTypeFilter): Observable<TypeResponse> {
    const params: HttpParams = this._http.getRequestParams(filter);
    return this._http.get<TypeResponse>('/Type/List', { params });
  }

  createType(type: TypeCreate): Observable<BosResponseTypeCreate> {
    return this._http.post('/Type', {
      ...type,
      siteId: this._http.siteId
    });
  }

  updateType(type: TypeUpdate): Observable<BosResponseTypeCreate> {
    return this._http.put('/Type', {
      ...type,
      siteId: this._http.siteId
    });
  }

  getTypeById(typeId: number): Observable<void> {
    return this._http.get<void>(`/Type`, {
      params: {
        typeId,
        siteId: this._http.siteId
      }
    });
  }

  deleteType(typeId: number): Observable<void> {
    return this._http.delete<void>(`/Type`, {
      params: {
        typeId,
        siteId: this._http.siteId
      }
    });
  }

  downloadExcel(): Observable<HttpResponse<Blob>> {
    return this._http.get('/Type/download/excel', {
      observe: 'response',
      responseType: 'blob',
      params: {
        siteId: this._http.siteId
      }
    });
  }

  downloadPdf(): Observable<HttpResponse<Blob>> {
    return this._http.get('/Type/download/pdf', {
      observe: 'response',
      responseType: 'blob',
      params: {
        siteId: this._http.siteId
      }
    });
  }

  public getTypes(isActive?: boolean): Observable<SvcBosTypeCombo[]> {
    let params = new HttpParams()
      .append('siteId', this._http.siteId);

    if (isActive || isActive === false)
      params = params.append('isActive', isActive);

    return this._http.get(`/Type/BySiteId`, {
      params
    });
  }

  public getListBehaviorsByType(typeId: number): Observable<SvcBosListTypeBehaviors> {
    return this._http.get('/Type/ListTypeBehaviors', {
      params: {
        siteId: this._http.siteId,
        typeId
      }
    })
  }
}
