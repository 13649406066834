import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcHttpClient } from 'projects/lib-shared-common/src/public-api';
import {
  DistributionItem,
  DistributionDetails,
  DistributionPostResponse,
  UpdateDistributionRequest,
  DeleteDistributionRequest
} from './interfaces/svc-distribution-manage.interface';

@Injectable({
  providedIn: 'root'
})
export class SvcDistributionManageService extends SvcHttpClient {

  constructor(
    protected appConfig: AppEnvironmentConfig,
    protected httpClient: HttpClient,

  ) {
    super(appConfig.APIs.apiUrlMasterdata, httpClient);
  }

  public getDistributionList(applicationId: string, siteId: number): Observable<DistributionItem[]> {
    const params = new HttpParams()
      .set('applicationId', applicationId)
      .set('siteId', siteId.toString());

    return this.get<DistributionItem[]>('/Distribution/List', { params });
  }

  public downloadFile(applicationId: string, siteId: number, type: 'pdf' | 'excel'): Observable<HttpResponse<Blob>> {
    const params = new HttpParams()
      .set('applicationId', applicationId)
      .set('siteId', siteId.toString());

    return this.get(`/Distribution/Download/${type}`, {
      params: params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  public getDistributionById(distributionId: number): Observable<DistributionDetails> {
    const params = new HttpParams()
      .set('distributionId', distributionId.toString());

    return this.get<DistributionDetails>('/Distribution/ById', { params });
  }

  public createDistribution(distribution: DistributionPostResponse): Observable<DistributionPostResponse> {
    return this.post<DistributionPostResponse>('/Distribution', distribution);
  }

  public updateDistribution(distribution: UpdateDistributionRequest): Observable<UpdateDistributionRequest> {
    return this.put<UpdateDistributionRequest>('/Distribution', distribution);
  }

  public deleteDistribution(distribution: DeleteDistributionRequest): Observable<void> {
    return this.delete<void>('/Distribution', { body: distribution });
  }
}
