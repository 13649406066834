import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISvcChipGroupedOption, ISvcSelectOption } from 'projects/lib-shared-component/src/public-api';
import { catchError, finalize, forkJoin, tap } from 'rxjs';
import { PraiseItem, PraiseTeam, PraiseUser } from "./modal-praise-new-models";
import { ModalPraiseNewService } from "./modal-praise-new.service";
import { UserEnvironment, UserService } from "projects/lib-shared-core/src/public-api";
import { SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';

@Component({
  selector: 'app-new-praise-form',
  templateUrl: './modal-praise-new.component.html',
  styleUrls: ['./modal-praise-new.component.scss']
})
export class ModalPraiseNewComponent implements OnInit {

  @Output() praiseAdded: EventEmitter<PraiseItem> = new EventEmitter<PraiseItem>();

  praiseForm: FormGroup;
  praise: PraiseItem;

  titles: ISvcSelectOption[];

  recipeOptions: ISvcChipGroupedOption[] = [];

  usersIds: string[];
  teamsIds: number[];

  environment: UserEnvironment;

  isOptionsLoading = false;
  isSubmiting = false;

  constructor(
    private _dialogRef: MatDialogRef<ModalPraiseNewComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private formBuilder: FormBuilder,
    private _praiseItemService: ModalPraiseNewService,
    private _userService: UserService,
    private _toastService: SvcToastService,
    private _translocoService: TranslocoService,
    private _httpErrorService: HttpErrorService,
  ) {
    this.praise = this._data?.praise;
  }

  ngOnInit() {
    this.environment = this._userService.environment;
    this.loadFormData();
    this.prepareForm();
  }

  private prepareForm() {
    this.praiseForm = this.formBuilder.group({
      recipients: [null, [Validators.required, Validators.minLength(1)]],
      titleId: [null, [Validators.required, Validators.minLength(3)]],
      description: [null, [Validators.required, Validators.maxLength(300)]],
      copyImmediateSupervisor: [false]
    });
  }

  private loadFormData() {
    this.isOptionsLoading = true;

    forkJoin({
      titles: this._praiseItemService.listTitlesActive(),
      users: this._userService.getUsers(),
      teams: this._userService.getTeams()
    }).pipe(
      tap((response) => {
        this.titles = response.titles.map(r => ({
          text: r.description,
          value: r.titleId
        }));
        this.usersIds = response.users.map(u => u.userId);
        this.teamsIds = response.teams.map(t => t.groupId);
      }),
      finalize(() => this.isOptionsLoading = false),
    ).subscribe();
  }

  save() {
    this.praiseForm.markAllAsTouched();
    if (this.praiseForm.invalid) return;

    let praise: PraiseItem = {
      praiseId: null,
      recipientTeams: this.praiseForm.value.recipients.filter((x: any) => typeof x === 'number'),
      recipientUsers: this.praiseForm.value.recipients.filter((x: any) => typeof x === 'string'),
      titleId: this.praiseForm.value.titleId,
      text: this.praiseForm.value.description,
      userOriginatorId: this._userService.userId$,
      copyImmediateSupervisor: this.praiseForm.value.copyImmediateSupervisor,
    };

    this.isSubmiting = true;
    this._praiseItemService.addPraise(praise).pipe(
      tap(r => {
        praise.praiseId = r.praiseId;
        this.praiseAdded.emit(praise);
        this._dialogRef.close({
          hadChanges: true,
          data: { ...praise, praiseCreated: true }
        });
        this._toastService.success(this._translocoService.translate('Seu elogio foi enviado com sucesso!'));
      }),
      catchError((error) => {
        this._toastService.error(
          this._translocoService.translate(this._httpErrorService.getErrorMessage(error))
        );
        return error;
      }),
      finalize(() => this.isSubmiting = false)
    ).subscribe();
  }

}
