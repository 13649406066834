import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { SvcPostPageComponent } from "./components/svc-post-page/svc-post-page.component";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class IdResolver  {
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    route.data.dialog.id = route.params['id'];
    return true;
  }
}

export const postsRoutes: Route[] = [
  {
    path: '',
    component: SvcPostPageComponent,
  },
  {
    path: ':id',
    component: SvcPostPageComponent,
  },
  // {
  //   path: ':id',
  //   component: SvcPostViewModalComponent,
  //   resolve: {
  //     dialogRef: SvcDialogResolver
  //   },
  //   data: {
  //     width: '100%',
  //     maxWidth: '500px',
  //     dialog: {
  //       id: ''
  //     }
  //   },
  //   canActivate: [IdResolver]
  // },
];
