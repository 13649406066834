<ng-container *screenSizes="let size">
  <div class="p-4" *ngIf="isLoading">
    <div class="w-full flex gap-2 mt-2 justify-center items-center">
      <ngx-skeleton-loader
        class="w-[20%]"
        [theme]="{width: '100%', height: '40px', borderRadius: '8px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        class="w-20"
        [theme]="{width: '100%', height: '36px', borderRadius: '8px'}"
      >
      </ngx-skeleton-loader>
    </div>
  
    <div class="w-full flex gap-4 mt-2 justify-center items-start">
      <ngx-skeleton-loader
        class="w-8"
        [theme]="{width: '100%', height: '150px', borderRadius: '16px', transform: 'rotate(180deg)'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        class="w-6 mx-6"
        [theme]="{width: '100%', height: '100px', borderRadius: '16px', transform: 'rotate(45deg)', marginLeft: '8px', marginRight: '8px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        class="w-[25%]"
        [theme]="{width: '100%', height: '60px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
    
      <ngx-skeleton-loader
        class="w-[20%]"
        [theme]="{width: '100%', height: '50px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        *ngIf="size?.isAboveXS"
        class="w-[15%]"
        [theme]="{width: '100%', height: '32px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
    </div>
  
    <div class="w-full flex gap-4 justify-center items-start" [ngClass]="size?.isAboveXS ? '-mt-20' : '-mt-10'">
      <ngx-skeleton-loader
        [ngClass]="{
          'w-[20%]': size?.isAboveXS,
          'w-[40%] -mt-4': !size?.isAboveXS
        }"
        [theme]="{width: '100%', height: '32px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
  
      
      <ngx-skeleton-loader
        *ngIf="size?.isAboveXS"
        class="w-[12%]"
        [theme]="{width: '100%', height: '32px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        *ngIf="size?.isAboveXS"
        class="w-[15%]"
        [theme]="{width: '100%', height: '32px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        class="w-8 -mt-7"
        [theme]="{width: '100%', height: '160px', borderRadius: '16px', transform: 'rotate(180deg)'}"
      >
      </ngx-skeleton-loader>
    </div>
  
    <div class="w-full flex gap-4 mt-2 items-start" [ngClass]="{'justify-center': size?.isAboveXS}">
      <ngx-skeleton-loader
        class="-mt-24"
        [ngClass]="{
          'w-[25%]': size?.isAboveXS,
          'w-[60%]': !size?.isAboveXS
        }"
        [theme]="{width: '100%', height: '60px', borderRadius: '32px'}"
      >
      </ngx-skeleton-loader>
    
      <ngx-skeleton-loader
        *ngIf="size?.isAboveXS"
        class="w-[20%] -mt-24"
        [theme]="{width: '100%', height: '50px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
  
      <ngx-skeleton-loader
        *ngIf="size?.isAboveXS"
        class="w-[15%]"
        [theme]="{width: '100%', height: '32px', borderRadius: '16px'}"
      >
      </ngx-skeleton-loader>
    </div>
  
    <div class="w-full flex gap-4  items-start" [ngClass]="{
      '-mt-14 justify-center': size?.isAboveXS
    }">
      <ngx-skeleton-loader
        [ngClass]="{
          'w-[30%]': size?.isAboveXS,
          'w-[80%]': !size?.isAboveXS
        }"
        [theme]="{width: '100%', height: '60px', borderRadius: '32px'}"
      >
      </ngx-skeleton-loader>
    </div>
  </div>
</ng-container>

<div #viewport *ngIf="!isLoading && processedData?.length" class="w-full">
  <d3-encapsulation-component 
    [height]="viewport.clientWidth" 
    [width]="viewport.clientWidth" 
    [data]="processedData" 
    [font]="font" 
    [fontWeight]="weight" 
    [fontStyle]="style" 
    [padding]="padding"
    [rotate]="rotate" 
    [autoFill]="autoFill" 
    [fillMapper]="fillMapper" 
    [animations]="animations" 
    [speed]="speed"
    [tooltip]="tooltip" 
    [hover]="hover" 
    [selection]="selection" 
    [theme]="theme" 
    (wordClick)="onWordClick($event)"
    (wordMouseOver)="onWordMouseOver($event)" 
    (wordMouseMove)="onWordMouseMove($event)"
    (wordMouseOut)="onWordMouseOut($event)">
  </d3-encapsulation-component>
</div>

<div class="flex items-center justify-center p-4" *ngIf="!isLoading && !processedData?.length">
  <p class="text-default text-base text-center">
    {{ 'Não há resultados' | transloco }}
  </p>
</div>