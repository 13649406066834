import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GetUserSessionResponse, SvcCopilotMessage } from '../../models/copilot.model';
import { SvcCopilotService } from '../../services/svc-copilot.service';
import { catchError, finalize, tap } from 'rxjs';
import { SvcCopilotInputComponent } from '../svc-copilot-input/svc-copilot-input.component';
import { User, UserService } from 'projects/lib-shared-core/src/public-api';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'svc-copilot-chat',
  templateUrl: './svc-copilot-chat.component.html',
  styleUrls: ['./svc-copilot-chat.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
  host: {
    '[@slide]': '{ value: \'\', params: {} }',
    '[@.disabled]': 'externalCall',
  }
})
export class SvcCopilotChatComponent implements OnInit {
  @Output() public onClose = new EventEmitter();

  @Input() public smallSize: boolean = false;

  @Input() public externalCall: boolean = false;

  @ViewChild(SvcCopilotInputComponent)
  public copilotInput: SvcCopilotInputComponent;

  @ViewChild('scrollContainer')
  private scrollContainer!: ElementRef;

  public messages: SvcCopilotMessage[] = [];
  public isLoading: boolean = false;
  public currentUser: User;
  public messagesAreGone: boolean = false;
  public emptyViewContent: string = '';

  constructor(
    private _copilotService: SvcCopilotService,
    private _translocoService: TranslocoService,
    private _userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentUser = this._userService.user;
    this.setWelcome();
    this._getMessages();
  }

  scrollToBottom(): void {
    if(this.messages.length > 0){
      setTimeout(()=>{
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      });
    }
  }

  setWelcome() : void{
    this.emptyViewContent = this._translocoService.translate(
    `<span>Olá <label class=\"font-semibold\">{0}</label><label>! Sou o </label><label class=\"font-semibold\">{1}</label>, seu parceiro para apoiar e otimizar suas rotinas de trabalho. Se precisar de insights sobre o conteúdo de excelência operacional existente no Solvace, estou à disposição para conversarmos e explorarmos juntos.</span><br/><br/><span>Quer algumas sugestões de como podemos começar? Estou em constante aprendizado e a cada dia ficando mais inteligente. Atualmente posso ajudar com temas como:</span><br/><br/><ol><li>- Dicas para melhorar a eficiência e a produtividade da equipe.</li><li>- Estratégias para simplificar processos.</li><li>- Soluções práticas para tomada de decisão.</li></ol><br/><br/><span>É só falar!</span>`
    ).replace('{0}', this.currentUser.firstLastName)
    .replace('{1}', 'Jack Copilot');
  }

  setNewInteractions(event: SvcCopilotMessage) : void{
    this.messages = [...this.messages, event];
    this.scrollToBottom();
  }

  private _getMessages() {
    if (!this.messagesAreGone){
      this.isLoading = true;

      this._copilotService.getMessageHistory().pipe(
        tap((res: GetUserSessionResponse[]) => {

          return res.map(item =>{
            this.messages.push({
              messageId: item.messageId,
              content: item.prompt,
              isOwnerMessage: true
            } as SvcCopilotMessage);

            this.messages.push({
              messageId: item.messageId,
              content: item.answer,
              isOwnerMessage: false,
              liked: item.liked
            } as SvcCopilotMessage);
          });
        }),
        catchError((error) => {
          console.log(error);
          return error;
        }),
        finalize(() => {           
          this.scrollToBottom();
          this.isLoading = false;
          this.messagesAreGone = true;
        })
      ).subscribe();
    }
  }
}
