export enum ActionPlanStatusTyped {
  late = 10,
  ontime = 20,
  approval = 30,
  completed = 40,
  inactive = 50
}

export interface ActionPlanStatusMapping {
  [key: number]: string;
}

export const ActionPlanStatusType: ActionPlanStatusMapping = {
  [ActionPlanStatusTyped.late]: 'late',
  [ActionPlanStatusTyped.ontime]: 'ontime',
  [ActionPlanStatusTyped.approval]: 'approval',
  [ActionPlanStatusTyped.completed]: 'completed',
  [ActionPlanStatusTyped.inactive]: 'inactive',
}

export const ActionPlanStatusName: ActionPlanStatusMapping = {
  [ActionPlanStatusTyped.late]: 'Atrasado',
  [ActionPlanStatusTyped.ontime]: 'No prazo',
  [ActionPlanStatusTyped.approval]: 'Em Aprovação',
  [ActionPlanStatusTyped.completed]: 'Cumprido',
  [ActionPlanStatusTyped.inactive]: 'Cancelado',
}
