<div class="py-4 overflow-x-hidden">
  <div class="flex items-center justify-center" *ngIf="isLoading && !isViewMode">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

  <div class="flex-col gap-4" [class]="isLoading && !isViewMode ? 'hidden' : 'flex'">
    <div class="pl-4 flex items-center gap-4">
      <p class="text-base font-medium text-primary">
        {{ 'Total de comportamentos' | transloco }}:
      </p>

      <div class="min-w-6 min-h-6 rounded-[80px] bg-primary flex items-center justify-center">
        <p class="text-base font-normal text-white">
          {{ (totalFilledBehaviors ?? 0) | formatNumberDefault }}
        </p>
      </div>
    </div>

    <div class="w-full flex items-center justify-between gap-2">
      <svc-search-field
        inputId="bos-register-behaviors-search"
        [forceWidthFull]="true"
        (onChanged)="searchBehaviors($event)"
        *ngIf="!isViewMode"
      >
      </svc-search-field>

      <div [class.button-expand-accordions]="isViewMode">
        <svc-button
          buttonId="bos-register-behaviors-expand-accordions"
          icon-only
          color="primary"
          size="lg"
          [mode]="isAllBehaviorsOpen ? 'flat' : 'stroked'"
          (click)="openBehaviors()"
        >
          <mat-icon class="icon-size-6" color="primary" svgIcon="heroicons_solid:bars-3-center-left">
          </mat-icon>
        </svc-button>
      </div>
    </div>

    <div class="flex items-center justify-center p-4" *ngIf="!isLoading && (behaviors?.length === 0 || noneResultsSearch)">
      <p class="text-default text-base text-center">
        {{ 'Não há resultados' | transloco }}
      </p>
    </div>

    <div class="max-h-[575px] overflow-y-auto overflow-x-hidden" *ngIf="behaviors?.length && !noneResultsSearch">
      <mat-accordion class="flex flex-col gap-4 pr-2 -mx-4" multi>
        <ng-container *ngFor="let behavior of behaviors; let index = index">
          <mat-expansion-panel
            #panel
            hideToggle
            [id]="'bos-register-accordion-' + index"
            [class.hidden]="behavior?.isHidden"
          >

            <mat-expansion-panel-header class="flex gap-4" (click)="checkHasAccordionOpen()">
              <mat-panel-title>
                <p class="text-base font-normal text-default break-all">
                  {{ behavior?.categoryName }}
                </p>
              </mat-panel-title>

              <mat-panel-description class="flex justify-end gap-4 m-0 flex-grow-0">
                <div class="min-w-6 min-h-6 rounded-[80px] bg-primary flex items-center justify-center">
                  <p class="text-base font-normal text-white">
                    {{ (totalFilledCategory?.[index] ?? 0) | formatNumberDefault }}
                  </p>
                </div>

                <mat-icon
                  class="text-default icon-size-6"
                  [svgIcon]="panel?.expanded ? 'mat_solid:arrow_drop_up' : 'mat_solid:arrow_drop_down'"
                >
                </mat-icon>
              </mat-panel-description>

            </mat-expansion-panel-header>

            <div class="flex flex-col gap-4 mt-4">
              <ng-container *ngFor="let info of behavior?.behaviors; index as i">
                <form
                  class="flex flex-col gap-4 card-behavior"
                  *ngIf="formBehaviors?.controls?.categories?.controls?.[index]?.controls?.behaviors?.controls[i] as behaviorControl"
                  [formGroup]="behaviorControl"
                  [ngClass]="{ 'hidden': info?.isHidden }"
                >

                  <div class="flex items-center gap-2">
                    <mat-icon
                      class="text-[#E22A2E] icon-size-5"
                      svgIcon="heroicons_solid:exclamation-circle"
                      *ngIf="info?.isCritical"
                    >
                    </mat-icon>

                    <p class="text-black text-base font-normal break-all">
                      {{ info?.behaviorName }}
                    </p>
                  </div>

                  <div class="grid grid-cols-2 gap-2">
                    <img
                      checkImageLoaded
                      class="w-[227px] h-[139px] object-cover"
                      srcOnError="assets/images/default-thumbnail.png"
                      [src]="info?.urlPhoto"
                    >

                    <div class="flex flex-col gap-2">
                      <svc-select
                        formControlName="realizedOkTotal"
                        fallbackTextValue="-"
                        [inputId]="'bos-register-form-behavior-correct-category-' + index + '-behavior-' + i"
                        [label]="'Correto' | transloco"
                        [options]="comboOptions"
                        [readonly]="isViewMode"
                      >
                      </svc-select>

                      <svc-select
                        formControlName="realizedNOkTotal"
                        fallbackTextValue="-"
                        [inputId]="'bos-register-form-behavior-incorrect-category-' + index + '-behavior-' + i"
                        [label]="'Incorreto' | transloco"
                        [options]="comboOptions"
                        [readonly]="isViewMode"
                      >
                      </svc-select>
                    </div>
                  </div>

                  <svc-audio-to-text-wrapper
                    class="d-block w-full overflow-hidden"
                    *ngIf="isViewMode || (behaviorControl?.controls?.realizedOkTotal?.value || behaviorControl?.controls?.realizedNOkTotal?.value)"
                    [showVoiceToTextButton]="!isViewMode && showVoiceToTextButton"
                    [control]="behaviorControl?.controls?.comment"
                  >
                    <svc-text-area
                      class="block w-full"
                      fallbackTextValue="-"
                      formControlName="comment"
                      [inputId]="'bos-register-form-behavior-observation-category-' + index + '-behavior-' + i"
                      [minRows]="5"
                      [label]="'Observações' | transloco"
                      [placeholder]="'Opcional' | transloco"
                      [readonly]="isViewMode"
                    >
                    </svc-text-area>
                  </svc-audio-to-text-wrapper>
                </form>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>

  </div>
</div>
