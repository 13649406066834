import { Component, forwardRef, HostBinding, Inject, Injector, Input, Optional, ViewChild } from '@angular/core';
import { SvcDialogService } from 'projects/lib-shared-component/src/public-api';
import { SvcModalShareComponent } from "../svc-modal-share/svc-modal-share.component";
import { Post } from '../../models/post';
import { environment } from "projects/environments/environment";
import { SvcReactionComponent } from "../../../svc-reaction/svc-reaction.component";
import { StaticApplicationId } from 'projects/lib-shared-core/src/public-api';
import { ReactionTypeEnum } from "../../../svc-reaction/models/reaction-type.model";
import { SvcCommentsChatComponent } from "../../../svc-comments/components/svc-comments-chat/svc-comments-chat.component";
import { SvcSharedListModalComponent } from '../svc-shared-list-modal/svc-shared-list-modal.component';
import { SvcTotalsStatusComponent } from '../../../svc-totals-status/svc-totals-status.component';
import { SvcTotalReactionsComponent } from '../../../svc-reaction/svc-total-reactions/svc-total-reactions.component';
import { SvcPostItemComponent } from '../svc-post-item/svc-post-item.component';
import { SvcPostViewModalComponent } from '../svc-post-view-modal/svc-post-view-modal.component';

@Component({
  selector: 'svc-post-item-footer',
  templateUrl: './svc-post-item-footer.component.html',
  styleUrls: ['./svc-post-item-footer.component.scss']
})
export class SvcPostItemFooterComponent {

  @Input('post') _post: Post;

  @HostBinding('class.from-post-item') public fromPostItem = false;
  @HostBinding('class.from-post-modal') public fromPostModal = false;

  @ViewChild(SvcReactionComponent) public svcReaction: SvcReactionComponent;
  @ViewChild(SvcTotalReactionsComponent) public svcTotalReactions: SvcTotalReactionsComponent;
  @ViewChild(SvcCommentsChatComponent) public svcCommentsChatComponent: SvcCommentsChatComponent;
  @ViewChild(SvcTotalsStatusComponent) public svcTotalsStatus: SvcTotalsStatusComponent;

  public env = environment;

  public isReactionLoading = false;
  public appId = StaticApplicationId.posts;
  public showComments = false;

  constructor(
    private _svcDialog: SvcDialogService,
    @Optional() @Inject(forwardRef(() => SvcPostItemComponent))
    svcPostItem: SvcPostItemComponent,
    @Optional() @Inject(forwardRef(() => SvcPostViewModalComponent))
    svcPostModal: SvcPostViewModalComponent,
  ) {
    this.fromPostItem = !!(svcPostItem);
    this.fromPostModal = !!(svcPostModal);
  }

  sharePost() {
    this._svcDialog.open(SvcModalShareComponent, {
      data: this._post,
      disableClose: true,
      width: '500px'
    });
  }

  usersShared() {
    this._svcDialog.open(SvcSharedListModalComponent, {
      data: { postId: this._post.id },
      width: '100%',
      maxWidth: '350px'
    });
  }

  protected readonly ReactionTypeEnum = ReactionTypeEnum;

  public toggleCommentsChat() {
    this.showComments = !this.showComments;
    if (this.showComments)
      setTimeout(() => {
        this.svcCommentsChatComponent?.focus();
      });
  }

  public refreshSubcomponents() {
    this.updateTotalStatus();
    this.updateReaction();
    this.updateTotalReactions();
    this.updateChat();
  }

  public refreshCounters() {
    this.updateTotalStatus();
    this.updateReaction();
    this.updateTotalReactions();
  }

  public updateTotalStatus() {
    this.svcTotalsStatus?.refresh();
  }

  public updateTotalReactions() {
    this.svcTotalReactions?.refresh();
  }

  public updateReaction() {
    this.svcReaction?.refresh();
  }

  public updateChat() {
    this.svcCommentsChatComponent?.refresh();
  }
}
