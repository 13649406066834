export interface ISvcCard<T> {
    property: keyof T | string;
    actionProperty?: keyof T | string;
    type:
        | 'thumbnail'
        | 'title'
        | 'description'
        | 'id'
        | 'user'
        | 'status'
        | 'date'
        | 'comments'
        | 'actions'
        | 'hidden'
        | 'simpleProgress'
        | 'multiColorProgress'
        | 'reactions'
        | 'priorityButton'
        | 'lockButton'
        | 'settings'
        | 'reactionButton'
        | 'commentButton'
        | 'avatar';
    readonly?: boolean
}
