import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalSelectAreaOrLocationComponent } from './modal-select-location/modal-select-area-or-location.component';
import { ISvcChipGroupedOption, ISvcChipOption } from 'projects/lib-shared-component/src/public-api';
import { FormControl } from '@angular/forms';
import { SvcLocationsService } from './service/svc-location.service';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';
import { Uda } from './service/uda.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'svc-location-field',
  templateUrl: './svc-location-field.component.html',
  styleUrls: ['./svc-location-field.component.scss']
})
export class SvcLocationFieldComponent implements OnDestroy, OnChanges {

  @Input() label: string;
  @Input() formControl: FormControl;
  @Input() canSelectArea: boolean = true;
  @Input() readonly: boolean = false;
  @Input() fallbackTextValue = '-';
  @Input() placeholder: string;
  @Input() inputId = 'svc-location-field-locations';

  protected isLoading = true;
  protected areas: Uda[] = [];
  protected locations: Uda[] = [];
  protected options: ISvcChipGroupedOption[] = [];

  private othersName: string;

  #destroy = new Subject();
  #formControlSubscription: Subscription;

  constructor(
    private _matDialog: MatDialog,
    private _service: SvcLocationsService,
    private _translocoService: TranslocoService,
  ) {
    this.othersName = this._translocoService.translate('Outros');
  }

  public ngOnInit() {
    this._service.initialOptions$.pipe(
      takeUntil(this.#destroy),
      tap((initialOptions) => {
        this.areas = initialOptions.areas;
        this.locations = initialOptions.locations;
        this.options = [
          ...(
            this.canSelectArea
              ? [{
                group: this._translocoService.translate('Áreas'),
                options: this.areas.map((a) => (<ISvcChipOption>{
                  label: a.udaName,
                  value: a.udaId,
                  data: a,
                }))
              }]
              : []
          ),
          {
            group: this._translocoService.translate('Localidades'),
            options: this.locations.map((l) => (<ISvcChipOption>{
              label: l.udaName,
              value: l.udaId,
              data: l,
            }))
          },
        ];
      })
    ).subscribe();
    this._service.initialOptionsIsLoading$.pipe(
      takeUntil(this.#destroy),
      tap((loading) => {
        this.isLoading = loading;
      })
    ).subscribe();
    this._service.getInitialOptions();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('formControl' in changes) {
      this.#listenFormControl();
    }
  }

  public openDialog() {
    if (this.isLoading || this.formControl?.disabled) {
      return;
    }
    this._matDialog.open(ModalSelectAreaOrLocationComponent, {
      data: { canSelectArea: this.canSelectArea },
      panelClass: ['p-4'],
    })
      .afterClosed()
      .subscribe((location: { id: number, text: string }) => {
        if (location) {
          this.options = this.options.filter(opt => opt.group !== this.othersName);
          if (!this.areas.some(a => a.udaId === location.id) && !this.locations.some(l => l.udaId === location.id)) {
            this.options = [
              this.options.length > 0 ? this.options[0] : null,
              this.options.length > 1 ? this.options[1] : null,
              {
                group: this.othersName,
                options: [{ value: location.id, label: location.text }],
              }
            ].filter(g => g !== null);
          }

          this.#formControlSubscription?.unsubscribe();
          setTimeout(() => {
            this.formControl?.setValue([location.id]);
            this.#listenFormControl();
          });
        }
      });
  }

  #listenFormControl() {
    this.#formControlSubscription?.unsubscribe();
    this.#formControlSubscription = this.formControl?.valueChanges.pipe(
      tap(() => {
        this.options = this.options.filter(opt => opt.group !== this.othersName);
      }),
    ).subscribe();
  }

  public ngOnDestroy() {
    this.#destroy?.next(null);
    this.#destroy?.complete();
    this.#formControlSubscription?.unsubscribe();
  }
}
