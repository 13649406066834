import { Component, Input, ViewChild } from '@angular/core';

import { Subject, catchError, takeUntil, tap } from 'rxjs';

import { StaticApplicationId } from 'projects/lib-shared-core/src/public-api';
import { AlertApplicationFeedService } from '../shared/services/alert-application-feed.service';
import { AlertErrorService } from '../shared/services/alert-error.service';
import { AlertApplicationFeed } from '../shared/interfaces/alert-application-feed.interface';
import { SvcCommentsChatComponent } from '../../../general/svc-comments/components/svc-comments-chat/svc-comments-chat.component';

@Component({
  selector: 'app-alert-feed-tab',
  templateUrl: './alert-feed-tab.component.html',
  styleUrls: ['./alert-feed-tab.component.scss']
})
export class AlertFeedTabComponent {

  @ViewChild(SvcCommentsChatComponent) public svcCommentsChat: SvcCommentsChatComponent;

  @Input() public isAddMode: boolean;
  @Input() public alertId: number;

  public alertFeedKey: string;
  public alertApplicationId: string = StaticApplicationId.alert;

  private unsubscribe$ = new Subject<void>();

  constructor (
    private _alertApplicationFeedService: AlertApplicationFeedService,
    private _alertErrorService: AlertErrorService
  ) { }

  public ngOnInit(): void {
    this.getAlertFeedKey();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getAlertFeedKey(): void {
    if (!this.isAddMode)
      this._alertApplicationFeedService.getApplicationFeed()
        .pipe(
          tap((alertApplicationFeed: AlertApplicationFeed) =>
            this.alertFeedKey = alertApplicationFeed.feedKey
          ),
          catchError(err => {
            this._alertErrorService.showErrorInToast(err);
            return err;
          }),
          takeUntil(this.unsubscribe$)
        ).subscribe();
  }
}
