<div *ngIf="!loading && total > 0" class="flex items-center">
  <div *ngFor="let type of reactionsTypeReacted" class="flex-auto">
    <img [src]="type.staticImageUrl" />
  </div>
  <div class="pl-2 text-md">
    <a href="javascript:void(0)" (click)="modalShowReactions(totalReactions)" [ngClass]="classText" [id]="buttonId">{{ total }}</a>
  </div>
</div>

<div class="flex items-center" *ngIf="loading">
  <div class="flex-auto">
    <ngx-skeleton-loader [theme]="{
      'margin-bottom': 0,
      width: '40px',
      height: '15px'}"></ngx-skeleton-loader>
  </div>
 
</div>