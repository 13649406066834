import { Injectable } from '@angular/core';
import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Subject, timer } from 'rxjs';

import { SvcToastGamifiedComponent } from '../svc-toast-gamified.component';
import { SvcToastService } from '../../svc-toast.service';
import { SvcGlobalDataPrefetching } from 'projects/lib-shared-core/src/lib/data-prefetching/svc-global-data-prefetching';

@Injectable({
  providedIn: 'root',
})
export class SvcToastGamifiedService {

  public newPoints$ = new Subject<number>();

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _toastService: SvcToastService,
    private _svcGlobalDataPrefetching: SvcGlobalDataPrefetching,
  ) {

  }

  public showToastGamified(message: string, points: number): void {
    const isGamefied$ = this._svcGlobalDataPrefetching.data.isGamefied;
    const isGamefied = isGamefied$.isLoading ? false : isGamefied$.value;
    isGamefied ? this.openToastGamified(message, points) : this._toastService.success(message);
  }

  private openToastGamified(message: string, points: number): void {
    if (this._overlayRef) this.closeToastGamified();

    const config = new OverlayConfig({
      hasBackdrop: false,
      positionStrategy: this._overlay.position()
        .global()
        .right('25px')
        .bottom('56px'),
      scrollStrategy: this._overlay.scrollStrategies.noop()
    });

    this._overlayRef = this._overlay.create(config);

    const toastPortal = new ComponentPortal(SvcToastGamifiedComponent);
    const toastRef = this._overlayRef.attach(toastPortal);
    this._overlayRef.hostElement.classList.add('svc-toast-gamification');

    toastRef.instance.toastConfig = { message, points };

    timer(200).subscribe({
      next: () => {
        const toastElement = this._overlayRef.overlayElement.querySelector('svc-toast-gamified .toast-gamification');
        toastElement.classList.add('toast-appear');
      }
    });
  }

  public adjustToastGamifiedPosition(): void {
    if (this._overlayRef) {
      const positionStrategy = this._overlayRef.getConfig().positionStrategy;

      if (positionStrategy instanceof GlobalPositionStrategy) {
        this._overlayRef.hostElement.classList.add('svc-toast-gamification-top');
        positionStrategy.bottom('calc(100vh - 118px)');
        positionStrategy.right('75px');
        this._overlayRef.updatePosition();
      }
    }
  }

  public closeToastGamified(points?: number): void {
    if (this._overlayRef) this._overlayRef.dispose();

    if (points > 0) this.newPoints$.next(points);
  }
}
