import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { Newsletter } from '../models/newsletter.model';

@Injectable({ providedIn: 'root' })
export class NewsletterResource {
  constructor(private httpClient: HttpClient, private _appConfig: AppEnvironmentConfig) { }

  getAll() {
    return this.httpClient.get<Newsletter>(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/CurrentEnvironmentList`);
  }

  getCurrentEnvironmentUnreadCount() {
    return this.httpClient.get<number>(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/CurrentEnvironmentUnreadCount`);
  }

  getEnvironmentById(id: number) {
    return this.httpClient.get<Newsletter>(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/ById?id=${id}`);
  }

  markAsRead(id: number) {
    return this.httpClient.put(`${this._appConfig.APIs.apiUrlAuth}/Newsletter/${id}`, null);
  }

}
