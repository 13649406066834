<div
  *ngIf="!chartInfo?.error"
  class="flex flex-col flex-auto"
  [ngStyle]="chartInfo?.loading ? { 'filter': 'blur(2px)' } : null"
  [class]="hasDataChart && !isLoading ? 'flex' : 'hidden'"  
>
  <div
    echarts
    class="flex flex-auto "
    [style.height]="height"
    [options]="chartOptions"
    (chartInit)="onChartInit($event)"
    (chartClick)="onChartClick($event)"
  ></div>
  <ng-content select="svc-chart-buttons"></ng-content>
</div>
<div *ngIf="chartInfo?.error" class="absolute flex justify-center items-center left-0 top-0 w-full h-full" (click)="refresh($event)">
  <mat-icon class="text-primary icon-size-4 inline-block align-middle mr-1" [svgIcon]="'heroicons_solid:exclamation-triangle'" [style.verticalAlign]="'middle'"></mat-icon>
  <span class="text-primary text-sm underline">
    {{ errorMsg }}
  </span>
</div>

<ng-container *screenSizes="let size">
  <div class="flex justify-center items-end gap-3" *ngIf="!chartInfo?.error && (chartInfo?.loading || isLoading)">
    <div class="flex flex-col gap-1" *ngFor="let bar of barsSkeleton; index as index" >
      <ngx-skeleton-loader 
        class="w-18" 
        [theme]="{width: '100%', minHeight: '33px', height: bar, borderTopLeftRadius: '8px', borderTopRightRadius: '8px', maxHeight: height}"
      >
      </ngx-skeleton-loader>

      <ngx-skeleton-loader 
        class="self-center justify-self-center"
        [theme]="{width: '28px', height: '16px', borderRadius: '0'}"
      >
      </ngx-skeleton-loader>
    </div>
  </div>
</ng-container>