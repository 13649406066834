import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LibServiceCilAnalyticsService } from './lib-service-cil-analytics.service';
import { ModuleHttpClient } from './module-http-client';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [
        ModuleHttpClient,
        LibServiceCilAnalyticsService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class LibServiceCilAnalyticsModule { }
