import { inject, Injectable } from '@angular/core';
import { SvcDataPrefetching, SvcDataPrefetchingItem, SvcDataPrefetchingItemStructure } from './svc-data-prefetching.abstract';
import { map, of, zip } from 'rxjs';
import { SvcAppDataPrefetching as SvcAppDataPrefetching } from './svc-app-data-prefetching.abstract';
import { SvcGamificationService } from '../features/gamification/services/svc-gamification.service';
import { AuthService } from '../auth/auth.service';
import { SvcParameters } from '../enums/svc-parameter.enum';
import { ParameterService } from '../services/parameter/parameter.service';

interface SvcGlobalData extends SvcDataPrefetchingItemStructure {
	isGamefied: SvcDataPrefetchingItem<boolean>;
  alertShowTabActionPlan: SvcDataPrefetchingItem<boolean>;
  voiceToText: SvcDataPrefetchingItem<boolean>;
	bosShowTabActionPlan: SvcDataPrefetchingItem<boolean>;
	bosShowOriginator: SvcDataPrefetchingItem<boolean>;
  workCouncils: SvcDataPrefetchingItem<boolean>
}

@Injectable({
	providedIn: 'root'
})
export class SvcGlobalDataPrefetching extends SvcDataPrefetching<SvcGlobalData> {

	private appDataPrefetching = inject(SvcAppDataPrefetching, { optional: true });
	private authService = inject(AuthService);
	private alreadyFetched = false;

	private svcGamificationService = inject(SvcGamificationService);
	private svcParameterService = inject(ParameterService);

	public isLoading$ = zip(
		this._isLoading.asObservable(),
		this.appDataPrefetching?.isLoading$ ?? of(false),
	).pipe(
		map((result) => result[0] && result[1])
	);

	public getAppData<T>() {
		return <T>this.appDataPrefetching?.data;
	}

	private getPrepareDataFetching(): SvcGlobalData {
		return {
			isGamefied: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcGamificationService.getIsGamefied().pipe(
					map((response) => response.isGamefied),
				),
			}),
      alertShowTabActionPlan: new SvcDataPrefetchingItem<boolean>({
        valueFallback: false,
        get: () => this.svcParameterService.getSiteParam(SvcParameters.AlertShowTabActionPlan).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
      }),
			bosShowTabActionPlan: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.BosShowTabActionPlan).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			bosShowOriginator: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.BosFieldOriginatorModal).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
			voiceToText: new SvcDataPrefetchingItem<boolean>({
				valueFallback: false,
				get: () => this.svcParameterService.getSiteParam(SvcParameters.VoiceToText).pipe(
					map((response) => response?.toUpperCase() === 'TRUE'),
				)
			}),
      workCouncils: new SvcDataPrefetchingItem<boolean>({
        valueFallback: false,
        get: () => this.svcParameterService.getGlobalParam(SvcParameters.WorkCouncils)
          .pipe(
					  map((response) => response?.toUpperCase() === 'TRUE'),
				  )
      }),
		};
	}

	public initAndFetch(options?: { force: boolean }) {
		this.authService.check().subscribe(authenticated => {
			if (authenticated && (!this.alreadyFetched || options?.force)) {
				this.alreadyFetched = true;
				this.reset();
				this.prepareDataFetching(this.getPrepareDataFetching());
				this.fetch();
				this.appDataPrefetching?.initAndFetch();
			}
		});
	}

	public reset(): void {
		super.reset();
		this.appDataPrefetching?.reset();
	}
}
