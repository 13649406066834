import { FormControl, ValidatorFn } from '@angular/forms';
import { ISvcChipGroupedOption, ISvcChipOption } from '../../svc-controls/svc-chip/interfaces/svc-chip.interface';
import { ISvcSelectOption } from '../../svc-controls/svc-select/svc-select.component';

export interface SvcFilterField {
    id?: string;
    label?: string;
    name: string;
    initialValue?: any;
    type: 'text' | 'textarea' | 'select' | 'date' | 'chip' | 'chip-grouped' | 'toggle' | 'select-period' | 'date-range';
    config?: {
        options?: ISvcSelectOption[] | ISvcChipOption[] | ISvcChipGroupedOption[],
        minDate?: Date,
        maxDate?: Date,
        showCheckbox?: boolean,
    };
    dependsAnother?: string;
    disabled?: boolean;
    cleanable?: boolean;
    validators?: ValidatorFn[];
}

export interface SvcFilterFields {
  field: SvcFilterField,
  control: FormControl<any>,
  name: string;
  value: any;
}
