import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { CommonCounter, CommonPagination, CommonPaginationRequest } from 'projects/lib-shared-model/src/public-api';
import { ModuleHttpClient } from './module-http-client';
import { KaizenIdea } from './models/kaizen-idea.interface';

export interface KaizenSummary { lcy: number, totalIdeas: number }
@Injectable()
export class LibServiceKaizenAnalyticsService {

  private _approvalsIndicator: Subject<CommonCounter[]> = new Subject<CommonCounter[]>();
  private _kaizenSummary: Subject<KaizenSummary> = new Subject<KaizenSummary>();

  constructor(
    private _httpClient: ModuleHttpClient,
  ) { }

  get _approvalsIndicator$(): Observable<CommonCounter[]> {
    return this._approvalsIndicator.asObservable();
  }

  get _kaizenSummary$(): Observable<KaizenSummary> {
    return this._kaizenSummary.asObservable();
  }

  public getKaizenApprovalsIndicator(): Observable<CommonCounter[]> {
    return this._httpClient.get<CommonCounter[]>('/Counter/kaizen/approvals').pipe(
      tap((indicator) => {
        this._approvalsIndicator.next(indicator);
      })
    );
  }

  public getMyIdeas(pagination: CommonPaginationRequest): Observable<CommonPagination<KaizenIdea[]>> {
    const params = Object.keys(pagination)
      .reduce((params, key) => {
        const value = pagination[key];
        if (!value
          || (Array.isArray(value) && !value.length)
          || (typeof value === "string" && !value.length) || (typeof value === "number" && false) || (typeof value === "boolean" && false)
        ) {
          return params;
        }
        params[key] = value;
        return params;
      }, {});

    return this._httpClient.get('/Idea', { params: params });
  }

  public getTypes() {
    return this._httpClient.get<{
      "typeId": number,
      "typeName": string
    }[]>('/Type/names');
  }

  public getKaizenSummary() {
    return this._httpClient.get<KaizenSummary>('/Idea/summary')
      .pipe(
        tap((summary) => this._kaizenSummary.next(summary))
      );
  }
}
