import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { generateModuleMenuURL } from 'projects/lib-shared-common/src/public-api';
import { SvcAppSettings, UserService } from 'projects/lib-shared-core/src/public-api';
import { SvcActionPlanList, SvcInsertPlanFromDraftPlanResponse } from '../interfaces/svc-actionplan-list.interface';
import { UserAvatarSize } from 'projects/lib-shared-component/src/public-api';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { LegacyService } from 'projects/lib-shared-feature/src/public-api';

@Injectable()
export class SvcActionPlanService {

  private readonly _apiActionPlan: string = this._appConfig.APIs.apiUrlActionPlan;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _appSettings: SvcAppSettings,
    private _http: HttpClient,
    private _legacyService: LegacyService,
    private _translocoService: TranslocoService,
    private _userService: UserService
  ) {
    this._legacyService.addLegacySupport();
  }

  public openDialogActionPlanLegacy(systemReferenceId: number, planId: number, isEdit: boolean, isDraft: boolean): void {
    const applicationId: string = this._appSettings.applicationId;

    const url: string | Function = generateModuleMenuURL(
      'MESSAGE',
      location.origin,
      'openModal',
      `{
        "functionName": "OpenDynamicModal",
        "urlToOpen": "/actionplan/Plan/View",
        "modalData": {
          "flEdit": ${isEdit},
          "flDraft": ${isDraft},
          "planId": ${planId},
          "systemId": "${applicationId}",
          "systemAdditionalReferenceId": 1,
          "systemReferenceId": ${systemReferenceId}
        },
        "modalId": "#registry-action-plan-modal",
        "modalTimeout": 0,
        "loadingText": "${this._translocoService.translate('Carregando')}"
      }`
    );

    if (typeof url === 'function')
      url();
  }

  private _getActionsPlansParams(applicationId: string, referenceId: number, search?: string, additionalReferenceId?: number): HttpParams {
    let params = new HttpParams()
    .append('applicationId', applicationId)
    .append('referenceId', referenceId);

    if (search)
      params = params.append('search', search);

    if (additionalReferenceId)
      params = params.append('additionalReferenceId', additionalReferenceId);

    return params;
  }

  private _mapActionsPlans(actionPlans: SvcActionPlanList[]): SvcActionPlanList[] {
    return actionPlans?.map((actionPlan: SvcActionPlanList) => {
      return {
        ...actionPlan,
        delayedDays: Math.abs(actionPlan?.delayedDays),
        svcUserAvatar: {
          name: actionPlan?.senderName,
          picture: actionPlan?.senderPicture,
          initialsColor: actionPlan?.senderInitialsColor,
          size: UserAvatarSize.Table
        }
      }
    });
  }

  public getActionsPlans(referenceId: number, applicationId: string, search?: string, additionalReferenceId?: number): Observable<SvcActionPlanList[]> {
    const params: HttpParams = this._getActionsPlansParams(applicationId, referenceId, search, additionalReferenceId);

    return this._http.post(`${this._apiActionPlan}/Analytics/actionplan/linked/list`, null, {
      params
    })
      .pipe(
        map((actionPlans: SvcActionPlanList[]) => this._mapActionsPlans(actionPlans))
      );
  }

  public insertPlanFromDraftPlan(tempId: number, registerId: number): Observable<SvcInsertPlanFromDraftPlanResponse> {
    const params: any = {
      siteId: this._userService.user.lastSiteId,
      applicationId: this._appSettings.applicationId,
      temp_id: tempId,
      registerId,
    };
    const supplierId: number = this._userService.user?.supplierId;
    if (supplierId)
      params.supplierId = supplierId;

    return this._http.get<SvcInsertPlanFromDraftPlanResponse>(`${location.origin}/helpers/actionPlan/InsertPlanFromDraftPlan`, {
      params
    });
  }
}
