export interface ISvcCalendarSearch {
  searhCalendarEvents(
    monthStartDate: Date,
    monthFinalDate: Date,
    startDate: Date,
    finalDate: Date
  ): Promise<any>;

  searchCalendarFutureEvents(
    monthStartDate: Date,
    monthFinalDate: Date,
    startDate: Date,
    finalDate: Date
  ): Promise<any>;
}
