import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcCarouselModule, SvcDialogsModule, SvcUserAvatarModule } from 'projects/lib-shared-component/src/public-api';
import { SvcCommentsModule } from '../../general/svc-comments/svc-comments.module';
import { SvcTotalsStatusModule } from '../../general/svc-totals-status/svc-totals-status.module';
import { SvcReactionModule } from '../../general/svc-reaction/svc-reaction.module';
import { ModalViewCommunicationComponent } from './modal-view-communication.component';
import { CommunicationService } from './communication.service';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    ModalViewCommunicationComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    SvcDialogsModule,
    QuillModule,
    SvcButtonsModule,
    SvcUserAvatarModule,
    SvcReactionModule,
    SvcCommentsModule,
    SvcCarouselModule,
    SvcTotalsStatusModule,
    TranslocoModule
  ],
  providers: [
    CommunicationService,
  ],
  exports: [
    ModalViewCommunicationComponent,
  ]
})
export class ModalViewCommunicationModule { }
