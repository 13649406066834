import {EventEmitter, inject, Injectable, OnDestroy} from "@angular/core";
import {Observable} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {SvcDialogService} from "projects/lib-shared-component/src/public-api";
import { HttpClient } from "@angular/common/http";
import {AppEnvironmentConfig} from "projects/config/model/environment.config.model";
import {getAccessTokenPayloadInfo } from "projects/lib-shared-common/src/public-api";

@Injectable()
export abstract class ModalInsightsDefault<T> implements OnDestroy {

  /**
   * Returns true when the check believes it is necessary to display the modal
   */
  abstract check(): Observable<boolean>;

  /**
   * Display Insight Modal
   */
  abstract showModal(): MatDialogRef<any>;

  /**
   * Returns true when response whas saved.
   * If returns false modal will not close
   */
  abstract saveOnClose(): Observable<boolean>;

  /**
   * Fires when Insight is finished
   */
  public onClose = new EventEmitter();
  public onFinishInsight= new EventEmitter();

  public model: T;
  protected modal: MatDialogRef<any>;
  protected _dialogService: SvcDialogService;
  protected _httpClient: HttpClient;
  protected _appConfig: AppEnvironmentConfig;
  protected _userId: string;
  protected _cookieKey: string;

  constructor(
  ) {
    this._dialogService = inject(SvcDialogService);
    this._httpClient = inject(HttpClient);
    this._appConfig = inject(AppEnvironmentConfig);
    this._userId = getAccessTokenPayloadInfo('UserId');
  }

  public dialog() {
    return this._dialogService;
  }

  public run() {

    this.check().subscribe({
      next: r => {

        if (!r) {
          this.onClose.emit();
          return;
        }

        this.modal = this.showModal();

        this.onFinishInsight.subscribe(r => {

          this.saveOnClose().subscribe(r => {
            if (!r) return;
            this.onClose.emit();
            this.modal.close();
          });

        });
      },
      error: (err) => {
        this.onClose.emit();
      }
    })
  }

  protected setCookie(key: string, value: any) {
    const date = new Date();
    date.setTime(date.getTime() + (400*24*60*60*1000));
    document.cookie = `${key}=${value};expires=${date.toUTCString()};path=/`
  }

  protected getCookie(key: string): any {
    const cookie = document.cookie.match(`(^| )${key}=([^;]+)`);
    return cookie ? cookie[2] : null;
  }

  ngOnDestroy(): void {
  }

}
