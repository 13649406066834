import { Component, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SvcAppSettings, UserService } from 'projects/lib-shared-core/src/public-api';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { SvcDistributionAccordionItem } from './interfaces/svc-distribution-manage.interface';
import { SvcDistributionManageFormComponent } from './svc-distribution-manage-form/svc-distribution-manage-form.component';
import { SvcDistributionManageService } from './svc-distribution-manage.service';
import { SvcDistributionAccordionItemSub } from './interfaces/svc-distribution-manage.interface';
import { HttpResponse } from '@angular/common/http';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { SvcDataTableAccordion } from 'projects/lib-shared-component/src/public-api';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { Subject } from 'rxjs';

@Component({
  selector: 'svc-distribution-manage',
  templateUrl: './svc-distribution-manage.component.html',
  styleUrls: ['./svc-distribution-manage.component.scss']
})
export class SvcDistributionManageComponent {

  @ViewChild(SvcDistributionManageFormComponent) distributionForm: SvcDistributionManageFormComponent;

  @AutoDestroy public destroy$ = new Subject<void>();

  public isLoading = true;

  public moduleName: string;
  public applicationId: string;
  public siteId: number;
  public subItemEmit: SvcDistributionAccordionItemSub;
  public exporting = false;

  public items: SvcDistributionAccordionItem[] = [];

  dataTable: SvcDataTableAccordion = {
    subItemsPropertyName: 'sub',
    columns: [
      {
        label: this._translocoService.translate('Distribuição'),
        sort: true,
        property: 'area',
        isSearchable: true,
        accordion: {
          property: 'area',
          type: 'string'
        },
        type: 'string',
        cssClass: 'uppercase',
        cellCssClass: 'uppercase',
      },
      {
        label: this._translocoService.translate('Total responsável'),
        sort: false,
        property: 'total',
        accordion: {
          property: 'total',
          type: 'number',
          cssClass: 'w-[180px] text-center',
          cellCssClass: 'w-[180px] text-center'
        },
        cssClass: 'w-[180px] text-center uppercase',
        cellCssClass: 'w-[180px] text-center',
        type: 'numberRounded'
      }
    ]
  };

  constructor(
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _userService: UserService,
    private _distributionManageService: SvcDistributionManageService,
    private _httpErrorService: HttpErrorService,
  ) {
    this.moduleName = this._translocoService.translate(_appSettings.applicationName);
    this.applicationId = this._appSettings.applicationId;
    this.siteId = this._userService.user.originSiteId;
    this.fetchDistributions();
  }

  public fetchDistributions(): void {
    this._distributionManageService.getDistributionList(this.applicationId, this.siteId).pipe(
      tap((data) => this.transformData(data)),
      catchError(err => {
        this._httpErrorService.showErrorInToast(err)
        return err;
      }),
      finalize(() => this.isLoading = false),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private transformData(data: any[]): void {
    this.isLoading = true;

    const transformedItems: SvcDistributionAccordionItem[] = [];
    let currentItem: SvcDistributionAccordionItem | null = null;

    data.forEach(item => {
      const responsibles = item.responsibles.map((responsible: any) => responsible.responsibleId);

      if (item.isDepartment) {
        if (currentItem) {
          transformedItems.push(currentItem);
        }
        currentItem = {
          area: item.department,
          areaId: item.areaId,
          distributionId: item.distributionId,
          total: item.responsiblesCount,
          sub: [],
          responsibles: responsibles
        };
      } else if (currentItem) {
        currentItem.sub.push({
          area: item.area,
          areaId: item.areaId,
          distributionId: item.distributionId,
          total: item.responsiblesCount,
          responsibles: responsibles
        });
      }
    });

    if (currentItem) {
      transformedItems.push(currentItem);
    }

    this.items = transformedItems;
  }

  private downloadFileFromResponse(responseBlob: HttpResponse<Blob>) {
    const fileName = responseBlob.headers.get('content-disposition')?.split(';')[1].split('=')[1];
    const blobFile: Blob = responseBlob.body as Blob
    const link = document.createElement('a');
    link.download = fileName ?? this._translocoService.translate('Distribuição');
    link.href = URL.createObjectURL(blobFile);
    link.click();
  }

  public handleExportFile(format: 'pdf' | 'excel'): void {
    this.exporting = true;

    this._distributionManageService.downloadFile(this.applicationId, this.siteId, format).pipe(
      tap((responseBlob: HttpResponse<Blob>) => 
        this.downloadFileFromResponse(responseBlob)
    ),
      catchError(err => {
        this._httpErrorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.exporting = false),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  public handleSubRowClick(item: SvcDistributionAccordionItemSub): void {
    this.subItemEmit = {
      area: item.area,
      areaId: item.areaId,
      distributionId: item.distributionId,
      responsibles: item.responsibles
    };
  }
}
