import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Site } from './sites.types';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { SvcCacheName } from '../../../services/cache/svc-cache-name.enum';
import { SvcDataCacheService } from '../../../services/cache/svc-data-cache.service';
import { UserService } from '../../user/services/user.service';
import { AuthService } from '../../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  private _sites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>([]);
  public sites$ = this._sites.asObservable();
  public currentSite: Site;

  private _sitesPendingRequest: Subject<Site[]>;
  private set _sitesCached(value: Site[]) {
    this._svcDataCacheService.set<Site[]>(SvcCacheName.SITES, value);
  }
  private get _sitesCached() {
    const sites = this._svcDataCacheService.get<Site[]>(SvcCacheName.SITES) ?? [];
    return sites.length > 0 ? sites : null;
  }

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _userService: UserService,
    private _svcDataCacheService: SvcDataCacheService,
    private _authService: AuthService,
  ) {
    this._userService.user$.pipe(
      filter((user) => !!(user)),
      tap((user) => {
        const sites = this._sites.value;
        if (sites?.length > 0) {
          sites.forEach((site) => {
            site.isSiteCurrent = site.siteId === user.lastSiteId;
          });
          this._sitesCached = sites;
          this.currentSite = sites.find((x) => x.isSiteCurrent);
          this._sites.next(sites);
        }
      })
    ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all sites
   */
  public getAll(): Observable<Site[]> {
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (!authenticated) return of([]);
        if (!this._sitesPendingRequest && !this._sitesCached) {
          this._sitesPendingRequest = new Subject<Site[]>();
          this._httpClient.get<Site[]>(`${this._appConfig.APIs.apiUrlAuth}/site`).pipe(
            tap((sites) => {
              this._sitesPendingRequest.next(sites);
            }),
            catchError((error) => {
              this._sitesPendingRequest.error(error);
              return error;
            }),
            finalize(() => {
              this._sitesPendingRequest.complete();
              this._sitesPendingRequest = null;
            }),
          ).subscribe();
        }
        return (this._sitesCached ? of(this._sitesCached) : this._sitesPendingRequest).pipe(
          tap((sites) => {
            if (this._sitesCached && this._userService.user) {
              sites.forEach((site) => {
                site.isSiteCurrent = site.siteId === this._userService.user.lastSiteId;
              });
            }
            this._sitesCached = sites;
            this.currentSite = sites.find((x) => x.isSiteCurrent);
            this._sites.next(sites);
          })
        );
      })
    );
  }

  /**
   * Update the active site
   *
   * @param id
   */
  public update(id: number): Observable<{ isSucess: boolean }> {
    return this._httpClient.put<{ isSucess: boolean }>(`${this._appConfig.APIs.apiUrlAuth}/UserPreference/change-site`, { siteId: id }).pipe(
      tap((done) => {
        if (done.isSucess) {
          this.currentSite = null;
          const sites = (this._sitesCached ?? this._sites.value).map((site) => {
            site.isSiteCurrent = site.siteId === id;
            if (site.isSiteCurrent) {
              this.currentSite = site;
            }
            return site;
          });
          this._sitesCached = sites;
          this._sites.next(sites);
        }
      })
    );
  }
}
