<svc-modal-bos-type
  [isAddMode]="modalConfig?.mode === modalTypes.add"
  [class]="modalConfig?.mode === modalTypes.add && !typeSelected?.label ? 'block' : 'hidden'"
  (typeSelected)="setTypeSelected($event)"
  (closeModal)="dialogRef?.close()"
  (finishLoadType)="getData()"
>
</svc-modal-bos-type>

<svc-dialog-primary
  dialogStyle="empty"
  [fullscreen]="['XS', 'SM']"
  [class]="modalConfig?.mode !== modalTypes.add || typeSelected?.label ? 'block' : 'hidden'"
>
  <div svc-dialog-title class="flex w-full">
    <img class="w-auto max-w-fit max-h-8" [src]="environment.logoImageNegative">

    <div class="flex items-center mx-auto text-w">
      <h1 class="uppercase text-base font-bold">
        {{ "BOS" | transloco }}
      </h1>
    </div>
  </div>

  <div svc-dialog-content class="min-h-[715px] sm:min-h-[1223px]">
    <div class="flex items-center justify-center py-30" *ngIf="isLoading">
      <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div class="flex-col gap-4 px-4 pb-4 pt-6" [class]="isLoading ? 'hidden' : 'flex'">
      <form class="flex flex-col gap-4" [formGroup]="formBos">
        <div class="grid grid-cols-2 gap-4">
          <svc-text
            formControlName="feedbackId"
            inputId="bos-register-form-id"
            [label]="'Número' | transloco"
            [readonly]="true"
          >
          </svc-text>

          <svc-text
            inputId="bos-register-form-type"
            [formControl]="type"
            [label]="'Tipo' | transloco"
            [readonly]="true"
          >
          </svc-text>
        </div>

        <div class="flex flex-col gap-2" *ngIf="(!observerEnabled || modalConfig?.mode === modalTypes.view) && !isLoadingObservers">
          <p class="text-default input-label-size input-label-weight">
            {{'Originador' | transloco}}
          </p>

          <div class="flex gap-2" *ngIf="userAvatar?.name || userAvatar?.picture">
            <svc-user-avatar [info]="userAvatar"></svc-user-avatar>

            <p class="text-default text-base font-medium">
              {{ userAvatar?.name }}
            </p>
          </div>
        </div>

        <svc-chip
          *ngIf="(observerEnabled && modalConfig?.mode !== modalTypes.view) || isLoadingObservers"
          type="single"
          formControlName="creatorUserId"
          fallbackTextValue="-"
          inputId="bos-register-form-originator"
          [label]="'Originador' | transloco"
          [options]="optionsCombos?.observers"
          [loading]="modalConfig?.mode !== modalTypes.view && isLoadingObservers"
          [readonly]="modalConfig?.mode === modalTypes.view"
        >
        </svc-chip>

        <svc-location-field
          inputId="bos-register-form-location"
          [formControl]="formBos?.controls?.locationId"
          [readonly]="modalConfig?.mode === modalTypes.view"
        >
        </svc-location-field>

        <div class="grid grid-cols-2 gap-4">
          <svc-chip
            type="single"
            formControlName="teamId"
            fallbackTextValue="-"
            inputId="bos-register-form-team"
            [placeholder]="'Opcional' | transloco"
            [label]="'Time Originador' | transloco"
            [options]="optionsCombos?.teams"
            [loading]="modalConfig?.mode !== modalTypes.view && isAllCombosLoading"
            [readonly]="modalConfig?.mode === modalTypes.view"
          >
          </svc-chip>

          <svc-chip
            type="single"
            formControlName="shiftId"
            fallbackTextValue="-"
            inputId="bos-register-form-shift"
            [label]="'Turno observado' | transloco"
            [options]="optionsCombos?.shifts"
            [loading]="modalConfig?.mode !== modalTypes.view && isAllCombosLoading"
            [readonly]="modalConfig?.mode === modalTypes.view"
          >
          </svc-chip>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <svc-chip
            type="single"
            formControlName="contractorId"
            fallbackTextValue="-"
            inputId="bos-register-form-contractor"
            [placeholder]="'Opcional' | transloco"
            [label]="'Contratista observado' | transloco"
            [options]="optionsCombos?.contractors"
            [loading]="modalConfig?.mode !== modalTypes.view && isAllCombosLoading"
            [readonly]="modalConfig?.mode === modalTypes.view"
          >
          </svc-chip>
        </div>

        <svc-form-custom-field
          #customField
          [featureName]="customFieldsFeature.BosType"
          [registryId]="typeSelected?.value"
          [systemReferenceId]="modalConfig?.id"
          [isViewMode]="modalConfig?.mode === modalTypes.view"
        >
        </svc-form-custom-field>

        <div class="flex gap-2 px-4 py-2 rounded-lg bg-primary-200">
          <mat-icon class="text-primary icon-size-6 self-center" svgIcon="mat_solid:date_range">
          </mat-icon>

          <div class="grid grid-cols-2 gap-2 w-full">
            <svc-date-picker
              formControlName="openDate"
              inputId="bos-register-form-open-date"
              [label]="'Data de abertura' | transloco"
              [showIcon]="false"
            >
            </svc-date-picker>

            <svc-date-picker
              formControlName="feedbackDate"
              inputId="bos-register-form-feedback-date"
              [max]="maxDate"
              [label]="'Data de observação' | transloco"
              [showIcon]="false"
            >
            </svc-date-picker>
          </div>
        </div>
      </form>

      <mat-tab-group #tabgroup mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab [label]="'BOS' | transloco">
          <svc-modal-bos-tab-behaviors
            #tabBehaviors
            [isViewMode]="modalConfig?.mode === modalTypes.view"
            [typeId]="typeSelected?.value"
          >
          </svc-modal-bos-tab-behaviors>
        </mat-tab>

        <mat-tab
          *ngIf="showTabActionPlan || isShowTabActionPlanLoading"
          [disabled]="isShowTabActionPlanLoading"
        >
          <ng-template mat-tab-label>
            {{'Planos de ação' | transloco}}
            <ng-container *ngIf="openPlans && !isShowTabActionPlanLoading">
              <mat-icon class="ml-3 mr-1 icon-size-3" svgIcon="heroicons_solid:clipboard-list" [class.text-primary]="tabgroup.selectedIndex === 1"></mat-icon>
              <p class="font-normal text-base">{{ completePlans }}/{{ openPlans }}</p>
            </ng-container>
          </ng-template>

          <div class="py-4">
            <svc-actionplan
              [id]="modalConfig?.id"
              [originatorId]="bos?.creatorUserId || formBos?.controls?.creatorUserId?.value?.[0]"
              [rolesCreatePermission]="rolesCreatePermission"
              [isViewMode]="modalConfig.mode === SvcModalBosType.view"
              (openPlans)="openPlans = $event"
              (completePlans)="completePlans = $event"
            ></svc-actionplan>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div svc-dialog-actions-options class="flex gap-2 items-center">
    <button
      class="w-7 h-7 min-h-7 min-w-7"
      *ngIf="modalConfig?.mode === modalTypes.view && userHasPermissionToEdit"
      mat-icon-button
      buttonId="bos-register-more-options"
      [matMenuTriggerFor]="moreOptionsMenu"
    >
      <mat-icon class="text-current text-white icon-size-5" svgIcon="heroicons_solid:ellipsis-vertical">
      </mat-icon>
    </button>

    <mat-menu xPosition="before" #moreOptionsMenu="matMenu">
      <button
        class="flex items-center gap-2"
        id="bos-register-remove"
        mat-menu-item
        (click)="deleteBos()"
      >
        <mat-icon class="icon-size-4 text-default" svgIcon="heroicons_solid:trash">
        </mat-icon>

        <span class="text-default text-base font-normal">
          {{ 'Remover' | transloco }}
        </span>
      </button>
    </mat-menu>

    <svc-button
      *ngIf="modalConfig?.mode !== modalTypes.add && userHasPermissionToEdit"
      buttonId="bos-register-edit"
      color="on-primary"
      mode="stroked"
      type="button"
      cdkFocusInitial
      [processing]="isLoading"
      [disabled]="modalConfig?.mode === modalTypes.view ? false : formBos?.invalid || customField?.formCustomFields?.invalid"
      (click)="setEditMode()"
    >
      <p class="font-semibold text-base">
        {{ modalConfig?.mode === modalTypes.view ? ("Editar" | transloco) : ("Salvar" | transloco) }}
      </p>
    </svc-button>

    <svc-button
      color="on-primary"
      type="button"
      buttonId="bos-register-close"
      [mode]="modalConfig?.mode === modalTypes.add ? 'stroked' : 'flat'"
      (click)="dialogRef.close()"
    >
      <p class="font-semibold text-base">
        {{ "Fechar" | transloco }}
      </p>
    </svc-button>

    <svc-button
      *ngIf="modalConfig?.mode === modalTypes.add"
      buttonId="bos-register-add"
      color="on-primary"
      mode="flat"
      type="button"
      cdkFocusInitial
      [disabled]="formBos?.invalid || customField?.formCustomFields?.invalid"
      [processing]="isLoading"
      (click)="createOrUpdateFeedback()"
    >
      <p class="font-semibold text-base">
        {{ "Incluir" | transloco}}
      </p>
    </svc-button>
  </div>
</svc-dialog-primary>
