import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable } from "rxjs";
import * as screenSizes from 'tailwind/screenSizes';

export type BreakpointName = 'XS' | 'SM' | 'MD' | 'LG' | 'XL';

export enum MediaQueryBreakpointStart {
  /**
   * 600
   */
	SM = screenSizes.SM,
  /**
   * 960
   */
	MD = screenSizes.MD,
  /**
   * 1280
   */
	LG = screenSizes.LG,
  /**
   * 1440
   */
	XL = screenSizes.XL,
}

export interface MediaQuerySize {
	isXS: boolean;
	isSM: boolean;
	isMD: boolean;
	isLG: boolean;
	isXL: boolean;
	isAboveXS: boolean;
	isAboveSM: boolean;
	isAboveMD: boolean;
	isBelowMD: boolean;
	isAboveLG: boolean;
	isBelowLG: boolean;
	isBelowXL: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class SvcMediaQuery {

	private _size = new BehaviorSubject<MediaQuerySize>(this._getMediaQuerySize());
	private get _width() {
		return document.body?.parentElement?.clientWidth ?? window.innerWidth;
	} 
	public get size$(): Observable<MediaQuerySize> {
		return this._size.asObservable();
	}
	public get currentSize(): MediaQuerySize {
		return this._size.value;
	}

	constructor() {
    fromEvent(window, 'resize').subscribe(() => this._onWindowResize());
	}

	public isIn(breakpoints: BreakpointName[]) {
		return breakpoints.some((bp) => {
			if (this._isValidBreakpoint(bp)) {
				const nextBreakpoint = this._getNextBreakpointName(bp);
				return nextBreakpoint
					? this._width < MediaQueryBreakpointStart[nextBreakpoint]
					: this._width >= MediaQueryBreakpointStart.XL;
			}
			return false;
		});
	}

	private _isValidBreakpoint(breakpoint: BreakpointName): boolean {
		return ['XS', 'SM', 'MD', 'LG', 'XL'].includes(breakpoint)
	}

	private _getNextBreakpointName(breakpoint: BreakpointName): BreakpointName {
		switch(breakpoint) {
			case 'XS': return 'SM';
			case 'SM': return 'MD';
			case 'MD': return 'LG';
			case 'LG': return 'XL';
		}
		return null;
	}

	private _onWindowResize() {
		const size = this._getMediaQuerySize();
		if (JSON.stringify(size) !== JSON.stringify(this._size.value)) {
			this._size.next(size);
		}
	}

	private _getMediaQuerySize(): MediaQuerySize {
		return {
			isXS: this._width < MediaQueryBreakpointStart.SM,
			isSM: this._width >= MediaQueryBreakpointStart.SM && this._width < MediaQueryBreakpointStart.MD,
			isMD: this._width >= MediaQueryBreakpointStart.MD && this._width < MediaQueryBreakpointStart.LG,
			isLG: this._width >= MediaQueryBreakpointStart.LG && this._width < MediaQueryBreakpointStart.XL,
			isXL: this._width >= MediaQueryBreakpointStart.XL,
			isAboveXS: this._width >= MediaQueryBreakpointStart.SM,
			isAboveSM: this._width >= MediaQueryBreakpointStart.MD,
			isAboveMD: this._width >= MediaQueryBreakpointStart.LG,
			isBelowMD: this._width < MediaQueryBreakpointStart.MD,
			isAboveLG: this._width >= MediaQueryBreakpointStart.XL,
			isBelowLG: this._width < MediaQueryBreakpointStart.LG,
			isBelowXL: this._width < MediaQueryBreakpointStart.XL,
		};
	}
}