<div class="flex-auto divide-y" *transloco="let t">
  <ag-virtual-scroll *ngIf="tasks && tasks.length > 0" #vs [items]="tasks" height="500px" [min-row-height]="90">
    <div *ngFor="let task of vs.items" class="table w-full hover:bg-zinc-100 py-2 px-2">
      <div class="flex">
        <div class="flex flex-col" [style.width.px]="52">
          <div class="flex items-center justify-start pl-2" [style.minHeight.px]="30">
            <svc-bookmark-button
              *ngIf="task.hasBookmark"
              iconSizeClass="icon-size-5"
              [referenceId]="task.id"
              [applicationId]="task.applicationId"
              [initialValue]="task.isBookmark"
              (onInitialValueObtained)="handleBookmarkChange(task, $event)"
              (onValueChanged)="handleBookmarkChange(task, $event)"
            ></svc-bookmark-button>
            <svc-lock-button class="flex" *ngIf="task.hasOwnProperty('locked') && task.locked !== null" [readonly]="true"
              [locked]="task.locked"></svc-lock-button>
          </div>
          <div class="flex -space-x-7 justify-center">
            <div *ngFor="let owner of task.owners | slice:0:2; index as i">
              <svc-user-avatar
                *ngIf="i < 1 || (i >= 1 && task.owners.length <= 2)"
                [info]="{ name: owner.userName, picture: owner.picture, size: avatarSize, initialsColor: owner.initialsColor }"
                [matTooltip]="owner.userName"
              ></svc-user-avatar>
              <a
                *ngIf="i >= 1 && task.owners.length > 2"
                class="flex items-center justify-center w-10 h-10 text-base font-medium text-white bg-primary rounded-full border-2 border-white"
                [matTooltip]="(task.owners | getProperty:'userName') | slice:1 | join">
                +{{task.owners.length - 1}}
              </a>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-auto pl-3">
          <div class="flex items-center justify-between">
            <a class="text-primary-500 text-sm font-bold whitespace-nowrap cursor-pointer"
              (click)="openModuleItem(task)">
              {{ t(task.label) }}-{{ task.id }}{{ task.registryNumber ? ('/' + task.registryNumber) : '' }}
            </a>
            <p *ngIf="task.deadline > 0"
              class="flex-auto text-default text-sm leading-tight line-clamp-3 max-h-16 grow break-all text-right">
              <span class="block mx-2">
                {{ task.deadline | formatNumberDefault }} {{t('dias')}}
              </span>
            </p>
            <svc-status-badge class="flex justify-end" [badgeData]="task.status"></svc-status-badge>
          </div>
          <div class="relative text-default text-sm py-1" [style.height.px]="54">
            <p class="absolute leading-tight line-clamp-3 break-all -translate-y-2/4 top-1/2">
              {{ task.description }}
            </p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="[taskModules.CHECKLIST, taskModules.CIL].includes(task.moduleName)">
        <div class="basis-full relative mt-1">
          <svc-multicolor-progress-bar
            [progressData]="task.itemsData"
            [template-tooltip]="tooltipTemplate"
            (mouseClickColumn)="openInpectionNok(task, $event)"
          ></svc-multicolor-progress-bar>
          <ng-template #tooltipTemplate>
            <div class="flex flex-col text-white">
              <div class="flex flex-row">
                <span class="grow whitespace-nowrap uppercase">{{t('Total tarefas')}}</span>
                <span class="ml-3">{{ task.itemsData.total }}</span>
              </div>
              <hr class="mx-0 my-1" />

              <div class="flex flex-col gap-1">
                <div *ngFor="let data of task.itemsData.data" class="flex flex-row items-center">
                  <div class="w-3 h-3 mr-2 rounded-full" [style.background-color]="data.color"></div>
                  <span class="grow">{{t(data.label)}}</span>
                  <span>{{data.value}}</span>
                </div>
              </div>

              <hr class="mx-0 my-1" />
              <div class="flex flex-row">
                <span class="grow whitespace-nowrap uppercase">{{t('Total realizadas')}}</span>
                <span class="ml-3">{{ task.itemsData.totalPerformed }}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ag-virtual-scroll>
</div>
