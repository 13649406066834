<!-- Button -->
<ng-container *ngIf="!renderAsMenuItemContent && !renderAsSubMenuItemContent">
  <button
    class="bg-inherit text-inherit"
    mat-flat-button
    [matMenuTriggerFor]="sites.length > 0 ? siteActions : null"
    [class.cursor-default]="sites.length <= 0"
  >
    <ng-container *ngTemplateOutlet="siteIcon; context: { $implicit: active }"></ng-container>
  </button>
  <mat-menu [xPosition]="'before'" #siteActions="matMenu">
    <div *ngIf="sites">
      <button
        mat-menu-item
        (click)="changeActiveSite(site.siteId)"
        *ngFor="let site of sites"
      >
        <ng-container
          *ngTemplateOutlet="siteIcon; context: { $implicit: site }"
        ></ng-container>
      </button>
    </div>
  </mat-menu>
</ng-container>

<ng-container *ngIf="renderAsMenuItemContent">
  <ng-container
    *ngTemplateOutlet="siteIcon; context: { $implicit: active }"
  ></ng-container>
</ng-container>

<ng-container *ngIf="renderAsSubMenuItemContent">
  <div *ngIf="sites">
    <button
      mat-menu-item
      (click)="changeActiveSite(site.siteId)"
      *ngFor="let site of sites"
    >
      <ng-container
        *ngTemplateOutlet="siteIcon; context: { $implicit: site }"
      ></ng-container>
    </button>
  </div>
</ng-container>

<ng-template #siteIcon let-site>
  <div class="flex flex-row items-center">
    <ng-container *ngIf="site">
      <mat-icon *ngIf="!site.isGuest" class="text-current icon-size-6" svgIcon="heroicons_solid:map-pin"/>
      <mat-icon *ngIf="site.isGuest" class="text-current icon-size-6" svgIcon="heroicons_solid:globe-alt"/>
      <span>{{ site.siteName }}</span>
    </ng-container>
    <div *ngIf="!site" class="w-6 h-6"></div>
  </div>
</ng-template>
