import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '../../../../lib-shared-core/src/lib/auth/auth.interceptor';
import { RouterModule } from "@angular/router";
import { authRoutes } from "./auth-routing";
import { NotFound404Module } from 'projects/lib-shared-core/src/public-api';

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forChild(authRoutes),
        NotFound404Module,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AuthModule { }
