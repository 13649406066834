import { ISvcAvatarMultiple } from 'projects/lib-shared-component/src/lib/svc-user-avatar/interfaces/svc-avatar-multiple.interface';

export interface PraiseItemCard {
  id: string;
  title: string;
  description: string;
  user: { id: string, name: string };
  avatar: ISvcAvatarMultiple;
  date: Date;
  hasReactions: boolean;
  hasComments: boolean;
}
