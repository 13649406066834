export interface GetCounterByIdParams {
  applicationId: string;
}

export interface GetCounterByIdResponse {
  id: number;
  total: number;
}

export interface GetCounterByStatusParams {
  applicationId: string;
}

export interface GetCounterByStatusResponse {
  lateId: number;
  lateText: string,
  lateTotal: number;
  latePercentage: number;
  onTimeId: number;
  onTimeText: string,
  onTimeTotal: number;
  onTimePercentage: number;
  inApprovalId: number;
  inApprovalText: string,
  inApprovalTotal: number;
  inApprovalPercentage: number;
  completedId: number;
  completedText: string,
  completedTotal: number;
  completedPercentage: number;
  total: number;
}