import { AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MediaQuerySize, SvcMediaQuery } from '../services/svc-media-query.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

type ScreenSize = 'XS' | 'SM' | 'MD' | 'LG' | 'XL';

@Directive({
	selector: '[matDialogFullscreen]'
})
export class MatDialogFullscreenDirective implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

	@Input('matDialogFullscreen') sizes: string | ScreenSize[] = ['XS'];
	@Input('fullscreenDisabled') disabled: boolean = false;
	@Output() onFullScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

	private get _element(): HTMLElement { return this._elementRef.nativeElement; }
	private _unsubscribe: Subject<any> = new Subject<any>();
	private _cdkOverlayWrapper: HTMLElement;

	constructor(
		@Optional()
    private _dialogRef: MatDialogRef<any>,
		private _elementRef: ElementRef<HTMLElement>,
		private _mediaQuery: SvcMediaQuery,
	) {
	}

	public ngOnInit() {
		this._dialogRef?.afterOpened().pipe(
			tap(() => this.ngAfterContentInit()),
		).subscribe();
	}

	public ngAfterViewInit() {
		this._mediaQuery.size$.pipe(
			takeUntil(this._unsubscribe),
			tap((size: MediaQuerySize) => {
				this._onMediaQueryChanged(size);
			}),
		).subscribe();
		this._prepareSize();
	}

	public ngAfterContentInit(): void {
		this._solveScrollProblems();
		this._tryToScrollToTheTop();
	}

	private _solveScrollProblems() {
		const cdkOverlayWrapper = this._getCdkOverlayWrapper() ?? this._getCdkOverlayConnectedBoundingBox();
		if (cdkOverlayWrapper) {
			cdkOverlayWrapper.style.justifyContent = null;
			cdkOverlayWrapper.style.alignItems = null;
			cdkOverlayWrapper.style.pointerEvents = 'auto';
			(cdkOverlayWrapper.children[0] as HTMLElement).style.margin = 'auto';
		}
	}

	private _prepareSize() {
		if (typeof this.sizes === 'string') {
			this.sizes = ((!this.sizes || <string>this.sizes == '') ? ['XS'] : [this.sizes]) as ScreenSize[];
		}
	}

	private _onMediaQueryChanged(size: MediaQuerySize) {
		const currentSize = this._getCurrentSize(size);
		const cdkOverlayWrapper = this._getCdkOverlayWrapper() ?? this._getCdkOverlayConnectedBoundingBox();
		if (this.disabled || cdkOverlayWrapper !== document.body) {
			const className = 'mat-dialog-fullscreen';
			if (this.disabled || !this.sizes?.includes(currentSize)) {
				if (cdkOverlayWrapper.classList.contains(className)) {
					cdkOverlayWrapper.classList.remove(className);
					this.onFullScreen.emit(false);
				}
			}
			else if (!cdkOverlayWrapper.classList.contains(className)) {
				cdkOverlayWrapper.classList.add(className);
				this.onFullScreen.emit(true);
			}
		}
	}

	private _getCurrentSize(size: MediaQuerySize): ScreenSize {
		if (size.isXS) {
			return 'XS';
		}
		if (size.isSM) {
			return 'SM';
		}
		if (size.isMD) {
			return 'MD';
		}
		if (size.isLG) {
			return 'LG';
		}
		return 'XL';
	}

	private _getCdkOverlayWrapper(): HTMLElement {
		if (this._cdkOverlayWrapper) {
			return this._cdkOverlayWrapper;
		}
		let parent: HTMLElement;
		do {
			parent = (parent ?? this._element).parentElement;
		} while ((parent == null || !parent.classList.contains('cdk-global-overlay-wrapper')) && parent !== document.body);
		this._cdkOverlayWrapper = parent;
		return parent;
	}

	private _getCdkOverlayConnectedBoundingBox(): HTMLElement {
		if (this._cdkOverlayWrapper) {
			return this._cdkOverlayWrapper;
		}
		let parent: HTMLElement;
		do {
			parent = (parent ?? this._element).parentElement;
		} while ((parent == null || !parent.classList.contains('cdk-overlay-connected-position-bounding-box')) && parent !== document.body);
		this._cdkOverlayWrapper = parent;
		return parent;
	}

	private _tryToScrollToTheTop(): void {
		const cdkOverlayWrapper = this._getCdkOverlayWrapper() ?? this._getCdkOverlayConnectedBoundingBox();
		if (cdkOverlayWrapper) {
			cdkOverlayWrapper.scrollTop = 0;
		}
	}

	public ngOnDestroy(): void {
		this._unsubscribe.next(null);
		this._unsubscribe.complete();
		const cdkOverlayWrapper = this._getCdkOverlayWrapper() ?? this._getCdkOverlayConnectedBoundingBox();
		cdkOverlayWrapper.classList.remove('mat-dialog-fullscreen');
	}
}
