import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {SvcFaqComponent} from "../svc-faq/svc-faq.component";
import { UserEnvironment, UserService } from 'projects/lib-shared-core/src/public-api';

@Component({
  selector: 'svc-process',
  templateUrl: './svc-process.component.html',
  styleUrls: ['./svc-process.component.scss']
})
export class SvcProcessComponent {
  public environment: UserEnvironment = this._userService.environment;

  constructor(
      public _dialogRef: MatDialogRef<SvcProcessComponent>,
      private _userService: UserService,
  ) {}

  closeDialog(){
    this._dialogRef.close();
  }
}
