<svc-dialog [fullscreen]="['XS', 'SM']">
  <mat-icon
    class="text-default icon-size-6"
    svc-dialog-title-icon
    svgIcon="heroicons_solid:plus"
  >
  </mat-icon>

  <p class="text-default font-bold text-xl" svc-dialog-title>
    {{ 'Nova observação' | transloco }}
  </p>

  <div class="p-2" svc-dialog-content>
    <svc-chip
      type="single"
      [formControl]="formControlType"
      [loading]="isLoadingTypes"
      [label]="'Tipo' | transloco"
      [options]="types"
    >
    </svc-chip>
  </div>

  <div svc-dialog-actions>
    <div class="flex gap-2 p-2">
      <svc-button
        color="primary"
        type="button"
        buttonId="bos-register-type-cancel"
        mode="stroked"
        (click)="closeModal.emit()"
      >
        <p class="text-sm text-primary font-semibold">
          {{ "Cancelar" | transloco }}
        </p>
      </svc-button>

      <svc-button
        buttonId="bos-register-type-confirm"
        color="primary"
        mode="flat"
        type="button"
        cdkFocusInitial
        [disabled]="formControlType?.invalid || isLoadingTypes"
        (click)="addType()"
      >
        <p class="text-sm font-semibold">
          {{ "Confirmar" | transloco}}
        </p>
      </svc-button>
    </div>
  </div>
</svc-dialog>
