import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule, SvcButtonsModule, SvcControlsModule, SvcDialogsModule } from 'projects/lib-shared-component/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedModelModule } from 'projects/lib-shared-model/src/public-api';
import { SvcLocationFieldComponent } from './svc-location-field.component';
import { ModalSelectAreaOrLocationComponent } from './modal-select-location/modal-select-area-or-location.component';
import { SvcLocationsService } from './service/svc-location.service';

@NgModule({
  declarations: [
    SvcLocationFieldComponent,
    ModalSelectAreaOrLocationComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedModelModule,
    TranslocoModule,
    SvcControlsModule,
    SvcButtonsModule,
    SvcDialogsModule,
  ],
  exports: [
    SvcLocationFieldComponent,
    ModalSelectAreaOrLocationComponent,
  ],
  providers: [
    SvcLocationsService,
  ],
})
export class SvcLocationFieldModule { }
