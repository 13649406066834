import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/public-api";
import { Observable } from "rxjs";
import { GetUserSessionResponse, QuestionResponse } from "../models/copilot.model";

@Injectable()
export class SvcCopilotService extends SvcHttpClient {
  constructor(
    protected _appConfig: AppEnvironmentConfig,
    protected _httpClient: HttpClient,
  ) {
    super(
      _appConfig.APIs.apiUrlCopilot,
      _httpClient
    );
  }

  public getMessageHistory(): Observable<GetUserSessionResponse[]> {
    return this.get('/Chat');
  }

  public sendPrompt(prompt: string): Observable<QuestionResponse> {
    return this.post('/Chat',
    { 
      prompt
    });
  }

  public sendReaction(messageId: string, liked: boolean): Observable<boolean> {
    return this.put('/Reaction',
      { 
        messageId,
        liked 
      });
  }
}