import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SvcCustomFieldHttpClient } from '../svc-custom-fields/svc-custom-field-http-client';
import { SvcFormCustomFieldValue, SvcFormCustomFieldValueData } from './svc-form-custom-field.interface';

@Injectable()
export class SvcFormCustomFieldValueService {

  constructor(
    private _http: SvcCustomFieldHttpClient,
  ) { }

  /**
   * Endpoint for creating (insert) or updating a Value.
   *
   * @param customFieldValue - The custom field Value to register or update.
   * @returns An observable of a boolean indicating the success of the operation.
   */
  public updateCustomFieldValue(customFieldValue: SvcFormCustomFieldValue): Observable<boolean> {
    return this._http.post('/CustomFieldValue', {
      ...customFieldValue,
      applicationId: this._http.applicationId,
      siteId: this._http.siteId,
    });
  }

  public getCustomFieldValue(featureName: string, registryId: number, systemReferenceId: number): Observable<SvcFormCustomFieldValueData> {
    return this._http.get('/CustomFieldValue', {
      params: {
        applicationId: this._http.applicationId,
        siteId: this._http.siteId,
        featureName,
        registryId,
        systemReferenceId
      }
    });
  }
}
