import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SvcCustomFieldService } from '../svc-custom-fields/svc-custom-field.service';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { catchError, finalize, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcCustomField, SvcCustomFieldList } from '../svc-custom-fields/svc-custom-field.interface';
import { SvcFormCustomFieldType } from './svc-form-custom-field.enum';
import { SvcFormCustomFieldValueData, SvcFormCustomFieldValueFields } from './svc-form-custom-field.interface';
import { SvcFormCustomFieldValueService } from './svc-form-custom-field-value.service';
import { ISvcChipOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-chip/interfaces/svc-chip.interface';

@Component({
  selector: 'svc-form-custom-field',
  templateUrl: './svc-form-custom-field.component.html',
  styleUrls: ['./svc-form-custom-field.component.scss']
})
export class SvcFormCustomFieldComponent implements OnInit, OnChanges {

  /**
  * Name of the feature, such as "bos-type" or "rca-type",
  * which corresponds to the type displayed in the registration form.
  */
  @Input() public featureName: string;

  /**
   * Unique identifier for the type created in the type registration screen
   * (e.g., BOS type, RCA type).
   */
  @Input() public registryId: number;

  /**
   * External reference ID for the register in the module,
   * such as the ID of a BOS, alert, etc.
   */
  @Input() public systemReferenceId: number;

  @Input() public isViewMode: boolean;

  @AutoDestroy public destroy$ = new Subject<void>();

  public formCustomFields: FormGroup = this._fb.group({
    fields: this._fb.array([])
  });
  public loadedCustomFields: boolean;
  public customFields: SvcCustomFieldList;
  public fieldTypes = SvcFormCustomFieldType;
  public isLoading: boolean;
  public fieldsIdsToRemoveCustomFields: number[] = [];

  constructor(
    private _customFieldService: SvcCustomFieldService,
    private _errorService: HttpErrorService,
    private _fb: FormBuilder,
    private _customFieldValueService: SvcFormCustomFieldValueService
  ) { }

  public ngOnInit(): void {
    this._getCustomFields();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.featureName?.previousValue !== changes?.featureName?.currentValue || changes?.registryId?.previousValue !== changes?.registryId?.currentValue)
      this._getCustomFields();
  }

  private _getFieldValueByType(field: SvcCustomField): any {
    if (field?.fieldTypeId === this.fieldTypes.Checkbox)
      return JSON.parse?.(field?.fieldValue?.[0]);
    else if (field?.fieldTypeId === this.fieldTypes.Number)
      return Number?.(field?.fieldValue?.[0]);
    else if ([this.fieldTypes.ListMultiple, this.fieldTypes.List].includes(field?.fieldTypeId))
      return field?.fieldValue;
    else
      return field?.fieldValue?.[0];
  }

  private _setValueForm(): void {
    this.customFields?.fields?.forEach((field: SvcCustomField, index: number) => {
      const fields = this.formCustomFields?.get?.('fields') as FormArray;
      const fieldControl = (fields?.controls?.[index] as FormGroup)?.controls?.fieldValue;
      fieldControl.setValue(field?.fieldValue);
    });
    this.formCustomFields.disable();
  }

  private _setCustomFields(customFields: SvcFormCustomFieldValueData, registryId: number): void {
    this.customFields = {
      featureName: this.featureName,
      registryId: registryId,
      fields: customFields?.fields?.map(field => {
        return {
          ...field,
          fieldValue: this._getFieldValueByType(field),
          optionsCombo: this._getOptionsCombo(field?.options)
        }
      })
    };
    this._createFormCustomFields();
    this._setValueForm();
  }

  public getCustomFieldByExternalReferenceId(registryId: number, systemReferenceId: number): Observable<any> {
    return this._customFieldValueService.getCustomFieldValue(this.featureName, registryId, systemReferenceId).pipe(
      tap((customFields: SvcFormCustomFieldValueData) => this._setCustomFields(customFields, registryId))
    );
  }

  private _canGetCustomFields(): boolean {
    return !this.systemReferenceId && this.featureName && this.registryId && !this.loadedCustomFields && !this.isLoading;
  }

  private _getCustomFields(): void {
    if (this._canGetCustomFields()) {
      this.isLoading = true;
      this._customFieldService.getCustomField(this.featureName, this.registryId).pipe(
        tap((customFields: SvcCustomFieldList) => {
          this.customFields = customFields;
          this._createFormCustomFields();
        }),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => {
          this.isLoading = false;
          this.loadedCustomFields = true;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    }
  }

  private _getOptionsCombo(options: string[]): ISvcChipOption[] {
    return options?.map(option => (
      {
        label: option,
        value: option,
      }
    ));
  }

  private _createFormCustomFields(): void {
    this.formCustomFields?.reset();
    const fields = this.formCustomFields?.get?.('fields') as FormArray;
    fields?.clear();

    this.customFields?.fields?.forEach((customField: SvcCustomField) => {
      customField.optionsCombo = this._getOptionsCombo(customField?.options);

      const field: FormGroup = this._fb.group({
        customFieldListId: [customField.customFieldListId],
        fieldValue:  [ ],
        fieldTypeId: [ customField.fieldTypeId ]
      });

      if (customField?.mandatory)
        field.get('fieldValue').setValidators(Validators.required);

      fields.push(field);
    });
  }

  private _getValueCustomField(field: any): SvcFormCustomFieldValueFields {
    return {
      customFieldListId: field?.customFieldListId,
      fieldValue: [ String(field?.fieldValue) ],
    };
  }

  private _getFieldsValue(isUpdate: boolean): SvcFormCustomFieldValueFields[] {
    const fields: SvcFormCustomFieldValueFields[] = [];
    this.fieldsIdsToRemoveCustomFields = [];

    this.formCustomFields?.value?.fields?.forEach(field => {
      if (field?.fieldTypeId === SvcFormCustomFieldType.Checkbox)
        fields.push({
          ...this._getValueCustomField(field),
          fieldValue: [ String(field?.fieldValue ?? false) ]
        });
      else if (Array.isArray(field?.fieldValue) && field?.fieldValue?.length)
        fields.push({
          ...this._getValueCustomField(field),
          fieldValue: field.fieldValue
        });
      else if (field?.fieldValue)
        fields.push(this._getValueCustomField(field));
      else if (!isUpdate)
        this.fieldsIdsToRemoveCustomFields.push(field?.customFieldListId);
    });

    return fields;
  }

  private _removeFieldsNoValue(): void {
    this.customFields.fields = this.customFields?.fields?.filter(
      field => !this.fieldsIdsToRemoveCustomFields.includes(field?.customFieldListId)
    );

    const fields = this.formCustomFields?.get('fields') as FormArray;

    fields.controls = fields?.controls?.filter((control: FormGroup) => {
      const fieldId = control?.get('customFieldListId')?.value;
      return !this.fieldsIdsToRemoveCustomFields.includes(fieldId);
    });
  }

  public changeCustomFieldRecord(externalReferenceId: number, isUpdate: boolean): Observable<boolean> {
    const fields: SvcFormCustomFieldValueFields[] = this._getFieldsValue(isUpdate);

    if (fields?.length)
      return this._customFieldValueService.updateCustomFieldValue({
        featureName: this.featureName,
        registryId: this.registryId,
        systemReferenceId: this.systemReferenceId ?? externalReferenceId,
        fields,
      }).pipe(
        tap(() => {
          this.formCustomFields.disable();
          this._removeFieldsNoValue();
        })
      );
    return of(true);
  }

  public formValid(): boolean {
    this.formCustomFields?.markAllAsTouched();
    return this.formCustomFields?.valid;
  }
}
