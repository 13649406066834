import { Injectable } from '@angular/core';
import {AppEnvironmentConfig} from "projects/config/model/environment.config.model";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _http: HttpClient
  ) { }

  getAppRules(appId: string) {
    return this._http.get<{
        ruleName: string,
        types: {
            configurationId: 0,
            typeName: string,
            active: true
        }[]
      }[]>(`${this._appConfig.APIs.apiUrlNotification}/Configuration/ByApplication`, { params: {
        applicationId: appId
      }});
  }

  getRule(ruleId: number) {
    return this._http.get<{
      ruleName: string,
      types: {
        configurationId: 0,
        typeName: string,
        active: true
      }[]
    }[]>(`${this._appConfig.APIs.apiUrlNotification}/Configuration/ByRule`, { params: {
      ruleId: ruleId
    }});
  }

  saveRule(config: {ConfigurationId: number, Active: boolean}) {
    const formData = new FormData();
    formData.append('ConfigurationId', config.ConfigurationId.toString());
    formData.append('Active', config.Active.toString());
    return this._http.put(`${this._appConfig.APIs.apiUrlNotification}/Configuration/ByUserId`, formData);
  }
}
