import {Component, EventEmitter, HostListener, inject, Input, Output, ViewContainerRef} from '@angular/core';
import { SvcCopilotChatComponent } from '../svc-copilot-chat/svc-copilot-chat.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/public-api';

@Component({
  selector: 'svc-copilot-float-button',
  templateUrl: './svc-copilot-float-button.component.html',
  styleUrls: ['./svc-copilot-float-button.component.scss']
})
export class SvcCopilotFloatButtonComponent {
  @Input() bottomCssClass: string;
  
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  #overlay = inject(Overlay);
  #overlayRef: OverlayRef | null = null;
  #viewContainerRef = inject(ViewContainerRef);
  #mediaQuery = inject(SvcMediaQuery);

  public onClick() {
    if (this.#overlayRef == null) {
      this.#overlayRef = this.#overlay.create({
        hasBackdrop: true,
        backdropClass: 'svc-backdrop-on-tablet',
        panelClass: ['h-full'],
        scrollStrategy: this.#overlay.scrollStrategies.block(),
        positionStrategy: this.#getOptionsPositionStrategy(),
      });
      this.#overlayRef.backdropClick().subscribe(() => {
        this.#overlayRef.detach();
      });
    }

    const component = new ComponentPortal(
      SvcCopilotChatComponent,
      this.#viewContainerRef
    );
    const componentRef = this.#overlayRef.attach(component);
    componentRef.instance.onClose.subscribe(() => this.#closeOverlay());
  }

  #getOptionsPositionStrategy() {
    return this.#overlay
    .position()
    .flexibleConnectedTo(document.body)
    .withLockedPosition(false)
    .withPush(true)
    .withPositions([
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
      },
    ]);
  }

  #closeOverlay() {
    this.#overlayRef.detach();
    this.#overlayRef = null;
  }

  @HostListener('window:resize')
  private onWindowResize() {
    this.#overlayRef?.updatePositionStrategy(this.#getOptionsPositionStrategy());
  }
}
