import { Dictionary } from 'typescript-collections';

export const ICONMENU: Dictionary<number, string> = (() => {
  const iconDefinitions = new Dictionary<number, string>();
  iconDefinitions.setValue(10, 'heroicons_solid:user');
  iconDefinitions.setValue(20, 'heroicons_solid:document-text');
  iconDefinitions.setValue(30, 'mat_solid:search');
  iconDefinitions.setValue(40, 'heroicons_solid:chart-bar-square');
  iconDefinitions.setValue(50, 'mat_solid:settings');
  iconDefinitions.setValue(70, 'heroicons_solid:book-open');
  iconDefinitions.setValue(280, 'mat_solid:settings');

  return iconDefinitions;
})();
