import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, of, take, tap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlResolver  {

  constructor(
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<MatDialogRef<any>> | Observable<any> {
    const data = route.data;
    const externalUrl = data['externalUrl'];

    window.open(externalUrl, '_self');
    return of();
  }
}
