import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  from,
  BehaviorSubject,
  lastValueFrom,
  map,
  Observable,
  of, Subject, switchMap,
  tap,
  throwError
} from 'rxjs';

import { NgxPermissionsService } from 'ngx-permissions';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { AuthCognitoService } from './auth-cognito.service';
import {
  deleteLegacyCookies,
  externalURLSamePageWithoutHistory,
  getAccessTokenPayloadInfo,
  getCookie,
  setCookie,
  signinLegacyApi, updateActionPlanAPIUrlCookie, updateAdministrationAPIUrlCookie,
  updateAuthenticationAPIUrlCookie,
  updateBookmarkAPIUrlCookie, updateChecklistAPIUrlCookie, updateCilAPIUrlCookie,
  updateCloudFrontKeyPairIdCookie,
  updateCloudFrontPolicyCookie,
  updateCloudFrontSignatureCookie, updateCommentManagerAPIUrlCookie, updateCommentsAPIUrlCookie,
  updateDateFormatCookie, updateDefectTagAPIUrlCookie,
  updateDocumentAPIUrlCookie,
  updateEnvironmentFullURLCookie,
  updateHashtagAPIUrlCookie, updateIdleTimeoutCookie,
  updateIntegrationAPITokenCookie,
  updateIntegrationAPIURLCookie,
  updateLanguageIdCookie, updateLastAccessCookie,
  updateLastSiteIdCookie,
  updateMultilingualAPIUrlCookie,
  updateSubtitleAPIUrlCookie,
  updateCopilotAPIUrlCookie,
  updateNotificationAPIUrlCookie, updatePraiseAPIUrlCookie,
  updateProductAPITokenCookie, updateReactionAPIUrlCookie,
  updateReloadSessionCookie,
  updateUserIdCookie
} from 'projects/lib-shared-common/src/public-api';
import { CloudfrontService } from "./legacy/cloudfront.service";
import { AuthStatus, UserStatusAuth } from './model/user-auth.model';
import { AcessSub, CognitoUser, EnvironmentInfo, EnvironmentUserInfo, SsoItem, TokenRefresh } from './model/environment.info.model';
import { AWS_DEFAULT_BACKGROUND_IMAGE } from '../Constants/AWS-IMAGES';
import { AWSAmplifyAuthResult } from './model/aws-amplify-auth-info';
import { User } from '../features/user/services/user.types';
import { AuthUtils } from '../../../../lib-shared-feature/src/lib/auth/auth.utils';
import { tryToRedirectToAspFromQueryParamData, tryToRedirectToCoreFromQueryParamData } from "./auth-functions";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'projects/environments/environment';
import { SvcDataCacheService } from '../services/cache/svc-data-cache.service';
import { ParameterService } from '../services/parameter/parameter.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  public signIn$ = new Subject<{ success: boolean }>();
  public signOut$ = new Subject<{ success: boolean }>();

  private _authenticated: boolean = false;
  private _temporaryUser: any;
  private _changePasswordRequired: boolean = false;
  private _emailToResetPassword: string;
  private _environmentURL: string;
  private _environmentInfo: EnvironmentInfo;
  private _userStatusAuth: UserStatusAuth = {} as UserStatusAuth;

  public solvaceLogo$ = new BehaviorSubject<string>('');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _permissionsService: NgxPermissionsService,
    private _authCognitoService: AuthCognitoService,
    private _parameterService: ParameterService,
    private _cloudfrontService: CloudfrontService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _dataCacheService: SvcDataCacheService
  ) {
    this._dataCacheService.initializeFromAuth(
      this.signIn$.asObservable(),
      this.signOut$.asObservable(),
    )
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    if (environment.isLocalhost) {
      localStorage.setItem('accessToken', token?.replace('\'\'', ''));
    }
    setCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C', token?.replace('\'\'', ''));
  }

  get accessToken(): string {
    if (environment.isLocalhost) return localStorage.getItem('accessToken');
    return (getCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C') || '').replace('\'\'', '');
  }

  get ssoRedirecting(): boolean {
    return !!localStorage.getItem('amplify-redirected-from-hosted-ui');
  }

  get environmentURL(): string {
    return this._environmentURL;
  }

  get userId(): string {
    return getAccessTokenPayloadInfo('UserId');
  }

  set userStatusAuth(userStatusAuth: UserStatusAuth) {
    this._userStatusAuth = userStatusAuth;
  }

  get userStatusAuth(): UserStatusAuth {
    return this._userStatusAuth;
  }

  set environmentURL(value: string) {
    if (value.startsWith('http://localhost:4200')) {
      this._environmentURL = 'https://devsp.solvacelabs.com';
      // this._environmentURL = 'https://qa.solvacelabs.com';
    } else {
      this._environmentURL = value;
    }
  }

  get passwordExpirationDays(): number {
    return Number.parseInt(localStorage.getItem('passwordExpirationDays')) || 90;
  }

  get changePasswordRequired(): boolean {
    return this._changePasswordRequired;
  }

  get environmentInfo(): EnvironmentInfo {
    return this._environmentInfo;
  }

  get needToRedirectToOldVersion() {
    const cookies = document.cookie.split(';').map((cookieStr) => {
      const splitedCookie = cookieStr.trim().split('=');
      return {
        name: splitedCookie[0],
        value: splitedCookie[1],
      };
    });
    const cookieName = `NEWVER-ACTIVE-{${this.userId}}`;
    for (const cookie of cookies) {
      if (cookie.name == cookieName) {
        return cookie.value === 'false';
      }
    }
    return true;
  }

  checkAuthenticatedProperty() {
    if (!this._authenticated) {
      this._authenticated = !!(this.accessToken);
    }
  }

  applyCongnitoConfig(env: EnvironmentInfo) {
    this._authCognitoService.applyCongnitoConfig(env);
  }

  ssoSignIn(sso: SsoItem) {
    this._authCognitoService.ssoSignIn(sso);
  }

  getCurrentUser(): Promise<CognitoUser | null> {
    return this._authCognitoService.getCurrentUser(this.environmentURL);
  }

  getCoverImage(): string {
    return `url(${this._environmentInfo?.backgroundImage || AWS_DEFAULT_BACKGROUND_IMAGE
      })`;
  }

  getIsGlobalUrl() {
    return localStorage.getItem('authFlowGlobal_environment') !== null;
  }

  postEnvironmentInfo(urlEnv?: string) {
    const url = `${this._appConfig.APIs.apiUrlAuth}/Environment/info`;
    return lastValueFrom(
      this._httpClient
        .post<EnvironmentInfo>(url, {
          environmentUrl: this.getEnvironmentUrlCurrent(urlEnv),
        })
        .pipe(
          tap((info) => {
            this._environmentInfo = info;
            this.solvaceLogo$.next(info.solvaceLogo);
          })
        )
    ).catch(() => {
      this._environmentInfo = null;
      return this._environmentInfo;
    })
  }

  postEnvironmentListByUserName(userName: string) {
    const url = `${this._appConfig.APIs.apiUrlAuth}/Environment/username`;

    return lastValueFrom(
      this._httpClient.post<EnvironmentUserInfo[]>(url, {
        userName: userName,
      })
    );
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get environment url current
   *
   * @param urlEnv
   */
  public getEnvironmentUrlCurrent(urlEnv: string): string {
    this.environmentURL =
      urlEnv ||
      document.location.origin;
    return this.environmentURL;
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Promise<any> {
    this._emailToResetPassword = email;
    return this._authCognitoService.sendVerificationCode(email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(code: string, password: string): Promise<any> {
    return this._authCognitoService.resetPasswordWithVerificationCode(
      code,
      this._emailToResetPassword,
      password
    );
  }

  changeTemporaryPassword(password: string): Promise<any> {
    return this._authCognitoService.changeTemporaryPassword(
      this._temporaryUser,
      password
    );
  }

  changePassword(oldPassword: string, newPassword): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.postEnvironmentInfo()
        .then((c) => {
          this.applyCongnitoConfig(c);
          this._authCognitoService.getCurrentAuthenticatedUser()
            .then((user) => {
              this._authCognitoService.changePassword(user, oldPassword, newPassword)
                .then(() => resolve())
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(
    credentials: {
      user: string;
      password: string;
    },
    urlEnv: string
  ): Promise<UserStatusAuth> {
    if (this._authenticated) {
      throwError(() => new Error('User is already logged in.'));
    }

    return this._authCognitoService
      .signIn(credentials)
      .then((response) => {
        if (response.result === AWSAmplifyAuthResult.Success) {
          return this.loginValidate(urlEnv);
        }
        else if (response.result === AWSAmplifyAuthResult.NewPasswordRequired) {
          this._temporaryUser = response.currentUser;
          this._changePasswordRequired = true;
          return {
            user: null,
            status: AuthStatus.TemporaryPasswordUser
          };
        }
      });
  }

  signInCurrentUser(urlEnv: string): Promise<UserStatusAuth> {
    return this.loginValidate(urlEnv);
  }

  private loginValidate(urlEnv: string) {
    return new Promise<UserStatusAuth>(async (resolve, reject) => {
      try {
        const userStatus = {} as UserStatusAuth;
        userStatus.user = await this.getCurrentUser();

        const sub = await this.postLoginSub(urlEnv, userStatus.user.ssoId);

        if (sub) {
          // Store the access token in the local storage
          this.accessToken = sub.accessToken;

          // Set and store user roles
          const roles = ['ADMIN'];
          this._permissionsService.loadPermissions(roles);
          localStorage.setItem('roles', JSON.stringify(roles));

          // Set the authenticated flag to true
          this._authenticated = true;

          // Set user status
          userStatus.status = await this.getUserStatusAuth();
          this.signIn$.next({ success: true });
        } else {
          // Set user status
          userStatus.status = AuthStatus.NotFoundUser;
        }

        this.userStatusAuth = userStatus;
        resolve(userStatus);
      } catch (err) {
        reject(null);
      }
    });
  }

  private postLoginSub(urlEnv: string, subId: string): Promise<AcessSub> {
    const url = `${this._appConfig.APIs.apiUrlAuth}/Login/sub`;

    return lastValueFrom(
      this._httpClient.post<AcessSub>(url, {
        sub: subId,
        environmentUrl: urlEnv,
      })
    ).catch((err) => {
      if (err instanceof HttpErrorResponse && err.status === 400)
        return null;
      else
        throw new Error(err);
    });
  }

  getUserStatusAuth(): Promise<AuthStatus> {
    const url = `${this._appConfig.APIs.apiUrlUsers}/User/status`;
    return lastValueFrom(
      this._httpClient.get<{ status: number, description: string }>(url).pipe(
        map((response) => {
          this._userStatusAuth.status = response.status;
          return response.status;
        })
      )
    ).catch(() => null);
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<any> {
    return of(true); //TODO: remove this line to enable token refresh

    const url = `${this._appConfig.APIs.apiUrlAuth}/Token/refresh`;

    // Sign in using the token
    return this._httpClient.get<TokenRefresh>(url).pipe(
      map((response) => {
        // Replace the access token with the new one
        this.accessToken = response.refreshToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user permission
        this._permissionsService.loadPermissions(
          JSON.parse(localStorage.getItem('roles'))
        );

        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(forceClear?: boolean): Observable<any> {
    // Clear local storage
    if (!forceClear && environment.isLocalhost) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiredToken');
    }
    else if (forceClear) {
      localStorage.clear();
    }

    //remove all legacy cookies
    deleteLegacyCookies();

    //clear permissions
    this._permissionsService.flushPermissions();

    // Set the authenticated flag to false
    this._authenticated = false;

    if (typeof (window as any).AppLogoffCall != 'undefined') {
      (window as any).AppLogoffCall.postMessage('<function></function>');
    }

    this.signOut$.next({ success: true });
    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {

    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingRefreshToken();
  }

  doValidatedUserRedirecting(user: User, userStatus: UserStatusAuth) {
    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
    const redirectAsp = this._activatedRoute.snapshot.queryParamMap.get('redirectAsp');
    const redirectCore = this._activatedRoute.snapshot.queryParamMap.get('redirectCore');

    if (userStatus.status !== AuthStatus.SupplierUser &&
      userStatus.status !== AuthStatus.ContractorUser &&
      userStatus.status !== AuthStatus.EmployeeUser
    )
      this._router.navigate(['/sign-out']);

    // Generate legacy support cookies
    from(this.signInLegacySupport(user)).subscribe(() => {
      if (userStatus.status === AuthStatus.SupplierUser) {
        externalURLSamePageWithoutHistory(`${userStatus.user.baseUrl}/suppliermanagement`, ``);
        return;
      }

      if (userStatus.status === AuthStatus.ContractorUser) {
        externalURLSamePageWithoutHistory(`${userStatus.user.baseUrl}/contractormanagement`, ``);
        return;
      }

      // Navigate to the redirect url (angular to angular)
      if (!tryToRedirectToAspFromQueryParamData(redirectAsp) && !tryToRedirectToCoreFromQueryParamData(redirectCore)) {
        this._router.navigateByUrl(redirectURL);
      }
    });
  }

  async signInLegacySupport(user: User) {

    //TODO: remove this function when no legacy support needed (ASP + netcore)

    //environment
    updateEnvironmentFullURLCookie(this._environmentInfo.url);

    //user preferences
    updateLastSiteIdCookie(user.lastSiteId);
    updateDateFormatCookie(user.dateFormat);
    updateLanguageIdCookie(user.languageId);
    updateUserIdCookie(this.userId);
    updateReloadSessionCookie('True');

    //product APIs
    updateProductAPITokenCookie(this.accessToken);
    updateBookmarkAPIUrlCookie(this._appConfig.APIs.apiUrlBookmark);
    updateDocumentAPIUrlCookie(this._appConfig.APIs.apiUrlDocuments);
    updateNotificationAPIUrlCookie(this._appConfig.APIs.apiUrlNotification);
    updateAuthenticationAPIUrlCookie(this._appConfig.APIs.apiUrlAuth);
    updateHashtagAPIUrlCookie(this._appConfig.APIs.apiUrlHashtag);
    updatePraiseAPIUrlCookie(this._appConfig.APIs.apiUrlPraise);
    updateReactionAPIUrlCookie(this._appConfig.APIs.apiUrlReaction);
    updateCommentsAPIUrlCookie(this._appConfig.APIs.apiUrlComments);
    updateActionPlanAPIUrlCookie(this._appConfig.APIs.apiUrlActionPlan);
    updateChecklistAPIUrlCookie(this._appConfig.APIs.apiUrlChecklist);
    updateCilAPIUrlCookie(this._appConfig.APIs.apiUrlCil);
    updateDefectTagAPIUrlCookie(this._appConfig.APIs.apiUrlDefectTag);
    updateAdministrationAPIUrlCookie(this._appConfig.APIs.apiUrlAdministration);
    updateCommentManagerAPIUrlCookie(this._appConfig.APIs.apiUrlCommentManager);
    updateMultilingualAPIUrlCookie(this._appConfig.APIs.apiUrlMultilingual);
    updateSubtitleAPIUrlCookie(this._appConfig.APIs.apiUrlSubtitle);
    updateCopilotAPIUrlCookie(this._appConfig.APIs.apiUrlCopilot);
    
    //cloudfront signed cookies
    const signedCookies = await lastValueFrom(this._cloudfrontService.getSignedCookies());
    updateCloudFrontPolicyCookie(signedCookies.policy, signedCookies.expiryDate, signedCookies.domain);
    updateCloudFrontSignatureCookie(signedCookies.signature, signedCookies.expiryDate, signedCookies.domain);
    updateCloudFrontKeyPairIdCookie(signedCookies.keyPairId, signedCookies.expiryDate, signedCookies.domain);

    const integrationToken = await lastValueFrom(this._parameterService.getGlobalParams(['IDLE_TIMEOUT', 'API_URL'], { cache: false }).pipe(
      switchMap((response) => {
        //idle timeout controller
        const idleTimeout = response.find((item) => item.key === 'IDLE_TIMEOUT')?.value ?? '';
        updateIdleTimeoutCookie(idleTimeout);
        updateLastAccessCookie(new Date());

        //integration APIs
        const apiUrl = response.find((item) => item.key === 'API_URL')?.value ?? '';
        updateIntegrationAPIURLCookie(apiUrl);
        if (environment.isLocalhost) {
          return of({ token: 'EMPTY_VALUE' });
        }
        return signinLegacyApi(
          this._httpClient,
          this._userStatusAuth.user.baseUrl,
          this._userStatusAuth.user.ssoId,
        );
      }),
    ));
    updateIntegrationAPITokenCookie(integrationToken.token);
  }

  async checkUserExpiredPassword(): Promise<{ status: 'expired' | 'expiring' | null, days?: number }> {
    const date = await this.getPasswordChangeDate();
    if (!date) {
      await this.setPasswordChangeDate();
      return { status: null };
    }
    const remainingDays = this.passwordExpirationDays - Math.ceil((new Date().getTime() - new Date(Number.parseInt(date.Value) * 1000).getTime()) / (1000 * 60 * 60 * 24));
    return {
      status: remainingDays <= 10 && remainingDays > 0 ? 'expiring' :
        remainingDays <= 0 ? 'expired' : null,
      days: remainingDays
    };
  }

  getExpiredToken() {
    return localStorage.getItem('expiredToken') === 'true';
  }

  setExpiredToken() {
    localStorage.setItem('expiredToken', 'true');
  }

  removeExpiredToken() {
    localStorage.removeItem('expiredToken');
  }

  async getPasswordChangeDate() {
    return await this._authCognitoService.getUserAttribute('custom:lastUpdatePassword');
  }

  async setPasswordChangeDate() {
    await this._authCognitoService.setUserAttribute('custom:lastUpdatePassword', Math.floor(new Date().getTime() / 1000).toString());
  }
}
