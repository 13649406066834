import { Directive, ElementRef, HostBinding, Inject, Input, forwardRef } from "@angular/core";
import { SvcBoardMode, SvcElementsBoardComponent } from "./svc-elements-board.component";
import { AutoDestroy } from "projects/lib-shared-common/src/lib/decorators/auto-destroy";
import { Subject, takeUntil, tap } from "rxjs";

@Directive({
  selector: '[svc-elements-board-item]',
})
export class SvcElementsBoardItemDirective {

  @HostBinding('class.svc-elements-board-item') private _class = true;
  @HostBinding('class.in-edit-mode') private _inEditMode = false;
  @HostBinding('class.is-moving') private _isMoving = false;
  @HostBinding('style.z-index') private _zIndex = null;
  @HostBinding('style.top.px') private _y = 0;
  @HostBinding('style.left.px') private _x = 0;

  @Input() set positionX(value: number) {
    this._x = value ?? 0;
  }
  @Input() set positionY(value: number) {
    this._y = value ?? 0;
  }
  
  @AutoDestroy
  destroy$: Subject<void> = new Subject<void>();

  public get y() { return this._y; }
  public get x() { return this._x; }
  public get viewportSize() { return this.board.viewportSize }

  public get element(): HTMLElement {
    return this.elementRef?.nativeElement;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Inject(forwardRef(() => SvcElementsBoardComponent))
    public board: SvcElementsBoardComponent,
  ) {
    this.board.mode$.pipe(
      takeUntil(this.destroy$),
      tap((mode) => {
        if (mode === SvcBoardMode.EDITING) {
          this._startEditMode();
        }
        else {
          this._endEditMode();
        }
      })
    ).subscribe();
  }

  public setPosition(position: { x: number, y: number }) {
    this._x = Math.max(position?.x ?? 0, 0);
    this._y = Math.max(position?.y ?? 0, 0);
    this.board?.checkBoardInnerSize();
  }

  public getZindex() {
    return this._zIndex;
  }

  public setZindex(zIndex: number) {
    this._zIndex = zIndex;
  }

  public positionToTheCenter() {
    const boardWidth = this.board.viewportSize.width;
    const boardHeight = this.board.viewportSize.height;
    this._x = Math.max((boardWidth - (this.element?.clientWidth ?? 0)) / 2, 0);
    this._y = Math.max((boardHeight - (this.element?.clientHeight ?? 0)) / 2, 0);
  }

  public startMoving() {
    this._isMoving = true;
  }
  public stopMoving() {
    this._isMoving = false;
  }

  private _startEditMode(){
    this._inEditMode = true;
    this._isMoving = false;
  }
  
  private _endEditMode(){
    this._inEditMode = false;
    this._isMoving = false;
  }
}